import http from "../http-common";

class PaymentCaseDataService {
  createPaymentCase(data) {
    return http.post('/payment_case', data);
  }
  deletePaymentCases(id) {
    return http.delete(`/payment_case/`+id);
  }
}

export default new PaymentCaseDataService();