import { render, staticRenderFns } from "./Setting-Privilege.vue?vue&type=template&id=001b77d8&scoped=true&"
import script from "./Setting-Privilege.vue?vue&type=script&lang=js&"
export * from "./Setting-Privilege.vue?vue&type=script&lang=js&"
import style0 from "./Setting-Privilege.vue?vue&type=style&index=0&id=001b77d8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "001b77d8",
  null
  
)

export default component.exports