<template>
  <div class="hello mt-3">
    <table class="table table-bordered">
      <thead>
        <tr>
          <th scope="col">ชื่อ-นามสกุล</th>
          <th scope="col">เลขบัตรประชาชน</th>
          <th scope="col" style="text-align: right">
           <button :disabled="cases.is_edit == 1" class="btn btn-success"> <a
              data-toggle="modal"
              data-target="#addModalRelative"
              @click="addRelative(0)" 
              >เพิ่ม</a
            ></button>
          </th>
        </tr>
      </thead>
      <tbody v-if="list.length > 0">
        <tr v-for="l in list" :key="l.relative_id">
          <td>{{ l.firstname }} {{ l.lastname }}</td>
          <td>{{ l.id_card_relative }}</td>
          <td style="text-align: right">
            
              <button :disabled="cases.is_edit == 1" class="btn btn-warning" style="color: white">
                <a         
                style="color:white"   
              data-toggle="modal"
              href="#"
              data-target="#addModalRelative"
              @click="addRelative(l.relative_id)"
            >แก้ไข</a>
              </button>
            &nbsp;
            <button
              class="btn btn-danger"
              :disabled="cases.is_edit == 1"
              @click="deleteRelative(l.relative_id)"
            >
              ลบ
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="list.length == 0" style="text-align: center">ไม่พบข้อมูล</div>

    <!-- Modal -->
    <div
      class="modal fade"
      id="addModalRelative"
      tabindex="-1"
      role="dialog"
      aria-labelledby="addModalRelativeLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <h5>ข้อมูลผู้ที่เกี่ยวข้อง</h5>
            </div>
            <div class="row">
              <div class="col-md-6 mt-2">
                <div class="form-group" style="text-align: left">
                  <label for="username"
                    >เลขบัตรประชาชน <span style="color: red"> *</span></label
                  >
                  <input
                    v-model="relative.id_card_relative"
                    @keypress="onlyNumber"
                    type="number"
                    min="1"
                    class="form-control"
                    id="username"
                    placeholder="เลขบัตรประชาชน 13 หลัก"
                  />
                </div>
              </div>
              <div class="col-md-8"></div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <div class="form-group" style="text-align: left">
                  <label for="province"
                    >คำนำหน้า <span style="color: red"> *</span></label
                  >
                  <select
            id="prefix"
            class="form-select mb-3"
            v-model="relative.prefix"
          >
            <option
              v-for="(s, i) in prefix"
              v-bind:value="s.prefix_id"
              :key="i + 1"
            >
              {{ s.name }}
            </option>
          </select>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group" style="text-align: left">
                  <label for="username"
                    >ชื่อ <span style="color: red"> *</span></label
                  >
                  <input
                    v-model="relative.firstname"
                    type="text"
                    min="1"
                    class="form-control"
                    id="firstname"
                    placeholder="ชื่อ"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group" style="text-align: left">
                  <label for="username"
                    >นามสกุล <span style="color: red"> *</span></label
                  >
                  <input
                    v-model="relative.lastname"
                    type="text"
                    min="1"
                    class="form-control"
                    id="lastname"
                    placeholder="นามสกุล"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group" style="text-align: left">
                  <label for="username"
                    >เบอร์โทรศัพท์ <span style="color: red"> *</span></label
                  >
                  <input
                    v-model="relative.phone"
                    type="number"
                    min="1"
                    class="form-control"
                    id="username"
                    placeholder="เบอร์โทรศัพท์"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group" style="text-align: left">
                  <label for="username"
                    >ไอดีไลน์ <span style="color: red"> *</span></label
                  >
                  <input
                    v-model="relative.line_id"
                    type="text"
                    min="1"
                    class="form-control"
                    id="birthday"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <!-- <div class="col-md-4">
                <div class="form-group" style="text-align: left">
                  <label for="username"
                    >เลือกพิกัด <span style="color: red"> *</span></label
                  >
                  <input
                    type="text"
                    min="1"
                    class="form-control"
                    id="lastname"
                    placeholder=""
                  />
                </div>
              </div> -->
              <div class="col-md-4">
                <div class="form-group" style="text-align: left">
                  <label for="province"
                    >ความสัมพันธ์ <span style="color: red"> *</span></label
                  >
                  <select
                    class="form-select mb-3"
                    v-model="relative.relationship"
                  >
                  <option value="ญาติใกล้ชิด">ญาติใกล้ชิด</option>
                    <option value="ผู้ใหญ่บ้าน">ผู้นำในพื้นที่</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <GmapMap
    :center="center"
    :zoom="15"
    map-style-id="roadmap"
    style="width: 70vmin; height: 50vmin"
    ref="mapRef"
    @click="handleMapClick"
  >
    <GmapMarker
      :position="marker.position"
      :clickable="true"
      :draggable="true"
      @drag="handleMarkerDrag"
      @click="panToMarker"
    />
  </GmapMap>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group" style="text-align: left">
                  <label for="username"
                    >ลองจิจูด </label
                  >
                  <input
                    v-model="address.longitude_house"
                    type="text"
                    class="form-control"
                    id="vilegeno_current"
                    placeholder=""
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group" style="text-align: left">
                  <label for="username"
                    >ละติจูด </label
                  >
                  <input
                    v-model="address.lattitude_house"
                    type="text"
                    min="0"
                    class="form-control"
                    id="income"
                    placeholder=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="bd-example mb-5">
            <button
              type="button"
              style="margin-right: 20px; width: 150px"
              class="btn btn-success"
              @click="saveRelative()"
            >
              บันทึก
            </button>
            <button
              type="button"
              id="closed"
              class="btn btn-danger"
              style="margin-right: 20px; width: 150px"
              data-dismiss="modal"
            >
              ยกเลิก
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RelativesDataService from "../../services/RelativesDataService.js";
import SuspectsRelativeDataService from "../../services/SuspectsRelativeDataService";
import CasesDataService from "../../services/CasesDataService";
import AddressDataService from "../../services/AddressDataService";
import RelativesAddressDataService from "../../services/RelativesAddressDataService";
import PrefixDataService from "../../services/PrefixDataService";

export default {
  data() {
    return {
      name: "HelloWorld",
      relative: [],
      case_id: "",
      suspect_id: "",
      list: [],
      relative_id: 0,
      address: [],
      cases:[],
      marker: { position: { lat: 6.5295263,
          lng: 101.2771295, } },
      center: { lat: 6.5295263,
          lng: 101.2771295, },
      prefix:[]

    };
  },
  mounted() {
    this.case_id = this.$route.params.id;
    // console.log(this.case_id);
    CasesDataService.getCase(this.case_id).then((res) => {
      this.cases = res.data
      this.suspect_id = res.data.suspect_id;
      // console.log(this.suspect_id);
      this.getralative();
    this.geolocate();
    this.getPrefix()
    });
  },
  methods: {   
    getPrefix(){
      PrefixDataService.getPrefixs().then((res) => {
        this.prefix = res.data;
        // console.log(res.data);
      });
    }, 
    geolocate() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        console.log(position.coords);
        if(this.address.length == 0){
this.marker.position = {
          lat: 6.5295263,
          lng: 101.2771295,
        };
        }else{
          this.marker.position = {
          lat: this.address.lattitude_house,
          lng: this.address.longitude_house,
        };
        }
        this.panToMarker();
      });
    },

    //sets the position of marker when dragged
    handleMarkerDrag(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    },

    //Moves the map view port to marker
    panToMarker() {
      this.$refs.mapRef.panTo(this.marker.position);
      // this.$refs.mapRef.setZoom(18);
    },

    //Moves the marker to click position on the map
    handleMapClick(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
this.address.longitude_house = this.marker.position.lng
this.address.lattitude_house = this.marker.position.lat
    },
    getralative() {
      SuspectsRelativeDataService.getRelativeBySuspect(this.suspect_id).then(
        (res) => {
          // console.log(res.data);
          this.list = res.data;
          if (this.list.length == 0) {
            // setTimeout(function () {
            //   location.reload();
            // }, 300);
          }
          // console.log(this.list);
        }
      );
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (
        keyCode < 48 ||
        keyCode > 57 ||
        this.relative.id_card_relative.length == 13
      ) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    addRelative(id) {
      if (id != 0) {
        // console.log(id);
        this.relative_id = id;
        RelativesDataService.getRelative(this.relative_id).then((res) => {
          this.relative = res.data;
          RelativesDataService.getRelativeAddress(this.relative_id).then(
            (res) => {
              this.address = res.data;
      this.geolocate()
            }
          );
        });
      } else {
        this.relative_id = 0;
        this.relative = [];
        this.address = []
      this.geolocate()
      }      
    },
    saveRelative() {
      if (this.relative.id_card_relative == null) {
        alert("กรุณากรอกหมายเลขบัตรประชาชน");
      } else if (this.relative.id_card_relative.length < 13) {
        alert("กรุณากรอกหมายเลขบัตรประชาชนให้ครบ");
      } else if (this.relative.prefix == null) {
        alert("กรุณาเลือกคำนำหน้าชื่อ");
      } else if (this.relative.firstname == null) {
        alert("กรุณากรอกชื่อ");
      } else if (this.relative.lastname == null) {
        alert("กรุณากรอกนามสกุล");
      } else if (this.relative.phone == null) {
        alert("กรุณากรอกเบอร์โทรศัพท์");
      } else if (this.relative.phone.length != 10) {
        alert("กรุณากรอกเบอร์โทรศัพท์ให้ครบ 10 หลัก");
      } else if (this.relative.line_id == null) {
        alert("กรุณากรอกไอดีไลน์");
      } else if (this.relative.relationship == null) {
        alert("กรุณาเลือกความสัมพันธ์");
      } else {
        var relative = {
          relative_type_id: 1,
          id_card_relative: this.relative.id_card_relative,
          prefix: this.relative.prefix,
          firstname: this.relative.firstname,
          lastname: this.relative.lastname,
          phone: this.relative.phone,
          line_id: this.relative.line_id,
          relationship: this.relative.relationship,
          created_by: this.currentUser.user_id,
          updated_by: this.currentUser.user_id,
        };
        // console.log(relative);
        var address = {
          longitude_house: this.address.longitude_house,
          lattitude_house: this.address.lattitude_house,
          created_by: this.currentUser.user_id,
          updated_by: this.currentUser.user_id,
        };
        if (this.relative_id != 0) {
          RelativesDataService.updateRelative(this.relative_id, relative).then(
            () => {
              // console.log(res.data);
              AddressDataService.updateAddress(
                this.address.address_id,
                address
              ).then(() => {
                // console.log(res.data);
              });
              this.getralative();
              document.getElementById("closed").click();
                    setTimeout(function () {
                location.reload();
              }, 300);
            }
          );
        } else {
          // console.log(relative);
          AddressDataService.createAddress(address).then((res) => {
                var address_id = res.data.id;
                // console.log(res.data);
                RelativesDataService.createRelative(relative).then((res) => {
            // console.log(res.data);
            var relative_id = res.data.id;
            var suspectrelative = {
              suspect_id: this.suspect_id,
              relative_id: relative_id,
              created_by: this.currentUser.user_id,
              updated_by: this.currentUser.user_id,
            };
             SuspectsRelativeDataService.createSuspectRelative(
              suspectrelative
            ).then(() => {
            });
                var relativeaddress = {
                  relative_id: relative_id,
                  address_id: address_id,
                  created_by: this.currentUser.user_id,
                  updated_by: this.currentUser.user_id,
                };
                // console.log(relativeaddress);
                RelativesAddressDataService.createRelativeAddress(
                  relativeaddress
                ).then(() => {        

          
              this.getralative();
              document.getElementById("closed").click();
                });
              });
                    setTimeout(function () {
                location.reload();
              }, 1000);
          });
        }
      }
    },
    deleteRelative(id) {
      RelativesAddressDataService.deleteRelativeAddress(
          id
        ).then(() => {
          if (this.list.length == 1) {
                  this.list = [];
                }
                this.getralative();
        });
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  font-family: "Kanit" !important;
}
.row {
  max-width: 1200px;
  margin: auto;
  background: white;
  padding: 10px;
  color: black !important;
}
.nav-link {
  color: black !important;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #09cc68;
}
.modal-content {
  float: center;
  text-align: center;
  width: 600px;
}
.modal {
  text-align: center;
  padding: 0 !important;
}
.modal:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}
.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  max-width: 600px;
}
</style>
