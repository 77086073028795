<template>
  <div class="hello">
    <div class="mt-5">
      <div class="row card mt-2">
        <h4 style="text-align: center">ค้นหา</h4>
        <div class="row">
          <div class="col-md-1"></div>
          <div class="col-md-5">
            <div class="form-group" style="text-align: left">
              <label for="province">เลขบัตรประชาชนผู้เสียหาย </label>
              <input class="form-control mb-3" v-model="event.id_card" />
            </div>
          </div>
          <div class="col-md-5">
            <div class="form-group" style="text-align: left">
              <label for="username"
                >วันที่ยื่นคำร้อง <span style="color: red"> *</span></label
              >
              <input id="fill_start_date" class="form-control" />
              <!-- <date-picker v-model="date" :config="options"></date-picker> -->
            </div>
          </div>
          <div class="col-md-1"></div>
        </div>
         <!-- <div class="row">
           <div class="col-md-1"></div>
          <div class="col-md-5">
            <div class="form-group" style="text-align: left">
              <label for="province">สถานะ </label>
               <multiselect 
    select-Label=""
    selected-Label=""
    deselect-Label=""
    v-model="value" 
    :options="status"
    :multiple="true"
    track-by="name"
    :custom-label="customLabel"
    :close-on-select="false"
    @select=onSelect($event)
    @remove=onRemove($event)
    >
    <span class="checkbox-label" slot="option" slot-scope="scope" @click.self="select(scope.option)">
    {{ scope.option.name }}
      <input class="test" type="checkbox" v-model="scope.option.checked" @focus.prevent/>
      
    </span>
  </multiselect>
            </div>
          </div>
        </div> -->
        <div class="col text-center">
          <button
            @click="search()"
            class="btn btn-primary mb-3"
            style="color: white"
          >
            <i class="fas fa-search"></i> ค้นหา
          </button>
        </div>
      </div>
    </div>
    <div class="row mt-5" v-if="nodata">
      <div class="mb-3" style="text-align: right">
            <button
              class="btn btn-success"
              style="color: white"
              @click="exportTableToExcel('tblData')"
            >
              Export
            </button>
          </div>
      <div class="card-body p-0">
        <div class="row">
          <div class="col-md-12 mt-3">
            <h5 class="form-group" style="color: #2da3f2; font-weight: bold">
              {{ typename }}
            </h5>
          </div>
        </div>
        <div class="row">
          <table class="table table-bordered" id="tblData">
            <thead>
               <tr>
                  <td style="text-align: center; background-color: #d9d9d9"
                  colspan="4">
                    รายงานประวัติการแก้ไขข้อมูลตามวันที่แก้ไข
                  </td>
                </tr>
                <tr>
                  <td style="text-align: center; background-color: #d9d9d9"
                  colspan="4">
                    วันที่ยื่นคำร้อง {{ fillstart }}
                  </td>
                </tr>
                <tr>
                  <td style="text-align: center; background-color: #d9d9d9"
                  colspan="4">
                    เลขบัตรประชาชนผู้เสียหาย {{ event.id_card }}
                  </td>
                </tr>
                 <tr>
                  <td style="text-align: center; background-color: #d9d9d9"
                  colspan="4">
                     {{ event.firstname }} {{ event.lastname }}
                  </td>
                </tr>
              <tr>
                <th scope="col" style="text-align:center">ลำดับ</th>
                <th scope="col" style="text-align:center">รายละเอียดการแก้ไข</th>
                <th scope="col" style="text-align:center">วันที่แก้ไข</th>
                <th scope="col" style="text-align:center">ผู้แก้ไข</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(l, i) in list" :key="'L' + i">
                <td scope="row" style="text-align:right">{{ i + 1 }}</td>
                <td style="text-align:left">{{ l.name }}</td>
                <td style="text-align:right">{{ convertdatetothai(l.updated_date) }}</td>
                <td style="text-align:left">{{ l.username }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- <div class="row card mt-5" v-if="nodata">
        <div class="card-body p-0">
          <div class="row">
            <div class="col-md-12 mt-3">
              <h5
                class="form-group"
                style="text-align: center; color: #2da3f2; font-weight: bold"
              >
                ไม่พบข้อมูล
              </h5>
            </div>
          </div>
        </div>
      </div>       -->
    </div>
    <div class="row mt-5">
      <div class="col text-center">
        <button class="btn btn-warning" style="color: white" @click="back()">
          ย้อนกลับ
        </button>
      </div>
    </div>
  </div>
</template>

  <script src="https://justice-api.sbpac.go.th/picker_date.js"></script>

<script>
const customLabels = {
  first: "<<",
  last: ">>",
  previous: "<",
  next: ">",
};

import ConvertService from "../../services/ConvertService";
import CasesEventDataService from "../../services/CasesEventDataService";
import ProvinceDataService from "../../services/ProvinceDataService";
import AmphurDataService from "../../services/AmphurDataService";
import DistrictDataService from "../../services/DistrictDataService";
import EventTypesDataService from "../../services/EventTypesDataService.js";
import ReportDataService from "../../services/ReportDataService";
import DamagesDataService from "../../services/DamagesDataService";
import StatusDataService from "../../services/StatusDataService.js";
import SuspectsDataService from "../../services/SuspectsDataService";

export default {
  components: {},
  data() {
    return {
      name: "HelloWorld",
      case_id: 0,
      id_card: "",
      columns: [],
      data: [],
      list: [],
      status: [],
      nodata: false,
      event: {},
      provinces: [],
      amphures: [],
      districts: [],
      event_type: [],
      chartData: {},
      plugins: [],
      series: [],
      chartOptions: {},
      month: [],
      fillstart: "",
      fillend: "",
      type: 2,
      typename: "",
      statusone: false,
      statustwo: false,
      statusthree: false,
      statusfour: false,
      statusfive: false,
      statussix: false,
      statusseven: false,
      statuseight: false,
      statusnine: false,
      statusten: false,
      filename: "",
      value: [],
    };
  },
  mounted() {
    var year = new Date().getFullYear()
    var start = year-2004
    var before = "-"+start+":+1"
    picker_date(document.getElementById("fill_start_date"), {
      year_range: before,
    });
    this.getProvince();
    this.getAmphur("");
    this.getDistrict("");
    this.geteventtype();
    this.getstatus();
  },
  methods: {
    customLabel (option) {
      return `${option.name}`
    },
    onSelect (option) {
      let index = this.status.findIndex(item => item.name==option.name);
      this.status[index].checked = true;
    },    
    onRemove (option) {
      let index = this.status.findIndex(item => item.name==option.name);
      this.status[index].checked = false;
      // console.log(option.name + "  Removed!! " + option.checked);
    },
    exportTableToExcel(tableID, filename = "") {
      var downloadLink;
      var dataType = "application/vnd.ms-excel";
      var tableSelect = document.getElementById(tableID);
      var tableHTML = tableSelect.outerHTML.replace(/ /g, "%20");

var file = "รายงานประวัติการแก้ไขข้อมูล.xls"
      
      // Specify file name
      filename = filename
        ? filename + ".xls"
        : file;

      // Create download link element
      downloadLink = document.createElement("a");

      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        var blob = new Blob(["\ufeff", tableHTML], {
          type: dataType,
        });
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Create a link to the file
        downloadLink.href = "data:" + dataType + ", " + tableHTML;

        // Setting the file name
        downloadLink.download = filename;

        //triggering the function
        downloadLink.click();
      }
    },
    getstatus() {
      StatusDataService.getStatus().then((res) => {
        // console.log(res.data);
        for (let s = 0; s < res.data.length; s++) {
          this.status.push({
            status_id: res.data[s].status_id,
            name: res.data[s].name,
            checked:true
          });
        }
      });
      this.value = this.status
      },
    exportexcel() {
      this.generateexcel();
      document.getElementById("excel").click();
    },
    generateexcel() {
      this.data = [];
      this.data = this.list;
      // console.log(this.columns);
      // console.log(this.data);
    },
    getprivil() {
      DamagesDataService.getDamagesAll().then((res) => {
        // console.log(res.data);
        this.privileged = res.data;
        this.generatecolumn();
        for (let p = 0; p < this.privileged.length; p++) {
          if (this.privileged[p].damage_type_id == 1) {
            this.statusone = true;
          } else if (this.privileged[p].damage_type_id == 2) {
            this.statustwo = true;
          } else if (this.privileged[p].damage_type_id == 3) {
            this.statusthree = true;
          } else if (this.privileged[p].damage_type_id == 4) {
            this.statusfour = true;
          } else if (this.privileged[p].damage_type_id == 5) {
            this.statusfive = true;
          } else if (this.privileged[p].damage_type_id == 6) {
            this.statussix = true;
          } else if (this.privileged[p].damage_type_id == 7) {
            this.statusseven = true;
          } else if (this.privileged[p].damage_type_id == 8) {
            this.statuseight = true;
          } else if (this.privileged[p].damage_type_id == 9) {
            this.statusnine = true;
          } else if (this.privileged[p].damage_type_id == 10) {
            this.statusten = true;
          }
        }
      });
    },
    generatecolumn() {
      this.columns = [];
      this.columns.push(
        {
          label: "ลำดับที่",
          field: "no",
          align: "center",
        },
        {
          label: "วันที่ยื่น",
          field: "filing_date",
        },
        {
          label: "เลขบัตรประจำตัวประชาชน",
          field: "id_card",
        },
        {
          label: "คำนำหน้า",
          field: "prefix",
        },
        {
          label: "ชื่อ-สกุล",
          field: "name",
        },
        {
          label: "วัน/เดือน/ปีเกิด",
          field: "birthday",
        },
        {
          label: "เบอร์โทร",
          field: "phone",
        },
        {
          label: "เพศ",
          field: "gender",
        },
        {
          label: "ประเภทกลุ่มผู้เสียหาย",
          field: "suspect_types",
        },
        {
          label: "อาชีพ",
          field: "career",
        },
        {
          label: "รายได้",
          field: "income",
        },
        {
          label: "สัญชาติ",
          field: "nationality",
        },
        {
          label: "บ้านเลขที่",
          field: "no_house",
        },
        {
          label: "หมู่ที่",
          field: "village_no_house",
        },
        {
          label: "ตำบล",
          field: "sub_district_house",
        },
        {
          label: "อำเภอ",
          field: "district_house",
        },
        {
          label: "จังหวัด",
          field: "province_house",
        },
        {
          label: "รหัสไปรษณีย์",
          field: "postal_code_house",
        },
        {
          label: "บ้านเลขที่",
          field: "no_current",
        },
        {
          label: "หมู่ที่",
          field: "village_no_current",
        },
        {
          label: "ตำบล",
          field: "sub_district_current",
        },
        {
          label: "อำเภอ",
          field: "district_current",
        },
        {
          label: "จังหวัด",
          field: "province_current",
        },
        {
          label: "รหัสไปรษณีย์",
          field: "postal_code_current",
        },
        {
          label: "ลักษณะเหตุการณ์",
          field: "event_type_name",
        },
        {
          label: "วันที่ควบคุมตัว",
          field: "detained_start_date",
        },
        {
          label: "วันที่ปล่อยตัว",
          field: "detained_end_date",
        },
        {
          label: "จำนวนวัน",
          field: "day",
        },
        {
          label: "หน่วยงานที่ควบคุมตัว",
          field: "department_detained_type_name",
        },
        {
          label: "สถานที่",
          field: "events",
        },
        {
          label: "วันที่เกิดเหตุการณ์",
          field: "crime_date",
        },
        {
          label: "จุดเกิดเหคุการณ์",
          field: "crime_scene",
        }
      );
      for (let p = 0; p < this.privileged.length; p++) {
        var fieldname = "";
        if (this.privileged[p].damage_type_id == 1) {
          fieldname = "one";
        }
        if (this.privileged[p].damage_type_id == 2) {
          fieldname = "two";
        }
        if (this.privileged[p].damage_type_id == 3) {
          fieldname = "three";
        }
        if (this.privileged[p].damage_type_id == 4) {
          fieldname = "four";
        }
        if (this.privileged[p].damage_type_id == 5) {
          fieldname = "five";
        }
        if (this.privileged[p].damage_type_id == 6) {
          fieldname = "six";
        }
        if (this.privileged[p].damage_type_id == 7) {
          fieldname = "seven";
        }
        if (this.privileged[p].damage_type_id == 8) {
          fieldname = "eight";
        }
        if (this.privileged[p].damage_type_id == 9) {
          fieldname = "nine";
        }
        if (this.privileged[p].damage_type_id == 10) {
          fieldname = "ten";
        }
        this.columns.push({
          label: this.privileged[p].name,
          field: fieldname,
        });
      }
    },
    chagestatus(evt) {
      this.event.status_name =
        evt.target.options[evt.target.options.selectedIndex].text;
      this.nodata = false;
    },
    getchart() {
      this.chartOptions = {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: this.month,
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
          },
        },
        title: {
          text: "",
          floating: true,
          offsetY: 330,
          align: "center",
          style: {
            color: "#444",
          },
        },
        colors: ["#4472C4", "#FFFF00", "#ED7D31"],
      };
    },
    geteventtype() {
      EventTypesDataService.getEventTypesAll().then((res) => {
        // console.log(res.data);
        this.event_type.push({
          event_type_id: 0,
          name: "ทั้งหมด",
        });
        for (let r = 0; r < res.data.length; r++) {
          this.event_type.push({
            event_type_id: res.data[r].event_type_id,
            name: res.data[r].name,
          });
        }
        this.event.event_type_id = this.event_type[0].event_type_id;
        this.event.event_type_name = this.event_type[0].name;
      });
    },
    onChangeDistrict(event) {
      // console.log(this.user.province)
      var amphur_id = event.target.value;
      this.event.sub_district_house = "";
      this.getDistrict(amphur_id);
    },
    onChangeProvince(event) {
      // console.log(this.user.province)
      var province_id = event.target.value;
      this.event.district_house = "";
      this.event.sub_district_house = "";
      this.getAmphur(province_id);
    },
    getDistrict(id) {
      if (id == "") {
        DistrictDataService.getDistrictsAll().then((res) => {
          this.districts = res.data;
        });
      } else {
        DistrictDataService.getDistrictAll(id).then((res) => {
          this.districts = res.data;
        });
      }
    },
    getAmphur(id) {
      if (id == "") {
        AmphurDataService.getAmphursAll().then((res) => {
          this.amphures = res.data;
          // console.log(res.data);
        });
      } else {
        AmphurDataService.getAmphurAll(id).then((res) => {
          this.amphures = res.data;
          // console.log(res.data);
        });
      }
      // this.savedraft();
    },
    getProvince() {
      ProvinceDataService.getProvinceAll().then((res) => {
        this.provinces = res.data;
        // console.log(res.data);
      });
    },
    cal(start, end) {
      var date1 = new Date(start);
      var date2 = new Date(end);
      var diffTime = date2.getTime() - date1.getTime();
      var diffDay = diffTime / (1000 * 3600 * 24);
      return diffDay;
    },
    getEventByCaseID() {
      CasesEventDataService.getEventByCaseID(this.case_id).then((res) => {
        this.list = res.data;
        // console.log(res.data);
      });
    },
    back() {
      this.$router.push("/home");
    },
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    },
    convertdatetothai(datetimes) {
      if (datetimes) {
        const date = new Date(datetimes);
        const result = date.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return result;
      } else {
        return "-";
      }
    },
    hasHistory() {
      return window.history.length > 2;
    },
    convertdatetothai(datetimes) {
      if (datetimes) {
        const date = new Date(datetimes);
        const result = date.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return result;
      } else {
        return "-";
      }
    },
    search() {
      if (this.event.id_card != '') {
         SuspectsDataService.getSuspects(this.event.id_card).then((res)=>{
                  this.event.firstname = res.data.firstname
                  this.event.lastname = res.data.lastname
                  // console.log(res.data.firstname);
                  // console.log(this.event);
                })
      }
      var status = '';
      for (let s = 0; s < this.value.length; s++) {
        status += this.value[s].status_id + ','
        
      }
      if (this.event.id_card == null) {
        alert("กรุณากรอกเลขบัตรประชาชน");
      } else if (document.getElementById("fill_start_date").value == "") {
        alert("กรุณากรอกวันที่ยื่นคำร้อง");
      } else {
        var start;
        var end;
        var s = document.getElementById("fill_start_date").value;
        // var dd = new Date(d)
        start = ConvertService.createddate(s);
        this.fillstart = this.convertdatetothai(start);
        var user_id = "";
        if (this.currentUser.role_id != 3 && this.currentUser.role_id != 1) {
          user_id = this.currentUser.user_id;
        }
        // console.log(user_id);
        ReportDataService.getHistory(this.event.id_card, start, user_id,status).then(
          (res) => {
              console.log(res.data);
            if (res.data.length != 0) {
              this.list = res.data;
              this.nodata = true;
            } else {
              alert("ไม่พบข้อมูล");
              this.nodata = false;
            }
          }
        );
      }
    },
    managecontent() {
      var no = 0;
      if (this.list.length != 0) {
        this.filename =
          "รายงานแสดงรายละเอียดเหตุการณ์ตามจังหวัด และอำเภอที่เกิดเหตุการณ์";
      }
      for (let l = 0; l < this.list.length; l++) {
        this.list[l].day = this.cal(
          this.list[l].detained_start_date,
          this.list[l].detained_end_date
        );
        this.list[l].detained_start_date = this.convertdatetothai(
          this.list[l].detained_start_date
        );
        this.list[l].detained_end_date = this.convertdatetothai(
          this.list[l].detained_end_date
        );
        this.list[l].filing_date = this.convertdatetothai(
          this.list[l].filing_date
        );
        this.list[l].birthday = this.convertdatetothai(this.list[l].birthday);
        this.list[l].crime_date = this.convertdatetothai(
          this.list[l].crime_date
        );
        if (!this.list[l].village_no_event) {
          this.list[l].village_no_event = "-";
        }
        if (!this.list[l].road_event) {
          this.list[l].road_event = "-";
        }
        if (!this.list[l].lane_event) {
          this.list[l].lane_event = "-";
        }
        if (!this.list[l].village_event) {
          this.list[l].village_event = "-";
        }
        this.list[l].events =
          "ม." +
          this.list[l].village_no_event +
          " ซ." +
          this.list[l].road_event +
          " ถนน" +
          this.list[l].lane_event +
          " หมู่บ้าน" +
          this.list[l].village_event +
          " ต." +
          this.list[l].sub_district_event +
          " อ." +
          this.list[l].district_event +
          " จ." +
          this.list[l].province_event +
          " " +
          this.list[l].post_code_event;

        if (l != 0) {
          // console.log(this.list[l].case_id, this.list[l - 1].case_id);
          if (this.list[l].case_id == this.list[l - 1].case_id) {
            this.list[l].prefix = "";
            this.list[l].firstname = "";
            this.list[l].lastname = "";
            this.list[l].id_card = "";
            this.list[l].village_no_house = "";
            this.list[l].no_house = "";
            this.list[l].sub_district_house = "";
            this.list[l].district_house = "";
            this.list[l].province_house = "";
            this.list[l].postal_code_house = "";
            this.list[l].birthday = "";
            this.list[l].filing_date = "";
            this.list[l].phone = "";
            this.list[l].gender = "";
            this.list[l].suspect_types = "";
            this.list[l].career = "";
            this.list[l].income = "";
            this.list[l].nationality = "";
            this.list[l].no_current = "";
            this.list[l].village_no_current = "";
            this.list[l].sub_district_current = "";
            this.list[l].sub_district_current = "";
            this.list[l].district_current = "";
            this.list[l].province_current = "";
            this.list[l].postal_code_current = "";

            if (this.list[l].case_id == this.list[l - 1].case_id) {
              this.list[l].no = "";
              // this.list[l].span = 9
              // this.list[l].prefix =  "รวมเป็นเวลา "+ this.list[l].sumday+" วัน เป็นเงิน " +this.list[l].sumamount.toLocaleString() + " บาท"
            }
          } else {
            no = no + 1;
            this.list[l].no = no;
          }
        } else {
          no = no + 1;
          this.list[l].no = no;
        }
        if (this.list[l].village_no_house == null) {
          this.list[l].village_no_house = "-";
        }
      }
    },
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  font-family: "Kanit" !important;
}
.row {
  max-width: 1200px;
  margin: auto;
  background: white;
  padding: 10px;
  color: black !important;
}
a:visited {
  color: black;
} /* visited link */
a:hover {
  color: black;
} /* mouse over link */
a:active {
  color: black;
}
a:link {
  color: black;
}
</style>
