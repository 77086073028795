import http from "../http-common";

class DocsEventDataService {
  getDocEvents(){
    return http.get('/doc_eventtypes');
  }
  getDocsEvent(type) {
    if(type == ''){
      return http.get('/doc_eventtypes');
    }else{
      return http.get('/doc?doc_eventtypes='+type);
    }
  }
  getDocEvent(id) {
    return http.get(`/doc_eventtypes/`+id);
  }
  createDocEvent(data) {
    return http.post('/doc_eventtypes', data);
  }
  updateDocEvent(id,data) {
    return http.put(`/doc_eventtypes/${id}`, data);
  }
  getDocEventByType(id) {
    return http.get(`/doc_eventtypes/getdoc/`+id);
  }  
  deleteDocEventBycategorydocid(id) {
    return http.delete(`/doc_eventtypes/`+id);
  } 
  getDocEventByEventID(id) {
    return http.get('/doc_eventtypes/findDocEventByEventID/'+id);
  }
}

export default new DocsEventDataService();