import http from "../http-common";

class QualityDetailDataService {
  getQualities(id_card,id_card_des) {
    return http.get('/quality_detail?id_card='+id_card+'&&id_card_des='+id_card_des);
  }
  getIDSuspectQuality() {
    return http.get('/quality_detail/getidcardsuspect');
  }
  getQuality(id,type) {
    return http.get(`/quality_detail/`+id+'/'+type);
  }
  getDetailQuality(id,type) {
    return http.get(`/quality_detail/getdetail/`+id+'/'+type);
  }  
  createQuality(data) {
    return http.post('/quality_detail', data);
  }
  updateQuality(id,data) {
    return http.put(`/quality_detail/${id}`, data);
  }
}

export default new QualityDetailDataService();