<template>
  <div class="hello mt-5">
    <b-tabs
      active-nav-item-class="text-success"
      content-class="mt-3 tab-active-class"
    >
      <b-tab
        :title-item-class="'tab-title-class'"
        title="รายงานตามข้อมูลเหตุการณ์"
        :active="tab === '1'"
        @click="go(1)"
        ><p><EventReport v-if="tab === '1'" /></p
      ></b-tab>
      <b-tab
        :title-item-class="'tab-title-class'"
        title="รายงานการช่วยเหลือ"
        :active="tab === '2'"
        @click="go(2)"
        ><p><HelpReport v-if="tab === '2'" /></p
      ></b-tab>
      <b-tab
        :title-item-class="'tab-title-class'"
        title="รายงานตามการช่วยเหลือการติดตาม"
        :active="tab === '3'"
        @click="go(3)"
        ><p><TrackingReport v-if="tab === '3'" /></p
      ></b-tab>
      <b-tab
        :title-item-class="'tab-title-class'"
        title="รายงานตามการช่วยเหลือด้านคุณภาพชีวิต"
        :active="tab === '4'"
        @click="go(4)"
        ><p><QualityReport v-if="tab === '4'" /></p
      ></b-tab>
      <b-tab
        :title-item-class="'tab-title-class'"
        title="รายงานทางด้านสถิติ"
        :active="tab === '5'"
        @click="go(5)"
        ><p><StatReport v-if="tab === '5'" /></p
      ></b-tab>
      <b-tab
        :title-item-class="'tab-title-class'"
        title="รายงานตามสถานะ"
        :active="tab === '6'"
        @click="go(6)"
        ><p><StatusReport v-if="tab === '6'" /></p
      ></b-tab>
      <b-tab
        :title-item-class="'tab-title-class'"
        title="รายงานประวัติการแก้ไขข้อมูลตามวันที่แก้ไข"
        :active="tab === '7'"
        @click="go(7)"
        ><p><HistoryReport v-if="tab === '7'" /></p
      ></b-tab>
    </b-tabs>
  </div>
</template>

<script>
import EventReport from "./EventReport.vue";
import HelpReport from "./HelpReport.vue";
import TrackingReport from "./TrackingReport.vue";
import QualityReport from "./QualityReport.vue";
import StatReport from "./StatReport.vue";
import StatusReport from "./StatusReport.vue";
import HistoryReport from "./HistoryReport.vue";

export default {
  props: ["msg"],
  components: {
    EventReport,
    HelpReport,
    TrackingReport,
    QualityReport,
    StatReport,
    StatusReport,
    HistoryReport,
  },
  data() {
    return {
      name: "HelloWorld",
      tab: "",
      case_id: "",
      cases: [],
    };
  },
  methods: {
    go(t) {
      setTimeout(function () {
        location.reload();
      }, 50);
      this.$router.push("/report/" + t);
    },
    hasHistory() {
      return window.history.length > 2;
    },
  },
  mounted() {
    this.tab = this.$route.params.tab;
    // console.log(this.tab);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  color: black !important;
  font-family: "Kanit" !important;
}
.row {
  max-width: 1200px;
  margin: auto;
  background: white;
  padding: 10px;
  color: black !important;
}
.text-success {
  color: #09cc68 !important;
}
.nav-link {
  color: black !important;
}
</style>
