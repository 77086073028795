import http from "../http-common";

class CasesDataService {
  getCases() {
    return http.get('/cases');
  }
  getCaseByManagement(){
    return http.get('/cases/getcasesall');
  }
  getCase(id) {
    return http.get(`/cases/`+id);
  }
  getcommitt(id) {
    return http.get(`/cases/getcommitt/`+id);
  }
  getCaseInfo(id) {
    return http.get(`/cases/getcaseInfo/`+id);
  }
  getCaseSuspect(id_card,fill_date) {
    return http.get(`/cases/getcasesuspect?id_card=`+id_card+'&&fill_date='+fill_date);
  }
  SearchAllCaseByUser(id_card,date,status,user_id) {
    return http.get(`/cases/getcasessearch?id_card=`+id_card+'&&date='+date+'&&status='+status+'&&user_id='+user_id);
  }
  SearchAllCase(id_card,start,end,status) {
    return http.get(`/cases/getcasessearchall?id_card=`+id_card+'&&start='+start+'&&end='+end+'&&status='+status);
  }
  SearchAllProvince(id_card,start,end,status,amphure) {
    return http.get(`/cases/getcasesprovince?id_card=`+id_card+'&&start='+start+'&&end='+end+'&&status='+status+'&&amphure='+amphure);
  }
  getCaseByCreated(id) {
    return http.get(`/cases/getcases/`+id);
  }
  createCase(data) {
    return http.post('/cases', data);
  }
  updateCase(id,data) {
    return http.put(`/cases/${id}`, data);
  }
  updateFillingCase(id,data) {
    return http.put(`/cases/updatefilling/${id}`, data);
  }
  updateStatus(id,data) {
    return http.put(`/cases/updatestatus/${id}`, data);
  }
  updateEdit(id,data) {
    return http.put(`/cases/updateedit/${id}`, data);
  }
  updateCheck(id,data) {
    return http.put(`/cases/updatestatuscheck/${id}`, data);
  }
  updatestatusnotran(id,data) {
    return http.put(`/cases/updatestatusnotran/${id}`, data);
  }
}

export default new CasesDataService();