<template>
  <div class="hello">
    <HomeIcon />
    <div class="mt-5">
      <div class="row mt-5">
        <div class="col text-right">
          <a href="disaster/0">
            <button
              class="btn btn-success"
              style="color: white; background-color: #09cc68"
            >
              <i class="fas fa-plus"></i> เพิ่มข้อมูล
            </button></a
          >
        </div>
      </div>
      <div class="row card mt-2">
        <div class="card-body p-0">
          <h4 style="text-align: center">ค้นหา</h4>
          <div class="row">
             <div class="col-md-4"></div>
            <div class="col-md-4">
              <div class="form-group" style="text-align: left">
                <label for="username">เลขบัตรประชาชนผู้เสียหาย</label>
                <input
                v-model="id_card"
                  type="number"
                  class="form-control"
                  id="username"
                  placeholder="เลขบัตรประชาชน 13 หลัก"
                />
              </div>
            </div>
             <!-- <div class="col-md-4">
              <div class="form-group" style="text-align: left">
                <label for="username">เลขบัตรประชาชนทายาท </label>
                <input
                v-model="id_card_des"
                  type="text"
                  class="form-control"
                  id="username"
                  placeholder="เลขบัตรประชาชน 13 หลัก"
                />
              </div>
            </div> -->
            <div class="col-md-3"></div>
          </div>
        </div>
        <div class="col text-center">
          <button @click="search()" class="btn btn-primary" style="color: white">
            <i class="fas fa-search"></i> ค้นหา
          </button>
        </div>
      </div>
      <div class="mt-5">
        <div class="row">
          <div class="col-md-12">
            <h4 style="text-align: left" class="mb-5">
              รายการข้อมูล
            </h4>
          </div>
          <div class="col-md-3">เลขบัตรประชาชนผู้เสียหาย</div>
          <div class="col-md-7">ชื่อผู้เสียหาย</div>
          <!-- <div class="col-md-4">ข้อมูลทายาท</div>
          <div class="col-md-2">ข้อมูลการช่วยเหลือ</div> -->
          <div class="col-md-2">จัดการ</div>
        </div>
        <div class="row card" v-for="l in pageOfItems" :key="l.case_id">
          <div class="card-body p-0">
            <div class="row">
              <div class="col-md-3">
                {{ l.id_card }}
              </div>
               <div class="col-md-7">
                {{l.firstname}} {{ l.lastname }}
              </div>
              <!-- <div class="col-md-4">{{ l.id_card_relative }} <br/> {{ l.firstname_relative }} {{ l.lastname_relative }}</div>
              <div class="col-md-2">{{ l.help_detail }}</div> -->
              <div class="col-md-2">
                <a :href="'/integration/disaster/' + l.disaster_id"
                  ><i class="fas fa-pencil-alt"></i>&nbsp;</a
                >
              </div>
            </div>
          </div>
        </div>
        <div v-if="list.length == 0" style="text-align: center">
          <h5>ไม่พบข้อมูล</h5>
        </div>
      </div>
      <div class="row" align="right">
        <div class="col-md-12">
          <jw-pagination
            :items="list"
            @changePage="onChangePage"
            :labels="customLabels"
          ></jw-pagination>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col text-center">
          <button
            class="btn btn-warning"
            style="color: white"
            @click="back()"
          >
            ย้อนกลับ 
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

  <script src="https://justice-api.sbpac.go.th/picker_date.js"></script>

<script>

const customLabels = {
    first: '<<',
    last: '>>',
    previous: '<',
    next: '>'
};


import HomeIcon from "../../HomeIcon.vue";
import DisasterDataService from "../../../services/DisasterDataService";
import StatusDataService from "../../../services/StatusDataService.js";

export default {
  components: {
    HomeIcon,
  },
  data() {
    return {
      name: "HelloWorld",
      list: [],
      status: [],
      pageOfItems: [],
      date: "",
      options: {
        format: "DD/MM/YYYY",
        useCurrent: false,
      },
      location: "",
      latLong: "",
      customLabels,
      id_card:'',
      id_card_des:''
    };
  },
  mounted() {
    var year = new Date().getFullYear()
    var start = year-2004
    var before = "-"+start+":+0"
    this.getCase();
    this.getstatus();
    picker_date(document.getElementById("my_date"), { year_range: before });
  },
  methods: {
    back(){
      this.$router.push("/home");
    },
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    },
    getstatus() {
      StatusDataService.getStatus().then((res) => {
        this.status = res.data;
      });
    },

    convertdatetothai(datetimes) {
      if (datetimes) {
        const date = new Date(datetimes);
        const result = date.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return result;
      } else {
        return "-";
      }
    },
    getCase() {
      DisasterDataService.getQualities('','').then(
        (res) => {
          // console.log(res.data);
          this.list = res.data;
        }
      );
    },
    hasHistory() {
      return window.history.length > 2;
    },
    search(){
      DisasterDataService.getQualities(this.id_card,this.id_card_des).then(
        (res) => {
          // console.log(res.data);
          this.list = res.data;
        }
      );
      
    }
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  font-family: "Kanit" !important;
}
.row {
  max-width: 1200px;
  margin: auto;
  background: white;
  padding: 10px;
  color: black !important;
}
a:visited {
  color: black;
} /* visited link */
a:hover {
  color: black;
} /* mouse over link */
a:active {
  color: black;
}
a:link {
  color: black;
}
</style>
