import http from "../http-common";

class PrivilegeDataService {
  getPrivileges() {
    return http.get('/privilege');
  }
  getPrivilege(id) {
    return http.get(`/privilege/`+id);
  }
  getPrivilegeByCaseID(id) {
    return http.get(`/privilege/getprivilege/`+id);
  }
  getPrivilegeByEvent(id,event) {
    return http.get(`/privilege/getprivilegebyEvent/`+id+'/'+event);
  }
  getPrivilegeGroup() {
    return http.get(`/privilege/getgroupprivilege`);
  }
  getPrivilegeByEventId(id) {
    return http.get(`/event_privilege/eventid/`+id);
  }
  createPrivilege(data) {
    return http.post('/privilege', data);
  }
  checkeventandpri(data) {
    return http.post('/event_privilege/eventandpri', data);
  }
  createEventPrivilege(data) {
    return http.post('/event_privilege', data);
  }
  updatePrivilege(id,data) {
    return http.put(`/privilege/${id}`, data);
  }
  deletePrivilege(id) {
    return http.delete(`/event_privilege/${id}`);
  }
}

export default new PrivilegeDataService();
