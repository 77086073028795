<template>
  <div class="hello">
    <HomeIcon />
    <div class="row">
      <div class="row card mt-2">
        <div class="card-body mt-2">
          <div class="col-md-12 mt-5">
            <h3 style="text-align: center">ข้อมูลคุณภาพชีวิต</h3>
          </div>
        </div>

        <div class="hello mt-3" v-if="quality_id != 0">
          <div class="col-md-12 mt-5">
            <h3 class="form-group" style="color: #2da3f2; font-weight: bold">
              รายการข้อมูลคุณภาพชีวิต
            </h3>
          </div>
          <h4>ผู้เสียหาย</h4>
          <table class="table table-bordered">
            <thead class="table-active">
              <tr>
                <th scope="col">ครั้งที่</th>
                <!-- <th scope="col">เลขบัตรประชาชนผู้เสียหาย</th> -->
                <th scope="col">ชื่อ-นามสกุลผู้เสียหาย</th>
                <th scope="col">วันทึ่ช่วยเหลือคุณภาพชีวิต</th>
                <th scope="col">ข้อมูลการช่วยเหลือ</th>
                <th scope="col" style="text-align: right">
                  <!-- <button v-if="showadd" class="btn btn-success">
                    <a
                      href="#"
                      data-toggle="modal"
                      style="color: white"
                      data-target="#addModalDescendants"
                      @click="addQuality()"
                      >เพิ่ม</a
                    >
                  </button> -->
                </th>
              </tr>
            </thead>
            <tbody v-if="qualitylistsus.length > 0">
              <tr
                class="table-success"
                v-for="(l, i) in qualitylistsus"
                :key="i"
              >
                <td>{{ i + 1 }}</td>
                <!-- <td>{{ l.id_card_relative }}</td> -->
                <td>{{ l.prefix }}{{ l.firstname }} {{ l.lastname }}</td>
                <td>{{ convertdatetothai(l.quality_date) }}</td>
                <td>{{ l.help_detail }}</td>
                <td style="text-align: right">
                  <button
                    v-if="l.select == false"
                    class="btn btn-info"
                    style="color: white"
                  >
                    <a
                      href="#"
                      style="color: white"
                      data-toggle="modal"
                      data-target="#addModalDescendants"
                      @click="selectqulitysus(l)"
                      >แก้ไข</a
                    >
                  </button>
                  <button
                    v-if="l.select == true"
                    class="btn btn-warning"
                    style="color: white"
                  >
                    <a
                      href="#"
                      style="color: white"
                      data-toggle="modal"
                      data-target="#addModalDescendants"
                      @click="selectqulitysus(l)"
                      >แก้ไข</a
                    >
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="qualitylistsus.length == 0" style="text-align: center">
            ไม่พบข้อมูล
          </div>
        </div>
        <div class="hello mt-3" v-if="quality_id != 0">
          <h4>ทายาท</h4>
          <table class="table table-bordered">
            <thead class="table-active">
              <tr>
                <th scope="col">ครั้งที่</th>
                <th scope="col">เลขบัตรประชาชนทายาท</th>
                <th scope="col">ชื่อ-นามสกุลทายาท</th>
                <th scope="col">วันทึ่ช่วยเหลือคุณภาพชีวิต</th>
                <th scope="col">ข้อมูลการช่วยเหลือ</th>
                <th scope="col" style="text-align: right">
                  <!-- <button v-if="showadd" class="btn btn-success">
                    <a
                      href="#"
                      data-toggle="modal"
                      style="color: white"
                      data-target="#addModalDescendants"
                      @click="addQuality()"
                      >เพิ่ม</a
                    >
                  </button> -->
                </th>
              </tr>
            </thead>
            <tbody v-if="qualitylist.length > 0">
              <tr class="table-success" v-for="(l, i) in qualitylist" :key="i">
                <td>{{ i + 1 }}</td>
                <td>{{ l.id_card_relative }}</td>
                <td>{{ l.prefix }}{{ l.firstname }} {{ l.lastname }}</td>
                <td>{{ convertdatetothai(l.quality_date) }}</td>
                <td>{{ l.help_detail }}</td>
                <td style="text-align: right">
                  <button
                    v-if="l.select == false"
                    class="btn btn-info"
                    style="color: white"
                  >
                    <a
                      href="#"
                      style="color: white"
                      data-toggle="modal"
                      data-target="#addModalDescendants"
                      @click="selectqulity(l)"
                      >แก้ไข</a
                    >
                  </button>
                  <button
                    v-if="l.select == true"
                    class="btn btn-warning"
                    style="color: white"
                  >
                    <a
                      href="#"
                      style="color: white"
                      data-toggle="modal"
                      data-target="#addModalDescendants"
                      @click="selectqulity(l)"
                      >แก้ไข</a
                    >
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="qualitylist.length == 0" style="text-align: center">
            ไม่พบข้อมูล
          </div>
        </div>
      </div>
      <div class="row card mt-5">
        <div class="row">
          <div class="col-md-12 mt-3">
            <h5 class="form-group" style="color: #2da3f2; font-weight: bold">
              ข้อมูลผู้เสียหาย
            </h5>
          </div>
          <div class="col-md-4">
            <div class="form-group" style="text-align: left">
              <label
                >เลขบัตรประชาชนผู้เสียหาย
                <span style="color: red"> *</span></label
              >
              <div class="input-group mb-3">
                <input
                  :disabled="quality_id != 0"
                  type="number"
                  v-model="quality.id_card"
                  min="1"
                  class="form-control"
                  id="idcard"
                  placeholder="เลขบัตรประชาชน 13 หลัก"
                />
                <a @click="search()" href="#"
                  ><span class="input-group-text" id="basic-addon2"
                    >ค้นหา</span
                  ></a
                >
              </div>
              <!-- <div v-if="filteredList.length != 1">
                <ul
                  class="list-group"
                  v-for="car in filteredList"
                  :key="car.id"
                >
                  <li
                    class="
                      list-group-item
                      d-flex
                      justify-content-between
                      align-items-center
                    "
                  >
                    {{ car.id_card }}
                    <a href="#" @click="copysuspect(car)"
                      ><span class="badge badge-primary badge-pill"
                        >เลือก</span
                      ></a
                    >
                  </li>
                </ul>
              </div> -->
            </div>
          </div>
          <div class="col-md-8"></div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group" style="text-align: left">
              <label for="province"
                >คำนำหน้า <span style="color: red"> *</span></label
              >
              <select
                :disabled="quality_id != 0"
                class="form-select mb-3"
                v-model="quality.prefix"
              >
                <option
                  v-for="(s, i) in prefix"
                  v-bind:value="s.prefix_id"
                  :key="i + 1"
                >
                  {{ s.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-4">
            <label for="username"
              >ชื่อ <span style="color: red"> *</span></label
            >
            <input
              :disabled="quality_id != 0"
              v-model="quality.firstname"
              type="text"
              min="1"
              class="form-control"
              id="firstname"
              placeholder="ชื่อ"
            />
          </div>
          <div class="col-md-4">
            <label for="username"
              >นามสกุล <span style="color: red"> *</span></label
            >
            <input
              :disabled="quality_id != 0"
              v-model="quality.lastname"
              type="text"
              min="1"
              class="form-control"
              id="lastname"
              placeholder="นามสกุล"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-md-3">
            <button v-if="showadd && quality_id != 0" class="btn btn-success">
              <a
                href="#"
                data-toggle="modal"
                style="color: white"
                data-target="#addModalDescendants"
                @click="addQuality()"
                >เพิ่มข้อมูลคุณภาพชีวิต</a
              >
            </button>
          </div>
        </div>
      </div>
      <div class="row card mt-3">
        <div class="row">
          <div class="col-md-12 mt-3">
            <h5
              v-if="type == 1"
              class="form-group"
              style="color: #2da3f2; font-weight: bold"
            >
              ข้อมูลทายาท
            </h5>
            <h5
              v-else
              class="form-group"
              style="color: #2da3f2; font-weight: bold"
            >
              ข้อมูลผู้เสียหาย
            </h5>
          </div>
        </div>
        <div class="hello" v-if="!showadd">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th scope="col">เลขบัตรประชาชนทายาท</th>
                <th scope="col">ชื่อ-นามสกุลทายาท</th>
                <th scope="col" style="text-align: right"></th>
              </tr>
            </thead>
            <tbody v-if="list.length > 0">
              <tr v-for="l in list" :key="l.relative_id">
                <td>{{ l.id_card_relative }}</td>
                <td>{{ l.prefixname }}{{ l.firstname }} {{ l.lastname }}</td>
                <td style="text-align: right">
                  <button
                    v-if="l.select == false"
                    class="btn btn-success"
                    style="color: white"
                  >
                    <a
                      href="#"
                      style="color: white"
                      data-toggle="modal"
                      data-target="#addModalDescendants"
                      @click="copydescendant(l)"
                      >เพิ่ม</a
                    >
                  </button>
                  <button
                    v-if="l.select == true"
                    class="btn btn-warning"
                    style="color: white"
                  >
                    <a
                      href="#"
                      style="color: white"
                      data-toggle="modal"
                      data-target="#addModalDescendants"
                      @click="copydescendant(l)"
                      >เพิ่ม</a
                    >
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="list.length == 0" style="text-align: center">
            ไม่พบข้อมูล
          </div>
          <label>
            <input
              id="sameaddress"
              v-model="samesuspect"
              @click="changerelative"
              type="checkbox"
            />
            <span style="font-size: 15pt"> ผู้เสียหาย </span>
          </label>
        </div>
        <div class="row mt-3">
          <div class="col-md-4">
            <div class="form-group" style="text-align: left">
              <label>เลขบัตรประชาชน <span style="color: red"> *</span></label>
              <input
                type="number"
                v-model="quality.id_card_relative"
                min="1"
                class="form-control"
                id="idcard"
                placeholder="เลขบัตรประชาชน 13 หลัก"
              />
              <!-- <div v-if="filtereddesList.length != 1">
                <ul
                  class="list-group"
                  v-for="car in filtereddesList"
                  :key="car.id"
                >
                  <li
                    class="
                      list-group-item
                      d-flex
                      justify-content-between
                      align-items-center
                    "
                  >
                    {{ car.id_card_relative }}
                    <a href="#" @click="copydescendant(car)"
                      ><span class="badge badge-primary badge-pill"
                        >เลือก</span
                      ></a
                    >
                  </li>
                </ul>
              </div> -->
            </div>
          </div>
          <div class="col-md-8"></div>
          <div class="col-md-3">
            <div class="form-group" style="text-align: left">
              <label for="province"
                >คำนำหน้า <span style="color: red"> *</span></label
              >
              <select class="form-select mb-3" v-model="quality.prefixrelative">
                <option
                  v-for="(s, i) in prefix_des"
                  v-bind:value="s.prefix_id"
                  :key="i + 1"
                >
                  {{ s.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-4">
            <label for="username"
              >ชื่อ <span style="color: red"> *</span></label
            >
            <input
              v-model="quality.firstnamerelative"
              type="text"
              min="1"
              class="form-control"
              id="firstname"
              placeholder="ชื่อ"
            />
          </div>
          <div class="col-md-4">
            <label for="username"
              >นามสกุล <span style="color: red"> *</span></label
            >
            <input
              v-model="quality.lastnamerelative"
              type="text"
              min="1"
              class="form-control"
              id="lastname"
              placeholder="นามสกุล"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group" style="text-align: left">
              <label for="province"
                >เพศ <span style="color: red"> *</span></label
              >
              <select class="form-select mb-3" v-model="quality.gender">
                <option value="ชาย">ชาย</option>
                <option value="หญิง">หญิง</option>
                <option value="อื่นๆ">อื่นๆ</option>
              </select>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group" style="text-align: left">
              <label for="province"
                >สถานะ <span style="color: red"> *</span></label
              >
              <select class="form-select mb-3" v-model="quality.status">
                <option value="โสด">โสด</option>
                <option value="แต่งงานแล้ว">แต่งงานแล้ว</option>
                <option value="หย่าร้าง">หย่าร้าง</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group" style="text-align: left">
              <label for="username"
                >เบอร์โทรศัพท์ <span style="color: red"> *</span></label
              >
              <input
                v-model="quality.phone"
                type="number"
                min="1"
                class="form-control"
                id="username"
                placeholder="เบอร์โทรศัพท์"
              />
            </div>
          </div>
          <div class="col-md-4" v-if="quality.type == 1">
            <div class="form-group" style="text-align: left">
              <label for="province">ความสัมพันธ์ทายาท </label>
              <select class="form-select mb-3" v-model="quality.relationship">
                <option
                  v-for="(r, i) in relationships"
                  v-bind:value="r.relationship_type_id"
                  :key="i"
                >
                  {{ r.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group" style="text-align: left">
              <label for="username">สัญชาติ </label>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-check" style="text-align: left">
              <label>
                <input
                  v-model="quality.nationality"
                  value="ไทย"
                  name="national"
                  class="form-check-input"
                  type="radio"
                  @change="onChangenational"
                />
                <span> สัญชาติไทย </span>
              </label>
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-check" style="text-align: left">
              <label>
                <input
                  v-model="quality.nationality"
                  name="national"
                  value="อื่นๆ"
                  class="form-check-input"
                  type="radio"
                  @change="onChangenational"
                />
                <span> สัญชาติอื่นๆ </span>
              </label>
            </div>
          </div>
          <div class="col-md-3">
            <select
              class="form-select mb-3"
              v-if="other"
              v-model="nationalityother"
            >
              <option value="กัมพูชา">กัมพูชา</option>
              <option value="ลาว">ลาว</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <h5 style="color: #2da3f2; font-weight: bold">
              ที่อยู่ตามบัตรประชาชน
            </h5>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <div class="form-group" style="text-align: left">
              <label for="username">บ้านเลขที่ </label>
              <input
                v-model="quality.no_house"
                type="text"
                class="form-control"
                id="no_current"
                placeholder="บ้านเลขที่"
              />
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group" style="text-align: left">
              <label for="username">หมู่ที่ </label>
              <input
                v-model="quality.village_no_house"
                type="number"
                class="form-control"
                id="vilegeno_current"
                placeholder="หมู่ที่"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group" style="text-align: left">
              <label for="username">ซอย </label>
              <input
                v-model="quality.road_house"
                type="text"
                min="0"
                class="form-control"
                id="vilegeno_current"
                placeholder=""
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group" style="text-align: left">
              <label for="username">ถนน </label>
              <input
                v-model="quality.lane_house"
                type="text"
                class="form-control"
                id="vilegeno_current"
                placeholder="ถนน"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group" style="text-align: left">
              <label for="username">หมู่บ้าน </label>
              <input
                v-model="quality.village_house"
                type="text"
                min="0"
                class="form-control"
                id="income"
                placeholder="หมู่บ้าน"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group" style="text-align: left">
              <label for="province"
                >จังหวัด <span style="color: red"> *</span></label
              >
              <select
                class="form-select mb-3"
                v-model="quality.province_house"
                @change="onChangeProvince"
              >
                <option
                  v-for="(p, i) in provinces"
                  v-bind:value="p.PROVINCE_ID"
                  :key="i + 2"
                >
                  {{ p.PROVINCE_NAME }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group" style="text-align: left">
              <label for="province"
                >อำเภอ <span style="color: red"> *</span></label
              >
              <select
                class="form-select mb-3"
                v-model="quality.district_house"
                @change="onChangeDistrict"
              >
                <option
                  v-for="(a, i) in amphures"
                  v-bind:value="a.AMPHUR_ID"
                  :key="i + 3"
                >
                  {{ a.AMPHUR_NAME }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group" style="text-align: left">
              <label for="province"
                >ตำบล <span style="color: red"> *</span></label
              >
              <select
                class="form-select mb-3"
                v-model="quality.sub_district_house"
                @change="onChangePostalCode"
              >
                <option
                  v-for="(d, i) in districts"
                  v-bind:value="d.DISTRICT_ID"
                  :key="i + 4"
                >
                  {{ d.DISTRICT_NAME }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group" style="text-align: left">
              <label for="username">รหัสไปรษณีย์ </label>
              <input
                v-model="postal_code_house"
                type="text"
                class="form-control"
                id="income"
                placeholder=""
              />
            </div>
          </div>
        </div>
        <div class="row" style="display: none">
          <div class="col-md-4">
            <div class="form-group" style="text-align: left">
              <label for="username">ลองจิจูด </label>
              <input
                v-model="quality.longitude_house"
                type="text"
                class="form-control"
                id="vilegeno_current"
                placeholder=""
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group" style="text-align: left">
              <label for="username">ละติจูด </label>
              <input
                v-model="quality.lattitude_house"
                type="text"
                min="0"
                class="form-control"
                id="income"
                placeholder=""
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <h5 style="color: #2da3f2; font-weight: bold">ที่อยู่ปัจจุบัน</h5>
          </div>
          <div class="col-md-4">
            <label>
              <input
                v-model="sameaddress"
                @click="changeaddress"
                type="checkbox"
              />
              <span> ที่อยู่เดียวกับที่อยู่บัตรประชาชน </span>
            </label>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <div class="form-group" style="text-align: left">
              <label for="username">บ้านเลขที่ </label>
              <input
                v-model="quality.no_current"
                type="text"
                class="form-control"
                id="no_current"
                placeholder="บ้านเลขที่"
              />
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group" style="text-align: left">
              <label for="username">หมู่ที่ </label>
              <input
                v-model="quality.village_no_current"
                type="number"
                class="form-control"
                id="vilegeno_current"
                placeholder="หมู่ที่"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group" style="text-align: left">
              <label for="username">ซอย </label>
              <input
                v-model="quality.road_current"
                type="text"
                min="0"
                class="form-control"
                id="vilegeno_current"
                placeholder=""
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group" style="text-align: left">
              <label for="username">ถนน </label>
              <input
                v-model="quality.lane_current"
                type="text"
                class="form-control"
                id="vilegeno_current"
                placeholder="ถนน"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group" style="text-align: left">
              <label for="username">หมู่บ้าน </label>
              <input
                v-model="quality.village_current"
                type="text"
                min="0"
                class="form-control"
                id="income"
                placeholder="หมู่บ้าน"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group" style="text-align: left">
              <label for="province"
                >จังหวัด <span style="color: red"> *</span></label
              >
              <select
                class="form-select mb-3"
                v-model="quality.province_current"
                @change="onChangeProvincecurrent"
              >
                <option
                  v-for="(p, i) in provincescurrent"
                  v-bind:value="p.PROVINCE_ID"
                  :key="i + 5"
                >
                  {{ p.PROVINCE_NAME }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group" style="text-align: left">
              <label for="province"
                >อำเภอ <span style="color: red"> *</span></label
              >
              <select
                class="form-select mb-3"
                v-model="quality.district_current"
                @change="onChangeDistrictcurrent"
              >
                <option
                  v-for="(a, i) in amphurescurrent"
                  v-bind:value="a.AMPHUR_ID"
                  :key="i + 6"
                >
                  {{ a.AMPHUR_NAME }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group" style="text-align: left">
              <label for="province"
                >ตำบล <span style="color: red"> *</span></label
              >
              <select
                class="form-select mb-3"
                v-model="quality.sub_district_current"
                @change="onChangePostalCodecurrent"
              >
                <option
                  v-for="(d, i) in districtscurrent"
                  v-bind:value="d.DISTRICT_ID"
                  :key="i + 7"
                >
                  {{ d.DISTRICT_NAME }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group" style="text-align: left">
              <label for="username">รหัสไปรษณีย์ </label>
              <input
                v-model="postal_code_current"
                type="text"
                min="0"
                class="form-control"
                id="income"
                placeholder=""
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-5">
            <h5 class="form-group" style="color: #2da3f2; font-weight: bold">
              ข้อมูลคุณภาพชีวิต
            </h5>
          </div>
          <div class="col-md-4">
            <div class="form-group" style="text-align: left">
              <label for="username"
                >วันทึ่ช่วยเหลือคุณภาพชีวิต
                <span style="color: red"> *</span></label
              >
              <!-- <input
                v-model="tracking.tracking_date"
                type="date"
                min="0"
                class="form-control"
                id="income"
                placeholder=""
              /> -->
              <input id="quality_date" class="form-control" />
            </div>
          </div>
          <div class="col-md-8"></div>
          <div class="col-md-8">
            <div class="form-group" style="text-align: left">
              <label for="username"
                >ข้อมูลการช่วยเหลือ <span style="color: red"> *</span></label
              >
              <textarea
                rows="3"
                v-model="quality.help_detail"
                type="text"
                class="form-control"
                id="income"
                placeholder=""
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group" style="text-align: left">
              <label for="username">หมายเหตุ </label>
              <input
                v-model="quality.remark"
                type="text"
                min="0"
                class="form-control"
                id="income"
                placeholder=""
              />
            </div>
          </div>
        </div>
        <div class="row">
          <h5 style="color: #2da3f2; font-weight: bold">
            แนบเอกสารคุณภาพชีวิต
          </h5>
          <table class="table table-bordered">
            <thead>
              <tr>
                <th scope="col">เอกสาร</th>
                <th scope="col">ชื่อไฟล์</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(d, i) in doclistquality" :key="i">
                <td>{{ d.name }}</td>
                <td>
                  <a
                    :href="d.file_data"
                    target="_blank"
                  >
                    {{ d.file_name }}
                  </a>
                </td>
                <td style="text-align: center">
                  <input
                    id="my_filequality"
                    class="form-control"
                    type="file"
                    accept="application/pdf"
                    @change="onFileChangesus"
                  />
                  <button
                    v-if="!d.file_name"
                    id="get_filequality"
                    class="btn btn-success"
                    @click="onchange(d.document_id, d.category_doc_id)"
                  >
                    เพิ่ม
                  </button>
                  <button
                    id="deletedocsuspect"
                    class="btn btn-danger"
                    @click="deletedocsus(d.category_doc_id)"
                    v-if="d.file_name"
                  >
                    ลบ
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div
            class="modal fade"
            id="showModalImagequality"
            tabindex="-1"
            role="dialog"
            aria-labelledby="showModalImagequality"
            aria-hidden="true"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="row" style="height: 630px">
                    <div class="col-md-12">
                      <object>
                        <embed
                          id="pdfID"
                          type="text/html"
                          width="100%"
                          height="100%"
                          :src="imagesrc"
                        />
                      </object>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <h5 style="color: #2da3f2; font-weight: bold">
            แนบรูปภาพคุณภาพชีวิต
          </h5>
          <table class="table table-bordered">
            <thead>
              <tr>
                <th scope="col">รูปภาพ</th>
                <th scope="col">ชื่อรูป</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(d, i) in imagelistquality" :key="i">
                <td>{{ d.name }}</td>
                <td>
                  <a
                    :href="d.file_data"
                    target="_blank"
                  >
                    {{ d.file_name }}
                  </a>
                </td>
                <td style="text-align: center">
                  <input
                    id="my_imagequality"
                    class="form-control"
                    type="file"
                    accept="image/*"
                    @change="onImageChangesus"
                  />
                  <button
                    v-if="!d.file_name"
                    id="get_imagequality"
                    class="btn btn-success"
                    @click="onchangeImage(d.document_id, d.category_doc_id)"
                  >
                    เพิ่ม
                  </button>
                  <button
                    id="deletedocsuspect"
                    class="btn btn-danger"
                    @click="deleteimagesus(d.category_doc_id)"
                    v-if="d.file_name"
                  >
                    ลบ
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div
            class="modal fade"
            id="showModalquality"
            tabindex="-1"
            role="dialog"
            aria-labelledby="showModalquality"
            aria-hidden="true"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="row">
                    <div class="col-md-12">
                      <img :src="imagesrc" style="width:100%" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-md-12 text-center">
            <button
              class="btn btn-warning"
              style="color: white; width: 200px"
              @click="back()"
            >
              ย้อนกลับ
            </button>
            &nbsp;
            <button
              class="btn btn-success"
              style="color: white; width: 200px"
              @click="save()"
            >
              บันทึก
            </button>
            &nbsp;
            <!-- <button
              class="btn btn-danger"
              style="color: white; width: 200px"
              @click="next()"
            >
              ลบ
            </button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script src="https://justice-api.sbpac.go.th/picker_date.js"></script>

<script>
import QualityDataService from "../../services/QualityDataService";
import ProvinceDataService from "../../services/ProvinceDataService";
import AmphurDataService from "../../services/AmphurDataService";
import DistrictDataService from "../../services/DistrictDataService";
import SuspectsRelativeDataService from "../../services/SuspectsRelativeDataService";
import RelationshipsDataService from "../../services/RelationshipsDataService";
import RelativesDataService from "../../services/RelativesDataService";
import ConvertService from "../../services/ConvertService";
import DocumentsDataService from "../../services/DocumentsDataService";
import DocsDataService from "../../services/DocsDataService";
import HomeIcon from "../HomeIcon.vue";
import PrefixDataService from "../../services/PrefixDataService";
import QualityDetailDataService from "../../services/QualityDetailDataService";
import AddressDataService from "../../services/AddressDataService";
import RelativesAddressDataService from "../../services/RelativesAddressDataService";
import SuspectsDataService from "../../services/SuspectsDataService";
import HistoryCaseDataService from "../../services/HistoryCaseDataService";
import axios from "axios";

export default {
  components: {
    HomeIcon,
  },
  data() {
    return {
      name: "HelloWorld",
      quality: [],
      postal_code_current: "",
      postal_code_house: "",
      relationships: [],
      provinces: [],
      amphures: [],
      districts: [],
      provincescurrent: [],
      amphurescurrent: [],
      districtscurrent: [],
      other: "",
      doclistquality: [],
      sameaddress: false,
      idcardsuspectlist: [],
      id_suspect: "",
      showsearchsuspect: true,
      idcarddescendantlist: [],
      id_descendant: "",
      suspect_id: 0,
      relative_id: 0,
      nationalityother: "",
      quality_id: "",
      imagetype: "",
      imagesrc: "",
      docname: "",
      doctype: "",
      documents: [],
      tempdoc: [],
      prefix: [],
      prefix_des: [],
      list: [],
      qualitylist: [],
      quality_detail_id: 0,
      address_id: 0,
      showlist: false,
      showadd: true,
      imagelistquality: [],
      tempimage: [],
      image: [],
      image_id: "",
      catimageid: "",
      samesuspect: false,
      type: 1,
      qualitylistsus: [],
    };
  },
  mounted() {
    var year = new Date().getFullYear()
    var start = year-2004
    var before = "-"+start+":+0"
    this.quality_id = this.$route.params.id;
    this.getProvinceAll();
    this.getAmphurAll("");
    this.getDistrictAll("");
    this.getProvinceAllcurrent();
    this.getAmphurAllcurrent("");
    this.getDistrictAllcurrent("");
    this.getIDCard();
    this.getIDCardDescendant();
    this.getrelationship();
    picker_date(document.getElementById("quality_date"), {
      year_range: before,
    });
    picker_date(document.getElementById("quality_next_time"), {
      year_range: before,
    });
    if (this.quality_id != 0) {
      this.getlist();
      this.getlistsus();
      this.getquality();
    }
    this.getPrefix();
    this.getDocquality();
    this.getImagequality();
  },
  methods: {
    back() {
      this.$router.push("/qualitylist");
    },
    search() {
      this.list = [];
      this.quality.firstname = "";
      this.quality.lastname = "";
      this.quality.prefix = "";
      QualityDataService.getSuspectByIDCard(this.quality.id_card).then(
        (res) => {
          // console.log(res.data);
          if (res.data.length == 0) {
            alert("ไม่พบข้อมูล");
          } else {
            this.copysuspect(res.data);
          }
        }
      );
    },
    changerelative() {
      for (let qq = 0; qq < this.list.length; qq++) {
        this.list[qq].select = false;
      }
      if (this.suspect_id == "") {
        alert("กรุณาเลือกข้อมูลผู้เสียหาย");
      } else if (this.samesuspect == false) {
        SuspectsDataService.getaddresssuspect(this.suspect_id).then((res) => {
          // console.log(res.data);
          this.type = 2;
          this.getAmphurAll(res.data.province_house);
          this.getDistrictAll(res.data.district_house);
          this.getpostcode(res.data.sub_district_house);
          this.getAmphurAllcurrent(res.data.province_current);
          this.getDistrictAllcurrent(res.data.district_current);
          this.getpostcodecurrent(res.data.sub_district_current);
          this.relative_id = res.data.suspect_id;
          this.quality.relative_id = res.data.suspect_id;
          this.quality.id_card_relative = res.data.id_card;
          this.quality.prefixrelative = res.data.prefix;
          this.quality.firstnamerelative = res.data.firstname;
          this.quality.lastnamerelative = res.data.lastname;
          this.quality.gender = res.data.gender;
          this.quality.status = res.data.status;
          this.quality.phone = res.data.phone;
          this.quality.relationship = res.data.relationship;
          this.quality.no_house = res.data.no_house;
          this.quality.village_no_house = res.data.village_no_house;
          this.quality.road_house = res.data.road_house;
          this.quality.lane_house = res.data.lane_house;
          this.quality.village_house = res.data.village_house;
          this.quality.province_house = res.data.province_house;
          this.quality.district_house = res.data.district_house;
          this.quality.sub_district_house = res.data.sub_district_house;
          this.postal_code_house = res.data.postal_code_house;
          this.quality.no_current = res.data.no_current;
          this.quality.village_no_current = res.data.village_no_current;
          this.quality.road_current = res.data.road_current;
          this.quality.lane_current = res.data.lane_current;
          this.quality.village_current = res.data.village_current;
          this.quality.province_current = res.data.province_current;
          this.quality.district_current = res.data.district_current;
          this.quality.sub_district_current = res.data.sub_district_current;
          this.postal_code_current = res.data.postal_code_current;
          if (res.data.nationality != "ไทย") {
            this.other = true;
            this.nationalityother = res.data.nationality;
            this.quality.nationality = "อื่นๆ";
          } else {
            this.quality.nationality = res.data.nationality;
          }
          this.sameaddress = res.data.is_same;
        });
      } else {
        this.quality.relative_id = "";
        this.quality.id_card_relative = "";
        this.quality.prefixrelative = "";
        this.quality.firstnamerelative = "";
        this.quality.lastnamerelative = "";
        this.quality.gender = "";
        this.quality.status = "";
        this.quality.phone = "";
        this.quality.relationship = "";
        this.quality.no_house = "";
        this.quality.village_no_house = "";
        this.quality.road_house = "";
        this.quality.lane_house = "";
        this.quality.village_house = "";
        this.quality.province_house = "";
        this.quality.district_house = "";
        this.quality.sub_district_house = "";
        this.postal_code_house = "";
        this.quality.no_current = "";
        this.quality.village_no_current = "";
        this.quality.road_current = "";
        this.quality.lane_current = "";
        this.quality.village_current = "";
        this.quality.province_current = "";
        this.quality.district_current = "";
        this.quality.sub_district_current = "";
        this.postal_code_current = "";
        this.quality.nationality = "";
        this.sameaddress = "";
        this.type = 1;
      }
    },
    addQuality() {
      document.getElementById("quality_date").value = null;
      this.samesuspect = false;
      this.quality_detail_id = 0;
      if (this.quality_id == 0) {
        this.showadd = false;
        this.showlist = true;
        this.getDocquality();
        this.getImagequality();
      } else {
        for (let qq = 0; qq < this.qualitylist.length; qq++) {
          this.qualitylist[qq].select = false;
        }
        QualityDataService.getQuality(this.quality_id).then((res) => {
          this.quality = [];
          this.showadd = false;
          this.showlist = true;
          this.suspect_id = res.data.suspect_id;
          this.quality.suspect_id = res.data.suspect_id;
          this.quality.id_card = res.data.id_card;
          this.quality.prefix = res.data.prefix;
          this.quality.firstname = res.data.firstname;
          this.quality.lastname = res.data.lastname;
          this.copysuspect(this.quality);
          this.getDocquality();
          this.getImagequality();
        });
      }
    },
    convertdatetothai(datetimes) {
      if (datetimes) {
        const date = new Date(datetimes);
        const result = date.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return result;
      } else {
        return "-";
      }
    },
    getPrefix() {
      PrefixDataService.getPrefixs().then((res) => {
        this.prefix = res.data;
        this.prefix_des = res.data;
        // console.log(res.data);
      });
    },
    getDocquality() {
      DocumentsDataService.getDocQuality(this.quality_detail_id, 6).then(
        (res) => {
          this.documents = res.data;
          // console.log(this.documents);
          DocsDataService.getDocByType(6).then((res) => {
            this.doclistquality = res.data;
            // console.log(this.doclistquality);
            for (let d = 0; d < this.doclistquality.length; d++) {
              this.doclistquality[d].document_id = 0;
              for (let dd = 0; dd < this.documents.length; dd++) {
                if (
                  this.doclistquality[d].category_doc_id ==
                  this.documents[dd].category_doc_id
                ) {
                  this.doclistquality[d].document_id =
                    this.documents[dd].document_id;
                  this.doclistquality[d].file_name =
                    this.documents[dd].file_name;
                  this.doclistquality[d].file_type =
                    this.documents[dd].file_type;
                  this.doclistquality[d].file_data =
                    this.documents[dd].file_data;
                }
              }
            }
            this.tempdoc = this.doclistquality;
          });
        }
      );
    },
    getImagequality() {
      DocumentsDataService.getDocQuality(this.quality_detail_id, 9).then(
        (res) => {
          this.image = res.data;
          // console.log(this.documents);
          DocsDataService.getDocByType(9).then((res) => {
            this.imagelistquality = res.data;
            // console.log(this.imagelistquality);
            for (let d = 0; d < this.imagelistquality.length; d++) {
              this.imagelistquality[d].document_id = 0;
              for (let dd = 0; dd < this.image.length; dd++) {
                if (
                  this.imagelistquality[d].category_doc_id ==
                  this.image[dd].category_doc_id
                ) {
                  this.imagelistquality[d].document_id =
                    this.image[dd].document_id;
                  this.imagelistquality[d].file_name = this.image[dd].file_name;
                  this.imagelistquality[d].file_type = this.image[dd].file_type;
                  this.imagelistquality[d].file_data = this.image[dd].file_data;
                }
              }
            }
            this.tempimage = this.imagelistquality;
          });
          // console.log(this.imagelistquality);
        }
      );
    },
    onchange(document_id, catdocid) {
      this.document_id = document_id;
      // console.log(this.document_id);
      this.catdocid = catdocid;
      document.getElementById("my_filequality").click();
    },
    onchangeImage(document_id, catdocid) {
      this.image_id = document_id;
      // console.log(this.document_id);
      this.catimageid = catdocid;
      document.getElementById("my_imagequality").click();
    },
    getsrcimagequality(type, url) {
      this.imagetype = type;
      this.imagesrc = url;
    },
    deletedocsus(id) {
      for (let t = 0; t < this.tempdoc.length; t++) {
        if (this.tempdoc[t].category_doc_id == id) {
          this.tempdoc[t].file_name = "";
          this.tempdoc[t].file_type = "";
          this.tempdoc[t].file_data = "";
        }
      }
      this.getDocTemp();
    },
    deleteimagesus(id) {
      for (let t = 0; t < this.tempimage.length; t++) {
        if (this.tempimage[t].category_doc_id == id) {
          this.tempimage[t].file_name = "";
          this.tempimage[t].file_type = "";
          this.tempimage[t].file_data = "";
        }
      }
      this.getImageTemp();
    },
    onFileChangesus(evt) {
      const files = evt.target.files || evt.dataTransfer.files;
      const selectedImage = evt.target.files[0]; // get first file
      // console.log(selectedImage);
      this.docname = selectedImage.name;
      this.doctype = selectedImage.type;
      if (!files.length) return;
      this.createDoc(files[0]);
    },
    onImageChangesus(evt) {
      const files = evt.target.files || evt.dataTransfer.files;
      const selectedImage = evt.target.files[0]; // get first file
      // console.log(selectedImage);
      this.docname = selectedImage.name;
      this.doctype = selectedImage.type;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createDoc(file) {
      const reader = new FileReader();

      reader.onload = (evt) => {
        this.image = evt.target.result;
        // console.log(this.docid);
        const formData = new FormData();
          formData.append("file", file); // appending file
          //  sending file to the backend
          // var http = "https://api-ri.ponnipa.in.th/upload";
          
    var link = ConvertService.gethttp()
    // console.log(link);
          var http = link+"/uploadquality?name="+this.docname;
          axios
            .post(http, formData)
            .then((res) => {
              console.log(res.data);
            })
            .catch((err) => {
              console.log(err);
            });
        var file_data = link+"/uploads/Quality/"+this.docname;
        for (let t = 0; t < this.tempdoc.length; t++) {
          if (this.tempdoc[t].category_doc_id == this.catdocid) {
            this.tempdoc[t].file_name = this.docname;
            this.tempdoc[t].file_type = this.doctype;
            this.tempdoc[t].file_data = file_data;
          }
        }
        this.getDocTemp();
      };

      reader.readAsDataURL(file);
    },
    createImage(file) {
      const reader = new FileReader();

      reader.onload = (evt) => {
        this.image = evt.target.result;
        const formData = new FormData();
          formData.append("file", file); // appending file
          //  sending file to the backend
          // var http = "https://api-ri.ponnipa.in.th/upload";
          
    var link = ConvertService.gethttp()
    // console.log(link);
          var http = link+"/uploadquality?name="+this.docname;
          axios
            .post(http, formData)
            .then((res) => {
              console.log(res.data);
            })
            .catch((err) => {
              console.log(err);
            });
        var file_data = link+"/uploads/Quality/"+this.docname;
        for (let t = 0; t < this.tempimage.length; t++) {
          if (this.tempimage[t].category_doc_id == this.catimageid) {
            this.tempimage[t].file_name = this.docname;
            this.tempimage[t].file_type = this.doctype;
            this.tempimage[t].file_data = file_data;
          }
        }
        this.getImageTemp();
      };

      reader.readAsDataURL(file);
    },
    getDocTemp() {
      // console.log(this.doclist);
      // console.log(this.tempdoc);
      DocsDataService.getDocByType(6).then((res) => {
        this.doclistquality = res.data;
        for (let d = 0; d < this.doclistquality.length; d++) {
          for (let dd = 0; dd < this.tempdoc.length; dd++) {
            if (
              this.doclistquality[d].category_doc_id ==
              this.tempdoc[dd].category_doc_id
            ) {
              this.doclistquality[d].file_name = this.tempdoc[dd].file_name;
              this.doclistquality[d].file_type = this.tempdoc[dd].file_type;
              this.doclistquality[d].file_data = this.tempdoc[dd].file_data;
            }
          }
        }
      });
      // console.log(this.doclistquality);
    },
    getImageTemp() {
      // console.log(this.doclist);
      // console.log(this.tempimage);
      DocsDataService.getDocByType(9).then((res) => {
        this.imagelistquality = res.data;
        // console.log(this.imagelistquality);
        for (let d = 0; d < this.imagelistquality.length; d++) {
          for (let dd = 0; dd < this.tempimage.length; dd++) {
            if (
              this.imagelistquality[d].category_doc_id ==
              this.tempimage[dd].category_doc_id
            ) {
              this.imagelistquality[d].file_name = this.tempimage[dd].file_name;
              this.imagelistquality[d].file_type = this.tempimage[dd].file_type;
              this.imagelistquality[d].file_data = this.tempimage[dd].file_data;
            }
          }
        }
      });
      // console.log(this.imagelistquality);
    },
    getlist() {
      QualityDetailDataService.getQuality(this.quality_id, 1).then((res) => {
        // console.log(res.data);
        this.qualitylist = res.data;
        for (let q = 0; q < this.qualitylist.length; q++) {
          this.qualitylist[q].select = false;
        }
        if (this.quality_id == 0) {
          this.showlist = false;
        }
      });
    },
    getlistsus() {
      QualityDetailDataService.getQuality(this.quality_id, 2).then((res) => {
        // console.log(res.data);
        this.qualitylistsus = res.data;
        for (let q = 0; q < this.qualitylistsus.length; q++) {
          this.qualitylistsus[q].select = false;
        }
        if (this.quality_id == 0) {
          this.showlist = false;
        }
      });
    },
    selectqulitysus(data) {
      document.getElementById("quality_date").value = null;
      for (let qq = 0; qq < this.qualitylist.length; qq++) {
        this.qualitylist[qq].select = false;
      }
      for (let qq = 0; qq < this.qualitylistsus.length; qq++) {
        this.qualitylistsus[qq].select = false;
        if (
          data.quality_detail_id == this.qualitylistsus[qq].quality_detail_id
        ) {
          this.qualitylistsus[qq].select = true;
        }
      }
      this.quality_detail_id = data.quality_detail_id;
      this.getDocquality();
      this.getImagequality();
      if (this.quality_detail_id != 0) {
        this.showadd = true;
      }
      QualityDetailDataService.getDetailQuality(this.quality_detail_id, 2).then(
        (res) => {
          // console.log(res.data);
          this.getAmphurAll(res.data.province_house);
          this.getDistrictAll(res.data.district_house);
          this.getpostcode(res.data.sub_district_house);
          this.getAmphurAllcurrent(res.data.province_current);
          this.getDistrictAllcurrent(res.data.district_current);
          this.getpostcodecurrent(res.data.sub_district_current);

          this.quality = res.data;
          this.showlist = true;
          this.suspect_id = this.quality.suspect_id;
          this.relative_id = this.quality.relatives_id;
          this.address_id = this.quality.address_id;
          if (this.quality.nationality != null) {
            if (this.quality.nationality != "ไทย") {
              this.other = true;
              this.nationalityother = this.quality.nationality;
              this.quality.nationality = "อื่นๆ";
            }
          }
          if (this.quality.is_same == null) {
            this.sameaddress = false;
          } else {
            this.sameaddress = this.quality.is_same;
          }
          if (this.quality.quality_date != null) {
            this.quality.quality_date = ConvertService.getdate(
              new Date(this.quality.quality_date)
            );
            document.getElementById("quality_date").value =
              this.quality.quality_date;
          }
          if (this.quality.quality_next_time != null) {
            this.quality.quality_next_time = ConvertService.getdate(
              new Date(this.quality.quality_next_time)
            );
            document.getElementById("quality_next_time").value =
              this.quality.quality_next_time;
          }
          this.postal_code_house = this.quality.postal_code_house;
          this.postal_code_current = this.quality.postal_code_current;
          // console.log(res.data);
          // console.log(this.quality);
          this.type = 2;
        }
      );
    },
    selectqulity(data) {
      document.getElementById("quality_date").value = null;
      for (let qq = 0; qq < this.qualitylistsus.length; qq++) {
        this.qualitylistsus[qq].select = false;
      }
      for (let qq = 0; qq < this.qualitylist.length; qq++) {
        this.qualitylist[qq].select = false;
        if (data.quality_detail_id == this.qualitylist[qq].quality_detail_id) {
          this.qualitylist[qq].select = true;
        }
      }
      this.quality_detail_id = data.quality_detail_id;
      this.getDocquality();
      this.getImagequality();
      if (this.quality_detail_id != 0) {
        this.showadd = true;
      }
      QualityDetailDataService.getDetailQuality(this.quality_detail_id, 1).then(
        (res) => {
          this.getAmphurAll(res.data.province_house);
          this.getDistrictAll(res.data.district_house);
          this.getpostcode(res.data.sub_district_house);
          this.getAmphurAllcurrent(res.data.province_current);
          this.getDistrictAllcurrent(res.data.district_current);
          this.getpostcodecurrent(res.data.sub_district_current);
          // console.log(res.data);
          this.quality = res.data;
          this.showlist = true;
          this.suspect_id = this.quality.suspect_id;
          this.relative_id = this.quality.relatives_id;
          this.address_id = this.quality.address_id;
          if (this.quality.nationality != null) {
            if (this.quality.nationality != "ไทย") {
              this.other = true;
              this.nationalityother = this.quality.nationality;
              this.quality.nationality = "อื่นๆ";
            }
          }
          if (this.quality.is_same == null) {
            this.sameaddress = false;
          } else {
            this.sameaddress = this.quality.is_same;
          }
          if (this.quality.quality_date != null) {
            this.quality.quality_date = ConvertService.getdate(
              new Date(this.quality.quality_date)
            );
            document.getElementById("quality_date").value =
              this.quality.quality_date;
          }
          if (this.quality.quality_next_time != null) {
            this.quality.quality_next_time = ConvertService.getdate(
              new Date(this.quality.quality_next_time)
            );
            document.getElementById("quality_next_time").value =
              this.quality.quality_next_time;
          }
          this.postal_code_house = this.quality.postal_code_house;
          this.postal_code_current = this.quality.postal_code_current;
          // console.log(res.data);
          // console.log(this.quality);
          this.type = 1;
        }
      );
    },
    getquality() {
      QualityDataService.getQuality(this.quality_id).then((res) => {
        this.suspect_id = res.data.suspect_id;
        this.quality.id_card = res.data.id_card;
        this.quality.prefix = res.data.prefix;
        this.quality.firstname = res.data.firstname;
        this.quality.lastname = res.data.lastname;
        this.addQuality();
      });
    },
    copydescendant(data) {
      // console.log(data);
      this.samesuspect = false;
      this.relative_id = data.relatives_id;
      RelativesDataService.getRelativeAddressTracking(data.relatives_id).then(
        (res) => {
          this.getAmphurAll(res.data.province_house);
          this.getDistrictAll(res.data.district_house);
          this.getpostcode(res.data.sub_district_house);
          this.getAmphurAllcurrent(res.data.province_current);
          this.getDistrictAllcurrent(res.data.district_current);
          this.getpostcodecurrent(res.data.sub_district_current);
          this.quality.relative_id = data.relatives_id;
          this.quality.id_card_relative = data.id_card_relative;
          this.quality.prefixrelative = data.prefix;
          this.quality.firstnamerelative = data.firstname;
          this.quality.lastnamerelative = data.lastname;
          this.quality.gender = data.gender;
          this.quality.status = data.status;
          this.quality.phone = data.phone;
          this.quality.relationship = data.relationship;
          this.quality.no_house = res.data.no_house;
          this.quality.village_no_house = res.data.village_no_house;
          this.quality.road_house = res.data.road_house;
          this.quality.lane_house = res.data.lane_house;
          this.quality.village_house = res.data.village_house;
          this.quality.province_house = res.data.province_house;
          this.quality.district_house = res.data.district_house;
          this.quality.sub_district_house = res.data.sub_district_house;
          this.postal_code_house = res.data.postal_code_house;
          this.quality.no_current = res.data.no_current;
          this.quality.village_no_current = res.data.village_no_current;
          this.quality.road_current = res.data.road_current;
          this.quality.lane_current = res.data.lane_current;
          this.quality.village_current = res.data.village_current;
          this.quality.province_current = res.data.province_current;
          this.quality.district_current = res.data.district_current;
          this.quality.sub_district_current = res.data.sub_district_current;
          this.postal_code_current = res.data.postal_code_current;
          if (data.nationality != "ไทย") {
            this.other = true;
            this.nationalityother = data.nationality;
            this.quality.nationality = "อื่นๆ";
          } else {
            this.quality.nationality = data.nationality;
          }
          this.sameaddress = res.data.is_same;
          // console.log(this.quality);
          // this.postal_code_house = res.data.lan;
          // this.postal_code_house = res.data.postal_code_house;

          for (let lii = 0; lii < this.list.length; lii++) {
            this.list[lii].select = false;
            if (data.relatives_id == this.list[lii].relatives_id) {
              this.list[lii].select = true;
            }
          }
          this.getDocquality();
          this.getImagequality();
          this.type = 1;
        }
      );
    },
    copysuspect(data) {
      // console.log(data);
      this.suspect_id = data.suspect_id;
      this.quality.suspect_id = data.suspect_id;
      this.quality.id_card = data.id_card;
      this.quality.prefix = data.prefix;
      this.quality.firstname = data.firstname;
      this.quality.lastname = data.lastname;
      // console.log(this.quality);
      this.list = [];
      SuspectsRelativeDataService.getDescendantBySuspect(data.suspect_id).then(
        (res) => {
          // console.log(res.data);
          this.list = res.data;
          for (let ll = 0; ll < this.list.length; ll++) {
            this.list[ll].select = false;
          }
        }
      );
      if (this.quality_id == 0) {
        this.showadd = false;
        this.showlist = true;
      }
    },
    getIDCard() {
      QualityDataService.getIDSuspectQuality().then((res) => {
        this.idcardsuspectlist = res.data;
        // console.log(this.idcardsuspectlist);
      });
    },
    getIDCardDescendant() {
      SuspectsRelativeDataService.getDescendantTracking().then((res) => {
        this.idcarddescendantlist = res.data;
        // console.log(res.data);
      });
    },
    getrelationship() {
      RelationshipsDataService.getRelationshipsActive().then((res) => {
        this.relationships = res.data;
      });
    },
    onChangenational(event) {
      // console.log(event.target.value);
      if (event.target.value == "อื่นๆ") {
        this.other = true;
      } else {
        this.other = false;
      }
    },
    onChangeProvincecurrent(event) {
      // console.log(this.user.province)
      var province_id = event.target.value;
      this.quality.district_current = "";
      this.quality.sub_district_current = "";
      this.postal_code_current = "";
      this.getAmphurAllcurrent(province_id);
    },
    onChangeDistrictcurrent(event) {
      // console.log(this.user.province)
      var amphur_id = event.target.value;
      this.quality.sub_district_current = "";
      this.getDistrictAllcurrent(amphur_id);
    },
    onChangePostalCodecurrent(event) {
      //  console.log(event.target.value)
      var district_id = event.target.value;
      this.getpostcodecurrent(district_id);
    },
    getProvinceAllcurrent() {
      ProvinceDataService.getProvinceAll().then((res) => {
        this.provincescurrent = res.data;
      });
    },
    getAmphurAllcurrent(id) {
      if (id == "") {
        AmphurDataService.getAmphursAll().then((res) => {
          this.amphurescurrent = res.data;
        });
      } else {
        AmphurDataService.getAmphurAll(id).then((res) => {
          this.amphurescurrent = res.data;
        });
      }
    },
    getpostcodecurrent(id) {
      DistrictDataService.getPostCode(id).then((res) => {
        // console.log(res.data[0].POST_CODE);
        this.postal_code_current = res.data[0].POST_CODE;
      });
    },
    getDistrictAllcurrent(id) {
      if (id == "") {
        DistrictDataService.getDistrictsAll().then((res) => {
          this.districtscurrent = res.data;
        });
      } else {
        DistrictDataService.getDistrictAll(id).then((res) => {
          this.districtscurrent = res.data;
          this.postal_code_current = "";
        });
      }
    },
    onChangeProvince(event) {
      // console.log(this.user.province)
      var province_id = event.target.value;
      this.quality.district_house = "";
      this.quality.sub_district_house = "";
      this.postal_code_house = "";
      this.getAmphurAll(province_id);
    },

    onChangeDistrict(event) {
      // console.log(this.user.province)
      var amphur_id = event.target.value;
      this.quality.sub_district_house = "";
      this.getDistrictAll(amphur_id);
    },
    onChangePostalCode(event) {
      //  console.log(event.target.value)
      var district_id = event.target.value;
      this.getpostcode(district_id);
    },
    getProvinceAll() {
      ProvinceDataService.getProvinceAll().then((res) => {
        this.provinces = res.data;
      });
    },
    getAmphurAll(id) {
      if (id == "") {
        AmphurDataService.getAmphursAll().then((res) => {
          this.amphures = res.data;
        });
      } else {
        AmphurDataService.getAmphurAll(id).then((res) => {
          this.amphures = res.data;
        });
      }
    },
    getpostcode(id) {
      DistrictDataService.getPostCode(id).then((res) => {
        // console.log(res.data[0].POST_CODE);
        this.postal_code_house = res.data[0].POST_CODE;
      });
    },
    getDistrictAll(id) {
      if (id == "") {
        DistrictDataService.getDistrictsAll().then((res) => {
          this.districts = res.data;
        });
      } else {
        DistrictDataService.getDistrictAll(id).then((res) => {
          this.districts = res.data;
          this.postal_code_house = "";
        });
      }
    },
    changeaddress() {
      if (this.sameaddress == false) {
        this.getAmphurAllcurrent(this.quality.province_house);
        this.getDistrictAllcurrent(this.quality.district_house);
        this.getpostcodecurrent(this.quality.sub_district_house);

        this.quality.no_current = this.quality.no_house;
        this.quality.village_no_current = this.quality.village_no_house;
        this.quality.road_current = this.quality.road_house;
        this.quality.lane_current = this.quality.lane_house;
        this.quality.village_current = this.quality.village_house;
        this.quality.sub_district_current = this.quality.sub_district_house;
        this.quality.district_current = this.quality.district_house;
        this.quality.province_current = this.quality.province_house;
        this.postal_code_current = this.postal_code_house;
      } else if (this.sameaddress == true && this.quality.length == 0) {
        this.quality.no_current = "";
        this.quality.village_no_current = "";
        this.quality.road_current = "";
        this.quality.lane_current = "";
        this.quality.village_current = "";
        this.quality.sub_district_current = "";
        this.quality.district_current = "";
        this.quality.province_current = "";
        this.quality.postal_code_current = "";
        // this.descendants.longitude_current = "";
        // this.descendants.lattitude_current = "";
      }
    },
    save() {
      // console.log(this.quality_detail_id);
      if (this.quality.id_card == null) {
        alert("กรุณากรอกหมายเลขบัตรประชาชนผู้เสียหาย");
      } else if (this.quality.id_card.length < 13) {
        alert("กรุณากรอกหมายเลขบัตรประชาชนผู้เสียหายให้ครบ");
      } else if (this.quality.prefix == null) {
        alert("กรุณากรอกคำนำหน้าชื่อผู้เสียหาย");
      } else if (this.quality.firstname == null) {
        alert("กรุณากรอกชื่อผู้เสียหาย");
      } else if (this.quality.lastname == null) {
        alert("กรุณากรอกนามสกุลผู้เสียหาย");
      } else if (this.quality.id_card_relative == null) {
        alert("กรุณากรอกหมายเลขบัตรประชาชนทายาท");
      } else if (this.quality.id_card_relative.length < 13) {
        alert("กรุณากรอกหมายเลขบัตรประชาชนทายาทให้ครบ");
      } else if (this.quality.prefixrelative == null) {
        alert("กรุณากรอกคำนำหน้าชื่อทายาท");
      } else if (this.quality.firstnamerelative == null) {
        alert("กรุณากรอกชื่อทายาท");
      } else if (this.quality.lastnamerelative == null) {
        alert("กรุณากรอกนามสกุลทายาท");
      } else if (this.quality.province_house == null) {
        alert("กรุณาเลือกจังหวัดปัจจุบัน");
      } else if (this.quality.district_house == null) {
        alert("กรุณาเลือกอำเภอปัจจุบัน");
      } else if (this.quality.sub_district_house == null) {
        alert("กรุณาเลือกตำบลปัจจุบัน");
      } else if (this.quality.province_current == null) {
        alert("กรุณาเลือกจังหวัดตามบัตรประชาชน");
      } else if (this.quality.district_current == null) {
        alert("กรุณาเลือกอำเภอตามบัตรประชาชน");
      } else if (this.quality.sub_district_current == null) {
        alert("กรุณาเลือกตำบลตามบัตรประชาชน");
      }
      // else if (this.quality.gender == null) {
      //   alert("กรุณากรอกเพศทายาท");
      // } else if (this.quality.phone == null) {
      //   alert("กรุณากรอกเบอร์โทรศัพท์ทายาท");
      // } else if (this.quality.relationship == null) {
      //   alert("กรุณาเลือกความสัมพันธ์ทายาท");
      // } else if (this.quality.nationality == null) {
      //   alert("กรุณาเลือกสัญชาติทายาท");
      // }
      else if (document.getElementById("quality_date").value == "") {
        alert("กรุณากรอกวันทึ่ช่วยเหลือคุณภาพชีวิต");
      } else if (this.quality.help_detail == null) {
        alert("ข้อมูลการช่วยเหลือ");
      } else {
        if (this.quality.nationality == "อื่นๆ") {
          this.quality.nationality = this.nationalityother;
        }
        var address = {
          is_same: this.sameaddress,
          no_house: this.quality.no_house,
          village_no_house: this.quality.village_no_house,
          road_house: this.quality.road_house,
          lane_house: this.quality.lane_house,
          village_house: this.quality.village_house,
          sub_district_house: this.quality.sub_district_house,
          district_house: this.quality.district_house,
          province_house: this.quality.province_house,
          postal_code_house: this.postal_code_house,
          no_current: this.quality.no_current,
          village_no_current: this.quality.village_no_current,
          road_current: this.quality.road_current,
          lane_current: this.quality.lane_current,
          village_current: this.quality.village_current,
          sub_district_current: this.quality.sub_district_current,
          district_current: this.quality.district_current,
          province_current: this.quality.province_current,
          postal_code_current: this.postal_code_current,
          // longitude_house: this.quality.longitude_house,
          // lattitude_house: this.descendants.lattitude_house,
          // longitude_current: this.descendants.longitude_current,
          // lattitude_current: this.descendants.lattitude_current,
          created_by: this.currentUser.user_id,
          updated_by: this.currentUser.user_id,
        };
        var track = {
          suspect_id: this.suspect_id,
          created_by: this.currentUser.user_id,
          updated_by: this.currentUser.user_id,
        };
        if (this.quality_detail_id == 0) {
          AddressDataService.createAddress(address).then((res) => {
            // console.log(res.data.id);
            this.address_id = res.data.id;
            var relativeaddress = {
              relative_id: this.relative_id,
              address_id: this.address_id,
              created_by: this.currentUser.user_id,
              updated_by: this.currentUser.user_id,
            };
            // console.log(relativeaddress);
            if (this.type == 2) {
              var suspectaddress = {
                suspect_id: this.suspect_id,
                address_id: this.address_id,
                created_by: this.currentUser.user_id,
                updated_by: this.currentUser.user_id,
              };
              AddressDataService.createSuspectAddress(suspectaddress).then(
                () => {}
              );
            } else {
              RelativesAddressDataService.createRelativeAddress(
                relativeaddress
              ).then(() => {});
            }
            // console.log(res.data);
            if (this.quality_id == 0) {
              QualityDataService.createQuality(track).then((res) => {
                // console.log(res.data);
                this.quality_id = res.data.id;
                var qulitydetail = {
                  quality_id: this.quality_id,
                  relative_id: this.relative_id,
                  address_id: this.address_id,
                  type: this.type,
                  help_detail: this.quality.help_detail,
                  quality_date: ConvertService.createddate(
                    document.getElementById("quality_date").value
                  ),
                  remark: this.quality.remark,
                  created_by: this.currentUser.user_id,
                  updated_by: this.currentUser.user_id,
                };
                // console.log(qulitydetail);
                QualityDetailDataService.createQuality(qulitydetail).then(
                  (res) => {
                    // console.log(res.data);
                    this.quality_detail_id = res.data.id;
                    DocumentsDataService.deleteintegration(
                      this.quality_detail_id,
                      "quality_id"
                    ).then(() => {
                      // console.log(res.data);
                      for (let t = 0; t < this.tempdoc.length; t++) {
                        var doc = {
                          quality_id: this.quality_detail_id,
                          category_doc_id: this.tempdoc[t].category_doc_id,
                          file_name: this.tempdoc[t].file_name,
                          file_type: this.tempdoc[t].file_type,
                          file_data: this.tempdoc[t].file_data,
                          created_by: this.currentUser.user_id,
                          updated_by: this.currentUser.user_id,
                        };
                        //console.log(doc);
                        DocumentsDataService.createDocument(doc).then(() => {
                          // console.log(res.data);
                        });
                      }
                      for (let t = 0; t < this.tempimage.length; t++) {
                        var doc = {
                          quality_id: this.quality_detail_id,
                          category_doc_id: this.tempimage[t].category_doc_id,
                          file_name: this.tempimage[t].file_name,
                          file_type: this.tempimage[t].file_type,
                          file_data: this.tempimage[t].file_data,
                          created_by: this.currentUser.user_id,
                          updated_by: this.currentUser.user_id,
                        };
                        // //console.log(doc);
                        DocumentsDataService.createDocument(doc).then(() => {
                          // console.log(res.data);
                        });
                      }
                      var data={
            quality_id: this.quality_id,
            history_status_id:16,
            user_id:this.currentUser.user_id
          }
          HistoryCaseDataService.createHistory(data).then(()=>{

          })
                      this.$router.push("/quality/" + this.quality_id);  
                      alert("บันทึกสำเร็จ");
                      setTimeout(function () {
                        location.reload();
                      }, 1000);
                      window.scrollTo(0, 0);
                    });
                  }
                );
              });
            } else {
              var qulitydetail = {
                quality_id: this.quality_id,
                relative_id: this.relative_id,
                address_id: this.address_id,
                type: this.type,
                help_detail: this.quality.help_detail,
                quality_date: ConvertService.createddate(
                  document.getElementById("quality_date").value
                ),
                remark: this.quality.remark,
                created_by: this.currentUser.user_id,
                updated_by: this.currentUser.user_id,
              };
              // console.log(qulitydetail);
              QualityDetailDataService.createQuality(qulitydetail).then(
                (res) => {
                  // console.log(res.data);
                  this.quality_detail_id = res.data.id;
                  DocumentsDataService.deleteintegration(
                    this.quality_detail_id,
                    "quality_id"
                  ).then(() => {
                    // console.log(res.data);
                    for (let t = 0; t < this.tempdoc.length; t++) {
                      var doc = {
                        quality_id: this.quality_detail_id,
                        category_doc_id: this.tempdoc[t].category_doc_id,
                        file_name: this.tempdoc[t].file_name,
                        file_type: this.tempdoc[t].file_type,
                        file_data: this.tempdoc[t].file_data,
                        created_by: this.currentUser.user_id,
                        updated_by: this.currentUser.user_id,
                      };
                      // //console.log(doc);
                      DocumentsDataService.createDocument(doc).then(() => {
                        // console.log(res.data);
                      });
                    }
                    for (let t = 0; t < this.tempimage.length; t++) {
                      var doc = {
                        quality_id: this.quality_detail_id,
                        category_doc_id: this.tempimage[t].category_doc_id,
                        file_name: this.tempimage[t].file_name,
                        file_type: this.tempimage[t].file_type,
                        file_data: this.tempimage[t].file_data,
                        created_by: this.currentUser.user_id,
                        updated_by: this.currentUser.user_id,
                      };
                      // //console.log(doc);
                      DocumentsDataService.createDocument(doc).then(() => {
                        // console.log(res.data);
                      });
                    }
                    var data={
            quality_id: this.quality_id,
            history_status_id:17,
            user_id:this.currentUser.user_id
          }
          HistoryCaseDataService.createHistory(data).then(()=>{

          })
                    alert("บันทึกสำเร็จ");
                    setTimeout(function () {
                      location.reload();
                    }, 1000);
                    window.scrollTo(0, 0);
                  });
                }
              );
            }
          });
        } else {
          AddressDataService.updateAddress(this.address_id, address).then(
            () => {
              // console.log(this.quality_detail_id);
              var qulitydetail = {
                quality_id: this.quality_id,
                relative_id: this.relative_id,
                address_id: this.address_id,
                type: this.type,
                help_detail: this.quality.help_detail,
                remark: this.quality.remark,
                quality_date: ConvertService.createddate(
                  document.getElementById("quality_date").value
                ),
                created_by: this.currentUser.user_id,
                updated_by: this.currentUser.user_id,
              };
              // console.log(qulitydetail);
              QualityDetailDataService.updateQuality(
                this.quality_detail_id,
                qulitydetail
              ).then(() => {
                // console.log(this.quality_detail_id);
                DocumentsDataService.deleteintegration(
                  this.quality_detail_id,
                  "quality_id"
                ).then(() => {
                  // console.log(res.data);
                  for (let t = 0; t < this.tempdoc.length; t++) {
                    var doc = {
                      quality_id: this.quality_detail_id,
                      category_doc_id: this.tempdoc[t].category_doc_id,
                      file_name: this.tempdoc[t].file_name,
                      file_type: this.tempdoc[t].file_type,
                      file_data: this.tempdoc[t].file_data,
                      created_by: this.currentUser.user_id,
                      updated_by: this.currentUser.user_id,
                    };
                    // //console.log(doc);
                    DocumentsDataService.createDocument(doc).then(() => {
                      // console.log(res.data);
                    });
                  }
                  for (let t = 0; t < this.tempimage.length; t++) {
                    var doc = {
                      quality_id: this.quality_detail_id,
                      category_doc_id: this.tempimage[t].category_doc_id,
                      file_name: this.tempimage[t].file_name,
                      file_type: this.tempimage[t].file_type,
                      file_data: this.tempimage[t].file_data,
                      created_by: this.currentUser.user_id,
                      updated_by: this.currentUser.user_id,
                    };
                    // //console.log(doc);
                    DocumentsDataService.createDocument(doc).then(() => {
                      // console.log(res.data);
                    });
                  }
                  var data={
            quality_id: this.quality_id,
            history_status_id:17,
            user_id:this.currentUser.user_id
          }
          HistoryCaseDataService.createHistory(data).then(()=>{

          })
                  alert("บันทึกสำเร็จ");
                  setTimeout(function () {
                    location.reload();
                  }, 1000);
                  window.scrollTo(0, 0);
                });
              });
            }
          );
        }
      }
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    filteredList() {
      return this.idcardsuspectlist.filter((post) => {
        this.id_suspect = this.quality.id_card;
        // console.log(this.id_suspect);
        if (this.id_suspect != "") {
          return post.id_card.toLowerCase().includes(this.id_suspect);
        }
      });
    },
    filtereddesList() {
      return this.idcarddescendantlist.filter((post) => {
        this.id_descendant = this.quality.id_card_relative;

        return post.id_card_relative.toLowerCase().includes(this.id_descendant);
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  font-family: "Kanit" !important;
}
.row {
  max-width: 1200px;
  margin: auto;
  background: white;
  padding: 10px;
  color: black !important;
}
a:visited {
  color: black;
} /* visited link */
a:hover {
  color: black;
} /* mouse over link */
a:active {
  color: black;
}
a:link {
  color: black;
}
#my_filequality {
  display: none;
}
#my_imagequality {
  display: none;
}
</style>
