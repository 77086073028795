import http from "../http-common";

class ISOCDetailDataService {
  getQualities(id_card,id_card_des) {
    return http.get('/isoc_detail?id_card='+id_card+'&&id_card_des='+id_card_des);
  }
  getIDSuspectQuality() {
    return http.get('/isoc_detail/getidcardsuspect');
  }
  getQuality(id) {
    return http.get(`/isoc_detail/`+id);
  }
  getDetailQuality(id) {
    return http.get(`/isoc_detail/getdetail/`+id);
  }  
  createQuality(data) {
    return http.post('/isoc_detail', data);
  }
  createISOCHelp(data) {
    return http.post('/isoc_help', data);
  }
  updateQuality(id,data) {
    return http.put(`/isoc_detail/${id}`, data);
  }
  getHelpByID(id) {
    return http.get(`/isoc_help/gethelp/`+id);
  }  
  deleteHelpByID(id) {
    return http.delete(`/isoc_help/${id}`);
  }
}

export default new ISOCDetailDataService();