<template>
  <div>
    <vue-advanced-chat
      height="calc(100vh - 20px)"
      :current-user-id="currentUserId"
      :rooms="JSON.stringify(rooms)"
      :rooms-loaded="true"
      show-audio="false"
      show-add-room="false"
      show-reaction-emojis="false"
      show-emojis="false"
      accepted-files="video/*,image/*"
      :messages="JSON.stringify(messages)"
      :messages-loaded="messagesLoaded"
      @send-message="sendMessage($event.detail[0])"
      @fetch-messages="fetchMessages($event.detail[0])"
      :message-actions="messageActions"
      :text-messages="
        JSON.stringify({
          TYPE_MESSAGE: '',
          CONVERSATION_STARTED: '',
          NEW_MESSAGES: '',
          MESSAGES_EMPTY: '',
        })
      "
    />
  </div>
</template>

<script>
import { register } from "vue-advanced-chat";
// import { register } from '../../vue-advanced-chat/dist/vue-advanced-chat.es.js'
import ChatDataService from "../../services/ChatDataService.js";
import axios from "axios";
// import { saveAs } from 'file-saver';
import ConvertService from "../../services/ConvertService";

register();

export default {
  data() {
    return {
      messageActions: [],
      currentUserId: "",
      rooms: [],
      messages: [],
      messagesLoaded: false,
      timer: null,
      messageslist: [],
    };
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
     getRooms() {
      var listroom = [];
      this.rooms = []
      setInterval(() => {
        ChatDataService.getRooms().then((res) => {
          listroom = res.data;
//             for (let l = 0; l < listroom.length; l++) {
//               ChatDataService.getlastmessage(listroom[l].roomId).then((res)=>{
// listroom[l].lastMessage= {
//       _id: res.data._id,
//       content: res.data.content,
//       senderId: res.data.senderId,
//       username: res.data.username,
//       timestamp: res.data.timestamp,
// }
//     })
//     ChatDataService.getuserinroom(listroom[l].roomId).then((res)=>{
//       if (res.data.length > 0) {
//             var user = [];
//             res.data.forEach((value) => {
//               user.push({
//                 _id: value._id,
// 							username: value.username,
// 							avatar: value.avatar
//               });
//             });
//           }
//           // console.log(user);
// listroom[l].users = user
//     })           
//             }
            // console.log(this.rooms);
            this.rooms = listroom;
            // console.log(JSON.stringify(this.rooms));
          // }
        });
      }, 1000);
    },
    dynamicSort(property) {
      return function (a, b) {
        return a[property] < b[property]
          ? -1
          : a[property] > b[property]
          ? 1
          : 0;
      };
    },
    fetchMessages({ room, options = {} }) {
      this.messagesLoaded = false;
      // console.log(room);
      // console.log(this.rooms);
      this.roomId = room.roomId;
      this.messages = [];                     
      this.getmessage();
      setTimeout(() => {
        if (options.reset) {
          // this.messages = this.getmessage();
          this.timer = setInterval(() => {
            this.getmessageList();
            // console.log(this.messageslist.length);
            // console.log(this.messages.length);
            if (this.messageslist.length !== this.messages.length) {                        
              // this.getRooms()
              // console.log(this.roomId);
 var roomdata = {
          unreadCount:0
        };
        ChatDataService.updateRoomSupport(this.roomId,roomdata).then(() => {
          // this.getRooms()
          // this.roomId = room.roomId
        });
              this.messages = [];
              this.getmessage();  
            }
          }, 1000);
          this.messagesLoaded = true;
        } else {
          this.getmessage();
          this.messagesLoaded = true;
        }
        // this.addNewMessage()
      });
    },

    addMessages(reset) {
      const messages = [];

      for (let i = 0; i < 30; i++) {
        messages.push({
          _id: reset ? i : this.messages.length + i,
          content: `message ${i + 1}`,
          senderId: "4321",
          username: "John Doe",
          date: "13 November",
          timestamp: "10:20",
          seen: true,
          avatar: "/img/logo.79106c0d.jpg",
        });
      }
      messages.push({
        _id: 123,
        content: "test",
        senderId: "1234",
        username: "John Snow",
        date: "5 กันยายน 2565",
        timestamp: "10:20",
        seen: true,
        avatar: "/img/user.f9f26790.png",
        files: [
          {
            name: "logo",
            size: 67351,
            type: "jpg",
            audio: true,
            url: "blob:http://localhost:8081/47ab4469-97a2-45e2-9278-d9fd8f56fc1e",
            // preview:
            //   "https://i.picsum.photos/id/118/1500/1000.jpg?hmac=jumRsHTbfg0frrVd5Xw1187nwNcZCqJbqrdlXpPAIjw",
          },
        ],
      });
      messages.push({
        _id: 12356,
        content: "วิดีโอ",
        senderId: "1234",
        username: "John Snow",
        date: "5 กันยายน 2565",
        timestamp: "10:20",
        seen: true,
        avatar: "/img/user.f9f26790.png",
        files: [
          {
            name: "123",
            size: 67351,
            type: "mp4",
            audio: true,
            url: "blob:http://localhost:8081/47ab4469-97a2-45e2-9278-d9fd8f56fc1e",
          },
          {
            name: "123",
            size: 67351,
            type: "mp4",
            audio: true,
            url: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4",
          },
        ],
      });

      return messages;
    },
    getMessageFile(data) {
      if (this.messages.length != data.length) {
        this.messages = [];
      }
      
      data.forEach((ms,index) => {
        // console.log(ms);
        // console.log(index);
        ChatDataService.getMessageFile(ms._id).then((res) => {
          // console.log(res.data);
          if (res.data.length > 0) {
            var list = [];
            res.data.forEach((value) => {
              list.push({
                name: value.name,
                size: value.size,
                type: value.type,
                audio: true,
                url: value.url,
              });
            });
          }
          ms.created_date = ms.created_date.replace("Z", "");
          var date = new Date(ms.created_date)
          var t = date.getHours()
          var m = date.getMinutes()
          //  console.log(ms.created_date);
          // console.log(date);
          if (String(t).length == 1) {
            t = '0'+t
          }
          if (String(m).length == 1) {
            m = '0'+m
          }
          data[index].timestamp = t + "." + m + " น."
          data[index].date = this.convertdatetothai(date)
          data[index].files = list
        });
      });
      this.messages = data
    },
    convertdatetothai(datetimes) {
      if (datetimes) {
        const date = new Date(datetimes);
        const result = date.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return result;
      } else {
        return "-";
      }
    },
    getmessageList() {
      ChatDataService.getMessage(this.roomId).then((res) => {
        this.getMessageFileList(res.data);
      });
    },
    getMessageFileList(data) {
     if (this.messages.length != data.length) {
        this.messageslist = [];
      this.messageslist = data
      this.messageslist.forEach((ms,index) => {
        // console.log(ms);
        // console.log(index);
        ChatDataService.getMessageFile(ms._id).then((res) => {
          // console.log(res.data);
          if (res.data.length > 0) {
            var list = [];
            res.data.forEach((value) => {
              list.push({
                name: value.name,
                size: value.size,
                type: value.type,
                audio: true,
                url: value.url,
              });
            });
          }
          ms.created_date = ms.created_date.replace("Z", "");
          var date = new Date(ms.created_date)
          var t = date.getHours()
          var m = date.getMinutes()
          // console.log(String(t).length);
          if (String(t).length == 1) {
            t = '0'+t
          }
          if (String(m).length == 1) {
            m = '0'+m
          }
          this.messageslist[index].timestamp = t + "." + m + " น."
          this.messageslist[index].date = this.convertdatetothai(date)
          this.messageslist[index].files = list
        });
      });    
      }
    },
    getmessage() {
      ChatDataService.getMessage(this.roomId).then((res) => {
        this.getMessageFile(res.data);
        // console.log(this.messages);
      });
    },
    onUploadFile(files) {
      // console.log(files);
      const formData = new FormData();
      formData.append("file", files); // appending file
      //  sending file to the backend
      var http = "http://localhost:8080/upload";
      axios
        .post(http, formData)
        .then(() => {
          // console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getnewfile(localUrl, name) {
      axios({
        method: "get",
        url: localUrl, // blob url eg. blob:http://127.0.0.1:8000/e89c5d87-a634-4540-974c-30dc476825cc
        responseType: "blob",
      }).then(function (response) {
        var reader = new FileReader();
        reader.readAsDataURL(response.data);
        reader.onloadend = function () {
          var base64data = reader.result;
          // console.log(base64data);
          var arr = base64data.split(","),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

          while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
          }

          var data = new File([u8arr], name, { type: mime });
          const formData = new FormData();
          formData.append("file", data); // appending file
          //  sending file to the backend

          var link = ConvertService.gethttp();
          // console.log(link);
          var http = link + "/upload?name=" + name;
          axios
            .post(http, formData)
            .then(() => {
              // console.log(res.data);
            })
            .catch((err) => {
              console.log(err);
            });
        };
      });
    },
    sendMessage(message) {
      var data = {
        room_id: this.roomId,
        content: message.content,
        senderId: this.currentUserId,
        username: "",
        seen: false,
        avatar: "/img/logo.79106c0d.jpg",
      };
      ChatDataService.createMessage(data).then((res) => {
        // console.log(res.data);
        if (message.files) {
          // console.log(message.files);
          for (let f = 0; f < message.files.length; f++) {
            this.getnewfile(
              message.files[f].localUrl,
              message.files[f].name + "." + message.files[f].extension
            );
            // console.log(message.files[f]);
            var urlpath =
            // "http://134.122.57.139:81/uploads/Chat/" +
              "https://justice-api.sbpac.go.th/uploads/Chat/" +
              message.files[f].name +
              "." +
              message.files[f].extension;
            var file = {
              message_id: res.data.id,
              name: message.files[f].name,
              size: message.files[f].size,
              type: message.files[f].extension,
              audio: true,
              url: urlpath,
            };
            // console.log(file);
            ChatDataService.createMessageFile(file).then(() => {
              // console.log(res.data);
            });
          }
        }
      });
    },
    sortedArray() {
      return this.messages.sort((a, b) => a._id - b._id);
    },
  },
  mounted() {
    this.getRooms();
    this.currentUserId = this.currentUser.user_id;
    // console.log(this.currentUser.user_id);
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<style >
</style>
