import http from "../http-common";

class DocsDataService {
  getDocTypes(){
    return http.get('/doc_type');
  }
  getDocs(type) {
    if(type == ''){
      return http.get('/doc');
    }else{
      return http.get('/doc?type='+type);
    }
  }
  getDoc(id) {
    return http.get(`/doc/`+id);
  }
  createDoc(data) {
    return http.post('/doc', data);
  }
  updateDoc(id,data) {
    return http.put(`/doc/${id}`, data);
  }
  getDocByType(id) {
    return http.get(`/doc/getdoc/`+id);
  }
  getDocEventByEvent(id){
    return http.get(`/doc/getdocevent/`+id);
  }
}

export default new DocsDataService();