import http from "../http-common";

class DisasterDataService {
  getQualities(id_card,id_card_des) {
    return http.get('/disaster?id_card='+id_card+'&&id_card_des='+id_card_des);
  }
  getSuspectByIDCard(id) {
    return http.get(`/disaster/getidcard/`+id);
  }
  getIDSuspectQuality() {
    return http.get('/disaster/getidcardsuspect');
  }
  getQuality(id) {
    return http.get(`/disaster/`+id);
  }
  createQuality(data) {
    return http.post('/disaster', data);
  }
  updateQuality(id,data) {
    return http.put(`/disaster/${id}`, data);
  }
}

export default new DisasterDataService();