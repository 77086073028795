<template>
  <div class="hello">
    <div
      v-if="currentUser"
      style="text-align: right; margin-top: 10px; margin-right: 10px"
    >
      <router-link to="/home"
        ><i class="fas fa-home fa-lg" style="color: black"></i
      ></router-link>
    </div>
  </div>
</template>

<script>
import AuthService from "../services/auth/auth.service";
export default {
  data() {
    return {
      page: [],
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    this.loadpage();
  },
  methods: {
    loadpage() {
      // console.log(this.currentUser);
      if (this.currentUser) {
        AuthService.getpage(this.currentUser["user_id"]).then(
          (response) => {
            this.page = response.data;
            // console.log(this.page);
          },
          (error) => {
            console.log(error);
          }
        );
      } else {
        this.page = [
          {
            page: "ตรวจสอบข้อมูล",
            icon: "fas fa-check",
            route: "/home",
          },
          // {
          //   page: "ตั้งค่า",
          //   icon: "fas fa-check",
          //   route: "/setting",
          // },
        ];
      }
    },
  },
};
</script>

<style scoped>
body {
  font-family: "Kanit" !important;
}
.box {
  color: black !important;
}
#icon {
  background: #09cc68;
  display: inline-block;
  border-radius: 50px;
  padding: 10px;
}
.row {
  max-width: 700px;
  margin: auto;
  background: white;
  padding: 10px;
  color: black !important;
}
</style>
