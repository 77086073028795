import http from "../http-common";

class CasesEventDataService {
  getCasesEvents() {
    return http.get('/cases_event');
  }
  getCaseEvent(id) {
    return http.get(`/cases_event/`+id);
  }
  createCaseEvent(data) {
    return http.post('/cases_event', data);
  }
  updateCaseEvent(id,data) {
    return http.put(`/cases_event/${id}`, data);
  }  
  getEventByCaseID(id) {
    return http.get(`/cases_event/getevents/`+id);
  }
  deleteCaseEvent(id) {
    return http.delete(`/cases_event/`+id);
  }
}

export default new CasesEventDataService();