<template>
  <div class="hello">
    <table class="table table-bordered" v-if="doclist">
      <thead>
        <tr>
          <th scope="col">เอกสาร</th>
          <th scope="col">ชื่อไฟล์</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(d, i) in doclist" :key="i">
          <td>{{ d.name }}</td>
          <td>
            <a
              :href="d.file_data"
              target="_blank"
            >
              {{ d.file_name }}
            </a>
          </td>
          <td style="text-align: right">
            <input  
                id="my_file_case"
                class="form-control"
                type="file"
                accept="application/pdf"
                @change="onFileChange"
              />
              <button
               :disabled="casesedit.is_edit == 1"
                v-if="!d.file_name"
               id="get_file"
                class="btn btn-success"
                @click="onchange(d.document_id,d.category_doc_id)"
              >
                เพิ่ม
              </button>
            <button
              class="btn btn-danger"
              :disabled="casesedit.is_edit == 1"
              @click="deletedoc(d.document_id)"
              v-if="d.file_name"
            >
              ลบ
            </button>
          </td>
        </tr>
      </tbody>
    </table>
            <div v-else class="mt-5" style="text-align: center">
          <h5>ไม่พบข้อมูลเอกสาร</h5>
        </div>
    <div
      class="modal fade"
      id="showModalImageEvent"
      tabindex="-1"
      role="dialog"
      aria-labelledby="showModalImageEvent"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row" style="height:630px;">
              <div class="col-md-12">
                <object>
                    <embed
                      id="pdfID"
                      type="text/html"
                      width="100%"
                      height="100%"
                      :src="imagesrc"
                    />
                  </object>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DocsDataService from "../../services/DocsDataService";
import DocumentsDataService from "../../services/DocumentsDataService";
import CasesDataService from "../../services/CasesDataService";
import axios from "axios";
import ConvertService from "../../services/ConvertService";

export default {
  data() {
    return {
      name: "HelloWorld",
      doclist: [],
      imagesrc: "",
      tempdoc: [],
      catdocid: "",
      documents: [],
      case_id: "",
      image: "",
      casesedit: [],
      docid:''
    };
  },
  mounted() {
    this.case_id = this.$route.params.id;
    this.getdoc();
    this.getcase()
  },
  methods: {
    getcase(){
       CasesDataService.getCase(this.case_id).then((res) => {
        this.casesedit = res.data;
       });
    },
    getdoc() {
      DocumentsDataService.getDocEvent(this.case_id).then((res) => {
        this.documents = res.data;
        // console.log(res.data);
        DocsDataService.getDocEventByEvent(this.case_id).then((res) => {
          // console.log(res.data);
          this.doclist = res.data;
            for (let d = 0; d < this.doclist.length; d++) {
              this.doclist[d].document_id = 0
              for (let dd = 0; dd < this.documents.length; dd++) {
                if (
                  this.doclist[d].category_doc_id ==
                  this.documents[dd].category_doc_id
                ) {
                  this.doclist[d].document_id = this.documents[dd].document_id;
                  this.doclist[d].file_name = this.documents[dd].file_name;
                  this.doclist[d].file_type = this.documents[dd].file_type;
                  this.doclist[d].file_data = this.documents[dd].file_data;
                }
              }
          }
        });
      });
    },
    onchange(docid,catdocid) {
      this.docid = docid
      this.catdocid = catdocid;
      document.getElementById('my_file_case').click();
    },
    onFileChange(evt) {
      const files = evt.target.files || evt.dataTransfer.files;
      const selectedImage = evt.target.files[0]; // get first file
      // console.log(selectedImage);
      this.docname = selectedImage.name;
      this.doctype = selectedImage.type;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      const reader = new FileReader();

      reader.onload = (evt) => {
        
        this.image = evt.target.result;
        // console.log(this.docid);
        const formData = new FormData();
          formData.append("file", file); // appending file
          //  sending file to the backend
          // var http = "https://api-ri.ponnipa.in.th/upload";
          
    var link = ConvertService.gethttp()
    // console.log(link);
          var http = link+"/uploadevents?name="+this.docname;
          axios
            .post(http, formData)
            .then((res) => {
              console.log(res.data);
            })
            .catch((err) => {
              console.log(err);
            });
        var file_data = link+"/uploads/Events/"+this.docname;
        if (this.docid == 0) {
          var newdoc = {
          case_id: this.case_id,
          category_doc_id: this.catdocid,
          file_name: this.docname,
          file_type: this.doctype,
          file_data: file_data,
          created_by: this.currentUser.user_id,
          updated_by: this.currentUser.user_id,
        };
        // console.log(newdoc);
        DocumentsDataService.createDocument(newdoc).then(() => {
          this.getdoc();
        });
        }else{
          DocumentsDataService.updateDocument(this.docid,newdoc).then(() => {
          this.getdoc();
        });
        }
        
        // console.log(this.docid);
        // this.tempdoc.push({
        //   category_doc_id: this.catdocid,
        //   file_name: this.docname,
        //   file_type: this.doctype,
        //   file_data: this.image,
        // });
        // this.getDocTemp();
      };

      reader.readAsDataURL(file);
    },
    getsrcimage(url) {
      this.imagesrc = url;
    },
    getDocTemp() {
      this.doclist = [];
      // console.log(this.doclist);
      // console.log(this.tempdoc);
      DocsDataService.getDocByType(4).then((res) => {
        this.doclist = res.data;
        for (let d = 0; d < this.doclist.length; d++) {
          for (let dd = 0; dd < this.tempdoc.length; dd++) {
            if (
              this.doclist[d].category_doc_id ==
              this.tempdoc[dd].category_doc_id
            ) {
              this.doclist[d].file_name = this.tempdoc[dd].file_name;
              this.doclist[d].file_type = this.tempdoc[dd].file_type;
              this.doclist[d].file_data = this.tempdoc[dd].file_data;
            }
          }
        }
      });
      // console.log(this.doclist);
    },
    deletedoc(id) {
      DocumentsDataService.deleteDocument(id).then(() => {
        this.getdoc();
      });
      // for (let t = 0; t < this.tempdoc.length; t++) {
      //   if (this.tempdoc[t].category_doc_id == id) {
      //     this.tempdoc.splice(t, 1);
      //   }
      // }
      // this.getDocTemp();
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  font-family: "Kanit" !important;
}
.row {
  max-width: 1200px;
  margin: auto;
  background: white;
  padding: 10px;
  color: black !important;
}
.nav-link {
  color: black !important;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #09cc68;
}
#my_file_case {
    display: none;
}
</style>
