<template>
  <div class="hello" v-if="casesedit.status_id >= 2">
    <div class="row">
      <div class="col-md-12">
        <h5 style="color: #2da3f2;font-weight:bold;">สิทธิ์ที่ได้รับ</h5>
        <div v-if="casesedit.is_edit != 1">
          <div v-if="tab || casesedit.is_edit != 1" align="right" >
          <i v-if="show" class="fas fa-pencil-alt" @click="edit()"></i>
          </div>
        </div>        
        <table class="table table-bordered mt-3" v-if="show">
          <thead>
            <tr>
              <th scope="col" style="background-color: #7bf450;text-align:center">
                รายการช่วยเหลือ
              </th>
              <th
                scope="col"
                style="background-color: #7bf450;text-align:center"
                v-for="h in headevent"
                :key="h.event_type_id"
              >
                {{ h.event_name }}
              </th>
              <th scope="col" style="background-color: #7bf450;text-align:center">หน่วย</th>
            </tr>
          </thead>
          <tbody v-if="headhelp.length > 0">
            <tr v-for="(p, i) in headhelp" :key="i">
              <th scope="row">{{ p.privileged_name }}</th>
              <td style="text-align:right" v-if="one">{{ p.one.toLocaleString()}}</td>
              <td style="text-align:right" v-if="two">{{ p.two.toLocaleString()}}</td>
              <td style="text-align:right" v-if="three">{{ p.three.toLocaleString() }}</td>
              <td style="text-align:center">บาท</td>
            </tr>
            <tr>
              <th scope="col" style="background-color: #7bf450;text-align:center">รวม</th>
              <th v-if="one" scope="col" style="background-color: #7bf450;text-align:right">
                {{ sumone().toLocaleString() }}
              </th>
              <th v-if="two" style="background-color: #7bf450;text-align:right">
                {{ sumtwo().toLocaleString() }}
              </th>
              <th v-if="three" style="background-color: #7bf450;text-align:right">
                {{ sumthree().toLocaleString() }}
              </th>
              <th style="background-color: #7bf450;text-align:center">บาท</th>
            </tr>
          </tbody>
        </table>
         <table class="table table-bordered" v-if="!show">
          <thead>
            <tr>
              <th scope="col" style="background-color: #7bf450">
                รายการช่วยเหลือ
              </th>
              <th
                scope="col"
                style="background-color: #7bf450;text-align:center"
                v-for="h in headevent"
                :key="h.event_type_id"
              >
                {{ h.event_name }}
              </th>
              <th scope="col" style="background-color: #7bf450">หน่วย</th>
            </tr>
          </thead>
          <tbody v-if="headhelp.length > 0">
            <tr v-for="(p, i) in headhelp" :key="i">
              <th scope="row">{{ p.privileged_name }}</th>
              <td v-if="one"><input v-model="p.one" type="text" class="form-control" style="text-align:right" /></td>
              <td v-if="two"><input v-model="p.two" type="text" class="form-control" style="text-align:right" /></td>
              <td v-if="three"><input v-model="p.three" type="text" class="form-control" style="text-align:right" /></td>
              <td>บาท</td>
            </tr>
            <tr>
              <th scope="col" style="background-color: #7bf450">รวม</th>
              <th v-if="one" scope="col" style="background-color: #7bf450;text-align:right">
                {{ sumone().toLocaleString() }}
              </th>
              <th v-if="two" style="background-color: #7bf450;text-align:right">
                {{ sumtwo().toLocaleString() }}
              </th>
              <th v-if="three" style="background-color: #7bf450;text-align:right">
                {{ sumthree().toLocaleString() }}
              </th>
              <th style="background-color: #7bf450">บาท</th>
            </tr>
          </tbody>
        </table>
        <h6 style="font-weight: bold">
          รวมเยียวยาสุทธิ &nbsp;&nbsp;&nbsp;
          {{
            numberWithCommas(sumone() + sumtwo()+ sumthree())
          }}
          &nbsp;&nbsp;&nbsp;บาท
        </h6>
      </div>
    </div>
     <div class="row" v-if="!show">
      <div class="col-md-12 text-center">
        <button
          class="btn btn-success"
          style="color: white; width: 200px"
          @click="save()"
        >
          บันทึก
        </button>
        &nbsp;
        <button
          class="btn btn-danger"
          style="color: white; width: 200px"
          @click="cancle()"
        >
          ยกเลิก
        </button>
      </div>
    </div>
    <div class="row" v-if="show && tab">
      <div class="col-md-12 text-center">
        <button
          class="btn btn-warning"
          style="color: white; width: 200px"
          @click="back()"
        >
          ก่อนหน้า
        </button>&nbsp;
        <button
          class="btn btn-success"
          style="color: white; width: 200px"
          @click="save()"
          :disabled="casesedit.is_edit == 1"
        >
          บันทึกและไปต่อ
        </button>
        &nbsp;
        <button
          class="btn btn-warning"
          style="color: white; width: 200px"
          @click="next()"
        >
          ถัดไป
        </button>
      </div>
    </div>
  </div>
   <div v-else class="mt-5" style="text-align: center">
          <h5>ไม่พบข้อมูลสิทธิ์ที่ได้รับ <br/>กรุณากรอกข้อมูลคณะกรรมการ</h5>
        </div>
</template>

<script>
import CasesPrivilegeDataService from "../../services/CasesPrivilegeDataService.js";
import CasesDataService from "../../services/CasesDataService";
import HistoryCaseDataService from "../../services/HistoryCaseDataService";

export default {
  props: ["msg"],
  components: {},
  data() {
    return {
      head: "0",
      name: "HelloWorld",
      case_id: "",
      headevent: [],
      content: [],
      headpri: [],
      sums: [],
      tab:0,
      one:false,
      two:false,
      three:false,
      show:true,
      casesedit: [],
      headhelp:[]
    };
  },
  methods: {
    sumone(){
      var sum = 0
      for (let h = 0; h < this.headhelp.length; h++) {
        // console.log(this.headhelp[h].one);
        if (this.headhelp[h].one) {
        sum=sum+parseInt(this.headhelp[h].one)
        }
      }
      return sum
    },
    sumtwo(){
      var sum = 0
      for (let h = 0; h < this.headhelp.length; h++) {
        // console.log(this.headhelp[h].two);
        if (this.headhelp[h].two) {
        sum=sum+parseInt(this.headhelp[h].two)
        }
      }
      return sum
    },
    sumthree(){
      var sum = 0
      for (let h = 0; h < this.headhelp.length; h++) {
        // console.log(this.headhelp[h].three);
        if (this.headhelp[h].three) {
        sum=sum+parseInt(this.headhelp[h].three)
        }
      }
      return sum
    },
    getcase(){
       CasesDataService.getCase(this.case_id).then((res) => {
        this.casesedit = res.data;
       });
    },
    edit(){
this.show = false
    },
    cancle(){
this.show = true
    },
    save(){
      // console.log(this.content);
      for (let c = 0; c < this.headhelp.length; c++) {
      //  console.log(this.headhelp[c]);
       if (this.headhelp[c].one) {
         var cases1 = {
           amount:this.headhelp[c].one,
           updated_by: this.currentUser.user_id,
         }
         CasesPrivilegeDataService.updateCasePrivilege(this.headhelp[c].oneid,cases1).then(()=>{
          //  console.log(res.data);
         })
       }
       if (this.headhelp[c].two) {
          var cases2 = {
           amount:this.headhelp[c].two,
           updated_by: this.currentUser.user_id,
         }
         CasesPrivilegeDataService.updateCasePrivilege(this.headhelp[c].twoid,cases2).then(()=>{
          //  console.log(res.data);
         })
       }
       if (this.headhelp[c].three) {
          var cases3 = {
           amount:this.headhelp[c].three,
           updated_by: this.currentUser.user_id,
         }
         CasesPrivilegeDataService.updateCasePrivilege(this.headhelp[c].threeid,cases3).then(()=>{
          //  console.log(res.data);
         })
       }
        this.show = true
      }
      
      var data={
            case_id: this.case_id,
            history_status_id:9,
            user_id:this.currentUser.user_id
          }
          HistoryCaseDataService.createHistory(data).then(()=>{

          })
          alert("บันทึกสำเร็จ");
        setTimeout(function () {
          location.reload();
        }, 300);
    },
    numberWithCommas(x) {
      var r = 0;
      x = parseInt(x);
      if (isNaN(x)) {
        r = "";
      } else if (x == 0) {
        r = 0;
      } else {
        r = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      return r;
    },
    hasHistory() {
      return window.history.length > 2;
    },
    updateprivillege(){
      CasesPrivilegeDataService.getlisthelp(this.case_id).then((res) => {
      for (let c = 0; c < res.data.length; c++) {
      //  console.log(res.data[c]);
       if (res.data[c].one) {
         var cases1 = {
           amount:res.data[c].one,
           updated_by: this.currentUser.user_id,
         }
         CasesPrivilegeDataService.updateCasePrivilege(res.data[c].oneid,cases1).then(()=>{
          //  console.log(res.data);
         })
       }
       if (res.data[c].two) {
          var cases2 = {
           amount:res.data[c].two,
           updated_by: this.currentUser.user_id,
         }
         CasesPrivilegeDataService.updateCasePrivilege(res.data[c].twoid,cases2).then(()=>{
          //  console.log(res.data);
         })
       }
       if (res.data[c].three) {
          var cases3 = {
           amount:res.data[c].three,
           updated_by: this.currentUser.user_id,
         }
         CasesPrivilegeDataService.updateCasePrivilege(res.data[c].threeid,cases3).then(()=>{
          //  console.log(res.data);
         })
       }
      }
      this.getEvent()
      });
    },
    getEvent() {
      CasesPrivilegeDataService.getlisthelp(this.case_id).then((res) => {
        // console.log(res.data);
        this.headhelp = res.data
      })
      
      CasesPrivilegeDataService.getlisthelpsum(this.case_id).then((res) => {
        // console.log(res.data);
        this.sums = res.data
        // this.sums = {
        //     one: res.data[0].one,
        //     two: res.data[0].two,
        //     three: res.data[0].three,
        //   };
      })
      CasesPrivilegeDataService.getgroupCasePrivilegeByID(this.case_id).then(
        (res) => {
          this.headevent = res.data
         for (let h = 0; h < this.headevent.length; h++) {
          if (this.headevent[h].event_type_id == 1) {
              this.one = true                
            }
            if (this.headevent[h].event_type_id == 2) {
              this.two = true
                
            }
            if (this.headevent[h].event_type_id == 3) {
              this.three = true                
            }
         }
        }
      );
    },
    getPrivilege() {
      CasesPrivilegeDataService.getCasePrivilegeByID(this.case_id).then(
        (res) => {
          var privil = res.data;
          // this.content = res.data
          // console.log(res.data);
          var event = this.groupBy(privil, "privileged_id");
          // console.log(data[2][0].event_name);
          // console.log(event);
          if (this.headevent.length == 1) {
            for (let e = 0; e < privil.length; e++) {
            this.headpri.push({
              privileged_id: privil[e].privileged_id,
              privileged_name: privil[e].privileged_name,
            });
          }
          }else{
          for (let e = 0; e < privil.length; e++) {
            this.headpri.push({
              privileged_id: event[e+1][0].privileged_id,
              privileged_name: event[e+1][0].privileged_name,
            });
          }
        }
        }
      );
    },
    getcontent() {
      CasesPrivilegeDataService.getCasePrivilegeByID(this.case_id).then(
        (res) => {
          var privil = res.data;
          // console.log(res.data);
          for (let p = 0; p < this.headpri.length; p++) {
            for (let e = 0; e < this.headevent.length; e++) {
              for (let d = 0; d < privil.length; d++) {
                if (
                  privil[d].privileged_id == this.headpri[p].privileged_id &&
                  privil[d].event_type_id == this.headevent[e].event_type_id
                ) {
                  if (privil[d].event_type_id == 1) {
                    this.headpri[p].one = privil[d].amount;
                    this.headpri[p].oneid = privil[d].cases_privileged_id;
                  } else if (privil[d].event_type_id == 2) {
                    this.headpri[p].twoid = privil[d].cases_privileged_id;
                    this.headpri[p].two = privil[d].amount;
                  } else if (privil[d].event_type_id == 3) {
                    this.headpri[p].threeid = privil[d].cases_privileged_id;
                    this.headpri[p].three = privil[d].amount;
                  }
                }
              }
            }
          }
          this.content = this.headpri;
          // console.log(this.content);
          var one = 0;
          var two = 0;
          var three = 0;
          for (let p = 0; p < this.content.length; p++) {
            // console.log(this.content[p].one);
            if (isNaN(this.content[p].one)) {
              this.content[p].one = 0;
            }
            if (isNaN(this.content[p].two)) {
              this.content[p].two = 0;
            }
            if (isNaN(this.content[p].three)) {
              this.content[p].three = 0;
            }
            one = one + parseInt( this.content[p].one);
            two = two + parseInt(this.content[p].two);
            three = three + parseInt(this.content[p].three);
          }
          // this.sums = {
          //   one: one,
          //   two: two,
          //   three: three,
          // };
        }
      );
    },
    groupBy(array, key) {
      const result = {};
      array.forEach((item) => {
        if (!result[item[key]]) {
          result[item[key]] = [];
        }
        result[item[key]].push(item);
      });
      return result;
    },
    back() {
      this.$router.push("/newtreatment/" + this.case_id + "/3");
      setTimeout(function () {
        location.reload();
      }, 500);
      window.scrollTo(0, 0);
    },
    next() {
      this.$router.push("/info/" + this.case_id);
      setTimeout(function () {
        location.reload();
      }, 300);
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    this.case_id = this.$route.params.id;
      
    this.updateprivillege();
    // this.getPrivilege();
    this.getcontent();
    this.tab = this.$route.params.tab;
    this.getcase()
    // console.log(this.content);
    // console.log(this.sums);
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  font-family: "Kanit" !important;
}
.row {
  max-width: 1200px;
  margin: auto;
  background: white;
  padding: 10px;
  color: black !important;
}
a:visited {
  color: black;
} /* visited link */
a:hover {
  color: black;
} /* mouse over link */
a:active {
  color: black;
}
a:link {
  color: black;
}
</style>
