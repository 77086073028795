<template>
  <div class="hello">
    <div class="mt-5">
      <div class="row mt-5">
        <div class="col-md-12">
          <h5 style="color: #2da3f2; font-weight: bold">รายละเอียดเคส</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-md-2">
          <label>หมายเลขบัตรประชาชน</label>
        </div>
        <div class="col-md-10">
          <label>{{ susspect.id_card }}</label>
        </div>
      </div>
      <div class="row">
        <div class="col-md-2">
          <label>ชื่อ-นามสกุล</label>
        </div>
        <div class="col-md-10">
          <label>{{ susspect.firstname }} {{ susspect.lastname }}</label>
        </div>
      </div>
      <div class="row">
        <div class="col-md-2">
          <label>วันที่ยื่นคำร้อง</label>
        </div>
        <div class="col-md-10">
          <label>{{ convertdatetothai(commit.filing_date) }}</label>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <NewEventCom />
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 mt-3">
          <h5 style="color: #2da3f2; font-weight: bold">กรรมการรับรอง</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group" style="text-align: left">
            <label for="username"
              >วันที่พิจารณา <span style="color: red"> *</span></label
            >
            <input
              :disabled="commit.is_edit == 1"
              id="committee_date"
              class="form-control"
            />
            <!-- <date-picker :disabled="commit.is_edit == 1"
              v-model="commit.committee_date"
               :config="options"
           class="form-control"
            min="1997-01-01"
            max="2030-12-31"
            id="date"
            ></date-picker> -->
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group" style="text-align: left">
            <label for="username">รายละเอียดเพิ่มเติม </label>
            <input
              :disabled="commit.is_edit == 1"
              v-model="commit.detail"
              type="text"
              min="1"
              class="form-control"
              id="username"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-2" style="text-align: left">
          <label for="status"
            >ผลการพิจารณา<span style="color: red"> *</span></label
          >
        </div>
        <div class="col-md-1" style="text-align: left">
          <div class="form-check">
            <input
              :disabled="commit.is_edit == 1"
              v-model="status"
              class="form-check-input"
              id="active"
              value="true"
              type="radio"
              name="active"
            />
            <label class="form-check-label" for="active">รับรอง</label>
          </div>
        </div>
        <div class="col-md-9">
          <div class="form-check" style="text-align: left">
            <input
              :disabled="commit.is_edit == 1"
              v-model="status"
              class="form-check-input"
              id="disactive"
              value="false"
              type="radio"
              name="active"
            />
            <label class="form-check-label" for="disactive">ไม่รับรอง</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h5>แนบเอกสาร</h5>
          <DocCommit />
          <!-- <form>
            <div class="form-group mt-3">
              <label for="exampleFormControlFile1">ไฟล์แนบเอกสารรับรอง</label>
              <input
                type="file"
                class="form-control-file"
                id="exampleFormControlFile1"
              />
            </div>
          </form> -->
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        <button
          class="btn btn-warning"
          style="color: white; width: 200px"
          @click="back()"
        >
          ก่อนหน้า</button
        >&nbsp;
        <button
          class="btn btn-success"
          style="color: white; width: 200px"
          @click="save()"
          :disabled="commit.is_edit == 1"
        >
          บันทึกและไปต่อ
        </button>
        &nbsp;
        <button
          class="btn btn-warning"
          style="color: white; width: 200px"
          @click="next()"
        >
          ถัดไป
        </button>
      </div>
    </div>
  </div>
</template>
  <script src="https://justice-api.sbpac.go.th/picker_date.js"></script>

<script>
import NewEventCom from "../Events/NewEventCom.vue";
import CasesDataService from "../../services/CasesDataService";
import SuspectsDataService from "../../services/SuspectsDataService";
import DocCommit from "./DocCommit.vue";
import ConvertService from "../../services/ConvertService";
import HistoryCaseDataService from "../../services/HistoryCaseDataService";
import CasesPrivilegeDataService from "../../services/CasesPrivilegeDataService";
import CasesEventDataService from "../../services/CasesEventDataService";
import PrivilegeDataService from "../../services/PrivilegeDataService";

 
export default {
  props: ["msg"],
  components: {
    NewEventCom,
    DocCommit,
  },
  data() {
    return {
      head: "0",
      name: "HelloWorld",
      case_id: "",
      susspect: [],
      status: "",
      commit: [],
      privilege: [],
      cases: [],
      options: {
        format: "DD/MM/YYYY",
        useCurrent: false,
      },
    };
  },
  methods: {
    hasHistory() {
      return window.history.length > 2;
    },
    back() {
      this.$router.push("/newtreatment/" + this.case_id + "/2");
      setTimeout(function () {
        location.reload();
      }, 500);
      window.scrollTo(0, 0);
    },
    next() {
      this.$router.push("/newtreatment/" + this.case_id + "/4");
      setTimeout(function () {
        location.reload();
      }, 300);
      window.scrollTo(0, 0);
    },
    cal(start, end) {
      var date1 = new Date(start);
      var date2 = new Date(end);
      var diffTime = date2.getTime() - date1.getTime();
      var diffDay = diffTime / (1000 * 3600 * 24);
      return diffDay;
    },
    getcase() {
      CasesDataService.getCase(this.case_id).then((res) => {
        this.commit = res.data;
        // console.log(res.data.filing_date);
        if (res.data.committee_date != null) {
          this.commit.committee_date = ConvertService.getdate(
            new Date(res.data.committee_date)
          ); 
          document.getElementById("committee_date").value =
            this.commit.committee_date;
        }
var year = new Date().getFullYear()
    var start = year-2004
    var before = "-"+start+":+0"
        picker_date(document.getElementById("committee_date"), {
          year_range: before,
        });
        if (res.data.is_consideration == 1) {
          this.status = true;
        } else if (res.data.is_consideration == 0) {
          this.status = false;
        }
        var suspect_id = res.data.suspect_id;
        SuspectsDataService.getaddresssuspect(suspect_id).then((res) => {
          this.susspect = res.data;
          // console.log(res.data);
        });
      });
    },
    convertdatetothai(datetimes) {
      if (datetimes) {
        const date = new Date(datetimes);
        const result = date.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return result;
      } else {
        return "-";
      }
    },
    getPrivilege() {
      CasesEventDataService.getEventByCaseID(this.case_id).then((res) => {
        this.cases = res.data;
        console.log(res.data);
        PrivilegeDataService.getPrivilegeByCaseID(this.case_id).then((res) => {
          this.privilege = res.data;
          console.log(res.data);
          for (let c = 0; c < this.cases.length; c++) {
            for (let p = 0; p < this.privilege.length; p++) {
              if (
                this.privilege[p].fixed_or_daily == "daily" &&
                this.privilege[p].event_type_id ==
                  this.cases[c].event_type_id &&
                this.privilege[p].case_event_id == this.cases[c].case_event_id
              ) {
                var day = 0;
                day = this.cal(
                  this.cases[c].detained_start_date,
                  this.cases[c].detained_end_date
                );
                this.privilege[p].event_type_id = this.cases[c].event_type_id;
                this.privilege[p].amount = this.privilege[p].amount * day;
              }
              // else if (
              //   this.privilege[p].event_type_id !=
              //     this.cases[c].event_type_id
              // ){
              //   this.privilege.push({
              //     event_type_id:this.cases[c].event_type_id,
              //     privileged_id:1,
              //     amount:0
              //   })
              // }
            }
          }

          // console.log(this.privilege);
          CasesPrivilegeDataService.getCasePrivilegeByID(this.case_id).then(
          (res) => {
            console.log(res.data.length);
            if (res.data.length == 0) {
              for (let pp = 0; pp < this.privilege.length; pp++) {
                var CasePrivilege = {
                  case_id: this.case_id,
                  event_type_id: this.privilege[pp].event_type_id,
                  privileged_id: this.privilege[pp].privileged_id,
                  privileged_name:0,
                  event_name:0,
                  amount: this.privilege[pp].amount,
                  created_by: this.currentUser.user_id,
                  updated_by: this.currentUser.user_id,
                };
                // console.log(CasePrivilege);
                CasesPrivilegeDataService.createCasePrivilege(
                  CasePrivilege
                ).then(() => {                  
                });
              }
            }
            // setTimeout(function () {
            //   location.reload();
            // }, 300);
          }
        );
        });
      });
    },
    save() {
      var status_id = "";
      var status = "";
      // console.log(this.status);
      var comm = ConvertService.createddate(document.getElementById("committee_date").value)
      var fill = ConvertService.getdate(Date.now(this.commit.filing_date))
      fill = ConvertService.createddate(fill)
console.log(comm,fill);
      if (document.getElementById("committee_date").value == "") {
        alert("กรุณาเลือกวันที่พิจารณา");
      } 
      else if(comm > fill){
alert('กรุณาเลือกวันที่ให้ถูกต้อง')
      }
      else if (this.status == null) {
        alert("กรุณาเลือกผลการพิจารณา");
      }
      else{
        console.log(this.status);
      if (this.status == "true" || this.status === true) {
        status_id = 2;
        status = 1;
         CasesPrivilegeDataService.getCasePrivilegeGroupEvent(
                    this.case_id
                  ).then((res) => {
                    console.log(res.data);
                    var GroupCount = res.data;
                     PrivilegeDataService.getPrivilegeByCaseID(this.case_id).then((res) => {
         
                      console.log(res.data.length,GroupCount.length);
                      if (GroupCount.length != res.data.length) {
                        for (let o = 0; o < res.data.length; o++) {
                          CasesPrivilegeDataService.deleteCasePrivilege(
                           this.case_id
                          ).then(() => {});
                        }
                        this.getPrivilege()
                      }else{
                        this.getPrivilege()
                      }
                    });
                  });
      } else if (this.status == "false" || this.status === false) {
        status_id = 3;
        status = 0;
      }
      var commit = {
        status_id: status_id,
        detail: this.commit.detail,
        is_consideration: status,
        committee_date: ConvertService.createddate(
          document.getElementById("committee_date").value
        ),
        updated_by: this.currentUser.user_id,
      };
      // console.log(commit);
      // console.log(this.case_id);
      CasesDataService.updateCase(this.case_id, commit).then(() => {
        HistoryCaseDataService.getHistoryCaseByStatusID(
        this.case_id,
        status_id
      ).then((res) => {
        console.log(res.data);
        var history_case_id = res.data.history_case_id;
        if (res.data == "") {
          var data = {
            status_id: status_id,
            case_id: this.case_id,
            created_by: this.currentUser.user_id,
            created_date: ConvertService.createddate(
              document.getElementById("committee_date").value
            ),
          };
          HistoryCaseDataService.createHistoryCase(data).then(() => {
            // console.log(res.data);
            var data={
            case_id: this.case_id,
            history_status_id:7,
            user_id:this.currentUser.user_id
          }
          HistoryCaseDataService.createHistory(data).then(()=>{
alert("บันทึกสำเร็จ");
      setTimeout(function () {
        location.reload();
      }, 300);
      window.scrollTo(0, 0);
          })
          });
        } else {
          var dataupdate = {
            created_date: ConvertService.createddate(
              document.getElementById("committee_date").value
            ),
          };
          // console.log(history_case_id);
          HistoryCaseDataService.updateeHistoryCase(
            history_case_id,
            dataupdate
          ).then(() => {
            var data={
            case_id: this.case_id,
            history_status_id:8,
            user_id:this.currentUser.user_id
          }
          HistoryCaseDataService.createHistory(data).then(()=>{

          })
            alert("บันทึกสำเร็จ");
      setTimeout(function () {
        location.reload();
      }, 300);
      window.scrollTo(0, 0);
          });
        }
      });
      });
     
      // this.getcase();

      
      }
    },
  },
  mounted() {
    this.case_id = this.$route.params.id;
    if (this.case_id != 0) {
      this.getcase();
    }
    // console.log(this.msg);
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  font-family: "Kanit" !important;
}
.row {
  max-width: 1200px;
  margin: auto;
  background: white;
  padding: 10px;
  color: black !important;
}
a:visited {
  color: black;
} /* visited link */
a:hover {
  color: black;
} /* mouse over link */
a:active {
  color: black;
}
a:link {
  color: black;
}
</style>
