<template>
  <div class="card mt-5" style="width: 500px">
    <div class="card-body login-card-body">
      <h3 class="login-box-msg">เข้าสู่ระบบ</h3>
      <form>
        <div class="card-body">
          <div class="form-group">
            <label for="username">ชื่อผู้ใช้งาน</label>
            <input
              v-model="user.id_card"
              v-on:keyup.enter="signIn()"
              type="number"
              min="1"
              @keypress="onlyNumber"
              class="form-control"
              id="username"
              placeholder="กรุณากรอกชื่อผู้ใช้งาน"
            />
          </div>
          <div class="form-group">
            <label for="password">รหัสผ่าน</label>
            <input
              v-on:keyup.enter="signIn()"
              v-model="user.password"
              type="password"
              class="form-control"
              id="password"
              placeholder="กรุณากรอกรหัสผ่าน"
            />
          </div>
          <div class="social-auth-links text-center mb-3 mt-5">
            <a @click="signIn()" class="btn btn-block btn-success">
              เข้าสู่ระบบ
            </a>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "login",
  components: {},
  data() {
    return {
      user: [],
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57 || this.user.id_card.length == 13) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    signIn() {
      if (this.user.id_card == "" || this.user.id_card == null) {
        alert("กรุณากรอกชื่อผู้ใช้งาน");
      } else if (this.user.password == "" || this.user.password == null) {
        alert("กรุณากรอกรหัสผ่าน");
      } else {
        var user = {
          id_card: this.user.id_card,
          password: this.user.password,
        };
        this.$store.dispatch("auth/login", user).then(
          () => {
            // this.loading = true;
            setTimeout(function () {
              location.reload();
            }, 500);
            this.$router.push("/home");
          },
          (error) => {
            alert("ชื่อหรือรหัสผ่านไม่ถูกต้อง");
            console.log(error);
            // this.loading = false;
            // this.message =
            //   (error.response &&
            //     error.response.data &&
            //     error.response.data.message) ||
            //   error.message ||
            //   error.toString();
          }
        );
      }
    },
  },
  mounted() {
    if (this.currentUser) {
      this.$router.push("/home");
    }
  },
};
</script>

<style>
body {
  font-family: "Kanit" !important;
  color: black !important;
}
.card {
  max-width: 500px;
  margin: auto;
  background: white;
  padding: 10px;
  color: black !important;
}
</style>