<template>
  <div class="hello">
    <HomeIcon />
    <div class="mt-5" v-if="currentUser.role_id != 2 && currentUser.role_id != 7">
      <div class="row card mt-2">
        <div class="card-body p-0">
          <h4 style="text-align: center">ค้นหา</h4>
          <div class="row">
            <div class="col-md-2"></div>
            <div class="col-md-4">
              <div class="form-group" style="text-align: left">
                <label for="username">เลขบัตรประชาชน 13 หลัก </label>
                <input
                  v-model="id_card"
                  type="number"
                  class="form-control"
                  id="username"
                  placeholder="เลขบัตรประชาชน 13 หลัก"
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group" style="text-align: left">
                <label for="province">สถานะ </label>
                <select class="form-select mb-3" v-model="status_id">
                  <option
                    v-for="(s, i) in status"
                    v-bind:value="s.status_id"
                    :key="i + 1"
                  >
                    {{ s.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-2"></div>
            <div class="col-md-4">
              <div class="form-group" style="text-align: left">
                <label for="username">วันที่ยื่นคำร้อง</label>
                <input id="fill_start_date" class="form-control" />
                <!-- <date-picker v-model="date" :config="options"></date-picker> -->
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group" style="text-align: left">
                <label for="username">ถึงวันที่ยื่นคำร้อง</label>
                <input id="fill_end_date" class="form-control" />
                <!-- <date-picker v-model="date" :config="options"></date-picker> -->
              </div>
            </div>
          </div>
        </div>
        <div class="col text-center">
          <button
            @click="searchall()"
            class="btn btn-primary"
            style="color: white"
          >
            <i class="fas fa-search"></i> ค้นหา
          </button>
        </div>
      </div>
      <div class="mt-5">
        <div class="row">
          <div class="col-md-6">
            <h4 style="text-align: left" class="mb-5">
              รายการข้อมูลผู้เสียหาย
            </h4>
          </div>
          <div class="col-md-6">
            <h4 style="text-align: right" class="mb-5">
              <a @click="gotorequest()"
                >&nbsp;
                <button type="button" class="btn btn-success">
                  ยื่นเรื่องขออนุมัติเบิกเงิน</button
                >&nbsp;&nbsp;&nbsp;</a
              >
              <a :href="'/paymentlist'"
                >&nbsp;
                <button type="button" class="btn btn-primary">
                  รายการการเงิน</button
                >&nbsp;&nbsp;&nbsp;</a
              >
            </h4>
          </div>
          <div class="col-md-2">เลขบัตรประชาชน</div>
          <div class="col-md-2">ชื่อ-นามสกุล</div>
          <div class="col-md-2">จำนวนเงิน</div>
          <div class="col-md-2">วันที่ยื่นคำร้อง</div>
          <div class="col-md-2">วันที่ส่งเรื่อง</div>
          <div class="col-md-1">สถานะ</div>
          <div class="col-md-1">จัดการ</div>
        </div>
        <div class="row card" v-for="l in pageOfItemsall" :key="l.case_id">
          <div class="card-body p-0">
            <div class="row">
              <div class="col-md-2">
                <i class="fas fa-file-alt" :style="l.colour"></i>
                <span :style="l.colour"> {{ l.id_card }}</span>
              </div>
              <div class="col-md-2" :style="l.colour">
                {{ l.firstname }} {{ l.lastname }}
              </div>
              <div class="col-md-2" :style="l.colour" v-if="l.amount">
                {{ l.amount.toLocaleString() }} บาท 
              </div>
              <div class="col-md-2" :style="l.colour" v-else>
                0 บาท 
              </div>
              <div class="col-md-2" :style="l.colour">
                {{ convertdatetothai(l.filing_date) }}
              </div>
               <div class="col-md-2" :style="l.colour">
                {{ convertdatetothai(l.waiting_date) }}
              </div>
              <div class="col-md-1" :style="l.colour">{{ l.name }}</div>
              <div class="col-md-1" :style="l.colour">
                <a :href="'/caseinfo/' + l.case_id"
                  ><i class="fas fa-eye"></i>&nbsp;</a
                >
                <a v-if="l.status_id == 4" :href="'/caseinfo/' + l.case_id"
                  ><i class="fas fa-check"></i>&nbsp;</a
                >
              </div>
            </div>
          </div>
        </div>
        <div v-if="list.length == 0" style="text-align: center">
          <h5>ไม่พบข้อมูล</h5>
        </div>
      </div>
      <div class="row" align="right">
        <div class="col-md-12">
          <jw-pagination
            :items="list"
            @changePage="onChangePageall"
            :labels="customLabels"
          ></jw-pagination>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col text-center">
          <button
            class="btn btn-warning"
            style="color: white"
            @click="back()"
          >
            ย้อนกลับ
          </button>
        </div>
      </div>
    </div>
    <div class="mt-5"  v-if="currentUser.role_id == 2">
      <div class="row mt-5">
        <div class="col text-right">
          <a href="newtreatment/0/1">
            <button
              class="btn btn-success"
              style="color: white; background-color: #09cc68"
            >
              <i class="fas fa-plus"></i> เพิ่มข้อมูลผู้เสียหาย
            </button></a
          >
        </div>
      </div>
      <div class="row card mt-2">
        <div class="card-body p-0">
          <h4 style="text-align: center">ค้นหา</h4>
          <div class="row">
            <div class="col-md-4">
              <div class="form-group" style="text-align: left">
                <label for="username">เลขบัตรประชาชน 13 หลัก </label>
                <input
                  v-model="id_card"
                  type="number"
                  class="form-control"
                  id="username"
                  placeholder="เลขบัตรประชาชน 13 หลัก"
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group" style="text-align: left">
                <label for="username">วันที่ยื่นคำร้อง</label>
                <input id="fill_start_date" class="form-control" />
                <!-- <date-picker v-model="date" :config="options"></date-picker> -->
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group" style="text-align: left">
                <label for="province">สถานะ </label>
                <select class="form-select mb-3" v-model="status_id">
                  <option
                    v-for="(s, i) in status"
                    v-bind:value="s.status_id"
                    :key="i + 1"
                  >
                    {{ s.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="col text-center">
          <button
            @click="search()"
            class="btn btn-primary"
            style="color: white"
          >
            <i class="fas fa-search"></i> ค้นหา
          </button>
        </div>
      </div>
      <div class="mt-5">
        <div class="row">
          <div class="col-md-12">
            <h4 style="text-align: left" class="mb-5">
              รายการข้อมูลผู้เสียหาย
            </h4>
          </div>
          <div class="col-md-3">เลขบัตรประชาชน</div>
          <div class="col-md-3">ชื่อ-นามสกุล</div>
          <div class="col-md-2">วันที่ยื่นคำร้อง</div>
          <div class="col-md-2">วันที่อนุมัติ</div>
          <div class="col-md-1">สถานะ</div>
          <div class="col-md-1">จัดการ</div>
        </div>
        <div class="row card" v-for="l in pageOfItems" :key="l.case_id">
          <div class="card-body p-0">
            <div class="row">
              <div class="col-md-3">
                <i class="fas fa-file-alt"></i> {{ l.id_card }}
              </div>
              <div class="col-md-3">{{ l.firstname }} {{ l.lastname }}</div>
              <div class="col-md-2">{{ convertdatetothai(l.filing_date) }}</div>
              <div class="col-md-2">
                {{ convertdatetothai(l.committee_date) }}
              </div>
              <div class="col-md-1">{{ l.name }}</div>
              <div class="col-md-1">
                <a :href="'/info/' + l.case_id"
                  ><i class="fas fa-eye"></i>&nbsp;</a
                >
                <a :href="'/newtreatment/' + l.case_id + '/1'"
                  ><i class="fas fa-pencil-alt"></i>&nbsp;</a
                >
                <a
                  v-if="l.status_id == 1"
                  :href="'/newtreatment/' + l.case_id + '/3'"
                  ><i class="fas fa-check"></i>&nbsp;</a
                >
                <a
                  v-if="l.status_id <= 1"
                  href="#"
                  @click="deletecase(l.case_id)"
                  ><i class="fas fa-trash"></i>&nbsp;</a
                >
              </div>
            </div>
          </div>
        </div>
        <div v-if="list.length == 0" style="text-align: center">
          <h5>ไม่พบข้อมูล</h5>
        </div>
      </div>
      <div class="row" align="right">
        <div class="col-md-12">
          <jw-pagination
            :items="list"
            @changePage="onChangePage"
            :labels="customLabels"
          ></jw-pagination>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col text-center">
          <button
            class="btn btn-warning"
            style="color: white"
            @click="hasHistory() ? $router.go(-1) : $router.push('/')"
          >
            ย้อนกลับ
          </button>
        </div>
      </div>
    </div>
     <div class="mt-5" v-if="currentUser.role_id == 7">
      <div class="row card mt-2">
        <div class="card-body p-0">
          <h4 style="text-align: center">ค้นหา</h4>
          <div class="row">
            <div class="col-md-2"></div>
            <div class="col-md-4">
              <div class="form-group" style="text-align: left">
                <label for="username">เลขบัตรประชาชน 13 หลัก </label>
                <input
                  v-model="id_card"
                  type="number"
                  class="form-control"
                  id="username"
                  placeholder="เลขบัตรประชาชน 13 หลัก"
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group" style="text-align: left">
                <label for="province">สถานะ </label>
                <select class="form-select mb-3" v-model="status_id">
                  <option
                    v-for="(s, i) in status"
                    v-bind:value="s.status_id"
                    :key="i + 1"
                  >
                    {{ s.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-2"></div>
            <div class="col-md-4">
              <div class="form-group" style="text-align: left">
                <label for="username">วันที่ยื่นคำร้อง</label>
                <input id="fill_start_date" class="form-control" />
                <!-- <date-picker v-model="date" :config="options"></date-picker> -->
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group" style="text-align: left">
                <label for="username">ถึงวันที่ยื่นคำร้อง</label>
                <input id="fill_end_date" class="form-control" />
                <!-- <date-picker v-model="date" :config="options"></date-picker> -->
              </div>
            </div>
          </div>
        </div>
        <div class="col text-center">
          <button
            @click="searchall()"
            class="btn btn-primary"
            style="color: white"
          >
            <i class="fas fa-search"></i> ค้นหา
          </button>
        </div>
      </div>
      <div class="mt-5">
        <div class="row">
          <div class="col-md-12">
            <h4 style="text-align: left" class="mb-5">
              รายการข้อมูลผู้เสียหาย
            </h4>
          </div>
          <div class="col-md-2">เลขบัตรประชาชน</div>
          <div class="col-md-2">ชื่อ-นามสกุล</div>
          <div class="col-md-2">จำนวนเงิน</div>
          <div class="col-md-2">วันที่ยื่นคำร้อง</div>
          <div class="col-md-2">วันที่ส่งเรื่อง</div>
          <div class="col-md-1">สถานะ</div>
          <div class="col-md-1">จัดการ</div>
        </div>
        <div class="row card" v-for="l in pageOfItemsall" :key="l.case_id">
          <div class="card-body p-0">
            <div class="row">
              <div class="col-md-2">
                <i class="fas fa-file-alt" :style="l.colour"></i>
                <span :style="l.colour"> {{ l.id_card }}</span>
              </div>
              <div class="col-md-2" :style="l.colour">
                {{ l.firstname }} {{ l.lastname }}
              </div>
              <div class="col-md-2" :style="l.colour" v-if="l.amount">
                {{ l.amount.toLocaleString() }} บาท 
              </div>
              <div class="col-md-2" :style="l.colour" v-else>
                0 บาท 
              </div>
              <div class="col-md-2" :style="l.colour">
                {{ convertdatetothai(l.filing_date) }}
              </div>
               <div class="col-md-2" :style="l.colour">
                {{ convertdatetothai(l.waiting_date) }}
              </div>
              <div class="col-md-1" :style="l.colour">{{ l.name }}</div>
              <div class="col-md-1" :style="l.colour">
                <a :href="'/newtreatment/' + l.case_id + '/1'"
                  ><i class="fas fa-pencil-alt"></i>&nbsp;</a
                >
                <a :href="'/info/' + l.case_id"
                  ><i class="fas fa-eye"></i>&nbsp;</a
                >
              </div>
            </div>
          </div>
        </div>
        <div v-if="list.length == 0" style="text-align: center">
          <h5>ไม่พบข้อมูล</h5>
        </div>
      </div>
      <div class="row" align="right">
        <div class="col-md-12">
          <jw-pagination
            :items="list"
            @changePage="onChangePageall"
            :labels="customLabels"
          ></jw-pagination>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col text-center">
          <button
            class="btn btn-warning"
            style="color: white"
            @click="back()"
          >
            ย้อนกลับ
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

  <script src="https://justice-api.sbpac.go.th/picker_date.js"></script>

<script>
import HomeIcon from "../HomeIcon.vue";
import CasesDataService from "../../services/CasesDataService.js";
import StatusDataService from "../../services/StatusDataService.js";
import ConvertService from "../../services/ConvertService";

const customLabels = {
  first: "<<",
  last: ">>",
  previous: "<",
  next: ">",
};

export default {
  components: {
    HomeIcon,
  },
  data() {
    return {
      name: "HelloWorld",
      list: [],
      status: [],
      pageOfItems: [],
      pageOfItemsall: [],
      options: {
        format: "DD/MM/YYYY",
        useCurrent: false,
      },
      location: "",
      latLong: "",
      id_card: "",
      status_id: "",
      customLabels,
    };
  },
  mounted() {
    localStorage.removeItem("suspect");
    // console.log(this.currentUser.role_id);
    if (this.currentUser.role_id == 7) {
      this.getCaseProvince();
      picker_date(document.getElementById("fill_start_date"), {
        year_range: before,
      });
      picker_date(document.getElementById("fill_end_date"), {
        year_range: before,
      });
    } else if (this.currentUser.role_id == 2) {
      this.getCase();
      picker_date(document.getElementById("fill_start_date"), {
        year_range: before,
      });
    }else if (this.currentUser.role_id != 2 || this.currentUser.role_id != 7) {
      this.getCaseall();
      var year = new Date().getFullYear()
    var start = year-2004
    var before = "-"+start+":+0"
      picker_date(document.getElementById("fill_start_date"), {
        year_range: before,
      });
      picker_date(document.getElementById("fill_end_date"), {
        year_range: before,
      });
    } 
    this.getstatus();
    this.status_id = 0;
    // console.log(this.currentUser);
    // console.log(this.currentUser);
  },
  methods: {
    deletecase(id){
CasesDataService.updatestatusnotran(id).then(()=>{
  if (this.currentUser.role_id != 2 || this.currentUser.role_id != 7) {
      this.getCaseall();
  }else if (this.currentUser.role_id == 7) {
      this.getCaseProvince();
  }else if (this.currentUser.role_id == 2) {
      this.getCase();
  }
  setTimeout(function () {
        location.reload();
      }, 500);
})
    },
    back() {
      this.$router.push("/home");
      setTimeout(function () {
        location.reload();
      }, 500);
    },
    gotorequest() {
      var start = document.getElementById("fill_start_date").value;
      var end = document.getElementById("fill_end_date").value;
      // var dd = new Date(d)
      if (start != "") {
        start = ConvertService.createddate(start);
      } else if (start == "") {
        start = 0;
      }
      if (end != "") {
        end = ConvertService.createddate(end);
      } else if (end == "") {
        end = 0;
      }
      this.$router.push("/requestlist/" + start + "/" + end);
    },
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    },
    onChangePageall(pageOfItems) {
      // update page of items
      this.pageOfItemsall = pageOfItems;
    },
    getstatus() {
      this.status.push({
        status_id: 0,
        name: "ทั้งหมด",
      });
      // console.log(this.status);
      StatusDataService.getStatus().then((res) => {
        // console.log(res.data);
        for (let s = 0; s < res.data.length; s++) {
          this.status.push({
            status_id: res.data[s].status_id,
            name: res.data[s].name,
          });
        }
      });
    },

    convertdatetothai(datetimes) {
      if (datetimes) {
        const date = new Date(datetimes);
        const result = date.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return result;
      } else {
        return "-";
      }
    },
    getCase() {
      CasesDataService.SearchAllCaseByUser(
        '',
        '',
        '',
        this.currentUser.user_id
      ).then((res) => {
        console.log(res.data);
        this.list = res.data;
      });

      // CasesDataService.getCaseByCreated(this.currentUser.user_id).then(
      //   (res) => {
      //     // console.log(res.data);
      //     this.list = res.data;
      //   }
      // );
    },
    getCaseProvince() {
      CasesDataService.SearchAllProvince("", "", "", "",this.currentUser.province).then((res) => {
        // console.log(res.data);
        this.list = res.data;
        for (let ll = 0; ll < this.list.length; ll++) {
          if (this.list[ll].waiting_date != null) {
            var now = ConvertService.getdate(Date.now());
            now = ConvertService.createddate(now);
            var month = new Date(this.list[ll].waiting_date);
            // console.log(month);
            month.setMonth(month.getMonth() + 1);
            month = ConvertService.getdate(month);
            month = ConvertService.createddate(month);
            if (this.list[ll].status_id == 4 && now > month) {
              this.list[ll].colour = "color:red";
            } else {
              this.list[ll].colour = "color:black";
            }
          } else {
            this.list[ll].colour = "color:black";
          }
        }
        // console.log(this.list);
      });
    },
    getCaseall() {
      CasesDataService.SearchAllCase("", "", "", "").then((res) => {
        console.log(res.data);
        this.list = res.data;
        for (let ll = 0; ll < this.list.length; ll++) {
          if (this.list[ll].waiting_date != null) {
            var now = ConvertService.getdate(Date.now());
            now = ConvertService.createddate(now);
            var month = new Date(this.list[ll].waiting_date);
            // console.log(month);
            month.setMonth(month.getMonth() + 1);
            month = ConvertService.getdate(month);
            month = ConvertService.createddate(month);
            if (this.list[ll].status_id == 4 && now > month) {
              this.list[ll].colour = "color:red";
            } else {
              this.list[ll].colour = "color:black";
            }
          } else {
            this.list[ll].colour = "color:black";
          }
        }
        // console.log(this.list);
      });
    },
    hasHistory() {
      return window.history.length > 2;
    },
    searchall() {
      var start = document.getElementById("fill_start_date").value;
      var end = document.getElementById("fill_end_date").value;
      // var dd = new Date(d)
      if (start != "") {
        start = ConvertService.createddate(start);
      } else if (start == "") {
        start = "";
      }
      if (end != "") {
        end = ConvertService.createddate(end);
      } else if (end == "") {
        end = "";
      }
      var s = this.status_id;
      if (this.status_id == 0) {
        s = "";
      }
      // console.log(this.id_card);
      // console.log(da);
      CasesDataService.SearchAllCase(this.id_card, start, end, s).then(
        (res) => {
          // console.log(res.data);
          this.list = res.data;
        }
      );
    },
    search() {
      var da;
      var d = document.getElementById("fill_start_date").value;
      // var dd = new Date(d)
      if (d != "") {
        da = ConvertService.createddate(d);
      } else if (d == "") {
        da = "";
      }
      // if (this.id_card == '') {
      //   this.id_card = null
      // }
      // if (this.status_id == '') {
      //   this.status_id = null
      // }
      var s = this.status_id;
      if (this.status_id == 0) {
        s = "";
      }
      // console.log(this.id_card);
      // console.log(da);
      CasesDataService.SearchAllCaseByUser(
        this.id_card,
        da,
        s,
        this.currentUser.user_id
      ).then((res) => {
        // console.log(res.data);
        this.list = res.data;
      });
    },
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  font-family: "Kanit" !important;
}
.row {
  max-width: 1200px;
  margin: auto;
  background: white;
  padding: 10px;
  color: black !important;
}
a:visited {
  color: black;
} /* visited link */
a:hover {
  color: black;
} /* mouse over link */
a:active {
  color: black;
}
a:link {
  color: black;
}
</style>
