import http from "../http-common";

class MOEDataService {
  getQualities(id_card,id_card_des) {
    return http.get('/moe?id_card='+id_card+'&&id_card_des='+id_card_des);
  }
  getSuspectByIDCard(id) {
    return http.get(`/moe/getidcard/`+id);
  }
  getIDSuspectQuality() {
    return http.get('/moe/getidcardsuspect');
  }
  getQuality(id) {
    return http.get(`/moe/`+id);
  }
  createQuality(data) {
    return http.post('/moe', data);
  }
  updateQuality(id,data) {
    return http.put(`/moe/${id}`, data);
  }
}

export default new MOEDataService();