import Vue from "vue";
import Router from "vue-router";
import Home from './components/Home.vue';
import Login from './components/Login.vue';
import Setting from './components/Setting.vue';
import SettingUser from './components/Setting/Setting-User.vue';
import SettingRole from './components/Setting/Setting-Role.vue';
import SettingOther from './components/Setting/Setting-Other.vue';
import SettingPrivilege from './components/Setting/Setting-Privilege.vue';
import SettingHelp from './components/Setting/Setting-Help.vue';
import Tracking from './components/Tracking/Tracking.vue';
import Treatment from './components/Treatment/Treatment.vue';
import Privilege from './components/Setting/Setting-HelpPrivilege.vue';
import Quality from './components/Quality/Quality.vue';
import Profile from './components/Profile.vue';
import HomeIcon from './components/HomeIcon.vue';
import NewTreatment from './components/Treatment/NewTreatment.vue';
import Info from './components/Treatment/Info.vue';
import TrackingList from './components/Tracking/List.vue';
import QualityList from './components/Quality/List.vue';
import CaseInfo from './components/Treatment/CaseInfo.vue';
import RequestList from './components/Request/List.vue';
import PaymentList from './components/Payment/List.vue';
import Pending from './components/Pending/List.vue';
import Approve from './components/Approve/List.vue';
import Integration from './components/Integration/Integration.vue';
import MOPH from './components/Integration/MOPH/MOPH.vue';
import MSociety from './components/Integration/M_Society/MSociety.vue';
import MOE from './components/Integration/MOE/MOE.vue';
import Disaster from './components/Integration/Disaster/Disaster.vue';
import DLA from './components/Integration/DLA/DLA.vue';
import ISOC from './components/Integration/ISOC/ISOC.vue';
import Chat from './components/Suspect/Chat.vue';
import Support from './components/Suspect/Support.vue';
import Suspect from './components/Suspect/Suspect.vue';
import Report from './components/Report/Report.vue';


Vue.use(Router);
const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      alias: "/home",
      name: "home",
      component: Home
    },
    {
      path: "/login",
      alias: "/login",
      name: "login",
      component: Login
    },
    {
      path: "/profile",
      alias: "/profile",
      name: "profile",
      component: Profile
    },
    {
      path: "/setting",
      alias: "/setting",
      name: "setting",
      component: Setting
    },
    {
      path: "/setting",
      alias: "/setting",
      name: "setting",
      component: Setting
    },
    {
      path: "/user",
      alias: "/user",
      name: "user",
      component: SettingUser
    },
    {
      path: "/role",
      alias: "/role",
      name: "role",
      component: SettingRole
    },
    {
      path: "/setting/:name",
      alias: "/setting",
      name: "setting",
      component: SettingOther
    },
    {
      path: "/settingprivilege",
      alias: "/settingprivilege",
      name: "settingprivilege",
      component: SettingPrivilege
    },
    {
      path: "/settinghelp",
      alias: "/settinghelp",
      name: "settinghelp",
      component: SettingHelp
    },
    {
      path: "/privilege/:id",
      alias: "/privilege",
      name: "privilege",
      component: Privilege
    },
    {
      path: "/newtreatment/:id/:tab",
      alias: "/newtreatment/:id/:tab",
      name: "newtreatment",
      component: NewTreatment
    },
    {
      path: "/treatment",
      alias: "/treatment",
      name: "treatment",
      component: Treatment
    },
    {
      path: "/tracking/:id",
      alias: "/tracking",
      name: "tracking",
      component: Tracking
    },
    {
      path: "/trackinglist",
      alias: "/trackinglist",
      name: "trackinglist",
      component: TrackingList
    },
    {
      path: "/quality/:id",
      alias: "/quality",
      name: "quality",
      component: Quality
    },
    {
      path: "/qualitylist",
      alias: "/qualitylist",
      name: "qualitylist",
      component: QualityList
    },
    {
      path: "/iconhome",
      alias: "/iconhome",
      name: "iconhome",
      component: HomeIcon
    },
    {
      path: "/info/:id",
      alias: "/info",
      name: "info",
      component: Info
    },
    {
      path: "/caseinfo/:id",
      alias: "/caseinfo",
      name: "caseinfo",
      component: CaseInfo
    },
    {
      path: "/requestlist/:start/:end",
      alias: "/requestlist",
      name: "requestlist",
      component: RequestList
    },
    {
      path: "/paymentlist",
      alias: "/paymentlist",
      name: "paymentlist",
      component: PaymentList
    },
    {
      path: "/pending/:id",
      alias: "/pending",
      name: "pending",
      component: Pending
    },
    {
      path: "/approve/:id",
      alias: "/approve",
      name: "approve",
      component: Approve
    },
    {
      path: "/integration/:tab",
      alias: "/integration",
      name: "integration",
      component: Integration
    },
    {
      path: "/integration/moph/:id",
      alias: "/moph",
      name: "moph",
      component: MOPH
    },    
    {
      path: "/integration/m-society/:id",
      alias: "/m-society",
      name: "m-society",
      component: MSociety
    },
    {
      path: "/integration/moe/:id",
      alias: "/moe",
      name: "moe",
      component: MOE
    },
    {
      path: "/integration/disaster/:id",
      alias: "/disaster",
      name: "disaster",
      component: Disaster
    },
    {
      path: "/integration/dla/:id",
      alias: "/dla",
      name: "dla",
      component: DLA
    },
    {
      path: "/integration/isoc/:id",
      alias: "/isoc",
      name: "isoc",
      component: ISOC
    },
    {
      path: "/chat",
      alias: "/chat",
      name: "chat",
      component: Chat
    },
    {
      path: "/support",
      alias: "/support",
      name: "support",
      component: Support
    },
    {
      path: "/suspect/:tab",
      alias: "/suspect",
      name: "suspect",
      component: Suspect
    },
    {
      path: "/report/:tab",
      alias: "/report",
      name: "report",
      component: Report
    },
  ]
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/home','/chat','/suspect/1','/suspect/2','/suspect/3'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');
  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/home');
    // next();
  } else {
    next();
  }

});

export default router;
