import http from "../http-common";

class DistrictDataService {
  getname(id){
    return http.get('/district/'+id);
  }
  getDistricts() {
    return http.get('/district');
  }
  getDistrict(id) {
    return http.get(`/district/?id=`+id);
  
  }
  getDistrictsAll() {
    return http.get('/district/getdistrict');
  }
  getDistrictAll(id) {
    return http.get(`/district/getdistrict/?id=`+id);
  
  }
  getPostCode(id) {
    return http.get(`/district/getpostcode?id=`+id);
  }
}

export default new DistrictDataService();