import http from "../http-common";

class PrefixDataService {
  getPrefixs() {
    return http.get('/prefix');
  }
  getPrefix(id) {
    return http.get('/prefix/' + id);
  }
  getPrefixฤสส() {
    return http.get('/prefix/getprefix');
  }
  createPrefix(data) {
    return http.post('/prefix', data);
  }
  updatePrefix(id,data) {
    return http.put(`/prefix/${id}`, data);
  }
}

export default new PrefixDataService();