import http from "../http-common";

class DetainedDataService {
  getDetained() {
    return http.get('/detained');
  }
  getDetainedAll() {
    return http.get('/detained/getdetained');
  }
  getDetain(id) {
    return http.get(`/detained/`+id);
  }
  createDetain(data) {
    return http.post('/detained', data);
  }
  updateDetain(id,data) {
    return http.put(`/detained/${id}`, data);
  }
}

export default new DetainedDataService();