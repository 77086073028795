class ConvertService {
    getdate(value) {      
    var date = new Date(value);
    // console.log(date);
    var day = date.getDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear()+543;
    // console.log(day,month,year);
    if (day.toString().length == 1) {
      day = "0" + day;
    }
    if (month.toString().length == 1) {
      month = "0" + month;
    }
    return  day+ "/"+month + "/" + year;
  }
  createddate(value) {   
  var streetaddress = String(value);
    var d = streetaddress.substring(0, streetaddress.indexOf("/"));
    var m = streetaddress.substring(5, streetaddress.indexOf("/"));
    m = m.substring(1, 3);
    var y = streetaddress.substring(5);
    var full = m + "/" + d + y;
    // console.log(full);
    var date = new Date(full);
    // console.log(date);
    var day = date.getDate();
    var month = date.getMonth()+1;
    var year = date.getFullYear()-543;
    if (day.toString().length == 1) {
      day = "0" + day;
    }
    if (month.toString().length == 1) {
      month = "0" + month;
    }
    return  year + "-" + month + "-" + day;
  }
  gethttp(){
    // return "http://justice.sbpac.go.th:81";
    return "https://justice-api.sbpac.go.th";
    // return "http://localhost:8080";
  }
}

export default new ConvertService();
