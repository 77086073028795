<template>
  <div class="hello">
    <div class="mt-5">
      <div class="row card mt-2">
        <div class="card-body p-0 mt-3">
          <h4 style="text-align: center">ค้นหา</h4>
          <div class="row">
            <div style="width: 12%"></div>
            <div class="col-md-9">
              <div class="form-group" style="text-align: left">
                <label for="province"
                  >แสดงรายงาน <span style="color: red"> *</span></label
                >
                <select
                  class="form-select mb-3"
                  v-model="type"
                  @change="getsearchtracking"
                >
                  <option value="1">
                    สถิติจำนวนเหตุการณ์ตามปีที่เกิดเหตุการณ์
                  </option>
                  <option value="2">สถิติจำนวนเหตุการณ์ตามจังหวัด</option>
                  <option value="3">
                    สถิติจำนวนผู้เสียหายตามประเภทความเสียหาย
                  </option>
                  <option value="4">
                    สถิติจำนวนผู้เสียหายตามปีที่เกิดเหตุการณ์
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row" v-if="type == 1 || type == 2 || type == 3">
            <div style="width: 12%"></div>
            <div class="col-md-4">
              <div class="form-group" style="text-align: left">
                <label for="username"
                  >วันที่ยื่นคำร้อง <span style="color: red"> *</span></label
                >
                <input id="fill_start_date" class="form-control" />
                <!-- <date-picker v-model="date" :config="options"></date-picker> -->
              </div>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-4">
              <div class="form-group" style="text-align: left">
                <label for="username"
                  >ถึงวันที่ยื่นคำร้อง <span style="color: red"> *</span></label
                >
                <input id="fill_end_date" class="form-control" />
                <!-- <date-picker v-model="date" :config="options"></date-picker> -->
              </div>
            </div>
          </div>
          <div class="row" v-if="type == 2">
            <div style="width: 12%"></div>
            <div class="col-md-3">
              <div class="form-group" style="text-align: left">
                <label for="province">จังหวัดตามทะเบียนบ้าน </label>
                <select
                  class="form-select mb-3"
                  v-model="event.province_house"
                  @change="onChangeProvince"
                  id="province_house"
                >
                  <option
                    v-for="(p, i) in provinces"
                    v-bind:value="p.PROVINCE_ID"
                    :key="i + 2"
                  >
                    {{ p.PROVINCE_NAME }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group" style="text-align: left">
                <label for="province">อำเภอตามทะเบียนบ้าน</label>
                <select
                  class="form-select mb-3"
                  v-model="event.district_house"
                  @change="onChangeDistrict"
                  id="district_house"
                >
                  <option
                    v-for="(a, i) in amphures"
                    v-bind:value="a.AMPHUR_ID"
                    :key="i + 3"
                  >
                    {{ a.AMPHUR_NAME }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group" style="text-align: left">
                <label for="province">ตำบลตามทะเบียนบ้าน</label>
                <select
                  class="form-select mb-3"
                  v-model="event.sub_district_house"
                  id="sub_district_house"
                >
                  <option
                    v-for="(d, i) in districts"
                    v-bind:value="d.DISTRICT_ID"
                    :key="i + 4"
                    :name="d.DISTRICT_NAME"
                  >
                    {{ d.DISTRICT_NAME }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row" v-if="type == 1">
            <div style="width: 12%"></div>
            <div class="col-md-4">
              <div class="form-group" style="text-align: left">
                <label for="province">ลักษณะเหตุการณ์ </label>
                <select class="form-select mb-3" v-model="event.event_type_id">
                  <option
                    v-for="(d, i) in event_type"
                    v-bind:value="d.event_type_id"
                    :key="i + 7"
                  >
                    {{ d.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-1"></div>
          <div class="col-md-4">
            <div class="form-group" style="text-align: left">
              <label for="province">สถานะ </label>
               <multiselect 
    select-Label=""
    selected-Label=""
    deselect-Label=""
    v-model="value" 
    :options="status"
    :multiple="true"
    track-by="name"
    :custom-label="customLabel"
    :close-on-select="false"
    @select=onSelect($event)
    @remove=onRemove($event)
    >
    <span class="checkbox-label" slot="option" slot-scope="scope" @click.self="select(scope.option)">
    {{ scope.option.name }}
      <input class="test" type="checkbox" v-model="scope.option.checked" @focus.prevent/>
      
    </span>
  </multiselect>
              <!-- <select class="form-select mb-3" v-model="event.status_id">
                <option
                  v-for="(d, i) in status"
                  v-bind:value="d.status_id"
                  :key="i + 7"
                >
                  {{ d.name }}
                </option>
              </select> -->
            </div>
          </div>
          </div>
            <div class="row" v-if="type == 2">
          <div style="width: 12%"></div>
          <div class="col-md-4">
            <div class="form-group" style="text-align: left">
              <label for="province">สถานะ </label>
               <multiselect 
    select-Label=""
    selected-Label=""
    deselect-Label=""
    v-model="value" 
    :options="status"
    :multiple="true"
    track-by="name"
    :custom-label="customLabel"
    :close-on-select="false"
    @select=onSelect($event)
    @remove=onRemove($event)
    >
    <span class="checkbox-label" slot="option" slot-scope="scope" @click.self="select(scope.option)">
    {{ scope.option.name }}
      <input class="test" type="checkbox" v-model="scope.option.checked" @focus.prevent/>
      
    </span>
  </multiselect>
            </div>
          </div>
        </div>
          <div class="row" v-if="type == 3">
            <div style="width: 12%"></div>
            <div class="col-md-4">
              <div class="form-group" style="text-align: left">
                <label for="province">ประเภทความเสียหาย </label>
                <select class="form-select mb-3" v-model="event.damage">
                  <option
                    v-for="(d, i) in damage"
                    v-bind:value="d.damage_type_id"
                    :key="i + 7"
                  >
                    {{ d.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-1"></div>
          <div class="col-md-4">
            <div class="form-group" style="text-align: left">
              <label for="province">สถานะ </label>
               <multiselect 
    select-Label=""
    selected-Label=""
    deselect-Label=""
    v-model="value" 
    :options="status"
    :multiple="true"
    track-by="name"
    :custom-label="customLabel"
    :close-on-select="false"
    @select=onSelect($event)
    @remove=onRemove($event)
    >
    <span class="checkbox-label" slot="option" slot-scope="scope" @click.self="select(scope.option)">
    {{ scope.option.name }}
      <input class="test" type="checkbox" v-model="scope.option.checked" @focus.prevent/>
      
    </span>
  </multiselect>
            </div>
          </div>
          </div>
          <div class="row" id="hidefour">
            <div style="width: 12%"></div>
            <div class="col-md-4">
              <div class="form-group" style="text-align: left">
                <label for="username"
                  >วันที่ยื่นคำร้อง <span style="color: red"> *</span></label
                >
                <input id="tracking_start_date" class="form-control" />
                <!-- <date-picker v-model="date" :config="options"></date-picker> -->
              </div>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-4">
              <div class="form-group" style="text-align: left">
                <label for="username"
                  >ถึงวันที่ยื่นคำร้อง <span style="color: red"> *</span></label
                >
                <input id="tracking_end_date" class="form-control" />
                <!-- <date-picker v-model="date" :config="options"></date-picker> -->
              </div>
            </div>
          </div>
          <div class="col text-center">
            <button
              @click="search()"
              class="btn btn-primary mb-3"
              style="color: white"
            >
              <i class="fas fa-search"></i> ค้นหา
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-5" v-if="nodata">
      <div class="card-body p-0">
        <div class="row">
          <div class="col-md-12 mt-3">
            <h5 class="form-group" style="color: #2da3f2; font-weight: bold">
              {{ typename }}
            </h5>
          </div>
        </div>
        <div v-if="type == 1">
          <div class="mb-3" style="text-align: right">
            <button
              class="btn btn-success"
              style="color: white"
              @click="exportTableToExcel('tblData')"
            >
              Export
            </button>
          </div>
          <div> 
          <div class="col-md-3"></div>
          <div class="col-md-7" style="margin: auto">
            <apexchart
              type="bar"
              :options="chartOptions"
              :series="series"
            ></apexchart>
          </div>
          <div class="col-md-2"></div>
        </div>
          <div class="col-md-12 mt-3" id="tblData">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th
                    scope="col"
                    style="text-align: center; background-color: #d9d9d9"
                    colspan="7"
                  >
                    {{ typename }}
                  </th>
                </tr>
                <tr>
                  <th
                    scope="col"
                    style="text-align: center; background-color: #d9d9d9"
                    colspan="7"
                  >
                    วันที่ยื่นคำร้อง {{ fillstart }} ถึง {{ fillend }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th style="text-align: center" scope="row"></th>
                  <th
                    style="text-align: center"
                    v-for="(m, i) in month"
                    :key="'M' + i"
                  >
                    {{ m }}
                  </th>
                  <th
                    style="text-align: center"
                    v-for="(h, i) in privil"
                    :key="'H' + i"
                  >
                    {{ h.name }} (บาท)
                  </th>
                  <th style="text-align: center">
                    รวมจำนวนเงินที่ช่วยเหลือ (บาท)
                  </th>
                </tr>
                <tr v-for="(y, i) in privillist" :key="'Y' + i">
                  <td style="text-align: left" scope="row">{{ y.name }}</td>
                  <td
                    style="text-align: right"
                    v-for="(s, i) in y.data"
                    :key="'S' + i"
                  >
                    {{ s.toLocaleString() || 0 }}
                  </td>
                  <td style="text-align: right" scope="row">
                    {{ y.sum.toLocaleString() }}
                  </td>
                </tr>
                <!-- <tr v-for="(h, i) in head" :key="'H' + i">
                  <td style="text-align: center">{{ h.name }}</td>
                  <td style="text-align: center">{{ h.data1 }}</td>
                  <td style="text-align: center">
                    {{ h.onepri.toLocaleString() }}
                  </td>
                  <td style="text-align: center">
                    {{ h.twopri.toLocaleString() }}
                  </td>
                  <td style="text-align: center">
                    {{ h.threepri.toLocaleString() }}
                  </td>
                  <td style="text-align: center">
                    {{ h.fourpri.toLocaleString() }}
                  </td>
                  <td style="text-align: center">
                    {{
                      (
                        h.onepri +
                        h.twopri +
                        h.threepri +
                        h.fourpri
                      ).toLocaleString()
                    }}
                  </td>
                </tr> -->
              </tbody>
            </table>
          </div>
        </div>
        <div v-if="type == 2">
          <div class="mb-3" style="text-align: right">
            <button
              class="btn btn-success"
              style="color: white"
              @click="exportTableToExcel('tblData2')"
            >
              Export
            </button>
          </div>
          <div class="col-md-3"></div>
          <div class="col-md-7" style="margin: auto">
            <apexchart
              type="bar"
              :options="chartOptions"
              :series="series"
            ></apexchart>
          </div>
          <div class="col-md-2"></div>
        </div>
        <div class="row" v-if="type == 2">
          <div class="col-md-12 mt-3" id="tblData2">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th
                    scope="col"
                    style="text-align: center; background-color: #d9d9d9"
                    colspan="3"
                  >
                    {{ typename }}
                  </th>
                </tr>
                <tr>
                  <th
                    scope="col"
                    style="text-align: center; background-color: #d9d9d9"
                    colspan="3"
                  >
                    วันที่ยื่นคำร้อง {{ fillstart }} ถึง {{ fillend }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th style="text-align: center" scope="row"></th>
                  <th
                    style="text-align: center"
                    v-for="(m, i) in month"
                    :key="'M' + i"
                  >
                    {{ m }}
                  </th>
                  <th style="text-align: center">รวม</th>
                </tr>
                <tr v-for="(h, i) in series" :key="'H' + i">
                  <td style="text-align: left">{{ h.name }}</td>
                  <td
                    style="text-align: right"
                    v-for="(d, i) in h.data"
                    :key="'H' + i"
                  >
                    {{ d }}
                  </td>
                  <td style="text-align: right">{{ h.sum }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-if="type == 3">
          <div class="mb-3" style="text-align: right">
            <button
              class="btn btn-success"
              style="color: white"
              @click="exportTableToExcel('tblData3')"
            >
              Export
            </button>
          </div>
          <div class="col-md-3"></div>
          <div class="col-md-7" style="margin: auto">
            <apexchart
              type="bar"
              :options="chartOptions"
              :series="series"
            ></apexchart>
          </div>
          <div class="col-md-2"></div>
        </div>
        <div class="row" v-if="type == 3">
          <div class="col-md-12 mt-3" id="tblData3">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th
                    scope="col"
                    style="text-align: center; background-color: #d9d9d9"
                    colspan="2"
                  >
                    {{ typename }}
                  </th>
                </tr>
                <tr>
                  <th
                    scope="col"
                    style="text-align: center; background-color: #d9d9d9"
                    colspan="2"
                  >
                    วันที่ยื่นคำร้อง {{ fillstart }} ถึง {{ fillend }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th style="text-align: center" scope="row">
                    ประเภทความเสียหาย
                  </th>
                  <th
                    style="text-align: center"
                    v-for="(m, i) in month"
                    :key="'M' + i"
                  >
                    {{ m }}
                  </th>
                </tr>
                <tr v-for="(h, i) in series" :key="'H' + i">
                  <td style="text-align: left">{{ h.name }}</td>
                  <td
                    style="text-align: right"
                    v-for="(d, i) in h.data"
                    :key="'H' + i"
                  >
                    {{ d }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row" v-if="type == 4">
           <div class="mb-3" style="text-align: right">
            <button
              class="btn btn-success"
              style="color: white"
              @click="exportTableToExcel('tblData4')"
            >
              Export
            </button>
          </div>
          <div class="col-md-12 mt-3" id="tblData4">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th
                    scope="col"
                    style="text-align: center; background-color: #d9d9d9"
                    colspan="5"
                  >
                    {{ typename }}
                  </th>
                </tr>
                <tr>
                  <th
                    scope="col"
                    style="text-align: center; background-color: #d9d9d9"
                    colspan="5"
                  >
                    วันที่ยื่นคำร้อง {{ fillstart }} ถึง {{ fillend }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th style="text-align: center" scope="row">ปี</th>
                  <th
                    style="text-align: center"
                    v-for="(h, i) in head"
                    :key="'H' + i"
                  >
                    {{ h.name }}
                  </th>
                  <th style="text-align: center">รวม</th>
                </tr>
                <tr v-for="(y, i) in year" :key="'Y' + i">
                  <td style="text-align: right" scope="row">{{ y.year }}</td>
                  <td
                    style="text-align: right"
                    v-for="(s, i) in y.data"
                    :key="'S' + i"
                  >
                    {{ s || 0 }}
                  </td>
                  <td style="text-align: right">{{ y.sum }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col text-center">
        <button class="btn btn-warning" style="color: white" @click="back()">
          ย้อนกลับ
        </button>
      </div>
    </div>
  </div>
</template>

  <script src="https://justice-api.sbpac.go.th/picker_date.js"></script>

<script>
const customLabels = {
  first: "<<",
  last: ">>",
  previous: "<",
  next: ">",
};

import CasesEventDataService from "../../services/CasesEventDataService";
import ProvinceDataService from "../../services/ProvinceDataService";
import AmphurDataService from "../../services/AmphurDataService";
import DistrictDataService from "../../services/DistrictDataService";
import EventTypesDataService from "../../services/EventTypesDataService.js";
import DamagesDataService from "../../services/DamagesDataService";
import ReportDataService from "../../services/ReportDataService";
import ConvertService from "../../services/ConvertService";
import StatusDataService from "../../services/StatusDataService";

export default {
   components: {  },
  data() {
    return {
      name: "HelloWorld",
      case_id: 0,
      id_card: "",
      data: {},
      list: [],
      status: [],
      nodata: false,
      event: {},
      provinces: [],
      amphures: [],
      districts: [],
      event_type: [],
      type: 1,
      typename: "สถิติจำนวนเหตุการณ์ตามปีที่เกิดเหตุการณ์",
      damage: [],
      series: [],
      chartOptions: {},
      month: [],
      head: [],
      privil: [],
      year: [],
      privillist: [],
      value: [],
    };
  },
  mounted() {
    var year = new Date().getFullYear()
    var start = year-2004
    var before = "-"+start+":+1"
    console.log(before);
    picker_date(document.getElementById("fill_start_date"), {
      year_range: before,
    });
    picker_date(document.getElementById("fill_end_date"), {
      year_range: before,
    });
    picker_date(document.getElementById("tracking_start_date"), {
      year_range: before,
    });
    picker_date(document.getElementById("tracking_end_date"), {
      year_range: before,
    });
    this.getProvince();
    this.getAmphur(0);
    this.getDistrict(0);
    this.geteventtype();
    this.getdamage();
    this.getstatus();
    this.event.province_house = 0;
    this.event.district_house = 0;
    this.event.sub_district_house = 0;
    this.event.damage = "";
  },
  methods: {
    customLabel (option) {
      return `${option.name}`
    },
    onSelect (option) {
      let index = this.status.findIndex(item => item.name==option.name);
      this.status[index].checked = true;
    },    
    onRemove (option) {
      let index = this.status.findIndex(item => item.name==option.name);
      this.status[index].checked = false;
      // console.log(option.name + "  Removed!! " + option.checked);
    },
    getstatus() {
     StatusDataService.getStatus().then((res) => {
        // console.log(res.data);
        for (let s = 0; s < res.data.length; s++) {
          this.status.push({
            status_id: res.data[s].status_id,
            name: res.data[s].name,
            checked:true
          });
        }
      });
      this.value = this.status
      },
    exportTableToExcel(tableID, filename = "") {
      var downloadLink;
      var dataType = "application/vnd.ms-excel";
      var tableSelect = document.getElementById(tableID);
      var tableHTML = tableSelect.outerHTML.replace(/ /g, "%20");

var file = ''
      if (this.type == 1) {
        file = "สถิติจำนวนเหตุการณ์ตามปีที่เกิดเหตุการณ์.xls";
      }else if (this.type == 2) {
        file = "สถิติจำนวนเหตุการณ์ตามจังหวัด.xls";
      }else if (this.type == 3) {
        file = "สถิติจำนวนผู้เสียหายตามประเภทความเสียหาย.xls";
      }else if (this.type == 4) {
        file = "สถิติจำนวนผู้เสียหายตามปีที่เกิดเหตุการณ์.xls";
      }
      
      // Specify file name
      filename = filename
        ? filename + ".xls"
        : file;

      // Create download link element
      downloadLink = document.createElement("a");

      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        var blob = new Blob(["\ufeff", tableHTML], {
          type: dataType,
        });
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Create a link to the file
        downloadLink.href = "data:" + dataType + ", " + tableHTML;

        // Setting the file name
        downloadLink.download = filename;

        //triggering the function
        downloadLink.click();
      }
    },
    getdamage() {
      DamagesDataService.getDamagesAll().then((res) => {
        this.damage.push({
          damage_type_id: 0,
          name: "ทั้งหมด",
        });
        for (let r = 0; r < res.data.length; r++) {
          this.damage.push({
            damage_type_id: res.data[r].damage_type_id,
            name: res.data[r].name,
          });
        }
        this.event.damage = this.damage[0].damage_type_id;
      });
    },
    getsearchtracking(evt) {
      // console.log(evt);
      this.type = evt.target.value;
      this.typename = evt.target.options[evt.target.options.selectedIndex].text;
      this.nodata = false;
      if (document.getElementById("fill_start_date")) {
        document.getElementById("fill_start_date").value = null;
      }
       if (document.getElementById("fill_end_date")) {
      document.getElementById("fill_end_date").value = null;
       }
      document.getElementById("hidefour").style.display = "none";
console.log(this.type);
      if (this.type == 4) {
        document.getElementById("hidefour").style.display = "flex";
      }else{
        document.getElementById("hidefour").style.display = "none";
      }
    },
    geteventtype() {
      EventTypesDataService.getEventTypesAll().then((res) => {
        // console.log(res.data);
        this.event_type.push({
          event_type_id: 0,
          name: "ทั้งหมด",
        });
        for (let r = 0; r < res.data.length; r++) {
          this.event_type.push({
            event_type_id: res.data[r].event_type_id,
            name: res.data[r].name,
          });
        }
        this.event.event_type_id = this.event_type[0].event_type_id;
        this.event.event_type_name = this.event_type[0].name;
      });
    },
    onChangeDistrict(event) {
      // console.log(this.user.province)
      var amphur_id = event.target.value;
      this.event.sub_district_house = 0;
      this.getDistrict(amphur_id);
    },
    onChangeProvince(event) {
      // console.log(this.user.province)
      var province_id = event.target.value;
      this.event.district_house = 0;
      this.event.sub_district_house = 0;
      this.getAmphur(province_id);
    },
    getDistrict(id) {
      this.districts = []
      if (id == 0) {
        DistrictDataService.getDistrictsAll().then((res) => {
          this.districts.push({
          DISTRICT_ID: 0,
          DISTRICT_NAME: "ทั้งหมด",
        });
        for (let r = 0; r < res.data.length; r++) {
          this.districts.push({
            DISTRICT_ID: res.data[r].DISTRICT_ID,
            DISTRICT_NAME: res.data[r].DISTRICT_NAME,
          });
        }
        });
      } else {
        DistrictDataService.getDistrictAll(id).then((res) => {
          this.districts.push({
          DISTRICT_ID: 0,
          DISTRICT_NAME: "ทั้งหมด",
        });
        for (let r = 0; r < res.data.length; r++) {
          this.districts.push({
            DISTRICT_ID: res.data[r].DISTRICT_ID,
            DISTRICT_NAME: res.data[r].DISTRICT_NAME,
          });
        }
        });
      }
    },
    getAmphur(id) {
      this.amphures = []
      if (id == 0) {
        AmphurDataService.getAmphursAll().then((res) => {
          // this.amphures = res.data;
          this.amphures.push({
          AMPHUR_ID: 0,
          AMPHUR_NAME: "ทั้งหมด",
        });
        for (let r = 0; r < res.data.length; r++) {
          this.amphures.push({
            AMPHUR_ID: res.data[r].AMPHUR_ID,
            AMPHUR_NAME: res.data[r].AMPHUR_NAME,
          });
        }
        });
      } else {
        AmphurDataService.getAmphurAll(id).then((res) => {
          this.amphures.push({
          AMPHUR_ID: 0,
          AMPHUR_NAME: "ทั้งหมด",
        });
        for (let r = 0; r < res.data.length; r++) {
          this.amphures.push({
            AMPHUR_ID: res.data[r].AMPHUR_ID,
            AMPHUR_NAME: res.data[r].AMPHUR_NAME,
          });
        }
          // console.log(res.data);
        });
      }
      // this.savedraft();
    },
    getProvince() {
      ProvinceDataService.getProvinceAll().then((res) => {
        // this.provinces = res.data;
        // console.log(res.data);
        this.provinces.push({
          PROVINCE_ID: 0,
          PROVINCE_NAME: "ทั้งหมด",
        });
        for (let r = 0; r < res.data.length; r++) {
          this.provinces.push({
            PROVINCE_ID: res.data[r].PROVINCE_ID,
            PROVINCE_NAME: res.data[r].PROVINCE_NAME,
          });
        }
      });
    },
    cal(start, end) {
      var date1 = new Date(start);
      var date2 = new Date(end);
      var diffTime = date2.getTime() - date1.getTime();
      var diffDay = diffTime / (1000 * 3600 * 24);
      return diffDay;
    },
    getEventByCaseID() {
      CasesEventDataService.getEventByCaseID(this.case_id).then((res) => {
        this.list = res.data;
        // console.log(res.data);
      });
    },
    back() {
      this.$router.push("/home");
    },
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    },
    convertdatetothai(datetimes) {
      if (datetimes) {
        const date = new Date(datetimes);
        const result = date.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return result;
      } else {
        return "-";
      }
    },
    hasHistory() {
      return window.history.length > 2;
    },
    getchart() {
      var color = [];
      if (this.type == 3) {
        color = [
          "#1DD26C",
          "#ed57e1",
          "#317F43",
          "#d03a10",
          "#A5A5A5",
          "#aede42",
        ];
      } else {
        color = ["#4472C4", "#FFFF00", "#ED7D31"];
      }
      this.chartOptions = {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: "top", // top, center, bottom
              style: {
                fontSize: "18px",
                fontWeight: "bold"
              }
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: -20,
          style: {
            fontSize: "18px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: this.month,
          labels: {
            style: {
                fontSize: '18px'
            }
       },
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
          },
        },
        title: {
          text: "",
          floating: true,
          offsetY: 330,
          align: "center",
          style: {
            color: "#444",
          },
        },
        legend: {
          fontSize: "18px"
        },
        colors: ["#4472C4", "#FFFF00", "#ED7D31"],
      };
    },
    search() {
      var status = '';
      for (let s = 0; s < this.value.length; s++) {
        status += this.value[s].status_id + ','
        
      }
      if (this.type == 1) {
        if (document.getElementById("fill_start_date").value == "") {
          alert("กรุณากรอกวันที่ยื่นคำร้อง");
        } else if (document.getElementById("fill_end_date").value == "") {
          alert("กรุณากรอกถึงวันที่ยื่นคำร้อง");
        } else {
          var start;
          var end;
          var s = document.getElementById("fill_start_date").value;
          var e = document.getElementById("fill_end_date").value;
          // var dd = new Date(d)
          start = ConvertService.createddate(s);
          end = ConvertService.createddate(e);
          this.fillstart = this.convertdatetothai(start);
          this.fillend = this.convertdatetothai(end);
          var user_id = "";
          if (this.currentUser.role_id != 3 && this.currentUser.role_id != 1) {
            user_id = this.currentUser.user_id;
          }
          ReportDataService.getStat1(
            start,
            end,
            this.event.event_type_id,
            user_id,
            status
          ).then((res) => {
            // console.log(res.data);
            if (res.data[0].month != 0) {
              this.nodata = true;
              this.series = res.data[0].series;
              this.month = res.data[0].month;
              this.year = res.data[0].month;
              this.head = res.data[0].series;
              this.privil = res.data[0].privil;
              this.privillist = res.data[0].privillist;
              //           console.log(res.data[0].privillist);
              // console.log(this.month.length);
              for (let ev = 0; ev < this.privillist.length; ev++) {
                var total = 0;
                for (
                  let index = 0;
                  index < this.privillist[ev].data.length;
                  index++
                ) {
                  if (index >= this.month.length) {
                    total += this.privillist[ev].data[index];
                  }
                }
                this.privillist[ev].sum = total;
              }
              this.getchart();
            } else {
              alert("ไม่พบข้อมูล");
              this.nodata = false;
            }
          });
        }
      } else if (this.type == 2) {
        if (document.getElementById("fill_start_date").value == "") {
          alert("กรุณากรอกวันที่ยื่นคำร้อง");
        } else if (document.getElementById("fill_end_date").value == "") {
          alert("กรุณากรอกถึงวันที่ยื่นคำร้อง");
        } else {
          var start;
          var end;
          var s = document.getElementById("fill_start_date").value;
          var e = document.getElementById("fill_end_date").value;
          // var dd = new Date(d)
          start = ConvertService.createddate(s);
          end = ConvertService.createddate(e);
          this.fillstart = this.convertdatetothai(start);
          this.fillend = this.convertdatetothai(end);
          var user_id = "";
          if (this.currentUser.role_id != 3 && this.currentUser.role_id != 1) {
            user_id = this.currentUser.user_id;
          }
          if (this.event.province_house == null) {
            this.event.province_house = "";
          }
          if (this.event.district_house == null) {
            this.event.district_house = "";
          }
          if (this.event.sub_district_house == null) {
            this.event.sub_district_house = "";
          }
          ReportDataService.getStat2(
            start,
            end,
            this.event.province_house,
            this.event.district_house,
            this.event.sub_district_house,
            user_id,
            status
          ).then((res) => {
            if (res.data[0].month != 0) {
              this.nodata = true;
              this.series = res.data[0].series;
              this.month = res.data[0].month;
              this.getchart();
              this.nodata = true;
              for (let ev = 0; ev < this.series.length; ev++) {
                var total = 0;
                for (
                  let index = 0;
                  index < this.series[ev].data.length;
                  index++
                ) {
                  total += this.series[ev].data[index];
                }
                this.series[ev].sum = total;
              }
            } else {
              alert("ไม่พบข้อมูล");
              this.nodata = false;
            }
          });
        }
      } else if (this.type == 3) {
        if (document.getElementById("fill_start_date").value == "") {
          alert("กรุณากรอกวันที่ยื่นคำร้อง");
        } else if (document.getElementById("fill_end_date").value == "") {
          alert("กรุณากรอกถึงวันที่ยื่นคำร้อง");
        } else {
          var start;
          var end;
          var s = document.getElementById("fill_start_date").value;
          var e = document.getElementById("fill_end_date").value;
          // var dd = new Date(d)
          start = ConvertService.createddate(s);
          end = ConvertService.createddate(e);
          this.fillstart = this.convertdatetothai(start);
          this.fillend = this.convertdatetothai(end);
          var user_id = "";
          if (this.currentUser.role_id != 3 && this.currentUser.role_id != 1) {
            user_id = this.currentUser.user_id;
          }
          ReportDataService.getStat3(
            start,
            end,
            this.event.damage,
            user_id,
            status
          ).then((res) => {
            // console.log(res.data);
            if (res.data.length != 0) {
              this.series = res.data[0].series;
              this.month = res.data[0].month;
              this.getchart();
              this.nodata = true;
            } else {
              alert("ไม่พบข้อมูล");
              this.nodata = false;
            }
          });
        }
      } else if (this.type == 4) {
        if (document.getElementById("tracking_start_date").value == "") {
          alert("กรุณากรอกวันที่ยื่นคำร้อง");
        } else if (document.getElementById("tracking_end_date").value == "") {
          alert("กรุณากรอกถึงวันที่ยื่นคำร้อง");
        } else {
          var start;
          var end;
          var s = document.getElementById("tracking_start_date").value;
          var e = document.getElementById("tracking_end_date").value;
          // var dd = new Date(d)
          start = ConvertService.createddate(s);
          end = ConvertService.createddate(e);
          this.fillstart = this.convertdatetothai(start);
          this.fillend = this.convertdatetothai(end);
          var user_id = "";
          if (this.currentUser.role_id != 3 && this.currentUser.role_id != 1) {
            user_id = this.currentUser.user_id;
          }
          ReportDataService.getStat4(start, end, user_id,
            status).then((res) => {
            // console.log(res.data);
            if (res.data[0].month != 0) {
              this.nodata = true;
              this.head = res.data[0].series;
              this.year = res.data[0].month;
              for (let ev = 0; ev < this.year.length; ev++) {
                var total = 0;
                for (
                  let index = 0;
                  index < this.year[ev].data.length;
                  index++
                ) {
                  total += this.year[ev].data[index];
                }
                this.year[ev].sum = total;
              }
            } else {
              alert("ไม่พบข้อมูล");
              this.nodata = false;
            }
          });
        }
      }
    },
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  font-family: "Kanit" !important;
}
.row {
  max-width: 1200px;
  margin: auto;
  background: white;
  padding: 10px;
  color: black !important;
}
a:visited {
  color: black;
} /* visited link */
a:hover {
  color: black;
} /* mouse over link */
a:active {
  color: black;
}
a:link {
  color: black;
}
#hidefour {
  display: none;
}
</style>
