import http from "../http-common";

class TrackingDataService {
  getTrackings(id_card,date,department) {
    return http.get('/tracking?id_card='+id_card+"&&date="+date+"&&department="+department);
  }
  getIDSuspectTracking() {
    return http.get('/tracking/getidcardsuspect');
  }
  getSuspectByIDCard(id) {
    return http.get(`/tracking/getidcard/`+id);
  }
  getTracking(id) {
    return http.get(`/tracking/`+id);
  }
  getTrackingSame(id) {
    return http.get(`/tracking/getsamesus/`+id);
  }
  getCheckTracking(id) {
    return http.get(`/tracking/getsusandrelate/`+id);
  }
  createTracking(data) {
    return http.post('/tracking', data);
  }
  updateTracking(id,data) {
    return http.put(`/tracking/${id}`, data);
  }
}

export default new TrackingDataService();