<template>
  <div class="hello">
    <HomeIcon />
    <div class="mt-5">
      <div class="row mt-5"></div>
      <div class="row card mt-2">
        <div class="card-body mt-2">
          <h4 style="text-align: center">ระบบการยื่นข้อมูลผู้เสียหาย</h4>
        </div>
        <div style="padding: 50px">
          <div class="row">
            <div class="col-md-12">
              <h5 style="color: #2da3f2;font-weight:bold;">ข้อมูลผู้เสียหาย</h5>
            </div>
          </div>
          <div class="row">
            <div class="col-md-2">
              <h6>วันที่ยื่นคำร้อง</h6>
            </div>
            <div class="col-md-10">
              <h6>{{convertdatetothai(cases.filing_date)}}</h6>
            </div>
          </div>
          <div class="row">
            <div class="col-md-2">
              <h6>หมายเลขบัตรประชาชน</h6>
            </div>
            <div class="col-md-10">
              <h6>{{suspect.id_card}}</h6>
            </div>
          </div>
          <div class="row">
            <div class="col-md-2">
              <h6>ชื่อ-นามสกุล</h6>
            </div>
            <div class="col-md-10">
              <h6>{{suspect.prefix}} {{suspect.firstname}} {{suspect.lastname}}</h6>
            </div>
          </div>
          <div class="row">
            <div class="col-md-2">
              <h6>วัน/เดือน/ปีเกิด</h6>
            </div>
            <div class="col-md-10">
              <h6>{{convertdatetothai(suspect.birthday)}}</h6>
            </div>
          </div>
          <div class="row">
            <div class="col-md-2">
              <h6>เบอร์โทรศัพท์</h6>
            </div>
            <div class="col-md-10">
              <h6>{{suspect.phone}}</h6>
            </div>
          </div>
          <div class="row">
            <div class="col-md-2">
              <h6>เพศ</h6>
            </div>
            <div class="col-md-10">
              <h6>{{suspect.gender}}</h6>
            </div>
          </div>
          <div class="row">
            <div class="col-md-2">
              <h6>อาชีพ</h6>
            </div>
            <div class="col-md-10">
              <h6>{{suspect.career}}</h6>
            </div>
          </div>
          <div class="row">
            <div class="col-md-2">
              <h6>รายได้</h6>
            </div>
            <div class="col-md-10">
                              <h6 v-if="suspect.income">{{ suspect.income.toLocaleString() }} บาทต่อเดือน</h6>
              <h6 v-else> -  บาทต่อเดือน</h6>

            </div>
          </div>
          <div class="row">
            <div class="col-md-2">
              <h6>สัญชาติ</h6>
            </div>
            <div class="col-md-10">
              <h6>{{suspect.nationality}}</h6>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <h6>ที่อยู่ตามบัตรประชาชน</h6>
            </div>
            <div class="col-md-12">
              <h6>บ้านเลขที่ {{ suspect.no_house || "-"}}  หมู่ที่ {{suspect.village_no_house|| '-'}} ซอย {{suspect.road_house|| '-'}} ถนน {{suspect.lane_house|| '-'}} หมู่บ้าน{{suspect.village_house|| '-'}} ตำบล{{suspect.district_house|| '-'}} อำเภอ{{suspect.sub_district_house|| '-'}} จังหวัด{{suspect.province_house|| '-'}} รหัสไปรษณีย์ {{suspect.postal_code_house|| '-'}}</h6>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <h6>ที่อยู่ปัจจุบัน</h6>
            </div>
            <div class="col-md-12">
              <h6>บ้านเลขที่ {{ suspect.no_current || "-"}} หมู่ที่ {{suspect.village_no_current || '-'}} ซอย {{suspect.road_current || '-'}} ถนน {{suspect.lane_current|| '-'}} หมู่บ้าน{{suspect.village_current|| '-'}} ตำบล{{suspect.district_current|| '-'}} อำเภอ{{suspect.sub_district_current|| '-'}} จังหวัด{{suspect.province_current|| '-'}} รหัสไปรษณีย์ {{suspect.postal_code_current|| '-'}}</h6>
            </div>
          </div>
          <!-- <div class="row mt-5">
            <div class="col-md-12">
              <h5 style="color: #2da3f2;font-weight:bold;">ข้อมูลผู้เกี่ยวข้อง</h5>
            </div>
          </div>
          <div class="row">
            <div class="col-md-2">
              <h6>ชื่อ-นามสกุล</h6>
            </div>
            <div class="col-md-10">
              <h6>{{relative.prefix}}{{relative.firstname}} {{relative.lastname}}</h6>
            </div>
          </div>
          <div class="row">
            <div class="col-md-2">
              <h6>เบอร์โทรศัพท์</h6>
            </div>
            <div class="col-md-10">
              <h6>{{relative.phone || '-'}}</h6>
            </div>
          </div>
          <div class="row">
            <div class="col-md-2">
              <h6>ไอดีไลน์</h6>
            </div>
            <div class="col-md-10">
              <h6>{{relative.line_id || '-'}}</h6>
            </div>
          </div>
          <div class="row">
            <div class="col-md-2">
              <h6>ละติจูด</h6>
            </div>
            <div class="col-md-10">
              <h6>{{relative.lattitude_house|| '-'}}</h6>
            </div>
          </div>
          <div class="row">
            <div class="col-md-2">
              <h6>ลองจิจูด</h6>
            </div>
            <div class="col-md-10">
              <h6>{{relative.longitude_house || '-'}}</h6>
            </div>
          </div> -->
          <div class="row mt-5">
            <div class="col-md-12">
              <h5 style="color: #2da3f2; font-weight: bold">
                ข้อมูลผู้เกี่ยวข้อง
              </h5>
              <RelativesView />
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-md-12">
              <h5 style="color: #2da3f2; font-weight: bold">ข้อมูลทายาท</h5>
              <DescendantsView />
            </div>
          </div>
          <div class="row">
            <div class="col-md-2">
              <h5>สถานะ</h5>
            </div>
            <div class="col-md-10">
              <h5>{{cases.name}}</h5>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-md-12">
              <h5 style="color: #2da3f2;font-weight:bold;">ข้อมูลลักษณะเหตุการณ์</h5>
            </div>
            <NewEventCom />
          </div>
          <PrivilegeInfo class="mt-5" />
          <div class="row mt-5" v-if="cases.status_id == 5 || cases.status_id == 2 && currentUser.role_id == 7">
            <div class="col-md-12">
              <h6 style="font-weight:bold">หมายเหตุ</h6>
            </div>
            <div class="col-md-12">
              <h6 style="font-weight:bold">
                เมื่อส่งให้ ศอ.บต. แล้ว ข้อมูลไม่ให้แก้ไข นอกจาก
                ศอ.บต.จะปลดล๊อกให้แก้ไข
              </h6>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col text-center">
              <button
                class="btn btn-warning"
                style="color: white; width: 200px"
                @click="hasHistory() ? $router.go(-1) : $router.push('/')"
              >
                ย้อนกลับ
              </button>
              &nbsp;
              <button
                class="btn btn-success"
                style="color: white; width: 200px"
                @click="save()"
                v-if="cases.status_id == 5 || cases.status_id == 2 && currentUser.role_id == 7"
              >
                ส่งเรื่องให้ ศอ.บต.
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HomeIcon from "../HomeIcon.vue";
import NewEventCom from "../Events/NewEventCom.vue";
import PrivilegeInfo from "../Privillege/PrivilegeInfo.vue";
import CasesDataService from '../../services/CasesDataService.js'
import AddressDataService from '../../services/AddressDataService'
import RelativesDataService from '../../services/RelativesDataService'
import NotifyDataService from '../../services/NotifyDataService'
import HistoryCaseDataService from '../../services/HistoryCaseDataService'
import ConvertService from '../../services/ConvertService'
import RelativesView from "./RelativesView.vue";
import DescendantsView from "./DescendantsView.vue";

export default {
  props: ["msg"],
  components: {
    HomeIcon,
    NewEventCom,
    PrivilegeInfo,
    RelativesView,
    DescendantsView,
  },
  data() {
    return {
      head: "0",
      name: "HelloWorld",
      suspect:[],
      case_id:'',
      cases:[],
      relative:[]
    };
  },
  methods: {
    hasHistory() {
      return window.history.length > 2;
    },
    save(){
      var status = {
        status_id:4,
        is_edit:true,
        updated_by:this.currentUser.user_id
      }
CasesDataService.updateStatus(this.case_id,status).then(()=>{
  var data = {
          case_id: this.case_id,
          status_id: 4,
          is_read: false,
          user_id: this.cases.created_by,
          role_id: 3,
          created_by: this.currentUser.user_id,
          updated_by: this.currentUser.user_id,
        };
        NotifyDataService.createNotify(data).then(() => {
          // console.log(res.data);
           var now = ConvertService.getdate(Date.now());
      now = ConvertService.createddate(now);
          var data = {
          status_id: 4,
          case_id: this.case_id,
          created_by:this.currentUser.user_id,
          created_date:now
        };
        HistoryCaseDataService.createHistoryCase(data).then(()=>{
          // console.log(res.data);
          })
        });
        var d={
            case_id: this.case_id,
            history_status_id:10,
            user_id:this.currentUser.user_id
          }
          HistoryCaseDataService.createHistory(d).then(()=>{

          })
  alert('ส่งเรื่องให้ ศอ.บต.เรียบร้อยแล้ว')
  this.$router.push("/treatment/");
      setTimeout(function () {
        location.reload();
      }, 300);
})
    },
    getdata(){
      CasesDataService.getCaseInfo(this.case_id).then((res) => {
        this.cases = res.data
        // console.log(res.data);
        AddressDataService.getAddressSuspect(res.data.suspect_id).then((res) => {
          this.suspect = res.data;
          // console.log(this.suspect);
        })
        RelativesDataService.getRelativeBySuspect(res.data.suspect_id).then((res) => {
          this.relative = res.data;
          // console.log(this.relative);
        })
      });
      },
          convertdatetothai(datetimes) {
      if (datetimes) {
        const date = new Date(datetimes);
        const result = date.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return result;
      } else {
        return "-";
      }
    },
  },
  mounted() {
    this.case_id = this.$route.params.id
    this.getdata()
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  font-family: "Kanit" !important;
}
.row {
  max-width: 1200px;
  margin: auto;
  background: white;
  padding: 10px;
  color: black !important;
}
a:visited {
  color: black;
} /* visited link */
a:hover {
  color: black;
} /* mouse over link */
a:active {
  color: black;
}
a:link {
  color: black;
}
</style>
