<template>
  <div class="hello">
    <HomeIcon />
    <div class="mt-5">
      <div class="row mt-5">
        <h4 style="text-align: center">
          ข้อมูลการอนุมัติจ่ายเงิน เลขหนังสืออ้างอิง {{ ref_no }}
        </h4>
      </div>
      <ViewPayment />

      <div class="row card mt-5">
        <div class="card-body p-0 mt-3">
          <h4 style="text-align: center">
            ข้อมูลการอนุมัติจ่ายเงิน เลขหนังสืออ้างอิง {{ ref_no }}
          </h4>
          <div class="row">
            <div class="col-md-2"></div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="exampleInputPassword1">วันที่จ่ายเงิน</label>
                <input id="payment_date" class="form-control" />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="exampleInputPassword1">Payment ID</label>
                <input
                  v-model="paymentID"
                  type="text"
                  class="form-control"
                  placeholder="Payment ID"
                />
              </div>
            </div>
            <div class="col-md-2"></div>
            <div class="col-md-2"></div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="exampleInputPassword1">รายละเอียดเพิ่มเติม</label>
                <input
                  v-model="remark_payment"
                  type="text"
                  class="form-control"
                  placeholder="รายละเอียดเพิ่มเติม"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-center">
              <button
          class="btn btn-warning"
          style="color: white; width: 200px"
          @click="back()"
        >
          ย้อนกลับ</button
        >&nbsp;
            <button
              @click="save()"
              class="btn btn-success"
              style="color: white; width: 200px"
              id="saveimage"
            >
              บันทึก
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

  <script src="https://justice-api.sbpac.go.th/picker_date.js"></script>

<script>
import HomeIcon from "../HomeIcon.vue";
import PaymentDataService from "../../services/PaymentDataService";
import ConvertService from "../../services/ConvertService";
import CasesDataService from "../../services/CasesDataService";
import HistoryCaseDataService from "../../services/HistoryCaseDataService";
import ViewPayment from "../Payment/View.vue";

const customLabels = {
  first: "<<",
  last: ">>",
  previous: "<",
  next: ">",
};

export default {
  components: {
    HomeIcon,
    ViewPayment
  },
  data() {
    return {
      name: "HelloWorld",
      list: [],
      status: [],
      pageOfItems: [],
      date: "",
      options: {
        format: "DD/MM/YYYY",
        useCurrent: false,
      },
      location: "",
      latLong: "",
      customLabels,
      ref_no: "",
      payment_id: 0,
      remark_payment: "",
      paymentID: "",
    };
  },
  mounted() {
     var year = new Date().getFullYear()
    var start = year-2004
    var before = "-"+start+":+0"
    // console.log(before);
    picker_date(document.getElementById("payment_date"), {
      year_range: before,
    });
    this.payment_id = this.$route.params.id;
    this.getCase();
  },
  methods: {
    back() {
      this.$router.push("/paymentlist");
      setTimeout(function () {
        location.reload();
      }, 500);
    },
    save() {
      if (document.getElementById("payment_date").value == "") {
        alert("กรุณากรอกวันที่จ่ายเงิน");
      } else if (this.paymentID == "") {
        alert("กรุณากรอก Payment ID");
      }else {
        var payment = {
          payment_date: ConvertService.createddate(
            document.getElementById("payment_date").value
          ),
          remark_payment: this.remark_payment,
          payment_id: this.paymentID,
          status_id: 9,
          created_by: this.currentUser.user_id,
          updated_by: this.currentUser.user_id,
        };
        PaymentDataService.updatePaymentApprove(this.payment_id, payment).then(
          () => {
            // console.log(res.data);
            for (let li = 0; li < this.list.length; li++) {
           if (this.list[li].no != '') {
             var save ={
        status_id:9,
        updated_by:this.currentUser.user_id
      }
      CasesDataService.updateCheck(this.list[li].case_id,save).then(() => {
        // var now = ConvertService.getdate(Date.now());
        //           now = ConvertService.createddate(now);
                  var data = {
                    status_id: 9,
                    case_id: this.list[li].case_id,
                    created_by: this.currentUser.user_id,
                    created_date: ConvertService.createddate(
            document.getElementById("payment_date").value
          ),
                  };
                  HistoryCaseDataService.createHistoryCase(data).then(() => {
                    // console.log(res.data);
                    var data={
            case_id: this.list[li].case_id,
            history_status_id:13,
            user_id:this.currentUser.user_id
          }
          HistoryCaseDataService.createHistory(data).then(()=>{
      //       alert("บันทึกสำเร็จ");
      //       setTimeout(function () {
      //   location.reload();
      // }, 300);

          })
                  });
      });
           }
          }
          alert("บันทึกสำเร็จ");
            
          }
        );
      }
    },
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    },
    convertdatetothai(datetimes) {
      if (datetimes) {
        const date = new Date(datetimes);
        const result = date.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        // const myArray = result.split(" ");
        // var day = this.thaiNumber(parseInt(myArray[0]));
        // var month = myArray[1];
        // var year = this.thaiNumber(parseInt(myArray[2]));
        // var val = day + " " + month + " " + year;
        return result;
      } else {
        return "-";
      }
    },
    cal(start, end) {
      var date1 = new Date(start);
      var date2 = new Date(end);
      var diffTime = date2.getTime() - date1.getTime();
      var diffDay = diffTime / (1000 * 3600 * 24);
      return diffDay;
    },
    thaiNumber(num) {
      var array = {
        1: "๑",
        2: "๒",
        3: "๓",
        4: "๔",
        5: "๕",
        6: "๖",
        7: "๗",
        8: "๘",
        9: "๙",
        0: "๐",
      };
      if (num == null) {
        str = "-"
      }else{
      var str = num.toString();
      for (var val in array) {
        str = str.split(val).join(array[val]);
      }
      }
      return str;
    },
    getCase() { 
      PaymentDataService.getPaymentName(this.payment_id).then((res) => {
        // console.log(res.data);
        this.ref_no = res.data.ref_no;
        if (res.data.payment_date != null) {
          document.getElementById("payment_date").value = ConvertService.getdate(res.data.payment_date)
        }
        if (res.data.payment_id != null) {
          this.paymentID = res.data.payment_id
        }
        if (res.data.remark_payment != null) {
          this.remark_payment = res.data.remark_payment
        }
      });
      PaymentDataService.getPayment(this.payment_id).then((res) => {
        // console.log(res.data);
        this.list = res.data;
        var no = 0;
        for (let l = 0; l < this.list.length; l++) {
          this.list[l].day =
            this.cal(
              this.list[l].detained_start_date,
              this.list[l].detained_end_date
            ) + " วัน";
          this.list[l].detained_start_date = this.convertdatetothai(
            this.list[l].detained_start_date
          );
          this.list[l].detained_end_date = this.convertdatetothai(
            this.list[l].detained_end_date
          );
          // this.list[l].village_no_house = this.thaiNumber(
          //   this.list[l].village_no_house
          // );
          // this.list[l].no_house = this.thaiNumber(this.list[l].no_house);

          // this.list[l].amount = (
          //   parseInt(this.list[l].one) + parseInt(this.list[l].two)
          // ).toLocaleString();
          // this.list[l].one = this.list[l].one.toLocaleString();
          // this.list[l].two = this.list[l].two.toLocaleString();
          if (l != 0) {
            if (
            this.list[l].case_id == this.list[l - 1].case_id &&
            this.list[l].event_type_id == this.list[l - 1].event_type_id
          ) {
            if (this.list[l].one) {
              this.list[l-1].one = this.list[l].one
              this.list[l].one = 0
              // this.list.splice(l, 1);
            }
            if (this.list[l].two) {
              this.list[l - 1].two = this.list[l].two;
              this.list[l].two = 0;
              // this.list.splice(l, 1);
            }
            if (this.list[l].three) {
              this.list[l - 1].three = this.list[l].three;
              this.list[l].three = 0;
              // this.list.splice(l, 1);
            }
            if (this.list[l].four) {
              this.list[l - 1].four = this.list[l].four;
              this.list[l].four = 0;
              // this.list.splice(l, 1);
            }
          }
            // console.log(this.list[l].case_id, this.list[l - 1].case_id);
            if (this.list[l].case_id == this.list[l - 1].case_id) {
              this.list[l].prefix = "";
              this.list[l].firstname = "";
              this.list[l].lastname = "";
              this.list[l].id_card = "";
              this.list[l].village_no_house = "";
              this.list[l].no_house = "";
              this.list[l].sub_district_house = "";
              this.list[l].district_house = "";
              this.list[l].province_house = "";
              if (this.list[l].case_id == this.list[l - 1].case_id) {
                this.list[l].no = "";
              }
            } else {
              no = no + 1;
              this.list[l].no = no;
            }
          } else {
            no = no + 1;
            this.list[l].no = no;
          }
          if (this.list[l].statuscheck == 1) {
            this.list[l].statuscheck = true;
          } else {
            this.list[l].statuscheck = false;
          }
        }
      });
      },
    hasHistory() {
      return window.history.length > 2;
    },
    search() {
      var d = document.getElementById("my_date").value;
      // var dd = new Date(d)
      console.log(d);
    },
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  font-family: "Kanit" !important;
}
.row {
  max-width: 1500px;
  margin: auto;
  background: white;
  padding: 10px;
  color: black !important;
}
a:visited {
  color: black;
} /* visited link */
a:hover {
  color: black;
} /* mouse over link */
a:active {
  color: black;
}
a:link {
  color: black;
}
</style>
