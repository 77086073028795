<template>
  <div class="hello mt-5">
    <b-tabs
      active-nav-item-class="text-success"
      content-class="mt-3 tab-active-class"
    >
      <b-tab :title-item-class="'tab-title-class'"
      title="ข้อมูลผู้เสียหาย" :active="tab === '1'" @click="go(1)"
        ><p><Suspects v-if="tab === '1'"/></p
      ></b-tab>
      <b-tab title="ข้อมูลลักษณะเหตุการณ์" :active="tab === '2'" @click="go(2)"
        ><p><Events v-if="tab === '2'"/></p
      ></b-tab>
      <b-tab title="ข้อมูลคณะกรรมการ" :active="tab === '3'" @click="go(3)"
        ><p><Committee v-if="tab === '3'"/></p></b-tab
      >
      <b-tab title="ข้อมูลสิทธิ์ที่ได้รับ" :active="tab === '4'" @click="go(4)"
        ><p><Privilege v-if="tab === '4'"/></p></b-tab
      >
    </b-tabs>
  </div>
</template>

<script>
import Suspects from "./Treatment/Suspects.vue";
import Events from "./Events/Events.vue";
import Committee from "./Committee/Committee.vue";
import Privilege from "./Privillege/Privilege.vue";
import CasesDataService from "../services/CasesDataService";

export default {
  props: ["msg"],
  components: {
    Suspects,
    Events,
    Committee,
    Privilege
  },
  data() {
    return {
      name: "HelloWorld",
      tab:'',
      case_id:'',
      cases:[],
    };
  },
  methods: {
    getcases() {
      CasesDataService.getCase(this.case_id).then((res) => {
        this.cases = res.data;
        // console.log(this.cases);
      })
      },
    go(t){
      this.$router.push("/newtreatment/" + this.case_id + "/"+t);
       setTimeout(function () {
        location.reload();
      }, 300);
    },
    hasHistory() {
      return window.history.length > 2;
    },
  },
  mounted() {
    this.tab = this.$route.params.tab
    this.case_id = this.$route.params.id
    this.getcases()
    // console.log(this.msg);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  color: black!important;
  font-family: "Kanit" !important;
}
.row {
  max-width: 1200px;
  margin: auto;
  background: white;
  padding: 10px;
  color: black !important;
}
.text-success {
  color: #09cc68!important;
}
.nav-link {
  color: black !important;
}
</style>
