import http from "../http-common";

class DLADetailDataService {
  getQualities(id_card,id_card_des) {
    return http.get('/dla_detail?id_card='+id_card+'&&id_card_des='+id_card_des);
  }
  getIDSuspectQuality() {
    return http.get('/dla_detail/getidcardsuspect');
  }
  getQuality(id,type) {
    return http.get(`/dla_detail/`+id+'/'+type);
  }
  getDetailQuality(id,type) {
    return http.get(`/dla_detail/getdetail/`+id+'/'+type);
  }  
  createQuality(data) {
    return http.post('/dla_detail', data);
  }
  updateQuality(id,data) {
    return http.put(`/dla_detail/${id}`, data);
  }
}

export default new DLADetailDataService();