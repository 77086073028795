<template>
  <div class="hello mt-3">
    <table class="table table-bordered">
      <thead>
        <tr>
          <th scope="col" style="text-align:center">ลักษณะเหตุการณ์</th>
          <th scope="col" style="text-align:center">วันที่ควบคุมตัว</th>
          <th scope="col" style="text-align:center">วันที่ปล่อยตัว</th>
          <th scope="col" style="text-align:center">จำนวนวัน</th>
          <th scope="col" style="text-align:center">หน่วยงานที่ควบคุมตัว</th>
          <th scope="col" style="text-align:center">สถานที่</th>
          <th scope="col" style="text-align: right">
            <button :disabled="casesedit.is_edit == 1" class="btn btn-success">
              <a
              data-toggle="modal"
              href="#"
              style="color:white"
              data-target="#addModalEvents"
              @click="sendInfo(0)"
              >เพิ่ม
            </a></button>
          </th>
        </tr>
      </thead>
      <tbody v-if="list.length > 0">
        <tr v-for="(l, i) in list" :key="i">
          <td style="text-align:center">{{ l.event_name }}</td>
          <td style="text-align:center">{{ convertdatetothai(l.detained_start_date) }}</td>
          <td style="text-align:center">{{ convertdatetothai(l.detained_end_date) }}</td>
          <td style="text-align:center">{{ cal(l.detained_start_date, l.detained_end_date) }} วัน</td>
          <td style="text-align:center">{{ l.department_detained }}</td>
          <td style="text-align:center">{{ l.event_id }}</td>
          <td style="text-align: right">
           
              <button :disabled="casesedit.is_edit == 1" class="btn btn-warning" style="color: white">
                 <a
              data-toggle="modal"
              href="#"
              style="color:white"
              data-target="#addModalEvents"
              @click="sendInfo(l.case_event_id)"
            >แก้ไข</a
            >
              </button> &nbsp;
            <button :disabled="casesedit.is_edit == 1" class="btn btn-danger" @click="deletecase(l.case_event_id)">
              ลบ
            </button>
          </td>
        </tr>
      </tbody>
    </table>
     <div v-if="list.length == 0" style="text-align: center">ไม่พบข้อมูล</div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="addModalEvents"
      tabindex="-1"
      role="dialog"
      aria-labelledby="addModalEventsLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <h5>{{header}}ข้อมูลลักษณะเหตุการณ์</h5>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group" style="text-align: left">
                  <label for="username"
                    >วันที่ควบคุมตัว <span style="color: red"> *</span></label
                  >
                  <input id="detained_start_date" class="form-control" />
                  <!-- <date-picker v-model="event.detained_start_date" :config="options"
           class="form-control"
            min="1997-01-01"
            max="2030-12-31"
            id="date"
            ></date-picker> -->
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group" style="text-align: left">
                  <label for="username"
                    >วันที่ปล่อยตัว <span style="color: red"> *</span></label
                  >
                  <input id="detained_end_date" class="form-control" />
                  <!-- <date-picker v-model="event.detained_end_date" :config="options"
           class="form-control"
            min="1997-01-01"
            max="2030-12-31"
            id="date"
            ></date-picker> -->
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group" style="text-align: left">
                  <label for="province"
                    >ลักษณะเหตุการณ์ <span style="color: red"> *</span></label
                  >
                  <select
                    class="form-select mb-3"
                    v-model="event.event_type_id"
                  >
                    <option
                      v-for="(d, i) in event_type"
                      v-bind:value="d.event_type_id"
                      :key="i + 7"
                    >
                      {{ d.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group" style="text-align: left">
                  <label for="province"
                    >หน่วยงานที่ควบคุมตัว
                    <span style="color: red"> *</span></label
                  >
                  <select
                    class="form-select mb-3"
                    v-model="event.department_detained_id"
                  >
                    <option
                      v-for="(d, i) in detained"
                      v-bind:value="d.department_detained_type_id"
                      :key="i + 7"
                    >
                      {{ d.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group" style="text-align: left">
                  <label for="province"
                    >สถานที่ควบคุม </label
                  >
                  <input
            v-model="event.event_id"
            type="text"
            min="0"
            class="form-control"
            id=""
            placeholder=""
          />
                  <!-- <select class="form-select mb-3" v-model="event.event_id">
                    <option
                      v-for="(c, i) in cases"
                      v-bind:value="c.event_id"
                      :key="i + 7"
                    >
                      {{ c.crime_scene }}
                    </option>
                  </select> -->
                </div>
              </div>
            </div>
          </div>
          <div class="bd-example mb-5">
            <button
              type="button"
              style="margin-right: 20px; width: 150px"
              class="btn btn-success"
              @click="save()"
            >
              บันทึก
            </button>
            <button
              type="button"
              id="closedcase"
              class="btn btn-danger"
              style="margin-right: 20px; width: 150px"
              data-dismiss="modal"
            >
              ยกเลิก
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script src="https://justice-api.sbpac.go.th/picker_date.js"></script>

<script>
import EventTypesDataService from "../../services//EventTypesDataService.js";
import DetainedDataService from "../../services/DetainedDataService";
import EventsDataService from "../../services/EventsDataService";
import CasesEventDataService from "../../services/CasesEventDataService";
import CasesDataService from "../../services/CasesDataService";
import ConvertService from "../../services/ConvertService";
import HistoryCaseDataService from "../../services/HistoryCaseDataService";

 
export default {
  components: {},
  data() {
    return {
      name: "HelloWorld",
      event: [],
      event_type: [],
      detained: [],
      case_id: "",
      cases: [],
      list: [],
      case_event_id: 0,
      tab: "1",
      header:'',
      casesedit: [],
      options: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
        } 
    };
  },
  mounted() {
    this.case_id = this.$route.params.id;
    // console.log(this.tab);
    if (this.case_id != 0) {      
    this.geteventtype();
    this.getdetained();
    this.getEvent();
    this.getEventByCaseID();
    this.getcase()
    }
  },
  methods: {
    getcase(){
       CasesDataService.getCase(this.case_id).then((res) => {
        this.casesedit = res.data;
       });
    },
    cal(start, end) {
      var date1 = new Date(start);
      var date2 = new Date(end);
      var diffTime = date2.getTime() - date1.getTime();
      var diffDay = diffTime / (1000 * 3600 * 24);
      return diffDay;
    },
    getEventByCaseID() {
      CasesEventDataService.getEventByCaseID(this.case_id).then((res) => {
        this.list = res.data;
        // console.log(res.data);
      });
    },
    sendInfo(id) {
      var year = new Date().getFullYear()
    var start = year-2004
    var before = "-"+start+":+0"
      if (id != 0) {
        this.header = 'แก้ไข'
        this.case_event_id = id;
        CasesEventDataService.getCaseEvent(this.case_event_id).then((res) => {
          // console.log(res.data);
          this.event = res.data;
          this.event.detained_start_date = ConvertService.getdate(new Date(res.data.detained_start_date))
          document.getElementById("detained_start_date").value = this.event.detained_start_date
          picker_date(document.getElementById("detained_start_date"), { year_range: before, });

          this.event.detained_end_date = ConvertService.getdate(new Date(res.data.detained_end_date))
          document.getElementById("detained_end_date").value = this.event.detained_end_date
          picker_date(document.getElementById("detained_end_date"), { year_range: before, });

        });
      } else {
        this.header = 'เพิ่ม'
        this.event = [];
        picker_date(document.getElementById("detained_start_date"), { year_range: before, });
          picker_date(document.getElementById("detained_end_date"), { year_range: before, });
        document.getElementById("detained_start_date").value = null
        document.getElementById("detained_end_date").value = null

      }
    },
    save() {
            var start = ConvertService.createddate(document.getElementById("detained_start_date").value)
      var end = ConvertService.createddate(document.getElementById("detained_end_date").value)
      var now = ConvertService.getdate(Date.now())
      now = ConvertService.createddate(now)
      // console.log(start,end,now);
      if (document.getElementById("detained_start_date").value == '') {
        alert('กรุณาเลือกวันที่ควบคุมตัว')
      }
      else if (document.getElementById("detained_end_date").value == '') {
        alert('กรุณาเลือกวันที่ปล่อยตัว')
      }
      else if (this.event.event_type_id == null) {
        alert('กรุณาเลือกลักษณะเหตุการณ์')
      }
       else if (this.event.department_detained_id == null) {
        alert('กรุณาเลือกหน่วยงานที่ควบคุมตัว')
      }
      // else if (this.event.event_id == null) {
      //   alert('กรุณากรอกสถานที่ควบคุม')
      // }
      // if(this.event.detained_start_date != null && this.event.detained_end_date != null){
      // var start = new Date(this.event.detained_start_date);
      // var end = new Date(this.event.detained_end_date); 
      
      else if (start > now){
        alert('กรุณาใส่วันควบคุมตัวห้ามเกินวันปัจจุบัน')
      } 
      else if (end > now){
        alert('กรุณาใส่วันปล่อยตัวห้ามเกินวันปัจจุบัน')
      }  
      else if (start > end){
        alert('กรุณาเลือกวันควบคุมตัวและวันปล่อยตัวให้ถูกต้อง')
      }
      // }
      else{
        if (this.event.event_id == null) {
          this.event.event_id = ''
        }
      var caseevent = {
        case_id: this.case_id,
        event_type_id: this.event.event_type_id,
        event_id: this.event.event_id,
        department_detained_id: this.event.department_detained_id,
        detained_start_date: ConvertService.createddate(document.getElementById("detained_start_date").value),
        detained_end_date: ConvertService.createddate(document.getElementById("detained_end_date").value),
        days:this.cal(ConvertService.createddate(document.getElementById("detained_start_date").value),ConvertService.createddate(document.getElementById("detained_end_date").value)),
        created_by: this.currentUser.user_id,
        updated_by: this.currentUser.user_id,
      };
      console.log(caseevent);
      if (this.case_event_id == 0) {
        CasesEventDataService.createCaseEvent(caseevent).then(() => {
          // console.log(res.data);
          var data={
            case_id: this.case_id,
            history_status_id:5,
            user_id:this.currentUser.user_id
          }
          HistoryCaseDataService.createHistory(data).then((res)=>{
            // console.log(res.data);
this.getEventByCaseID();
          document.getElementById("closedcase").click();
          setTimeout(function () {
              location.reload();
            }, 300);
            window.scrollTo(0, 0);
          })
          
        });
      } else {
        CasesEventDataService.updateCaseEvent(
          this.case_event_id,
          caseevent
        ).then(() => {
          // console.log(res.data);
          var data={
            case_id: this.case_id,
            history_status_id:6,
            user_id:this.currentUser.user_id
          }
          HistoryCaseDataService.createHistory(data).then(()=>{

          })
          this.getEventByCaseID();
          document.getElementById("closedcase").click();
          setTimeout(function () {
              location.reload();
            }, 300);
            window.scrollTo(0, 0);
        });
      }
      }
    },
    getEvent() {
      EventsDataService.getEventAll(this.case_id).then((res) => {
        this.cases = res.data;
      });
    },
    getdetained() {
      DetainedDataService.getDetainedAll().then((res) => {
        this.detained = res.data;
      });
    },
    geteventtype() {
      EventTypesDataService.getEventTypesAll().then((res) => {
        this.event_type = res.data;
      });
    },
    convertdatetothai(datetimes) {
      if (datetimes) {
        const date = new Date(datetimes);
        const result = date.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return result;
      } else {
        return "-";
      }
    },
    deletecase(id) {
      CasesEventDataService.deleteCaseEvent(id).then((res) => {

        console.log(res.data);
        if (this.list.length == 1) {
          this.list = []
        }
              alert("ลบข้อมูลสำเร็จ");
            setTimeout(function () {
        location.reload();
      }, 300);
        this.getEventByCaseID();
      });
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  font-family: "Kanit" !important;
}
.row {
  max-width: 1200px;
  margin: auto;
  background: white;
  padding: 10px;
  color: black !important;
}
.nav-link {
  color: black !important;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #09cc68;
}
.modal-content {
  float: center;
  text-align: center;
  width: 800px;
}
.modal {
  text-align: center;
  padding: 0 !important;
}
.modal:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}
.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  max-width: 700px;
}
</style>
