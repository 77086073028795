import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapVue } from 'bootstrap-vue'
import VueSweetalert2 from 'vue-sweetalert2';
// import { FontAwesomeIcon } from './plugins/font-awesome'
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import store from './store';
import JwPagination from 'jw-vue-pagination';
import * as VueGoogleMaps from 'vue2-google-maps'
import VueExcelXlsx from "vue-excel-xlsx";
import Chat from 'vue-beautiful-chat'
import VueApexCharts from 'vue-apexcharts'
import Multiselect from 'vue-multiselect'
Vue.component('multiselect', Multiselect)
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

require('../src/dist/css/adminlte.min.css')
require('../src/plugins/fontawesome-free/css/all.min.css')
require('../src/plugins/tempusdominus-bootstrap-4/css/tempusdominus-bootstrap-4.min.css')
require('../src/plugins/icheck-bootstrap/icheck-bootstrap.min.css')
require('../src/plugins/jqvmap/jqvmap.min.css')
require('../src/plugins/overlayScrollbars/css/OverlayScrollbars.min.css')
require('../src/plugins/daterangepicker/daterangepicker.css')
require('../src/plugins/summernote/summernote-bs4.min.css')
import datePicker from 'vue-bootstrap-datetimepicker';
// import DatepickerLite from "vue3-datepicker-lite";

Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(VueSweetalert2);
Vue.use(VueMoment, {
  moment,
})
Vue.use(Chat)
Vue.component('jw-pagination', JwPagination);
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyD-TXPZpYTzg9_Mrxo9NrqJgNBwFbmKWcg',
    libraries: 'places',
    region: 'TH',
    language: 'th',
  }
});
Vue.use(datePicker);
Vue.use(VueExcelXlsx);
// Vue.use(DatepickerLite);
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

