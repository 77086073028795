<template>
  <div class="hello mt-5">
    <b-tabs
      active-nav-item-class="text-success"
      content-class="mt-3 tab-active-class"
    >
      <b-tab
        :title-item-class="'tab-title-class'"
        title="กระทรวงสาธารณสุข"
        :active="tab === '1'"
        @click="go(1)"
        ><p><MOPHList v-if="tab === '1'" /></p
      ></b-tab>
      <b-tab
        title="กระทรวงพัฒนาสังคมและความมั่นคงของมนุษย์"
        :active="tab === '2'"
        @click="go(2)"
        ><p><MSocietyList v-if="tab === '2'" /></p
      ></b-tab>
      <b-tab title="กระทรวงศึกษาธิการ" :active="tab === '3'" @click="go(3)"
        ><p><MOEList v-if="tab === '3'" /></p
      ></b-tab>
      <b-tab
        title="กรมป้องกันและบรรเทาสาธารณภัย"
        :active="tab === '4'"
        @click="go(4)"
        ><p><DisasterList v-if="tab === '4'" /></p
      ></b-tab>
      <b-tab
        title="องค์กรปกครองส่วนท้องถิ่น"
        :active="tab === '5'"
        @click="go(5)"
        ><p><DLAList v-if="tab === '5'" /></p
      ></b-tab>
      <b-tab title="กอ.รมน." :active="tab === '6'" @click="go(6)"
        ><p><ISOCList v-if="tab === '6'" /></p
      ></b-tab>
    </b-tabs>
  </div>
</template>

<script>
import DLAList from "./DLA/List.vue";
import DisasterList from "./Disaster/List.vue";
import ISOCList from "./ISOC/List.vue";
import MOEList from "./MOE/List.vue";
import MOPHList from "./MOPH/List.vue";
import MSocietyList from "./M_Society/List.vue";

// DLA กรมส่งเสริมการปกครองท้องถิ่นและองค์กรปกครองส่วนท้องถิ่น
// Disaster กรมป้องกันและบรรเทาสาธารณภัย
// ISOC กองอำนวยการรักษาความมั่นคงภายในราชอาณาจักร
// MOE กระทรวงศึกษาธิการ
// MOPH กระทรวงสาธารณสุข
// M_Society กระทรวงการพัฒนาสังคมและความมั่นคงของมนุษย์

export default {
  props: ["msg"],
  components: {
    MOPHList,
    MSocietyList,
    MOEList,
    DisasterList,
    DLAList,
    ISOCList,
  },
  data() {
    return {
      name: "HelloWorld",
      tab: "",
      case_id: "",
      cases: [],
    };
  },
  methods: {
    go(t) {
      setTimeout(function () {
        location.reload();
      }, 50);
      this.$router.push("/integration/" + t);
    },
    hasHistory() {
      return window.history.length > 2;
    },
  },
  mounted() {
    this.tab = this.$route.params.tab;
    // console.log(this.msg);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  color: black !important;
  font-family: "Kanit" !important;
}
.row {
  max-width: 1200px;
  margin: auto;
  background: white;
  padding: 10px;
  color: black !important;
}
.text-success {
  color: #09cc68 !important;
}
.nav-link {
  color: black !important;
}
</style>
