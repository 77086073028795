<template>
  <div class="hello">
    <HomeIcon />
    <div class="row">
      <div class="row card mt-2">
        <div class="card-body mt-2">
          <div class="col-md-12 mt-5">
            <h3 style="text-align: center">ข้อมูลการติดตาม</h3>
          </div>
        </div>
        <div class="hello mt-3" v-if="tracking_id != 0">
          <div class="col-md-12 mt-5">
            <h3 class="form-group" style="color: #2da3f2; font-weight: bold">
              รายการข้อมูลติดตาม
            </h3>
          </div>
          <h4>ผู้เสียหาย</h4>
          <table class="table table-bordered">
            <thead class="table-active">
              <tr >
                <th scope="col">ครั้งที่</th>
                <!-- <th scope="col">เลขบัตรประชาชนผู้เสียหาย</th> -->
                <th scope="col">ชื่อ-นามสกุลผู้เสียหาย</th>
                <th scope="col">หน่วยงานที่ติดตาม</th>
                <th scope="col">สภาพความเป็นอยู่</th>
                <th scope="col">รายละเอียดปัญหา</th>
                <th scope="col">วันที่ติดตาม</th>
                <th scope="col" style="text-align: right">
                  <!-- <button v-if="showadd" class="btn btn-success">
                    <a
                      data-toggle="modal"
                      href="#"
                      style="color: white"
                      data-target="#addModalDescendants"
                      @click="addTracking()"
                      >เพิ่ม</a
                    >
                  </button> -->
                </th>
              </tr>
            </thead>
            <tbody v-if="trackinglistsus.length > 0">
              <tr class="table-success" v-for="(l, i) in trackinglistsus" :key="i">
                <td>{{ i + 1 }}</td>
                <!-- <td>{{ l.id_card_relative }}</td> -->
                <td>
                  {{ l.prefixrelative }}{{ l.firstnamerelative }}
                  {{ l.lastnamerelative }}
                </td>
                <td>{{ l.department }}</td>
                <td>{{ l.living_conditions }}</td>
                <td>{{ l.problem_detail }}</td>
                <td>{{ convertdatetothai(l.tracking_date) }}</td>
                <td style="text-align: right">
                  <button
                    v-if="l.select == false"
                    class="btn btn-info"
                    style="color: white"
                  >
                    <a
                      style="color: white"
                      data-toggle="modal"
                      href="#"
                      data-target="#addModalDescendants"
                      @click="selecttrackingsus(l)"
                      >แก้ไข</a
                    >
                  </button>
                  <button
                    v-if="l.select == true"
                    class="btn btn-warning"
                    style="color: white"
                  >
                    <a
                      style="color: white"
                      data-toggle="modal"
                      href="#"
                      data-target="#addModalDescendants"
                      @click="selecttrackingsus(l)"
                      >แก้ไข</a
                    >
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="trackinglistsus.length == 0" style="text-align: center">
            ไม่พบข้อมูล
          </div>
        </div>
        <div class="hello mt-3" v-if="tracking_id != 0">
          <h4>ทายาท</h4>
          <table class="table table-bordered">
            <thead class="table-active">
              <tr>
                <th scope="col">ครั้งที่</th>
                <th scope="col">เลขบัตรประชาชนทายาท</th>
                <th scope="col">ชื่อ-นามสกุลทายาท</th>
                <th scope="col">หน่วยงานที่ติดตาม</th>
                <th scope="col">สภาพความเป็นอยู่</th>
                <th scope="col">รายละเอียดปัญหา</th>
                <th scope="col">วันที่ติดตาม</th>
                <th scope="col" style="text-align: right">
                  <!-- <button v-if="showadd" class="btn btn-success">
                    <a
                      data-toggle="modal"
                      href="#"
                      style="color: white"
                      data-target="#addModalDescendants"
                      @click="addTracking()"
                      >เพิ่ม</a
                    >
                  </button> -->
                </th>
              </tr>
            </thead>
            <tbody v-if="trackinglist.length > 0">
              <tr class="table-success" v-for="(l, i) in trackinglist" :key="i">
                <td>{{ i + 1 }}</td>
                <td>{{ l.id_card_relative }}</td>
                <td>
                  {{ l.prefixrelative }}{{ l.firstnamerelative }}
                  {{ l.lastnamerelative }}
                </td>
                <td>{{ l.department }}</td>
                <td>{{ l.living_conditions }}</td>
                <td>{{ l.problem_detail }}</td>
                <td>{{ convertdatetothai(l.tracking_date) }}</td>
                <td style="text-align: right">
                  <button
                    v-if="l.select == false"
                    class="btn btn-info"
                    style="color: white"
                  >
                    <a
                      style="color: white"
                      data-toggle="modal"
                      href="#"
                      data-target="#addModalDescendants"
                      @click="selecttracking(l)"
                      >แก้ไข</a
                    >
                  </button>
                  <button
                    v-if="l.select == true"
                    class="btn btn-warning"
                    style="color: white"
                  >
                    <a
                      style="color: white"
                      data-toggle="modal"
                      href="#"
                      data-target="#addModalDescendants"
                      @click="selecttracking(l)"
                      >แก้ไข</a
                    >
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="trackinglist.length == 0" style="text-align: center">
            ไม่พบข้อมูล
          </div>
        </div>        
      </div>
      <div class="row card mt-5">
        <div class="row">
          <div class="col-md-12 mt-3">
            <h5 class="form-group" style="color: #2da3f2; font-weight: bold">
              ข้อมูลผู้เสียหาย
            </h5>
          </div>
          <div class="col-md-5">
            <div class="form-group" style="text-align: left">
              <label
                >เลขบัตรประชาชนผู้เสียหาย
                <span style="color: red"> *</span></label
              >

              <div class="input-group mb-3">
                <input
                  :disabled="tracking_id != 0"
                  type="number"
                  v-model="tracking.id_card"
                  min="1"
                  class="form-control"
                  id="idcard"
                  placeholder="เลขบัตรประชาชน 13 หลัก"
                />
                <a @click="search()" href="#"
                  ><span class="input-group-text" id="basic-addon2"
                    >ค้นหา</span
                  ></a
                >
              </div>
              <!-- <div v-if="filteredList.length != 1">
                <ul
                  class="list-group"
                  v-for="car in filteredList"
                  :key="car.id"
                >
                  <li
                    class="
                      list-group-item
                      d-flex
                      justify-content-between
                      align-items-center
                    "
                  >
                    {{ car.id_card }}
                    <a href="#" @click="copysuspect(car)"
                      ><span class="badge badge-primary badge-pill"
                        >เลือก</span
                      ></a
                    >
                  </li>
                </ul>
              </div> -->
            </div>
          </div>
          <div class="col-md-8"></div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group" style="text-align: left">
              <label for="province"
                >คำนำหน้า <span style="color: red"> *</span></label
              >
              <select
                :disabled="tracking_id != 0"
                class="form-select mb-3"
                v-model="tracking.prefix"
              >
                <option
                  v-for="(s, i) in prefix"
                  v-bind:value="s.prefix_id"
                  :key="i + 1"
                >
                  {{ s.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-4">
            <label for="username"
              >ชื่อ <span style="color: red"> *</span></label
            >
            <input
              :disabled="tracking_id != 0"
              v-model="tracking.firstname"
              type="text"
              min="1"
              class="form-control"
              id="firstname"
              placeholder="ชื่อ"
            />
          </div>
          <div class="col-md-4">
            <label for="username"
              >นามสกุล <span style="color: red"> *</span></label
            >
            <input
              :disabled="tracking_id != 0"
              v-model="tracking.lastname"
              type="text"
              min="1"
              class="form-control"
              id="lastname"
              placeholder="นามสกุล"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <button v-if="showadd && tracking_id !=0" class="btn btn-success">
                    <a
                      data-toggle="modal"
                      href="#"
                      style="color: white"
                      data-target="#addModalDescendants"
                      @click="addTracking()"
                      >เพิ่มข้อมูลการติดตาม</a
                    >
                  </button>
          </div>          
        </div>
      </div>
      <div class="row card mt-3">
        <div class="row">
          <div class="col-md-12 mt-3">
            <h5 v-if="type == 1" class="form-group" style="color: #2da3f2; font-weight: bold">
              ข้อมูลทายาท
            </h5>
            <h5 v-else class="form-group" style="color: #2da3f2; font-weight: bold">
              ข้อมูลผู้เสียหาย
            </h5>
          </div>
        </div>
        <div class="hello" v-if="!showadd">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th scope="col">เลขบัตรประชาชนทายาท</th>
                <th scope="col">ชื่อ-นามสกุลทายาท</th>
                <th scope="col" style="text-align: right"></th>
              </tr>
            </thead>
            <tbody v-if="list.length > 0">
              <tr v-for="l in list" :key="l.relative_id">
                <td>{{ l.id_card_relative }}</td>
                <td>{{ l.prefixname }}{{ l.firstname }} {{ l.lastname }}</td>

                <td style="text-align: right">
                  <button
                    v-if="l.select == false"
                    class="btn btn-success"
                    style="color: white"
                  >
                    <a
                      style="color: white"
                      data-toggle="modal"
                      href="#"
                      data-target="#addModalDescendants"
                      @click="copydescendant(l)"
                      >เพิ่ม</a
                    >
                  </button>
                  <button
                    v-if="l.select == true"
                    class="btn btn-warning"
                    style="color: white"
                  >
                    <a
                      style="color: white"
                      data-toggle="modal"
                      href="#"
                      data-target="#addModalDescendants"
                      @click="copydescendant(l)"
                      >เพิ่ม</a
                    >
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="list.length == 0" style="text-align: center">
            ไม่พบข้อมูล
          </div>
          <label>
            <input
              id="sameaddress"
              v-model="samesuspect"
              @click="changerelative"
              type="checkbox"
            />
            <span style="font-size:15pt"> ผู้เสียหาย </span>
          </label>
        </div>
        <div class="row mt-3">
          <div class="col-md-4">
            <div class="form-group" style="text-align: left">
              <label>เลขบัตรประชาชน <span style="color: red"> *</span></label>
              <input
                type="number"
                v-model="tracking.id_card_relative"
                min="1"
                class="form-control"
                id="idcard"
                placeholder="เลขบัตรประชาชน 13 หลัก"
              />
              <!-- <div v-if="filtereddesList.length != 1">
                <ul
                  class="list-group"
                  v-for="car in filtereddesList"
                  :key="car.id"
                >
                  <li
                    class="
                      list-group-item
                      d-flex
                      justify-content-between
                      align-items-center
                    "
                  >
                    {{ car.id_card_relative }}
                    <a href="#" @click="copydescendant(car)"
                      ><span class="badge badge-primary badge-pill"
                        >เลือก</span
                      ></a
                    >
                  </li>
                </ul>
              </div> -->
            </div>
          </div>
          <div class="col-md-8"></div>
          <div class="col-md-3">
            <div class="form-group" style="text-align: left">
              <label for="province"
                >คำนำหน้า <span style="color: red"> *</span></label
              >
              <select
                class="form-select mb-3"
                v-model="tracking.prefixrelative"
              >
                <option
                  v-for="(s, i) in prefix_des"
                  v-bind:value="s.prefix_id"
                  :key="i + 1"
                >
                  {{ s.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-4">
            <label for="username"
              >ชื่อ <span style="color: red"> *</span></label
            >
            <input
              v-model="tracking.firstnamerelative"
              type="text"
              min="1"
              class="form-control"
              id="firstname"
              placeholder="ชื่อ"
            />
          </div>
          <div class="col-md-4">
            <label for="username"
              >นามสกุล <span style="color: red"> *</span></label
            >
            <input
              v-model="tracking.lastnamerelative"
              type="text"
              min="1"
              class="form-control"
              id="lastname"
              placeholder="นามสกุล"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group" style="text-align: left">
              <label for="province"
                >เพศ <span style="color: red"> *</span></label
              >
              <select class="form-select mb-3" v-model="tracking.gender">
                <option value="ชาย">ชาย</option>
                <option value="หญิง">หญิง</option>
                <option value="อื่นๆ">อื่นๆ</option>
              </select>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group" style="text-align: left">
              <label for="province"
                >สถานะ <span style="color: red"> *</span></label
              >
              <select class="form-select mb-3" v-model="tracking.status">
                <option value="โสด">โสด</option>
                <option value="แต่งงานแล้ว">แต่งงานแล้ว</option>
                <option value="หย่าร้าง">หย่าร้าง</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group" style="text-align: left">
              <label for="username"
                >เบอร์โทรศัพท์ <span style="color: red"> *</span></label
              >
              <input
                v-model="tracking.phone"
                type="number"
                min="1"
                class="form-control"
                id="username"
                placeholder="เบอร์โทรศัพท์"
              />
            </div>
          </div>
          <div class="col-md-4" v-if="tracking.type == 1">
            <div class="form-group" style="text-align: left">
              <label for="province">ความสัมพันธ์ทายาท </label>
              <select class="form-select mb-3" v-model="tracking.relationship">
                <option
                  v-for="(r, i) in relationships"
                  v-bind:value="r.relationship_type_id"
                  :key="i"
                >
                  {{ r.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group" style="text-align: left">
              <label for="username">สัญชาติ </label>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-check" style="text-align: left">
              <label>
                <input
                  v-model="tracking.nationality"
                  value="ไทย"
                  name="national"
                  class="form-check-input"
                  type="radio"
                  @change="onChangenational"
                />
                <span> สัญชาติไทย </span>
              </label>
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-check" style="text-align: left">
              <label>
                <input
                  v-model="tracking.nationality"
                  name="national"
                  value="อื่นๆ"
                  class="form-check-input"
                  type="radio"
                  @change="onChangenational"
                />
                <span> สัญชาติอื่นๆ </span>
              </label>
            </div>
          </div>
          <div class="col-md-3">
            <select
              class="form-select mb-3"
              v-if="other"
              v-model="nationalityother"
            >
              <option value="กัมพูชา">กัมพูชา</option>
              <option value="ลาว">ลาว</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <h5 style="color: #2da3f2; font-weight: bold">
              ที่อยู่ตามบัตรประชาชน
            </h5>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <div class="form-group" style="text-align: left">
              <label for="username">บ้านเลขที่ </label>
              <input
                v-model="tracking.no_house"
                type="text"
                class="form-control"
                id="no_current"
                placeholder="บ้านเลขที่"
              />
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group" style="text-align: left">
              <label for="username">หมู่ที่ </label>
              <input
                v-model="tracking.village_no_house"
                type="number"
                class="form-control"
                id="vilegeno_current"
                placeholder="หมู่ที่"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group" style="text-align: left">
              <label for="username">ซอย </label>
              <input
                v-model="tracking.road_house"
                type="text"
                min="0"
                class="form-control"
                id="vilegeno_current"
                placeholder=""
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group" style="text-align: left">
              <label for="username">ถนน </label>
              <input
                v-model="tracking.lane_house"
                type="text"
                class="form-control"
                id="vilegeno_current"
                placeholder="ถนน"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group" style="text-align: left">
              <label for="username">หมู่บ้าน </label>
              <input
                v-model="tracking.village_house"
                type="text"
                min="0"
                class="form-control"
                id="income"
                placeholder="หมู่บ้าน"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group" style="text-align: left">
              <label for="province"
                >จังหวัด <span style="color: red"> *</span></label
              >
              <select
                class="form-select mb-3"
                v-model="tracking.province_house"
                @change="onChangeProvince"
              >
                <option
                  v-for="(p, i) in provinces"
                  v-bind:value="p.PROVINCE_ID"
                  :key="i + 2"
                >
                  {{ p.PROVINCE_NAME }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group" style="text-align: left">
              <label for="province"
                >อำเภอ <span style="color: red"> *</span></label
              >
              <select
                class="form-select mb-3"
                v-model="tracking.district_house"
                @change="onChangeDistrict"
              >
                <option
                  v-for="(a, i) in amphures"
                  v-bind:value="a.AMPHUR_ID"
                  :key="i + 3"
                >
                  {{ a.AMPHUR_NAME }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group" style="text-align: left">
              <label for="province"
                >ตำบล <span style="color: red"> *</span></label
              >
              <select
                class="form-select mb-3"
                v-model="tracking.sub_district_house"
                @change="onChangePostalCode"
              >
                <option
                  v-for="(d, i) in districts"
                  v-bind:value="d.DISTRICT_ID"
                  :key="i + 4"
                >
                  {{ d.DISTRICT_NAME }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group" style="text-align: left">
              <label for="username">รหัสไปรษณีย์ </label>
              <input
                v-model="postal_code_house"
                type="text"
                class="form-control"
                id="income"
                placeholder=""
              />
            </div>
          </div>
        </div>
        <div class="row" style="display: none">
          <div class="col-md-4">
            <div class="form-group" style="text-align: left">
              <label for="username">ลองจิจูด </label>
              <input
                v-model="tracking.longitude_house"
                type="text"
                class="form-control"
                id="vilegeno_current"
                placeholder=""
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group" style="text-align: left">
              <label for="username">ละติจูด </label>
              <input
                v-model="tracking.lattitude_house"
                type="text"
                min="0"
                class="form-control"
                id="income"
                placeholder=""
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <h5 style="color: #2da3f2; font-weight: bold">ที่อยู่ปัจจุบัน</h5>
          </div>
          <div class="col-md-4">
            <label>
              <input
                v-model="sameaddress"
                @click="changeaddress"
                type="checkbox"
              />
              <span> ที่อยู่เดียวกับที่อยู่บัตรประชาชน </span>
            </label>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <div class="form-group" style="text-align: left">
              <label for="username">บ้านเลขที่ </label>
              <input
                v-model="tracking.no_current"
                type="text"
                class="form-control"
                id="no_current"
                placeholder="บ้านเลขที่"
              />
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group" style="text-align: left">
              <label for="username">หมู่ที่ </label>
              <input
                v-model="tracking.village_no_current"
                type="number"
                class="form-control"
                id="vilegeno_current"
                placeholder="หมู่ที่"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group" style="text-align: left">
              <label for="username">ซอย </label>
              <input
                v-model="tracking.road_current"
                type="text"
                min="0"
                class="form-control"
                id="vilegeno_current"
                placeholder=""
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group" style="text-align: left">
              <label for="username">ถนน </label>
              <input
                v-model="tracking.lane_current"
                type="text"
                class="form-control"
                id="vilegeno_current"
                placeholder="ถนน"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group" style="text-align: left">
              <label for="username">หมู่บ้าน </label>
              <input
                v-model="tracking.village_current"
                type="text"
                min="0"
                class="form-control"
                id="income"
                placeholder="หมู่บ้าน"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group" style="text-align: left">
              <label for="province"
                >จังหวัด <span style="color: red"> *</span></label
              >
              <select
                class="form-select mb-3"
                v-model="tracking.province_current"
                @change="onChangeProvincecurrent"
              >
                <option
                  v-for="(p, i) in provincescurrent"
                  v-bind:value="p.PROVINCE_ID"
                  :key="i + 5"
                >
                  {{ p.PROVINCE_NAME }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group" style="text-align: left">
              <label for="province"
                >อำเภอ <span style="color: red"> *</span></label
              >
              <select
                class="form-select mb-3"
                v-model="tracking.district_current"
                @change="onChangeDistrictcurrent"
              >
                <option
                  v-for="(a, i) in amphurescurrent"
                  v-bind:value="a.AMPHUR_ID"
                  :key="i + 6"
                >
                  {{ a.AMPHUR_NAME }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group" style="text-align: left">
              <label for="province"
                >ตำบล <span style="color: red"> *</span></label
              >
              <select
                class="form-select mb-3"
                v-model="tracking.sub_district_current"
                @change="onChangePostalCodecurrent"
              >
                <option
                  v-for="(d, i) in districtscurrent"
                  v-bind:value="d.DISTRICT_ID"
                  :key="i + 7"
                >
                  {{ d.DISTRICT_NAME }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group" style="text-align: left">
              <label for="username">รหัสไปรษณีย์ </label>
              <input
                v-model="postal_code_current"
                type="text"
                min="0"
                class="form-control"
                id="income"
                placeholder=""
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-5">
            <h5 style="color: #2da3f2; font-weight: bold">ข้อมูลผู้ติดตาม</h5>
          </div>
          <div class="col-md-3">
            <div class="form-group" style="text-align: left">
              <label for="province"
                >คำนำหน้า <span style="color: red"> *</span></label
              >
              <select
                class="form-select mb-3"
                v-model="tracking.prefix_tracking"
              >
                <option
                  v-for="(s, i) in prefix_tracking"
                  v-bind:value="s.prefix_id"
                  :key="i + 1"
                >
                  {{ s.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-4">
            <label for="username"
              >ชื่อ <span style="color: red"> *</span></label
            >
            <input
              v-model="tracking.firstname_tracking"
              type="text"
              min="1"
              class="form-control"
              id="firstname"
              placeholder="ชื่อ"
            />
          </div>
          <div class="col-md-4">
            <label for="username"
              >นามสกุล <span style="color: red"> *</span></label
            >
            <input
              v-model="tracking.lastname_tracking"
              type="text"
              min="1"
              class="form-control"
              id="lastname"
              placeholder="นามสกุล"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group" style="text-align: left">
              <label for="username"
                >หน่วยงานที่ติดตาม <span style="color: red"> *</span></label
              >
              <input
                v-model="tracking.department"
                type="text"
                min="0"
                class="form-control"
                id="income"
                placeholder=""
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group" style="text-align: left">
              <label for="username"
                >สถานที่ไปติดตาม <span style="color: red"> *</span></label
              >
              <input
                v-model="tracking.location_tracking"
                type="text"
                min="0"
                class="form-control"
                id="income"
                placeholder=""
              />
            </div>
          </div>          
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group" style="text-align: left">
              <label for="username"
                >วันที่ลงติดตาม <span style="color: red"> *</span></label
              >
              <!-- <input
                v-model="tracking.tracking_date"
                type="date"
                min="0"
                class="form-control"
                id="income"
                placeholder=""
              /> -->
              <input id="tracking_date" class="form-control" />
            </div>
          </div>
          
          <div class="col-md-4">
            <div class="form-group" style="text-align: left">
              <label for="username"
                >การแจ้งเตือนติดตามครั้งต่อไป
                <span style="color: red"> *</span></label
              >
              <!-- <input
                v-model="tracking.tracking_next_time"
                type="date"
                min="0"
                class="form-control"
                id="income"
                placeholder=""
              /> -->
              <input id="tracking_next_time" class="form-control" />
            </div>
          </div>
        </div>
        <div class="row">          
          <div class="col-md-8">
            <div class="form-group" style="text-align: left">
              <label for="username"
                >สภาพความเป็นอยู่ <span style="color: red"> *</span></label
              >
              <textarea
                v-model="tracking.living_conditions"
                rows="3"
                type="text"
                min="0"
                class="form-control"
                id="income"
                placeholder=""
              />
            </div>
          </div>
        </div>
        <div class="row">          
          <div class="col-md-8">
            <div class="form-group" style="text-align: left">
              <label for="username"
                >รายละเอียดปัญหา <span style="color: red"> *</span></label
              >
              <textarea
              rows="3"
                v-model="tracking.problem_detail"
                type="text"
                min="0"
                class="form-control"
                id="income"
                placeholder=""
              />
            </div>
          </div>
        </div>
        <div class="row">
          <h5 style="color: #2da3f2; font-weight: bold">แนบเอกสารการติดตาม</h5>
          <table class="table table-bordered">
            <thead>
              <tr>
                <th scope="col">เอกสาร</th>
                <th scope="col">ชื่อไฟล์</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(d, i) in doclisttracking" :key="i">
                <td>{{ d.name }}</td>
                <td>
                  <a
                    :href="d.file_data"
                    target="_blank"
                  >
                    {{ d.file_name }}
                  </a>
                </td>
                <td style="text-align: center">
                  <input
                    id="my_filetracking"
                    class="form-control"
                    type="file"
                    accept="application/pdf"
                    @change="onFileChangesus"
                  />
                  <button
                    v-if="!d.file_name"
                    id="get_filetracking"
                    class="btn btn-success"
                    @click="onchange(d.document_id, d.category_doc_id)"
                  >
                    เพิ่ม
                  </button>
                  <button
                    id="deletedocsuspect"
                    class="btn btn-danger"
                    @click="deletedocsus(d.category_doc_id)"
                    v-if="d.file_name"
                  >
                    ลบ
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div
            class="modal fade"
            id="showModalImagetracking"
            tabindex="-1"
            role="dialog"
            aria-labelledby="showModalImagetracking"
            aria-hidden="true"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="row" style="height: 630px">
                    <div class="col-md-12">
                      <object>
                        <embed
                          id="pdfID"
                          type="text/html"
                          width="100%"
                          height="100%"
                          :src="imagesrc"
                        />
                      </object>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <h5 style="color: #2da3f2; font-weight: bold">แนบรูปภาพการติดตาม</h5>
          <table class="table table-bordered">
            <thead>
              <tr>
                <th scope="col">รูปภาพ</th>
                <th scope="col">ชื่อรูป</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(d, i) in imagelisttracking" :key="i">
                <td>{{ d.name }}</td>
                <td>
                  <a
                    :href="d.file_data"
                    target="_blank"
                  >
                    {{ d.file_name }}
                  </a>
                </td>
                <td style="text-align: center">
                  <input
                    id="my_imagetracking"
                    class="form-control"
                    type="file"
                    accept="image/*"
                    @change="onImageChangesus"
                  />
                  <button
                    v-if="!d.file_name"
                    id="get_imagetracking"
                    class="btn btn-success"
                    @click="onchangeImage(d.document_id, d.category_doc_id)"
                  >
                    เพิ่ม
                  </button>
                  <button
                    id="deletedocsuspect"
                    class="btn btn-danger"
                    @click="deleteimagesus(d.category_doc_id)"
                    v-if="d.file_name"
                  >
                    ลบ
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div
            class="modal fade"
            id="showModaltracking"
            tabindex="-1"
            role="dialog"
            aria-labelledby="showModaltracking"
            aria-hidden="true"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="row">
                    <div class="col-md-12">
                      <img :src="imagesrc" style="width:100%" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-md-12 text-center">
            <button
              class="btn btn-warning"
              style="color: white; width: 200px"
              @click="back()"
            >
              ย้อนกลับ
            </button>
            &nbsp;
            <button
              class="btn btn-success"
              style="color: white; width: 200px"
              @click="save()"
            >
              บันทึก
            </button>
            &nbsp;
            <!-- <button
              class="btn btn-danger"
              style="color: white; width: 200px"
              @click="next()"
            >
              ลบ
            </button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="https://justice-api.sbpac.go.th/picker_date.js"></script>

<script>
import TrackingDataService from "../../services/TrackingDataService";
import ProvinceDataService from "../../services/ProvinceDataService";
import AmphurDataService from "../../services/AmphurDataService";
import DistrictDataService from "../../services/DistrictDataService";
import SuspectsRelativeDataService from "../../services/SuspectsRelativeDataService";
import RelationshipsDataService from "../../services/RelationshipsDataService";
import RelativesDataService from "../../services/RelativesDataService";
import ConvertService from "../../services/ConvertService";
import DocumentsDataService from "../../services/DocumentsDataService";
import DocsDataService from "../../services/DocsDataService";
import HomeIcon from "../HomeIcon.vue";
import PrefixDataService from "../../services/PrefixDataService";
import TrackingDetailDataService from "../../services/TrackingDetailDataService";
import AddressDataService from "../../services/AddressDataService";
import RelativesAddressDataService from "../../services/RelativesAddressDataService";
import SuspectsDataService from "../../services/SuspectsDataService";
import HistoryCaseDataService from "../../services/HistoryCaseDataService";
import axios from "axios";

export default {
  components: {
    HomeIcon,
  },
  data() {
    return {
      name: "HelloWorld",
      tracking: [],
      postal_code_current: "",
      postal_code_house: "",
      relationships: [],
      provinces: [],
      amphures: [],
      districts: [],
      provincescurrent: [],
      amphurescurrent: [],
      districtscurrent: [],
      other: "",
      doclisttracking: [],
      sameaddress: false,
      idcardsuspectlist: [],
      id_suspect: "",
      showsearchsuspect: true,
      idcarddescendantlist: [],
      id_descendant: "",
      suspect_id: 0,
      relative_id: 0,
      nationalityother: "",
      tracking_id: "",
      imagetype: "",
      imagesrc: "",
      docname: "",
      doctype: "",
      documents: [],
      tempdoc: [],
      prefix: [],
      prefix_des: [],
      prefix_tracking: [],
      list: [],
      trackinglist: [],
      tracking_detail_id: 0,
      address_id: 0,
      showlist: false,
      showadd: true,
      imagelisttracking: [],
      tempimage: [],
      image: [],
      image_id: "",
      catimageid: "",
      samesuspect: false,
      type: 1,
      trackinglistsus: [],
    };
  },
  mounted() {
    this.tracking_id = this.$route.params.id;
    this.getProvinceAll();
    this.getAmphurAll("");
    this.getDistrictAll("");
    this.getProvinceAllcurrent();
    this.getAmphurAllcurrent("");
    this.getDistrictAllcurrent("");
    this.getIDCard();
    this.getIDCardDescendant();
    this.getrelationship();
    this.getPrefix();
    if (this.tracking_id != 0) {
      this.getlist();
      this.getlistsus();
      this.gettracking();
    }
    var year = new Date().getFullYear()
    var start = year-2004
    var before = "-"+start+":+0"
    picker_date(document.getElementById("tracking_date"), {
      year_range: before,
    });
    picker_date(document.getElementById("tracking_next_time"), {
      year_range: before,
    });

    this.getDoctracking();
    this.getImagetracking();
  },
  methods: {
    changerelative() {
      for (let qq = 0; qq < this.list.length; qq++) {
        this.list[qq].select = false;
      }
      if (this.suspect_id == "") {
        alert("กรุณาเลือกข้อมูลผู้เสียหาย");
      } else if (this.samesuspect == false) {
        SuspectsDataService.getaddresssuspect(this.suspect_id).then((res) => {
          // console.log(res.data);
          this.type = 2;
          this.getAmphurAll(res.data.province_house);
          this.getDistrictAll(res.data.district_house);
          this.getpostcode(res.data.sub_district_house);
          this.getAmphurAllcurrent(res.data.province_current);
          this.getDistrictAllcurrent(res.data.district_current);
          this.getpostcodecurrent(res.data.sub_district_current);
          this.relative_id = res.data.suspect_id;
          this.tracking.relative_id = res.data.suspect_id;
          this.tracking.id_card_relative = res.data.id_card;
          this.tracking.prefixrelative = res.data.prefix;
          this.tracking.firstnamerelative = res.data.firstname;
          this.tracking.lastnamerelative = res.data.lastname;
          this.tracking.gender = res.data.gender;
          this.tracking.status = res.data.status;
          this.tracking.phone = res.data.phone;
          this.tracking.relationship = res.data.relationship;
          this.tracking.no_house = res.data.no_house;
          this.tracking.village_no_house = res.data.village_no_house;
          this.tracking.road_house = res.data.road_house;
          this.tracking.lane_house = res.data.lane_house;
          this.tracking.village_house = res.data.village_house;
          this.tracking.province_house = res.data.province_house;
          this.tracking.district_house = res.data.district_house;
          this.tracking.sub_district_house = res.data.sub_district_house;
          this.postal_code_house = res.data.postal_code_house;
          this.tracking.no_current = res.data.no_current;
          this.tracking.village_no_current = res.data.village_no_current;
          this.tracking.road_current = res.data.road_current;
          this.tracking.lane_current = res.data.lane_current;
          this.tracking.village_current = res.data.village_current;
          this.tracking.province_current = res.data.province_current;
          this.tracking.district_current = res.data.district_current;
          this.tracking.sub_district_current = res.data.sub_district_current;
          this.postal_code_current = res.data.postal_code_current;
          if (res.data.nationality != "ไทย") {
            this.other = true;
            this.nationalityother = res.data.nationality;
            this.tracking.nationality = "อื่นๆ";
          } else {
            this.tracking.nationality = res.data.nationality;
          }
          this.sameaddress = res.data.is_same;
        });
      } else {
        this.tracking.relative_id = "";
        this.tracking.id_card_relative = "";
        this.tracking.prefixrelative = "";
        this.tracking.firstnamerelative = "";
        this.tracking.lastnamerelative = "";
        this.tracking.gender = "";
        this.tracking.status = "";
        this.tracking.phone = "";
        this.tracking.relationship = "";
        this.tracking.no_house = "";
        this.tracking.village_no_house = "";
        this.tracking.road_house = "";
        this.tracking.lane_house = "";
        this.tracking.village_house = "";
        this.tracking.province_house = "";
        this.tracking.district_house = "";
        this.tracking.sub_district_house = "";
        this.postal_code_house = "";
        this.tracking.no_current = "";
        this.tracking.village_no_current = "";
        this.tracking.road_current = "";
        this.tracking.lane_current = "";
        this.tracking.village_current = "";
        this.tracking.province_current = "";
        this.tracking.district_current = "";
        this.tracking.sub_district_current = "";
        this.postal_code_current = "";
        this.tracking.nationality = "";
        this.sameaddress = "";
        this.type = 1;
      }
    },
    back() {
      this.$router.push("/trackinglist");
    },
    search() {
      this.list = [];
      this.tracking.firstname = ''
      this.tracking.lastname = ''
      this.tracking.prefix = ''
      TrackingDataService.getSuspectByIDCard(this.tracking.id_card).then(
        (res) => {
          if (res.data.length == 0) {
            alert('ไม่พบข้อมูล')
          }else{
          this.copysuspect(res.data);
          }
        }
      );
    },
    addTracking() {
      this.samesuspect = false;
      document.getElementById("tracking_date").value = null;
      document.getElementById("tracking_next_time").value = null;
      this.tracking_detail_id = 0;
      if (this.tracking_id == 0) {
        this.showadd = false;
        this.showlist = true;
        this.getDoctracking();
        this.getImagetracking();
      } else {
        for (let qq = 0; qq < this.trackinglist.length; qq++) {
          this.trackinglist[qq].select = false;
        }
        TrackingDataService.getTracking(this.tracking_id).then((res) => {
          this.tracking = [];
          this.showadd = false;
          this.showlist = true;
          this.suspect_id = res.data.suspect_id;
          this.tracking.suspect_id = res.data.suspect_id;
          this.tracking.id_card = res.data.id_card;
          this.tracking.prefix = res.data.prefix;
          this.tracking.firstname = res.data.firstname;
          this.tracking.lastname = res.data.lastname;
          this.copysuspect(this.tracking);
          this.getDoctracking();
          this.getImagetracking();
        });
      }
    },
    getPrefix() {
      PrefixDataService.getPrefixs().then((res) => {
        this.prefix = res.data;
        this.prefix_des = res.data;
        this.prefix_tracking = res.data;
        // console.log(res.data);
      });
    },
    getDoctracking() {
      DocumentsDataService.getDocTracking(this.tracking_detail_id, 7).then(
        (res) => {
          this.documents = res.data;
          // console.log(this.documents);
          DocsDataService.getDocByType(7).then((res) => {
            this.doclisttracking = res.data;
            // console.log(this.doclisttracking);
            for (let d = 0; d < this.doclisttracking.length; d++) {
              this.doclisttracking[d].document_id = 0;
              for (let dd = 0; dd < this.documents.length; dd++) {
                if (
                  this.doclisttracking[d].category_doc_id ==
                  this.documents[dd].category_doc_id
                ) {
                  this.doclisttracking[d].document_id =
                    this.documents[dd].document_id;
                  this.doclisttracking[d].file_name =
                    this.documents[dd].file_name;
                  this.doclisttracking[d].file_type =
                    this.documents[dd].file_type;
                  this.doclisttracking[d].file_data =
                    this.documents[dd].file_data;
                }
              }
            }
            this.tempdoc = this.doclisttracking;
          });
        }
      );
    },
    getImagetracking() {
      DocumentsDataService.getDocTracking(this.tracking_detail_id, 10).then(
        (res) => {
          this.image = res.data;
          // console.log(res.data);
          DocsDataService.getDocByType(10).then((res) => {
            this.imagelisttracking = res.data;
            // console.log(this.imagelisttracking);
            for (let d = 0; d < this.imagelisttracking.length; d++) {
              this.imagelisttracking[d].document_id = 0;
              for (let dd = 0; dd < this.image.length; dd++) {
                if (
                  this.imagelisttracking[d].category_doc_id ==
                  this.image[dd].category_doc_id
                ) {
                  this.imagelisttracking[d].document_id =
                    this.image[dd].document_id;
                  this.imagelisttracking[d].file_name =
                    this.image[dd].file_name;
                  this.imagelisttracking[d].file_type =
                    this.image[dd].file_type;
                  this.imagelisttracking[d].file_data =
                    this.image[dd].file_data;
                }
              }
            }
            this.tempimage = this.imagelisttracking;
          });
        }
      );
    },
    convertdatetothai(datetimes) {
      if (datetimes) {
        const date = new Date(datetimes);
        const result = date.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return result;
      } else {
        return "-";
      }
    },
    onchange(document_id, catdocid) {
      this.document_id = document_id;
      // console.log(this.document_id);
      this.catdocid = catdocid;
      document.getElementById("my_filetracking").click();
    },
    onchangeImage(document_id, catdocid) {
      this.image_id = document_id;
      // console.log(this.document_id);
      this.catimageid = catdocid;
      document.getElementById("my_imagetracking").click();
    },
    getsrcimagetracking(type, url) {
      this.imagetype = type;
      this.imagesrc = url;
    },
    deletedocsus(id) {
      for (let t = 0; t < this.tempdoc.length; t++) {
        if (this.tempdoc[t].category_doc_id == id) {
          this.tempdoc[t].file_name = "";
          this.tempdoc[t].file_type = "";
          this.tempdoc[t].file_data = "";
        }
      }
      this.getDocTemp();
    },
    deleteimagesus(id) {
      for (let t = 0; t < this.tempimage.length; t++) {
        if (this.tempimage[t].category_doc_id == id) {
          this.tempimage[t].file_name = "";
          this.tempimage[t].file_type = "";
          this.tempimage[t].file_data = "";
        }
      }
      this.getImageTemp();
    },
    onFileChangesus(evt) {
      const files = evt.target.files || evt.dataTransfer.files;
      const selectedImage = evt.target.files[0]; // get first file
      // console.log(selectedImage);
      this.docname = selectedImage.name;
      this.doctype = selectedImage.type;
      if (!files.length) return;
      this.createDoc(files[0]);
    },
    onImageChangesus(evt) {
      const files = evt.target.files || evt.dataTransfer.files;
      const selectedImage = evt.target.files[0]; // get first file
      // console.log(selectedImage);
      this.docname = selectedImage.name;
      this.doctype = selectedImage.type;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createDoc(file) {
      const reader = new FileReader();

      reader.onload = (evt) => {
        this.image = evt.target.result;
        // console.log(this.docid);
        const formData = new FormData();
          formData.append("file", file); // appending file
          //  sending file to the backend
          // var http = "https://api-ri.ponnipa.in.th/upload";
          
    var link = ConvertService.gethttp()
    // console.log(link);
          var http = link+"/uploadtracking?name="+this.docname;
          axios
            .post(http, formData)
            .then((res) => {
              console.log(res.data);
            })
            .catch((err) => {
              console.log(err);
            });
        var file_data = link+"/uploads/Tracking/"+this.docname;
        for (let t = 0; t < this.tempdoc.length; t++) {
          if (this.tempdoc[t].category_doc_id == this.catdocid) {
            this.tempdoc[t].file_name = this.docname;
            this.tempdoc[t].file_type = this.doctype;
            this.tempdoc[t].file_data = file_data;
          }
        }
        this.getDocTemp();
      };

      reader.readAsDataURL(file);
    },
    createImage(file) {
      const reader = new FileReader();

      reader.onload = (evt) => {
        this.image = evt.target.result;
        // console.log(this.tempimage);
        // console.log(this.catdocid);
        const formData = new FormData();
          formData.append("file", file); // appending file
          //  sending file to the backend
          // var http = "https://api-ri.ponnipa.in.th/upload";
          
    var link = ConvertService.gethttp()
    // console.log(link);
          var http = link+"/uploadtracking?name="+this.docname;
          axios
            .post(http, formData)
            .then((res) => {
              console.log(res.data);
            })
            .catch((err) => {
              console.log(err);
            });
        var file_data = link+"/uploads/Tracking/"+this.docname;
        for (let t = 0; t < this.tempimage.length; t++) {
          if (this.tempimage[t].category_doc_id == this.catimageid) {
            this.tempimage[t].file_name = this.docname;
            this.tempimage[t].file_type = this.doctype;
            this.tempimage[t].file_data = file_data;
          }
        }
        this.getImageTemp();
      };

      reader.readAsDataURL(file);
    },
    getDocTemp() {
      // console.log(this.doclist);
      // console.log(this.tempdoc);
      DocsDataService.getDocByType(7).then((res) => {
        this.doclisttracking = res.data;
        for (let d = 0; d < this.doclisttracking.length; d++) {
          for (let dd = 0; dd < this.tempdoc.length; dd++) {
            if (
              this.doclisttracking[d].category_doc_id ==
              this.tempdoc[dd].category_doc_id
            ) {
              this.doclisttracking[d].file_name = this.tempdoc[dd].file_name;
              this.doclisttracking[d].file_type = this.tempdoc[dd].file_type;
              this.doclisttracking[d].file_data = this.tempdoc[dd].file_data;
            }
          }
        }
      });
      // console.log(this.doclisttracking);
    },
    getImageTemp() {
      // console.log(this.doclist);
      // console.log(this.tempimage);
      DocsDataService.getDocByType(10).then((res) => {
        this.imagelisttracking = res.data;
        // console.log(this.imagelisttracking);
        for (let d = 0; d < this.imagelisttracking.length; d++) {
          for (let dd = 0; dd < this.tempimage.length; dd++) {
            if (
              this.imagelisttracking[d].category_doc_id ==
              this.tempimage[dd].category_doc_id
            ) {
              this.imagelisttracking[d].file_name =
                this.tempimage[dd].file_name;
              this.imagelisttracking[d].file_type =
                this.tempimage[dd].file_type;
              this.imagelisttracking[d].file_data =
                this.tempimage[dd].file_data;
            }
          }
        }
      });
      // console.log(this.imagelisttracking);
    },
    getlistsus() {
      TrackingDetailDataService.getTracking(this.tracking_id, 2).then((res) => {
        // console.log(res.data);
        this.trackinglistsus = res.data;
        for (let q = 0; q < this.trackinglistsus.length; q++) {
          this.trackinglistsus[q].select = false;
        }
        if (this.tracking_id == 0) {
          this.showlist = false;
        }
      });
    },
    getlist() {
      TrackingDetailDataService.getTracking(this.tracking_id, 1).then((res) => {
        // console.log(res.data);
        this.trackinglist = res.data;
        for (let q = 0; q < this.trackinglist.length; q++) {
          this.trackinglist[q].select = false;
        }
        if (this.tracking_id == 0) {
          this.showlist = false;
        }
      });
    },
    selecttrackingsus(data) {
      for (let qq = 0; qq < this.trackinglist.length; qq++) {
        this.trackinglist[qq].select = false;
      }
      for (let qq = 0; qq < this.trackinglistsus.length; qq++) {
        this.trackinglistsus[qq].select = false;
        if (
          data.tracking_detail_id == this.trackinglistsus[qq].tracking_detail_id
        ) {
          this.trackinglistsus[qq].select = true;
        }
      }
      this.tracking_detail_id = data.tracking_detail_id;
      this.getDoctracking();
      this.getImagetracking();
      if (this.tracking_detail_id != 0) {
        this.showadd = true;
      }
      TrackingDetailDataService.getDetailTracking(
        this.tracking_detail_id,
        2
      ).then((res) => {
        // console.log(res.data);
        this.getAmphurAll(res.data.province_house);
        this.getDistrictAll(res.data.district_house);
        this.getpostcode(res.data.sub_district_house);
        this.getAmphurAllcurrent(res.data.province_current);
        this.getDistrictAllcurrent(res.data.district_current);
        this.getpostcodecurrent(res.data.sub_district_current);

        this.tracking = res.data;
        this.showlist = true;
        this.suspect_id = this.tracking.suspect_id;
        this.relative_id = this.tracking.relatives_id;
        this.address_id = this.tracking.address_id;
        if (this.tracking.nationality != null) {
          if (this.tracking.nationality != "ไทย") {
            this.other = true;
            this.nationalityother = this.tracking.nationality;
            this.tracking.nationality = "อื่นๆ";
          }
        }
        if (this.tracking.is_same == null) {
          this.sameaddress = false;
        } else {
          this.sameaddress = this.tracking.is_same;
        }
        this.postal_code_house = this.tracking.postal_code_house;
        this.postal_code_current = this.tracking.postal_code_current;

        if (this.tracking.tracking_date != null) {
          this.tracking.tracking_date = ConvertService.getdate(
            new Date(this.tracking.tracking_date)
          );
          // console.log(this.tracking.tracking_date);
          document.getElementById("tracking_date").value =
            this.tracking.tracking_date;
        }
        if (this.tracking.tracking_next_time != null) {
          this.tracking.tracking_next_time = ConvertService.getdate(
            new Date(this.tracking.tracking_next_time)
          );
          document.getElementById("tracking_next_time").value =
            this.tracking.tracking_next_time;
        }
        this.type = 2
        // console.log(res.data);
        // console.log(this.quality);
      });
    },
    selecttracking(data) {
      for (let qq = 0; qq < this.trackinglistsus.length; qq++) {
        this.trackinglistsus[qq].select = false;
      }
      for (let qq = 0; qq < this.trackinglist.length; qq++) {
        this.trackinglist[qq].select = false;
        if (
          data.tracking_detail_id == this.trackinglist[qq].tracking_detail_id
        ) {
          this.trackinglist[qq].select = true;
        }
      }
      this.tracking_detail_id = data.tracking_detail_id;
      this.getDoctracking();
      this.getImagetracking();
      if (this.tracking_detail_id != 0) {
        this.showadd = true;
      }
      TrackingDetailDataService.getDetailTracking(
        this.tracking_detail_id,
        1
      ).then((res) => {
        // console.log(res.data);
        this.getAmphurAll(res.data.province_house);
        this.getDistrictAll(res.data.district_house);
        this.getpostcode(res.data.sub_district_house);
        this.getAmphurAllcurrent(res.data.province_current);
        this.getDistrictAllcurrent(res.data.district_current);
        this.getpostcodecurrent(res.data.sub_district_current);

        this.tracking = res.data;
        this.showlist = true;
        this.suspect_id = this.tracking.suspect_id;
        this.relative_id = this.tracking.relatives_id;
        this.address_id = this.tracking.address_id;
        if (this.tracking.nationality != null) {
          if (this.tracking.nationality != "ไทย") {
            this.other = true;
            this.nationalityother = this.tracking.nationality;
            this.tracking.nationality = "อื่นๆ";
          }
        }
        if (this.tracking.is_same == null) {
          this.sameaddress = false;
        } else {
          this.sameaddress = this.tracking.is_same;
        }
        this.postal_code_house = this.tracking.postal_code_house;
        this.postal_code_current = this.tracking.postal_code_current;

        if (this.tracking.tracking_date != null) {
          this.tracking.tracking_date = ConvertService.getdate(
            new Date(this.tracking.tracking_date)
          );
          // console.log(this.tracking.tracking_date);
          document.getElementById("tracking_date").value =
            this.tracking.tracking_date;
        }
        if (this.tracking.tracking_next_time != null) {
          this.tracking.tracking_next_time = ConvertService.getdate(
            new Date(this.tracking.tracking_next_time)
          );
          document.getElementById("tracking_next_time").value =
            this.tracking.tracking_next_time;
        }
        // console.log(res.data);
        // console.log(this.quality);
        this.type = 1
      });
    },
    gettracking() {
      TrackingDataService.getTracking(this.tracking_id).then((res) => {
        // console.log(res.data);
        this.tracking = [];
        this.suspect_id = res.data.suspect_id;
        this.tracking.id_card = res.data.id_card;
        this.tracking.prefix = res.data.prefix;
        this.tracking.firstname = res.data.firstname;
        this.tracking.lastname = res.data.lastname;
        this.addTracking();
      });
    },
    copydescendant(data) {
      this.samesuspect = false;
      // console.log(data);
      this.relative_id = data.relatives_id;
      RelativesDataService.getRelativeAddressTracking(data.relatives_id).then(
        (res) => {
          // console.log(
          //   res.data.province_house,
          //   res.data.district_house,
          //   res.data.sub_district_house
          // );
          this.getAmphurAll(res.data.province_house);
          this.getDistrictAll(res.data.district_house);
          this.getpostcode(res.data.sub_district_house);
          this.getAmphurAllcurrent(res.data.province_current);
          this.getDistrictAllcurrent(res.data.district_current);
          this.getpostcodecurrent(res.data.sub_district_current);
          this.tracking.relative_id = data.relatives_id;
          this.tracking.id_card_relative = data.id_card_relative;
          this.tracking.prefixrelative = data.prefix;
          this.tracking.firstnamerelative = data.firstname;
          this.tracking.lastnamerelative = data.lastname;
          this.tracking.gender = data.gender;
          this.tracking.status = data.status;
          this.tracking.phone = data.phone;
          this.tracking.relationship = data.relationship;
          this.tracking.no_house = res.data.no_house;
          this.tracking.village_no_house = res.data.village_no_house;
          this.tracking.road_house = res.data.road_house;
          this.tracking.lane_house = res.data.lane_house;
          this.tracking.village_house = res.data.village_house;
          this.tracking.province_house = res.data.province_house;
          this.tracking.district_house = res.data.district_house;
          this.tracking.sub_district_house = res.data.sub_district_house;
          this.postal_code_house = res.data.postal_code_house;
          this.tracking.no_current = res.data.no_current;
          this.tracking.village_no_current = res.data.village_no_current;
          this.tracking.road_current = res.data.road_current;
          this.tracking.lane_current = res.data.lane_current;
          this.tracking.village_current = res.data.village_current;
          this.tracking.province_current = res.data.province_current;
          this.tracking.district_current = res.data.district_current;
          this.tracking.sub_district_current = res.data.sub_district_current;
          this.postal_code_current = res.data.postal_code_current;
          if (data.nationality != "ไทย") {
            this.other = true;
            this.nationalityother = data.nationality;
            this.tracking.nationality = "อื่นๆ";
          } else {
            this.tracking.nationality = data.nationality;
          }
          this.sameaddress = res.data.is_same;
          // console.log(res.data);
          // console.log(this.tracking);
          // this.postal_code_house = res.data.lan;
          // this.postal_code_house = res.data.postal_code_house;

          for (let lii = 0; lii < this.list.length; lii++) {
            this.list[lii].select = false;
            if (data.relatives_id == this.list[lii].relatives_id) {
              this.list[lii].select = true;
            }
          }
          this.getDoctracking();
          this.getImagetracking();
          this.type = 1
        }
      );
    },
    copysuspect(data) {
      // console.log(data);
      this.suspect_id = data.suspect_id;
      this.tracking.suspect_id = data.suspect_id;
      this.tracking.id_card = data.id_card;
      this.tracking.prefix = data.prefix;
      this.tracking.firstname = data.firstname;
      this.tracking.lastname = data.lastname;
      // console.log(this.tracking);
      this.list = [];
      SuspectsRelativeDataService.getDescendantBySuspect(data.suspect_id).then(
        (res) => {
          // console.log(res.data);
          this.list = res.data;
          for (let ll = 0; ll < this.list.length; ll++) {
            this.list[ll].select = false;
          }
        }
      );
      if (this.tracking_id == 0) {
        this.showadd = false;
        this.showlist = true;
      }
    },
    getIDCard() {
      TrackingDataService.getIDSuspectTracking().then((res) => {
        this.idcardsuspectlist = res.data;
        // console.log(this.idcardsuspectlist);
      });
    },
    getIDCardDescendant() {
      SuspectsRelativeDataService.getDescendantTracking().then((res) => {
        this.idcarddescendantlist = res.data;
        // console.log(res.data);
      });
    },
    getrelationship() {
      RelationshipsDataService.getRelationshipsActive().then((res) => {
        this.relationships = res.data;
      });
    },
    onChangenational(event) {
      // console.log(event.target.value);
      if (event.target.value == "อื่นๆ") {
        this.other = true;
      } else {
        this.other = false;
      }
    },
    onChangeProvincecurrent(event) {
      // console.log(this.user.province)
      var province_id = event.target.value;
      this.tracking.district_current = "";
      this.tracking.sub_district_current = "";
      this.postal_code_current = "";
      this.getAmphurAllcurrent(province_id);
    },
    onChangeDistrictcurrent(event) {
      // console.log(this.user.province)
      var amphur_id = event.target.value;
      this.tracking.sub_district_current = "";
      this.getDistrictAllcurrent(amphur_id);
    },
    onChangePostalCodecurrent(event) {
      //  console.log(event.target.value)
      var district_id = event.target.value;
      this.getpostcodecurrent(district_id);
    },
    getProvinceAllcurrent() {
      ProvinceDataService.getProvinceAll().then((res) => {
        this.provincescurrent = res.data;
      });
    },
    getAmphurAllcurrent(id) {
      if (id == "") {
        AmphurDataService.getAmphursAll().then((res) => {
          this.amphurescurrent = res.data;
        });
      } else {
        AmphurDataService.getAmphurAll(id).then((res) => {
          this.amphurescurrent = res.data;
        });
      }
    },
    getpostcodecurrent(id) {
      DistrictDataService.getPostCode(id).then((res) => {
        // console.log(res.data[0].POST_CODE);
        this.postal_code_current = res.data[0].POST_CODE;
      });
    },
    getDistrictAllcurrent(id) {
      if (id == "") {
        DistrictDataService.getDistrictsAll().then((res) => {
          this.districtscurrent = res.data;
        });
      } else {
        DistrictDataService.getDistrictAll(id).then((res) => {
          this.districtscurrent = res.data;
          this.postal_code_current = "";
        });
      }
    },
    onChangeProvince(event) {
      // console.log(this.user.province)
      var province_id = event.target.value;
      this.tracking.district_house = "";
      this.tracking.sub_district_house = "";
      this.postal_code_house = "";
      this.getAmphurAll(province_id);
    },

    onChangeDistrict(event) {
      // console.log(this.user.province)
      var amphur_id = event.target.value;
      this.tracking.sub_district_house = "";
      this.getDistrictAll(amphur_id);
    },
    onChangePostalCode(event) {
      //  console.log(event.target.value)
      var district_id = event.target.value;
      this.getpostcode(district_id);
    },
    getProvinceAll() {
      ProvinceDataService.getProvinceAll().then((res) => {
        this.provinces = res.data;
      });
    },
    getAmphurAll(id) {
      if (id == "") {
        AmphurDataService.getAmphursAll().then((res) => {
          this.amphures = res.data;
        });
      } else {
        AmphurDataService.getAmphurAll(id).then((res) => {
          this.amphures = res.data;
          // console.log(res.data);
        });
      }
      // console.log(this.amphures);
    },
    getpostcode(id) {
      DistrictDataService.getPostCode(id).then((res) => {
        // console.log(res.data[0].POST_CODE);
        this.postal_code_house = res.data[0].POST_CODE;
      });
    },
    getDistrictAll(id) {
      if (id == "") {
        DistrictDataService.getDistrictsAll().then((res) => {
          this.districts = res.data;
        });
      } else {
        DistrictDataService.getDistrictAll(id).then((res) => {
          this.districts = res.data;
          // console.log(res.data);
          this.postal_code_house = "";
        });
      }
    },
    changeaddress() {
      if (this.sameaddress == false) {
        this.getAmphurAllcurrent(this.tracking.province_house);
        this.getDistrictAllcurrent(this.tracking.district_house);
        this.getpostcodecurrent(this.tracking.sub_district_house);
        this.tracking.no_current = this.tracking.no_house;
        this.tracking.village_no_current = this.tracking.village_no_house;
        this.tracking.road_current = this.tracking.road_house;
        this.tracking.lane_current = this.tracking.lane_house;
        this.tracking.village_current = this.tracking.village_house;
        this.tracking.sub_district_current = this.tracking.sub_district_house;
        this.tracking.district_current = this.tracking.district_house;
        this.tracking.province_current = this.tracking.province_house;
        this.postal_code_current = this.postal_code_house;
      } else if (this.sameaddress == true && this.tracking.length == 0) {
        this.tracking.no_current = "";
        this.tracking.village_no_current = "";
        this.tracking.road_current = "";
        this.tracking.lane_current = "";
        this.tracking.village_current = "";
        this.tracking.sub_district_current = "";
        this.tracking.district_current = "";
        this.tracking.province_current = "";
        this.tracking.postal_code_current = "";
        // this.descendants.longitude_current = "";
        // this.descendants.lattitude_current = "";
      }
    },
    save() {
      // console.log(this.tracking_detail_id);
      if (this.tracking.id_card == null) {
        alert("กรุณากรอกหมายเลขบัตรประชาชนผู้เสียหาย");
      } else if (this.tracking.id_card.length < 13) {
        alert("กรุณากรอกหมายเลขบัตรประชาชนผู้เสียหายให้ครบ");
      } else if (this.tracking.prefix == null) {
        alert("กรุณากรอกคำนำหน้าชื่อผู้เสียหาย");
      } else if (this.tracking.firstname == null) {
        alert("กรุณากรอกชื่อผู้เสียหาย");
      } else if (this.tracking.lastname == null) {
        alert("กรุณากรอกนามสกุลผู้เสียหาย");
      } else if (this.tracking.id_card_relative == null) {
        alert("กรุณากรอกหมายเลขบัตรประชาชนทายาท");
      } else if (this.tracking.id_card_relative.length < 13) {
        alert("กรุณากรอกหมายเลขบัตรประชาชนทายาทให้ครบ");
      } else if (this.tracking.prefixrelative == null) {
        alert("กรุณากรอกคำนำหน้าชื่อทายาท");
      } else if (this.tracking.firstnamerelative == null) {
        alert("กรุณากรอกชื่อทายาท");
      } else if (this.tracking.lastnamerelative == null) {
        alert("กรุณากรอกนามสกุลทายาท");
      } else if (this.tracking.province_house == null) {
        alert("กรุณาเลือกจังหวัดปัจจุบัน");
      } else if (this.tracking.district_house == null) {
        alert("กรุณาเลือกอำเภอปัจจุบัน");
      } else if (this.tracking.sub_district_house == null) {
        alert("กรุณาเลือกตำบลปัจจุบัน");
      } else if (this.tracking.province_current == null) {
        alert("กรุณาเลือกจังหวัดตามบัตรประชาชน");
      } else if (this.tracking.district_current == null) {
        alert("กรุณาเลือกอำเภอตามบัตรประชาชน");
      } else if (this.tracking.sub_district_current == null) {
        alert("กรุณาเลือกตำบลตามบัตรประชาชน");
      }
      // else if (this.tracking.gender == null) {
      //   alert("กรุณากรอกเพศทายาท");
      // }
      // else if (this.tracking.phone == null) {
      //   alert("กรุณากรอกเบอร์โทรศัพท์ทายาท");
      // }
      // else if (this.tracking.relationship == null) {
      //   alert("กรุณาเลือกความสัมพันธ์ทายาท");
      // } else if (this.tracking.nationality == null) {
      //   alert("กรุณาเลือกสัญชาติทายาท");
      // }
      else if (this.tracking.prefix_tracking == null) {
        alert("กรุณาเลือกคำนำหน้าชื่อผู้ติดตาม");
      } else if (this.tracking.firstname_tracking == null) {
        alert("กรุณากรอกชื่อผู้ติดตาม");
      } else if (this.tracking.lastname_tracking == null) {
        alert("กรุณากรอกนามสกุลผู้ติดตาม");
      } else if (this.tracking.department == null) {
        alert("กรุณากรอกหน่วยงานที่ติดตาม");
      } else if (document.getElementById("tracking_date").value == "") {
        alert("กรุณากรอกวันที่ลงติดตาม");
      } else if (this.tracking.location_tracking == null) {
        alert("กรุณากรอกสถานที่ไปติดตาม");
      } else if (this.tracking.living_conditions == null) {
        alert("กรุณากรอกสภาพความเป็นอยู่");
      } else if (this.tracking.problem_detail == null) {
        alert("กรุณากรอกรายละเอียดปัญหา");
      } else if (document.getElementById("tracking_next_time").value == "") {
        alert("กรุณากรอกการแจ้งเตือนติดตามครั้งต่อไป");
      } else {
        if (this.tracking.nationality == "อื่นๆ") {
          this.tracking.nationality = this.nationalityother;
        }
        var address = {
          is_same: this.sameaddress,
          no_house: this.tracking.no_house,
          village_no_house: this.tracking.village_no_house,
          road_house: this.tracking.road_house,
          lane_house: this.tracking.lane_house,
          village_house: this.tracking.village_house,
          sub_district_house: this.tracking.sub_district_house,
          district_house: this.tracking.district_house,
          province_house: this.tracking.province_house,
          postal_code_house: this.postal_code_house,
          no_current: this.tracking.no_current,
          village_no_current: this.tracking.village_no_current,
          road_current: this.tracking.road_current,
          lane_current: this.tracking.lane_current,
          village_current: this.tracking.village_current,
          sub_district_current: this.tracking.sub_district_current,
          district_current: this.tracking.district_current,
          province_current: this.tracking.province_current,
          postal_code_current: this.postal_code_current,
          // longitude_house: this.quality.longitude_house,
          // lattitude_house: this.descendants.lattitude_house,
          // longitude_current: this.descendants.longitude_current,
          // lattitude_current: this.descendants.lattitude_current,
          created_by: this.currentUser.user_id,
          updated_by: this.currentUser.user_id,
        };
        console.log(address);
        var track = {
          suspect_id: this.suspect_id,
          created_by: this.currentUser.user_id,
          updated_by: this.currentUser.user_id,
        };
        if (this.tracking_detail_id == 0) {
          AddressDataService.createAddress(address).then((res) => {
            // console.log(res.data.id);
            this.address_id = res.data.id;
            var relativeaddress = {
              relative_id: this.relative_id,
              address_id: this.address_id,
              created_by: this.currentUser.user_id,
              updated_by: this.currentUser.user_id,
            };
            // console.log(relativeaddress);
            if (this.type == 2) {
              var suspectaddress = {
                suspect_id: this.suspect_id,
                address_id: this.address_id,
                created_by: this.currentUser.user_id,
                updated_by: this.currentUser.user_id,
              };
              AddressDataService.createSuspectAddress(suspectaddress).then(
                () => {}
              );
            } else {
              RelativesAddressDataService.createRelativeAddress(
                relativeaddress
              ).then(() => {});
            }
            if (this.tracking_id == 0) {
              TrackingDataService.createTracking(track).then((res) => {
                // console.log(res.data);
                this.tracking_id = res.data.id;
                var qulitydetail = {
                  tracking_id: this.tracking_id,
                  relative_id: this.relative_id,
                  address_id: this.address_id,
                  type: this.type,
                  prefix: this.tracking.prefix_tracking,
                  firstname: this.tracking.firstname_tracking,
                  lastname: this.tracking.lastname_tracking,
                  department: this.tracking.department,
                  tracking_date: ConvertService.createddate(
                    document.getElementById("tracking_date").value
                  ),
                  location_tracking: this.tracking.location_tracking,
                  living_conditions: this.tracking.living_conditions,
                  problem_detail: this.tracking.problem_detail,
                  tracking_next_time: ConvertService.createddate(
                    document.getElementById("tracking_next_time").value
                  ),
                  created_by: this.currentUser.user_id,
                  updated_by: this.currentUser.user_id,
                };
                // console.log(qulitydetail);
                TrackingDetailDataService.createTracking(qulitydetail).then(
                  (res) => {
                    // console.log(res.data);
                    this.tracking_detail_id = res.data.id;
                    DocumentsDataService.deleteintegration(
              this.quality_detail_id,              
            'tracking_id'
            ).then(() => {
              // console.log(res.data);
              for (let t = 0; t < this.tempdoc.length; t++) {
                  var doc = {
                    tracking_id: this.tracking_detail_id,
                    category_doc_id: this.tempdoc[t].category_doc_id,
                    file_name: this.tempdoc[t].file_name,
                    file_type: this.tempdoc[t].file_type,
                    file_data: this.tempdoc[t].file_data,
                    created_by: this.currentUser.user_id,
                    updated_by: this.currentUser.user_id,
                  };
                  //console.log(doc);
                  DocumentsDataService.createDocument(doc).then(() => {
                    // console.log(res.data);
                  });
                }
                 for (let t = 0; t < this.tempimage.length; t++) {
                    var doc = {
                      tracking_id: this.tracking_detail_id,
                      category_doc_id: this.tempimage[t].category_doc_id,
                      file_name: this.tempimage[t].file_name,
                      file_type: this.tempimage[t].file_type,
                      file_data: this.tempimage[t].file_data,
                      created_by: this.currentUser.user_id,
                      updated_by: this.currentUser.user_id,
                    };
                    // //console.log(doc);
                    DocumentsDataService.createDocument(doc).then(() => {
                      // console.log(res.data);
                    });
                  }       
                  var data={
            tracking_id: this.tracking_id,
            history_status_id:14,
            user_id:this.currentUser.user_id
          }
          HistoryCaseDataService.createHistory(data).then(()=>{

          })
                  this.$router.push("/tracking/" + this.tracking_id);     
            alert("บันทึกสำเร็จ");
            setTimeout(function () {
              location.reload();
            }, 1000);
            window.scrollTo(0, 0);
          });
                  }
                );
              });
            } else {
              var qulitydetail = {
                tracking_id: this.tracking_id,
                relative_id: this.relative_id,
                address_id: this.address_id,
                type: this.type,
                prefix: this.tracking.prefix_tracking,
                firstname: this.tracking.firstname_tracking,
                lastname: this.tracking.lastname_tracking,
                department: this.tracking.department,
                tracking_date: ConvertService.createddate(
                  document.getElementById("tracking_date").value
                ),
                location_tracking: this.tracking.location_tracking,
                living_conditions: this.tracking.living_conditions,
                problem_detail: this.tracking.problem_detail,
                tracking_next_time: ConvertService.createddate(
                  document.getElementById("tracking_next_time").value
                ),
                created_by: this.currentUser.user_id,
                updated_by: this.currentUser.user_id,
              };
              // console.log(qulitydetail);
              TrackingDetailDataService.createTracking(qulitydetail).then(
                (res) => {
                  // console.log(res.data);
                  this.tracking_detail_id = res.data.id;
                  DocumentsDataService.deleteintegration(
              this.quality_detail_id,              
            'tracking_id'
            ).then(() => {
              // console.log(res.data);
              for (let t = 0; t < this.tempdoc.length; t++) {
                  var doc = {
                    tracking_id: this.tracking_detail_id,
                    category_doc_id: this.tempdoc[t].category_doc_id,
                    file_name: this.tempdoc[t].file_name,
                    file_type: this.tempdoc[t].file_type,
                    file_data: this.tempdoc[t].file_data,
                    created_by: this.currentUser.user_id,
                    updated_by: this.currentUser.user_id,
                  };
                  //console.log(doc);
                  DocumentsDataService.createDocument(doc).then(() => {
                    // console.log(res.data);
                  });
                }
                 for (let t = 0; t < this.tempimage.length; t++) {
                    var doc = {
                      tracking_id: this.tracking_detail_id,
                      category_doc_id: this.tempimage[t].category_doc_id,
                      file_name: this.tempimage[t].file_name,
                      file_type: this.tempimage[t].file_type,
                      file_data: this.tempimage[t].file_data,
                      created_by: this.currentUser.user_id,
                      updated_by: this.currentUser.user_id,
                    };
                    // //console.log(doc);
                    DocumentsDataService.createDocument(doc).then(() => {
                      // console.log(res.data);
                    });
                  }          
                  var data={
            tracking_id: this.tracking_id,
            history_status_id:15,
            user_id:this.currentUser.user_id
          }
          HistoryCaseDataService.createHistory(data).then(()=>{

          })  
            alert("บันทึกสำเร็จ");
            setTimeout(function () {
              location.reload();
            }, 1000);
            window.scrollTo(0, 0);
          });
                }
              );
            }
          });
        } else {
          AddressDataService.updateAddress(this.address_id, address).then(
            () => {
              // console.log(this.tracking_detail_id);
              var qulitydetail = {
                tracking_id: this.tracking_id,
                relative_id: this.relative_id,
                address_id: this.address_id,
                type: this.type,
                prefix: this.tracking.prefix_tracking,
                firstname: this.tracking.firstname_tracking,
                lastname: this.tracking.lastname_tracking,
                department: this.tracking.department,
                tracking_date: ConvertService.createddate(
                  document.getElementById("tracking_date").value
                ),
                location_tracking: this.tracking.location_tracking,
                living_conditions: this.tracking.living_conditions,
                problem_detail: this.tracking.problem_detail,
                tracking_next_time: ConvertService.createddate(
                  document.getElementById("tracking_next_time").value
                ),
                created_by: this.currentUser.user_id,
                updated_by: this.currentUser.user_id,
              };
              // console.log(qulitydetail);
              TrackingDetailDataService.updateTracking(
                this.tracking_detail_id,
                qulitydetail
              ).then((res) => {
                // console.log(res.data);
                DocumentsDataService.deleteintegration(
              this.quality_detail_id,              
            'tracking_id'
            ).then(() => {
              // console.log(res.data);
              for (let t = 0; t < this.tempdoc.length; t++) {
                  var doc = {
                    tracking_id: this.tracking_detail_id,
                    category_doc_id: this.tempdoc[t].category_doc_id,
                    file_name: this.tempdoc[t].file_name,
                    file_type: this.tempdoc[t].file_type,
                    file_data: this.tempdoc[t].file_data,
                    created_by: this.currentUser.user_id,
                    updated_by: this.currentUser.user_id,
                  };
                  //console.log(doc);
                  DocumentsDataService.createDocument(doc).then(() => {
                    // console.log(res.data);
                  });
                }
                 for (let t = 0; t < this.tempimage.length; t++) {
                    var doc = {
                      tracking_id: this.tracking_detail_id,
                      category_doc_id: this.tempimage[t].category_doc_id,
                      file_name: this.tempimage[t].file_name,
                      file_type: this.tempimage[t].file_type,
                      file_data: this.tempimage[t].file_data,
                      created_by: this.currentUser.user_id,
                      updated_by: this.currentUser.user_id,
                    };
                    // //console.log(doc);
                    DocumentsDataService.createDocument(doc).then(() => {
                      // console.log(res.data);
                    });
                  }      
                  var data={
            tracking_id: this.tracking_id,
            history_status_id:15,
            user_id:this.currentUser.user_id
          }
          HistoryCaseDataService.createHistory(data).then(()=>{

          })        
            alert("บันทึกสำเร็จ");
            setTimeout(function () {
              location.reload();
            }, 1000);
            window.scrollTo(0, 0);
          });
                
              });
            }
          );
        }
      }
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    filteredList() {
      return this.idcardsuspectlist.filter((post) => {
        this.id_suspect = this.tracking.id_card;
        // console.log(this.id_suspect);
        if (this.id_suspect != "") {
          return post.id_card.toLowerCase().includes(this.id_suspect);
        }
      });
    },
    filtereddesList() {
      return this.idcarddescendantlist.filter((post) => {
        this.id_descendant = this.tracking.id_card_relative;

        return post.id_card_relative.toLowerCase().includes(this.id_descendant);
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  font-family: "Kanit" !important;
}
.row {
  max-width: 1200px;
  margin: auto;
  background: white;
  padding: 10px;
  color: black !important;
}
a:visited {
  color: black;
} /* visited link */
a:hover {
  color: black;
} /* mouse over link */
a:active {
  color: black;
}
a:link {
  color: black;
}
#my_filetracking {
  display: none;
}
#my_imagetracking {
  display: none;
}
</style>
