<template>
  <div class="hello">
    <HomeIcon /> 
    <div class="mt-5">
      <div class="row card mt-2">
        <div class="card-body p-0">
          <h4 style="text-align: center">ค้นหา</h4>
           <div class="row">
            <div class="col-sm-2"></div>
            <div class="col-sm-4">
              <div class="form-group" style="text-align: left">
                <label for="username">เลขบัตรประชาชนผู้เสียหาย</label>
                <input
                  v-model="id_card"
                  type="number"
                  class="form-control"
                  id="username"
                  placeholder="เลขบัตรประชาชน 13 หลัก"
                />
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group" style="text-align: left">
                <label for="username"
                  >วันที่ยื่นคำร้องขอรับการช่วยเหลือเยียวยา
                </label>
                <input id="fill_start_date" class="form-control" />
              </div>
            </div>
            <div class="col-sm-2"></div>
          </div>
        </div>
        <div class="col text-center">
          <button
            @click="search()"
            class="btn btn-primary"
            style="color: white"
          >
            <i class="fas fa-search"></i> ค้นหา
          </button>
        </div>
      </div>
      <div class="row card mt-5" v-if="suspect_id != 0">
        <div class="card-body p-0">
           <div style="padding: 50px">
          <div class="row">
            <div class="col-md-12">
              <h5 style="color: #2da3f2; font-weight: bold">
                ข้อมูลผู้เสียหาย
              </h5>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <h6>หมายเลขบัตรประชาชน</h6>
            </div>
            <div class="col-md-9">
              <h6>{{ suspect.id_card }}</h6>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <h6>ชื่อ-นามสกุล</h6>
            </div>
            <div class="col-md-9">
              <h6>
                {{ suspect.prefix }} {{ suspect.firstname }}
                {{ suspect.lastname }}
              </h6>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <h6>วัน/เดือน/ปีเกิด</h6>
            </div>
            <div class="col-md-9">
              <h6>{{ convertdatetothai(suspect.birthday) }}</h6>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <h6>เบอร์โทรศัพท์</h6>
            </div>
            <div class="col-md-9">
              <h6>{{ suspect.phone }}</h6>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <h6>เพศ</h6>
            </div>
            <div class="col-md-9">
              <h6>{{ suspect.gender }}</h6>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <h6>อาชีพ</h6>
            </div>
            <div class="col-md-9">
              <h6>{{ suspect.career }}</h6>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <h6>รายได้</h6>
            </div>
            <div class="col-md-9">
              <h6 v-if="suspect.income">{{ suspect.income.toLocaleString() }} บาทต่อเดือน</h6>
              <h6 v-else> -  บาทต่อเดือน</h6>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <h6>สัญชาติ</h6>
            </div>
            <div class="col-md-9">
              <h6>{{ suspect.nationality }}</h6>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <h6>ที่อยู่ตามบัตรประชาชน</h6>
            </div>
            <div class="col-md-12">
              <h6>
                บ้านเลขที่ {{ suspect.no_house || "-"}} 
                หมู่ที่ {{ suspect.village_no_house || "-"}} ซอย
                {{ suspect.road_house || "-" }} ถนน
                {{ suspect.lane_house || "-" }} หมู่บ้าน
                {{ suspect.village_house || "-" }} ตำบล
                {{ suspect.sub_district_house }} อำเภอ
                {{ suspect.district_house }} จังหวัด
                {{ suspect.province_house }} รหัสไปรษณีย์
                {{ suspect.postal_code_house }}
              </h6>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <h6>ที่อยู่ปัจจุบัน</h6>
            </div>
            <div class="col-md-12">
              <h6>
                บ้านเลขที่ {{ suspect.no_current || "-"}}
                หมู่ที่ {{ suspect.village_no_current || "-" }} ซอย
                {{ suspect.road_current || "-" }} ถนน
                {{ suspect.lane_current || "-" }} หมู่บ้าน{{
                  suspect.village_current || "-"
                }}
                ตำบล {{ suspect.sub_district_current }} อำเภอ
                {{ suspect.district_current }} จังหวัด
                {{ suspect.province_current }} รหัสไปรษณีย์
                {{ suspect.postal_code_current }}
              </h6>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-md-12">
              <h5 style="color: #2da3f2; font-weight: bold">ข้อมูลทายาท</h5>
              <table class="table table-bordered">
      <thead>
        <tr>
          <th scope="col">ชื่อ-นามสกุล</th>
          <th scope="col">เลขบัตรประชาชน</th>
          <th scope="col" style="text-align: right">
          </th>
        </tr>
      </thead>
      <tbody v-if="list.length > 0">
        <tr v-for="l in list" :key="l.relative_id">
          <td>{{ l.firstname }} {{ l.lastname }}</td>
          <td>{{ l.id_card_relative }}</td>
          <td style="text-align: right">
            <button
              class="btn btn-success"
              style="color: white"
            >
              <a
                style="color: white"
                data-toggle="modal"
                href="#"
                data-target="#addModalDescendantsView"
                @click="addDescendant(l.relatives_id)"
                >ดู</a
              >
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="list.length == 0" style="text-align: center">ไม่พบข้อมูล</div>
    
            </div>
          </div>
        </div>
        </div>
      </div>
<div class="row card mt-5" v-if="nodata">
          <div class="card-body p-0" >
          <div class="row">
            <div class="col-md-12 mt-3">
              <h5 class="form-group" style="text-align: center;color: #2da3f2; font-weight: bold">
                ไม่พบข้อมูล
              </h5>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col text-center">
          <button class="btn btn-warning" style="color: white" @click="back()">
            ย้อนกลับ
          </button>
        </div>
      </div>
    </div>
     <!-- Modal -->
    <div
      class="modal fade"
      id="addModalDescendantsView"
      tabindex="-1"
      role="dialog"
      aria-labelledby="addModalDescendantsViewLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <h5>ข้อมูลทายาท</h5>
            </div>
            <div class="row">
                <div class="col-md-2"></div>
              <div class="col-md-4">
                <h6 style="text-align: left">เลขบัตรประชาชน</h6>
              </div>
              <div class="col-md-6">
                <h6 style="text-align: left">{{ descendants.id_card_relative }}</h6>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-4">
                <h6 style="text-align: left">ชื่อ-นามสกุล</h6>
              </div>
              <div class="col-md-6">
                <h6 style="text-align: left">{{ descendants.firstname }}  {{ descendants.lastname }}</h6>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-4">
                <h6 style="text-align: left">วัน/เดือน/ปีเกิด</h6>
              </div>
              <div class="col-md-6">
                <h6 style="text-align: left">{{ descendants.birthday }}</h6>
              </div>
            </div>
<div class="row">
  <div class="col-md-2"></div>
              <div class="col-md-4">
                <h6 style="text-align: left">เพศ</h6>
              </div>
              <div class="col-md-6">
                <h6 style="text-align: left">{{ descendants.gender }}</h6>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-4">
                <h6 style="text-align: left">ความสัมพันธ์</h6>
              </div>
              <div class="col-md-6">
                <h6 style="text-align: left">{{ descendants.relationship }}</h6>
              </div>
            </div>
<div class="row">
  <div class="col-md-2"></div>
              <div class="col-md-4">
                <h6 style="text-align: left">อาชีพ</h6>
              </div>
              <div class="col-md-6">
                <h6 style="text-align: left">{{ descendants.career }}</h6>
              </div>
            </div>
<div class="row">
  <div class="col-md-2"></div>
              <div class="col-md-4">
                <h6 style="text-align: left">รายได้</h6>
              </div>
              <div class="col-md-6">
                <h6 v-if="descendants.income" style="text-align: left">{{ descendants.income.toLocaleString()}} บาทต่อเดือน</h6>
                <h6 v-else style="text-align: left">- บาทต่อเดือน</h6>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-4">
                <h6 style="text-align: left">เบอร์โทรศัพท์</h6>
              </div>
              <div class="col-md-6">
                <h6 style="text-align: left">{{ descendants.phone }} </h6>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-4">
                <h6 style="text-align: left">สถานะ</h6>
              </div>
              <div class="col-md-6">
                <h6 style="text-align: left">{{ descendants.status }} </h6>
              </div>
            </div>
<div class="row">
  <div class="col-md-2"></div>
              <div class="col-md-4">
                <h6 style="text-align: left">สัญชาติ</h6>
              </div>
              <div class="col-md-6">
                <h6 style="text-align: left">{{ descendants.nationality }} </h6>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-4">
                <h6 style="text-align: left">ที่อยู่ตามบัตรประชาชน</h6>
              </div>
              <div class="col-md-6">
                <h6 style="text-align: left">บ้านเลขที่ {{ descendants.no_house }} หมู่ที่ {{descendants.village_no_house}} 
                  ซอย {{descendants.road_house}} ถนน {{descendants.lane_house}} หมู่บ้าน {{descendants.village_house}}
                  ตำบล {{descendants.district_house}} อำเภอ {{descendants.sub_district_house}} จังหวัด {{descendants.province_house}} รหัสไปรษณีย์ {{postal_code_house}}
                </h6>
              </div>
            </div>

            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-4">
                <h6 style="text-align: left">ที่อยู่ปัจจุบัน</h6>
              </div>
              <div class="col-md-6">
                <h6 style="text-align: left">บ้านเลขที่ {{ descendants.no_current }} หมู่ที่ {{descendants.village_no_current}} 
                  ซอย {{descendants.road_current}} ถนน {{descendants.lane_current}} หมู่บ้าน {{descendants.village_current}}
                  ตำบล {{descendants.district_current}} อำเภอ {{descendants.sub_district_current}} จังหวัด {{descendants.province_current}} รหัสไปรษณีย์ {{postal_code_current}}
                </h6>
              </div>
            </div>
      </div>
          <div class="bd-example mb-5">
            <button
              type="button"
              id="closeddes"
              class="btn btn-danger"
              style="margin-right: 20px; width: 150px"
              data-dismiss="modal"
            >
              ปิดหน้าต่าง
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

  <script src="https://justice-api.sbpac.go.th/picker_date.js"></script>

<script>
const customLabels = {
  first: "<<",
  last: ">>",
  previous: "<",
  next: ">",
};

import HomeIcon from "../HomeIcon.vue";
import AddressDataService from "../../services/AddressDataService";
import SuspectsRelativeDataService from "../../services/SuspectsRelativeDataService";
import CasesDataService from "../../services/CasesDataService";
import CasesEventDataService from "../../services/CasesEventDataService";
import HistoryCaseDataService from "../../services/HistoryCaseDataService";
import RelativesDataService from "../../services/RelativesDataService";
import ConvertService from "../../services/ConvertService";


export default {
  components: {
    HomeIcon,
  },
  data() {
    return {
      name: "HelloWorld",
      case_id: 0,
      id_card: "",
      data: {},
      list: [],
      status:[],
      nodata:false,
      suspect:[],
      suspect_id:'',
      descendants:[],
      postal_code_house:'',
      postal_code_current:''
    };
  },
  mounted() {
    var year = new Date().getFullYear()
    var start = year-2004
    var before = "-"+start+":+0"
    picker_date(document.getElementById("fill_start_date"), {
      year_range: before,
    });
  },
  methods: {
    getstatus() {
      HistoryCaseDataService.getHistoryCaseByCaseID(this.case_id).then(
        (res) => {
          this.status = res.data;
          // console.log(res.data);
        }
      );
    },
    cal(start, end) {
      var date1 = new Date(start);
      var date2 = new Date(end);
      var diffTime = date2.getTime() - date1.getTime();
      var diffDay = diffTime / (1000 * 3600 * 24);
      return diffDay;
    },
    getEventByCaseID() {
      CasesEventDataService.getEventByCaseID(this.case_id).then((res) => {
        this.list = res.data;
        // console.log(res.data);
      });
    },
    back() {
      this.$router.push("/home");
    },
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    },
    convertdatetothai(datetimes) {
      if (datetimes) {
        const date = new Date(datetimes);
        const result = date.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return result;
      } else {
        return "-";
      }
    },
    hasHistory() {
      return window.history.length > 2;
    },
    addDescendant(id) {
      // console.log(id);
      if (id != 0) {
        this.relative_id = id;
        // console.log(id);
        RelativesDataService.getRelativeAddressCase(this.relative_id).then(
          (res) => {
            // console.log(res.data);
            this.descendants = res.data;

            if (this.descendants.nationality != null) {
              if (this.descendants.nationality != "ไทย") {
                this.other = true;
                this.nationalityother = this.descendants.nationality;
                this.descendants.nationality = "อื่นๆ";
              }
            }

            if (this.descendants.postal_code_house != null) {
              this.postal_code_house = res.data.postal_code_house;
            }
            if (this.descendants.postal_code_current != null) {
              this.postal_code_current = res.data.postal_code_current;
            }
            if (this.descendants.birthday != null) {
              this.descendants.birthday = ConvertService.getdate(
                new Date(this.descendants.birthday)
              );
            }
            this.address_id = res.data.address_id;
            if (this.descendants.is_same == null) {
              this.sameaddress = false;
            }
            // console.log(this.descendants);
          }
        );
      } else {
        this.descendants = [];
        this.postal_code_house = "";
        this.postal_code_current = "";
        this.relative_id = 0;
      }
    },
    search() {
      this.suspect_id = 0
      this.suspect = []
      this.list = []
      if (this.id_card == "") {
        alert("กรุณากรอกเลขบัตรประชาชนผู้เสียหาย");
      } else if (document.getElementById("fill_start_date").value == "") {
        alert("กรุณากรอกวันที่ยื่นคำร้องขอรับการช่วยเหลือเยียวยา");
      }else {
        var da;
        var d = document.getElementById("fill_start_date").value;
        // var dd = new Date(d)
        if (d != "") {
          da = ConvertService.createddate(d);
        } else if (d == "") {
          da = "";
        }
      CasesDataService.getCaseSuspect(this.id_card, da).then((res) => {
          // console.log(res.data);
          if (res.data.length == 0) {
            this.nodata = true;
          } else {
             this.nodata = false
          this.suspect_id = res.data.suspect_id;
          AddressDataService.getAddressSuspect(this.suspect_id).then(
          (res) => {
            this.suspect = res.data;
          }
        );
        SuspectsRelativeDataService.getDescendantBySuspect(this.suspect_id).then(
        (res) => {
          
          if (this.list.length < 0) {
            this.list = [];
          } else {
            this.list = res.data;
          }
        }
      );
    
          }
      })
        
        
      }
    },
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  font-family: "Kanit" !important;
}
.row {
  max-width: 1200px;
  margin: auto;
  background: white;
  padding: 10px;
  color: black !important;
}
a:visited {
  color: black;
} /* visited link */
a:hover {
  color: black;
} /* mouse over link */
a:active {
  color: black;
}
a:link {
  color: black;
}

.modal-content {
  float: center;
  text-align: center;
  width: 800px;
}
.modal {
  text-align: center;
  padding: 0 !important;
}
.modal:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}
.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  max-width: 700px;
}
</style>
