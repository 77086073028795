<template>
  <div class="hello mt-5">
    <b-tabs
      active-nav-item-class="text-success"
      content-class="mt-3 tab-active-class"
    >
      <b-tab
        :title-item-class="'tab-title-class'"
        title="ติดตามสถานะ"
        :active="tab === '1'"
        @click="go(1)"
        ><p><StatusTracking v-if="tab === '1'" /></p
      ></b-tab>
       <b-tab
        :title-item-class="'tab-title-class'"
        title="ติดตามสิทธิ์ที่ได้รับการช่วยเหลือ/สิทธิ์ที่ได้รับ"
        :active="tab === '2'"
        @click="go(2)"
        ><p><PrivilegeTracking v-if="tab === '2'" /></p
      ></b-tab>
       <b-tab
        :title-item-class="'tab-title-class'"
        title="ตรวจสอบข้อมูลที่เกี่ยวข้องกับผู้เสียหายและผู้ได้รับผลกระทบ"
        :active="tab === '3'"
        @click="go(3)"
        ><p><Tracking v-if="tab === '3'" /></p
      ></b-tab>
    </b-tabs>
  </div>
</template>

<script>
import StatusTracking from "./StatusTracking.vue";
import PrivilegeTracking from "./PrivilegeTracking.vue";
import Tracking from "./Tracking.vue";
export default {
  props: ["msg"],
  components: {
    StatusTracking,
    PrivilegeTracking,
    Tracking,
  },
  data() {
    return {
      name: "HelloWorld",
      tab: "",
      case_id: "",
      cases: [],
    };
  },
  methods: {
    go(t) {
      setTimeout(function () {
        location.reload();
      }, 50);
      this.$router.push("/suspect/" + t);
    },
    hasHistory() {
      return window.history.length > 2;
    },
  },
  mounted() {
    this.tab = this.$route.params.tab;
    // console.log(this.tab);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  color: black !important;
  font-family: "Kanit" !important;
}
.row {
  max-width: 1200px;
  margin: auto;
  background: white;
  padding: 10px;
  color: black !important;
}
.text-success {
  color: #09cc68 !important;
}
.nav-link {
  color: black !important;
}
</style>
