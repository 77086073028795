<template>
  <div class="hello mt-5">
    <div class="row">
      <div class="col-md-12">
        <h5 style="color: #2da3f2; font-weight: bold">ข้อมูลลักษณะเหตุการณ์</h5>
        <NewEvent />
      </div>
      <div class="col-md-12 mt-5">
        <h5 style="color: #2da3f2; font-weight: bold">ข้อมูลเหตุการณ์</h5>
        <NewCases />
      </div>
      <div class="col-md-12 mt-5">
        <h5 style="font-weight: bold">
          ความเสียหายที่ได้รับ <span style="color: red"> *</span>
        </h5>
      </div>
      <div class="col-md-2" v-for="m in damage" :key="m.damage_type_id">
        <div style="text-align: left">
          <label>
            <input
              :disabled="casesedit.is_edit == 1"
              type="checkbox"
              v-model="damage_id"
              :id="m.page"
              :value="m.damage_type_id"
            />
            <span> {{ m.name }}</span>
          </label>
        </div>
      </div>
      <div class="col-md-12 mt-5">
        <h5 style="color: #2da3f2; font-weight: bold">
          แนบเอกสารลักษณะเหตุการณ์
        </h5>
        <DocEvent />
      </div>
    </div>
    <div class="row mt-5">
      <div class="col text-center">
        <button
          class="btn btn-warning"
          style="color: white; width: 200px"
          @click="back()"
        >
          ก่อนหน้า
        </button>&nbsp;
        <button
          class="btn btn-success"
          style="color: white; width: 200px"
          @click="save()"
          :disabled="casesedit.is_edit == 1"
        >
          บันทึก
        </button>
        &nbsp;
        <button
          class="btn btn-warning"
          style="color: white; width: 200px"
          @click="next()"
        >
          ถัดไป
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import NewEvent from "./NewEvent.vue";
import NewCases from "./NewCases.vue";
import DocEvent from "./DocEvent.vue";
import DamagesDataService from "../../services/DamagesDataService";
import CasesDataService from "../../services/CasesDataService";
import PrivilegeDataService from "../../services/PrivilegeDataService.js";
import CasesEventDataService from "../../services/CasesEventDataService";
import CasesPrivilegeDataService from "../../services/CasesPrivilegeDataService";

export default {
  components: {
    NewEvent,
    NewCases,
    DocEvent,
  },
  data() {
    return {
      head: "0",
      name: "HelloWorld",
      case_id: "",
      damage: [],
      damage_id: [],
      casesedit: [],
      cases: [],
      privilege: [],
    };
  },
  methods: {
    getcase() {
      CasesDataService.getCase(this.case_id).then((res) => {
        this.casesedit = res.data;
        // console.log(this.casesedit);
      });
    },
    hasHistory() {
      return window.history.length > 2;
    },
    back() {
      this.$router.push("/newtreatment/" + this.case_id + "/1");
      setTimeout(function () {
        location.reload();
      }, 500);
      window.scrollTo(0, 0);
    },
    next() {
      this.$router.push("/newtreatment/" + this.case_id + "/3");
      setTimeout(function () {
        location.reload();
      }, 500);
      window.scrollTo(0, 0);
    },
    getdamage() {
      DamagesDataService.getDamagesAll().then((res) => {
        this.damage = res.data;
        // console.log(this.damage);
      });
    },
    getdamageByCaseId() {
      DamagesDataService.getDamagesByCaseId(this.case_id).then((res) => {
        // console.log(res.data);
        if (res.data.menu == null) {
          this.damage_id = [];
        } else {
          this.damage_id = JSON.parse(res.data.menu);
        }
      });
    },
    cal(start, end) {
      var date1 = new Date(start);
      var date2 = new Date(end);
      var diffTime = date2.getTime() - date1.getTime();
      var diffDay = diffTime / (1000 * 3600 * 24);
      return diffDay;
    },
    getPrivilege() {
      CasesEventDataService.getEventByCaseID(this.case_id).then((res) => {
        this.cases = res.data;
        // console.log(res.data);
        PrivilegeDataService.getPrivilegeByCaseID(this.case_id).then((res) => {
          this.privilege = res.data;
          // console.log(res.data);
          for (let c = 0; c < this.cases.length; c++) {
            for (let p = 0; p < this.privilege.length; p++) {
              if (
                this.privilege[p].fixed_or_daily == "daily" &&
                this.privilege[p].event_type_id ==
                  this.cases[c].event_type_id &&
                this.privilege[p].case_event_id == this.cases[c].case_event_id
              ) {
                var day = 0;
                day = this.cal(
                  this.cases[c].detained_start_date,
                  this.cases[c].detained_end_date
                );
                this.privilege[p].event_type_id = this.cases[c].event_type_id;
                this.privilege[p].amount = this.privilege[p].amount * day;
              }
              // else if (
              //   this.privilege[p].event_type_id !=
              //     this.cases[c].event_type_id
              // ){
              //   this.privilege.push({
              //     event_type_id:this.cases[c].event_type_id,
              //     privileged_id:1,
              //     amount:0
              //   })
              // }
            }
          }

          // console.log(this.privilege);
          CasesPrivilegeDataService.getCasePrivilegeByID(this.case_id).then(
          (res) => {
            // console.log(res.data.length);
            if (res.data.length == 0) {
              for (let pp = 0; pp < this.privilege.length; pp++) {
                var CasePrivilege = {
                  case_id: this.case_id,
                  event_type_id: this.privilege[pp].event_type_id,
                  privileged_id: this.privilege[pp].privileged_id,
                  amount: this.privilege[pp].amount,
                  created_by: this.currentUser.user_id,
                  updated_by: this.currentUser.user_id,
                };
                // console.log(CasePrivilege);
                CasesPrivilegeDataService.createCasePrivilege(
                  CasePrivilege
                ).then(() => {                  
                });
              }
            }
            setTimeout(function () {
              location.reload();
            }, 300);
          }
        );
        });
      });
    },
    save() {
      // this.getPrivilege();
      // console.log(this.privilege);
      if (this.damage_id.length == 0) {
        alert("กรุณาเลือกความเสียหายที่ได้รับ");
      } else {
        DamagesDataService.getDamagesAllByID(this.case_id).then((res) => {
          for (let dc = 0; dc < res.data.length; dc++) {
            // console.log(res.data[dc].map_damage_event_id);
            if (res.data.length != 0) {
              DamagesDataService.deleteDamage(
                res.data[dc].map_damage_event_id
              ).then(() => {
                // console.log(res.data);
              });
            }
          }
        });
        for (let d = 0; d < this.damage_id.length; d++) {
          var da = {
            damage_type_id: this.damage_id[d],
            case_id: this.case_id,
            created_by: this.currentUser.user_id,
            updated_by: this.currentUser.user_id,
          };
          DamagesDataService.createMapDamage(da).then(() => {});
        }
        
        // CasesPrivilegeDataService.getCasePrivilegeGroupEvent(
        //             this.case_id
        //           ).then((res) => {
        //             var GroupCount = res.data;
        //             CasesPrivilegeDataService.getCasePrivilegeByID(
        //               this.case_id
        //             ).then((res) => {
        //               if (GroupCount.length != res.data.length) {
        //                 for (let o = 0; o < res.data.length; o++) {
        //                   CasesPrivilegeDataService.deleteCasePrivilege(
        //                     res.data[o].cases_privileged_id
        //                   ).then(() => {});
        //                 }
        //                 for (let pp = 0; pp < GroupCount.length; pp++) {
        //                   var CasePrivilege = {
        //                     case_id: this.case_id,
        //                     event_type_id: GroupCount[pp].event_type_id,
        //                     privileged_id: GroupCount[pp].privileged_id,
        //                     amount: GroupCount[pp].amount,
        //                     created_by: this.currentUser.user_id,
        //                     updated_by: this.currentUser.user_id,
        //                   };
        //                   // console.log(CasePrivilege);
        //                   CasesPrivilegeDataService.createCasePrivilege(
        //                     CasePrivilege
        //                   ).then(() => {
        //                     // console.log(res.data);
        //                   });
        //                 }
        //               }
        //             });
        //           });

            alert("บันทึกสำเร็จ");
        
      }
    },
  },
  mounted() {
    this.case_id = this.$route.params.id;
    if (this.case_id != 0) {
      this.getdamage();
      this.getdamageByCaseId();
      this.getcase();
    }
    // console.log(this.msg);
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  font-family: "Kanit" !important;
}
.row {
  max-width: 1200px;
  margin: auto;
  background: white;
  padding: 10px;
  color: black !important;
}
a:visited {
  color: black;
} /* visited link */
a:hover {
  color: black;
} /* mouse over link */
a:active {
  color: black;
}
a:link {
  color: black;
}
</style>
