import http from "../http-common";

class NotifyDataService {
  getPrefixs() {
    return http.get('/notify');
  }
  getnotiByUserID(id) {
    return http.get('/notify/getnotiByUserID/' + id);
  }
  getnotiByRoleID(id) {
    return http.get('/notify/getnotiByRoleID/' + id);
  }
  getnotiByCaseID(id) {
    return http.get('/notify/getstatus/' + id);
  }
  createNotify(data) {
    return http.post('/notify', data);
  }
  updatePrefix(id,data) {
    return http.put(`/notify/${id}`, data);
  }
  updateRead(id,data) {
    return http.put(`/notify/updateread/${id}`, data);
  }
}

export default new NotifyDataService();