<template>
  <div class="hello">
    <HomeIcon />
    <div class="mt-5">
      <div class="row mt-5"></div>
      <div class="row card mt-2">
        <div class="card-body mt-2">
          <h4 style="text-align: center">ระบบการยื่นข้อมูลผู้เสียหาย</h4>
          <div style="margin:50px">
            <Tab :msg="head"/>
          </div>
        </div>        
      </div>      
    </div>
  </div>
</template>

<script>
import HomeIcon from "../HomeIcon.vue";
import Tab from "../Tab.vue";

export default {
  props: ['msg'],
  components: {
    HomeIcon,
    Tab,
  },
  data() {
    return {
      head:"0",
      name: "HelloWorld",
    };
  },
  methods: {
    hasHistory() {
      return window.history.length > 2;
    },
  },
  mounted() {
        this.head = this.$route.params.tab;
    // console.log(this.msg);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  font-family: "Kanit" !important;
}
.row {
  max-width: 1200px;
  margin: auto;
  background: white;
  padding: 10px;
  color: black !important;
}
a:visited {
  color: black;
} /* visited link */
a:hover {
  color: black;
} /* mouse over link */
a:active {
  color: black;
}
a:link {
  color: black;
}
</style>
