import http from "../http-common";

class AddressDataService {
  getAddresss() {
    return http.get('/address');
  }
  getAddress(id) {
    return http.get(`/address/`+id);
  }
  getAddressSuspect(id) {
    return http.get(`/address/getaddress/`+id);
  }
  createAddress(data) {
    return http.post('/address', data);
  }
  updateAddress(id,data) {
    return http.put(`/address/${id}`, data);
  }
  createSuspectAddress(data) {
    return http.post('/suspectaddress', data);
  }
  getSuspectAddress(id) {
    return http.get(`/suspectaddress/getaddressid/`+id);
  }
  deleteAddress(id) {
    return http.delete(`/address/`+id);
  }
}

export default new AddressDataService();