import http from "../http-common";

class EventTypesDataService {
  getEventTypes() {
    return http.get('/event_types');
  }
  getEventType(id) {
    return http.get(`/event_types/`+id);
  }
  getEventTypesAll() {
    return http.get('/event_types/geteventtype');
  }
  createEventType(data) {
    return http.post('/event_types', data);
  }
  updateEventType(id,data) {
    return http.put(`/event_types/${id}`, data);
  }
}

export default new EventTypesDataService();