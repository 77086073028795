<template>
  <div class="hello mt-3">
    <table class="table table-bordered">
      <thead>
        <tr>
          <th style="text-align:center" scope="col">ลักษณะเหตุการณ์</th>
          <th style="text-align:center" scope="col">วันที่ควบคุมตัว</th>
          <th style="text-align:center" scope="col">วันที่ปล่อยตัว</th>
          <th style="text-align:center" scope="col">จำนวนวัน</th>
          <th style="text-align:center" scope="col">หน่วยงานที่ควบคุมตัว</th>
          <th style="text-align:center" scope="col">สถานที่</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(l, i) in list" :key="i">
          <td>{{ l.event_name }}</td>
          <td style="text-align:center">{{ convertdatetothai(l.detained_start_date) }}</td>
          <td style="text-align:center">{{ convertdatetothai(l.detained_end_date) }}</td>
          <td style="text-align:center">{{ cal(l.detained_start_date, l.detained_end_date) }} วัน</td>
          <td style="text-align:center">{{ l.department_detained }}</td>
          <td style="text-align:center">{{ l.event_id }}</td>
        </tr>
      </tbody>
    </table>
    <!-- Modal -->
    <div
      class="modal fade"
      id="addModalEvents"
      tabindex="-1"
      role="dialog"
      aria-labelledby="addModalEventsLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <h5>เพิ่มข้อมูลลักษณะเหตุการณ์</h5>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group" style="text-align: left">
                  <label for="username"
                    >วันที่ควบคุมตัว <span style="color: red"> *</span></label
                  >
                  <input
                    v-model="event.detained_start_date"
                    type="date"
                    min="1"
                    class="form-control"
                    id="username"
                    placeholder="เลขบัตรประชาชน 13 หลัก"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group" style="text-align: left">
                  <label for="username"
                    >วันที่ปล่อยตัว <span style="color: red"> *</span></label
                  >
                  <input
                    v-model="event.detained_end_date"
                    type="date"
                    min="1"
                    class="form-control"
                    id="username"
                    placeholder="เลขบัตรประชาชน 13 หลัก"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group" style="text-align: left">
                  <label for="province"
                    >ลักษณะเหตุการณ์ <span style="color: red"> *</span></label
                  >
                  <select
                    class="form-select mb-3"
                    v-model="event.event_type_id"
                  >
                    <option
                      v-for="(d, i) in event_type"
                      v-bind:value="d.event_type_id"
                      :key="i + 7"
                    >
                      {{ d.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group" style="text-align: left">
                  <label for="province"
                    >หน่วยงานที่ควบคุมตัว
                    <span style="color: red"> *</span></label
                  >
                  <select
                    class="form-select mb-3"
                    v-model="event.department_detained_id"
                  >
                    <option
                      v-for="(d, i) in detained"
                      v-bind:value="d.department_detained_type_id"
                      :key="i + 7"
                    >
                      {{ d.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group" style="text-align: left">
                  <label for="province"
                    >สถานที่ควบคุม <span style="color: red"> *</span></label
                  >
                  <select class="form-select mb-3" v-model="event.event_id">
                    <option
                      v-for="(c, i) in cases"
                      v-bind:value="c.event_id"
                      :key="i + 7"
                    >
                      {{ c.crime_scene }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="bd-example mb-5">
            <button
              type="button"
              style="margin-right: 20px; width: 150px"
              class="btn btn-success"
              @click="save()"
            >
              บันทึก
            </button>
            <button
              type="button"
              id="closed"
              class="btn btn-danger"
              style="margin-right: 20px; width: 150px"
              data-dismiss="modal"
            >
              ยกเลิก
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventTypesDataService from "../../services//EventTypesDataService.js";
import DetainedDataService from "../../services/DetainedDataService";
import EventsDataService from "../../services/EventsDataService";
import CasesEventDataService from "../../services/CasesEventDataService";

export default {
  components: {},
  data() {
    return {
      name: "HelloWorld",
      event: [],
      event_type: [],
      detained: [],
      case_id: "",
      cases: [],
      list: [],
      case_event_id:0,
      tab:'1'
    };
  },
  mounted() {
    this.case_id = this.$route.params.id;
    if (this.case_id != 0) {
      
    // console.log(this.tab);
    this.geteventtype();
    this.getdetained();
    this.getEvent();
    this.getEventByCaseID();
    }
  },
  methods: {   
    cal(start, end) {
      var date1 = new Date(start);
      var date2 = new Date(end);
      var diffTime = date2.getTime() - date1.getTime();
      var diffDay = diffTime / (1000 * 3600 * 24);
      return diffDay;
    },
    getEventByCaseID() {
      CasesEventDataService.getEventByCaseID(this.case_id).then((res) => {
        this.list = res.data;
        // console.log(res.data);
      });
    },
    getdate(d) {
      var date = new Date(d);
      var day = date.getDate();
      var month = date.getMonth() + 1;
      var year = date.getFullYear();
      // console.log(day,month,year);
      if (day.toString().length == 1) {
        day = "0" + day;
      }
      if (month.toString().length == 1) {
        month = "0" + month;
      }
      return year + "-" + month + "-" + day;
    },
    sendInfo(id) {
      if (id != 0) {
        this.case_event_id = id
        CasesEventDataService.getCaseEvent(this.case_event_id).then((res)=>{
          // console.log(res.data);
          this.event = res.data
          this.event.detained_end_date = this.getdate(res.data.detained_end_date);
          this.event.detained_start_date = this.getdate(res.data.detained_start_date);
        })
      }else{
        this.event = []
      }
    },
    save() {
      var caseevent = {
        case_id: this.case_id,
        event_type_id: this.event.event_type_id,
        event_id: this.event.event_id,
        department_detained_id: this.event.department_detained_id,
        detained_start_date: this.event.detained_start_date,
        detained_end_date: this.event.detained_end_date,
        created_by: this.currentUser.user_id,
        updated_by: this.currentUser.user_id,
      };
      // console.log(caseevent);
      CasesEventDataService.createCaseEvent(caseevent).then(() => {
        // console.log(res.data);
        document.getElementById("closed").click();
      });
    },
    getEvent() {
      EventsDataService.getEventAll(this.case_id).then((res) => {
        this.cases = res.data;
      });
    },
    getdetained() {
      DetainedDataService.getDetainedAll().then((res) => {
        this.detained = res.data;
      });
    },
    geteventtype() {
      EventTypesDataService.getEventTypesAll().then((res) => {
        this.event_type = res.data;
      });
    },
    convertdatetothai(datetimes) {
      if (datetimes) {
        const date = new Date(datetimes);
        const result = date.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return result;
      } else {
        return "-";
      }
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  font-family: "Kanit" !important;
}
.row {
  max-width: 1200px;
  margin: auto;
  background: white;
  padding: 10px;
  color: black !important;
}
.nav-link {
  color: black !important;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #09cc68;
}
.modal-content {
  float: center;
  text-align: center;
  width: 800px;
}
.modal {
  text-align: center;
  padding: 0 !important;
}
.modal:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}
.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  max-width: 700px;
}
</style>
