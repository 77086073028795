import http from "../http-common";

class SuspectsTypeDataService {
  getSuspectTypes() {
    return http.get('/suspecttype');
  }
  getSuspectType(id) {
    return http.get(`/suspecttype/`+id);
  }
  createSuspectType(data) {
    return http.post('/suspecttype', data);
  }
  updateSuspectType(id,data) {
    return http.put(`/suspecttype/${id}`, data);
  }
}

export default new SuspectsTypeDataService();