<template>
  <div class="hello">
    <HomeIcon />
    <div class="mt-5">
      <div class="row mt-5">
        <h4 style="text-align: center">
          ข้อมูลรอการอนุมัติจ่ายเงิน เลขหนังสืออ้างอิง {{ ref_no }}
        </h4>
      </div>
      <div class="mt-5">
        <div class="row">
          <div class="col-md-12">
            <h4 style="text-align: left" class="mb-5">
              รายการข้อมูลการยื่นเรื่องขออนุมัติเบิกเงิน
            </h4>
          </div>
        </div>
      </div>
      <ViewPayment />

      <div class="row card mt-5">
        <div class="card-body p-0 mt-3">
          <h4 style="text-align: center">
            ข้อมูลรอการอนุมัติจ่ายเงิน เลขหนังสืออ้างอิง {{ ref_no }}
          </h4>
          <div class="row">
            <div class="col-md-2"></div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="exampleInputPassword1">วันที่ลงนาม</label>
                <input id="signing_date" class="form-control" />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="exampleInputPassword1">รายละเอียดเพิ่มเติม</label>
                <input
                  v-model="remark_signing"
                  type="text"
                  class="form-control"
                  placeholder="รายละเอียดเพิ่มเติม"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-center">
            <button
              class="btn btn-warning"
              style="color: white; width: 200px"
              @click="back()"
            >
              ย้อนกลับ</button
            >&nbsp;
            <button
              @click="save()"
              class="btn btn-success"
              style="color: white; width: 200px"
              id="saveimage"
            >
              บันทึก
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

  <script src="http://justice.sbpac.go.th:81/picker_date.js"></script>

<script>
import HomeIcon from "../HomeIcon.vue";
import PaymentDataService from "../../services/PaymentDataService";
import ConvertService from "../../services/ConvertService";
import CasesDataService from "../../services/CasesDataService";
import HistoryCaseDataService from "../../services/HistoryCaseDataService";
import ViewPayment from "../Payment/View.vue";

const customLabels = {
  first: "<<",
  last: ">>",
  previous: "<",
  next: ">",
};

export default {
  components: {
    HomeIcon,
    ViewPayment,
  },
  data() {
    return {
      name: "HelloWorld",
      list: [],
      status: [],
      pageOfItems: [],
      date: "",
      options: {
        format: "DD/MM/YYYY",
        useCurrent: false,
      },
      location: "",
      latLong: "",
      customLabels,
      ref_no: "",
      payment_id: 0,
      remark_signing: "",
      filing_date:''
    };
  },
  mounted() {
    var year = new Date().getFullYear()
    var start = year-2004
    var before = "-"+start+":+0"
    picker_date(document.getElementById("signing_date"), {
      year_range: before,
    });
    this.payment_id = this.$route.params.id;
    this.getCase();
  },
  methods: {
    back() {
      this.$router.push("/paymentlist");
      setTimeout(function () {
        location.reload();
      }, 500);
    },
    
    save() {
      // console.log(new Date(this.filing_date));
      if (document.getElementById("signing_date").value == "") {
        alert("กรุณากรอกวันที่ลงนาม");
      } 
//       else if(new Date(ConvertService.createddate(
//             document.getElementById("signing_date").value
//           )) > new Date(this.filing_date)){
// alert('กรุณากรอกวันที่ให้ถูกต้อง')
//       }
      else {
        var payment = {
          signing_date: ConvertService.createddate(
            document.getElementById("signing_date").value
          ),
          remark_signing: this.remark_signing,
          status_id: 8,
          created_by: this.currentUser.user_id,
          updated_by: this.currentUser.user_id,
        };
        PaymentDataService.updatePaymentPending(this.payment_id, payment).then(
          () => {
            // console.log(res.data);
            // console.log(this.list);
            for (let li = 0; li < this.list.length; li++) {
              if (this.list[li].no != "") {
                var data = {
                  status_id: 8,
                  updated_by: this.currentUser.user_id,
                };
                console.log(this.list[li].case_id);
                CasesDataService.updateCheck(this.list[li].case_id, data).then(
                  () => {
                    var data = {
                      status_id: 8,
                      case_id: this.list[li].case_id,
                      created_by: this.currentUser.user_id,
                      created_date: ConvertService.createddate(
                        document.getElementById("signing_date").value
                      ),
                    };
                    HistoryCaseDataService.createHistoryCase(data).then(
                      () => {
                        // console.log(res.data);
                        var data={
            case_id: this.list[li].case_id,
            history_status_id:12,
            user_id:this.currentUser.user_id
          }
          HistoryCaseDataService.createHistory(data).then(()=>{
// alert("บันทึกสำเร็จ");
            // setTimeout(function () {
            //   location.reload();
            // }, 300);
          })
                      }
                    );
                  }
                );
              }
            }
            alert("บันทึกสำเร็จ");
          }
        );
      }
    },
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    },
    convertdatetothai(datetimes) {
      if (datetimes) {
        const date = new Date(datetimes);
        const result = date.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        // const myArray = result.split(" ");
        // var day = this.thaiNumber(parseInt(myArray[0]));
        // var month = myArray[1];
        // var year = this.thaiNumber(parseInt(myArray[2]));
        // var val = day + " " + month + " " + year;
        return result;
      } else {
        return "-";
      }
    },
    cal(start, end) {
      var date1 = new Date(start);
      var date2 = new Date(end);
      var diffTime = date2.getTime() - date1.getTime();
      var diffDay = diffTime / (1000 * 3600 * 24);
      return diffDay;
    },
    thaiNumber(num) {
      var array = {
        1: "๑",
        2: "๒",
        3: "๓",
        4: "๔",
        5: "๕",
        6: "๖",
        7: "๗",
        8: "๘",
        9: "๙",
        0: "๐",
      };
      if (num == null) {
        str = "-";
      } else {
        var str = num.toString();
        for (var val in array) {
          str = str.split(val).join(array[val]);
        }
      }
      return str;
    },
    getCase() {
      PaymentDataService.getPaymentName(this.payment_id).then((res) => {
        // console.log(res.data);
        this.ref_no = res.data.ref_no;
        if (res.data.signing_date != null) {
          document.getElementById("signing_date").value =
            ConvertService.getdate(res.data.signing_date);
        }
        if (res.data.remark_signing != null) {
          this.remark_signing = res.data.remark_signing;
        }
      });
      PaymentDataService.getPayment(this.payment_id).then((res) => {
        // console.log(res.data);
        this.list = res.data;
        var no = 0;
        for (let l = 0; l < this.list.length; l++) {
          this.filing_date = this.list[l].filing_date
          this.list[l].day =
            this.cal(
              this.list[l].detained_start_date,
              this.list[l].detained_end_date
            ) + " วัน";
          this.list[l].detained_start_date = this.convertdatetothai(
            this.list[l].detained_start_date
          );
          this.list[l].detained_end_date = this.convertdatetothai(
            this.list[l].detained_end_date
          );
          // this.list[l].village_no_house = this.thaiNumber(
          //   this.list[l].village_no_house
          // );
          // this.list[l].no_house = this.thaiNumber(this.list[l].no_house);

          if (
            this.list[l].privileged_id == 1 ||
            this.list[l].privileged_id == 3
          ) {
            this.list[l].one = this.list[l].amount;
            this.list[l].two = 0;
          } else if (
            this.list[l].privileged_id == 2 ||
            this.list[l].privileged_id == 4
          ) {
            this.list[l].two = this.list[l].amount;
            this.list[l].one = 0;
          }
          this.list[l].amount = (
            parseInt(this.list[l].one) + parseInt(this.list[l].two)
          ).toLocaleString();
          this.list[l].one = this.list[l].one.toLocaleString();
          this.list[l].two = this.list[l].two.toLocaleString();
          if (l != 0) {
            // console.log(this.list[l].case_id, this.list[l - 1].case_id);
            if (this.list[l].case_id == this.list[l - 1].case_id) {
              this.list[l].prefix = "";
              this.list[l].firstname = "";
              this.list[l].lastname = "";
              this.list[l].id_card = "";
              this.list[l].village_no_house = "";
              this.list[l].no_house = "";
              this.list[l].sub_district_house = "";
              this.list[l].district_house = "";
              this.list[l].province_house = "";
              if (this.list[l].case_id == this.list[l - 1].case_id) {
                this.list[l].no = "";
              }
            } else {
              no = no + 1;
              this.list[l].no = no;
            }
          } else {
            no = no + 1;
            this.list[l].no = no;
          }
          if (this.list[l].statuscheck == 1) {
            this.list[l].statuscheck = true;
          } else {
            this.list[l].statuscheck = false;
          }
        }
      });
    },
    hasHistory() {
      return window.history.length > 2;
    },
    search() {
      var d = document.getElementById("my_date").value;
      // var dd = new Date(d)
      console.log(d);
    },
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  font-family: "Kanit" !important;
}
.row {
  max-width: 1500px;
  margin: auto;
  background: white;
  padding: 10px;
  color: black !important;
}
a:visited {
  color: black;
} /* visited link */
a:hover {
  color: black;
} /* mouse over link */
a:active {
  color: black;
}
a:link {
  color: black;
}
</style>
