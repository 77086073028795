import http from "../http-common";

class QualityDataService {
  getQualities(id_card,id_card_des) {
    return http.get('/quality?id_card='+id_card+'&&id_card_des='+id_card_des);
  }
  getSuspectByIDCard(id) {
    return http.get(`/quality/getidcard/`+id);
  }
  getIDSuspectQuality() {
    return http.get('/quality/getidcardsuspect');
  }
  getQuality(id) {
    return http.get(`/quality/`+id);
  }
  createQuality(data) {
    return http.post('/quality', data);
  }
  updateQuality(id,data) {
    return http.put(`/quality/${id}`, data);
  }
}

export default new QualityDataService();