import axios from "axios";

export default axios.create({
  // baseURL: "http://localhost:8080/api",
  // baseURL: "http://localhost:81/api",
  // baseURL: "http://justice.sbpac.go.th:81/api",
  baseURL: "https://justice-api.sbpac.go.th/api",
  //  baseURL: "http://134.122.57.139:81/api",   
  // baseURL: "https://api-sbpacdb.ponnipa.in.th/api",

  headers: {
    "Content-type": "application/json",
  }
});
