import http from "../http-common";

class TrackingDetailDataService {
  getTrackings(id_card,id_card_des) {
    return http.get('/tracking_detail?id_card='+id_card+'&&id_card_des='+id_card_des);
  }
  getIDSuspectTracking() {
    return http.get('/tracking_detail/getidcardsuspect');
  }
  getTracking(id,type) {
    return http.get(`/tracking_detail/`+id+'/'+type);
  }
  getDetailTracking(id,type) {
    return http.get(`/tracking_detail/getdetail/`+id+'/'+type);
  }  
  createTracking(data) {
    return http.post('/tracking_detail', data);
  }
  updateTracking(id,data) {
    return http.put(`/tracking_detail/${id}`, data);
  }
}

export default new TrackingDetailDataService();