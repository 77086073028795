<template>
  <div class="hello mt-3">
    <table class="table table-bordered">
      <thead>
        <tr>
          <th scope="col">ชื่อ-นามสกุล</th>
          <th scope="col">เลขบัตรประชาชน</th>
          <th scope="col" style="text-align: right">
          </th>
        </tr>
      </thead>
      <tbody v-if="list.length > 0">
        <tr v-for="l in list" :key="l.relative_id">
          <td>{{ l.firstname }} {{ l.lastname }}</td>
          <td>{{ l.id_card_relative }}</td>
          <td style="text-align: right">
            <button
              class="btn btn-success"
              style="color: white"
            >
              <a
                style="color: white"
                data-toggle="modal"
                href="#"
                data-target="#addModalDescendants"
                @click="addDescendant(l.relatives_id)"
                >ดู</a
              >
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="list.length == 0" style="text-align: center">ไม่พบข้อมูล</div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="addModalDescendants"
      tabindex="-1"
      role="dialog"
      aria-labelledby="addModalDescendantsLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <h5>ข้อมูลทายาท</h5>
            </div>
            <div class="row">
                <div class="col-md-2"></div>
              <div class="col-md-4">
                <h6 style="text-align: left">เลขบัตรประชาชน</h6>
              </div>
              <div class="col-md-6">
                <h6 style="text-align: left">{{ descendants.id_card_relative }}</h6>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-4">
                <h6 style="text-align: left">ชื่อ-นามสกุล</h6>
              </div>
              <div class="col-md-6">
                <h6 style="text-align: left">{{ descendants.firstname }}  {{ descendants.lastname }}</h6>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-4">
                <h6 style="text-align: left">วัน/เดือน/ปีเกิด</h6>
              </div>
              <div class="col-md-6">
                <h6 style="text-align: left">{{ descendants.birthday }}</h6>
              </div>
            </div>
<div class="row">
  <div class="col-md-2"></div>
              <div class="col-md-4">
                <h6 style="text-align: left">เพศ</h6>
              </div>
              <div class="col-md-6">
                <h6 style="text-align: left">{{ descendants.gender }}</h6>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-4">
                <h6 style="text-align: left">ความสัมพันธ์</h6>
              </div>
              <div class="col-md-6">
                <h6 style="text-align: left">{{ descendants.relationship }}</h6>
              </div>
            </div>
<div class="row">
  <div class="col-md-2"></div>
              <div class="col-md-4">
                <h6 style="text-align: left">อาชีพ</h6>
              </div>
              <div class="col-md-6">
                <h6 style="text-align: left">{{ descendants.career }}</h6>
              </div>
            </div>
<div class="row">
  <div class="col-md-2"></div>
              <div class="col-md-4">
                <h6 style="text-align: left">รายได้</h6>
              </div>
              <div class="col-md-6">
                <h6 style="text-align: left" v-if="descendants.income">{{ descendants.income.toLocaleString() }} บาทต่อเดือน</h6>
              <h6 style="text-align: left" v-else> -  บาทต่อเดือน</h6>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-4">
                <h6 style="text-align: left">เบอร์โทรศัพท์</h6>
              </div>
              <div class="col-md-6">
                <h6 style="text-align: left">{{ descendants.phone }} </h6>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-4">
                <h6 style="text-align: left">สถานะ</h6>
              </div>
              <div class="col-md-6">
                <h6 style="text-align: left">{{ descendants.status }} </h6>
              </div>
            </div>
<div class="row">
  <div class="col-md-2"></div>
              <div class="col-md-4">
                <h6 style="text-align: left">สัญชาติ</h6>
              </div>
              <div class="col-md-6">
                <h6 style="text-align: left">{{ descendants.nationality }} </h6>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-4">
                <h6 style="text-align: left">ที่อยู่ตามบัตรประชาชน</h6>
              </div>
              <div class="col-md-6">
                <h6 style="text-align: left">บ้านเลขที่ {{ descendants.no_house }} หมู่ที่ {{descendants.village_no_house}} 
                  ซอย {{descendants.road_house}} ถนน {{descendants.lane_house}} หมู่บ้าน {{descendants.village_house}}
                  ตำบล {{descendants.district_house}} อำเภอ {{descendants.sub_district_house}} จังหวัด {{descendants.province_house}} รหัสไปรษณีย์ {{postal_code_house}}
                </h6>
              </div>
            </div>

            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-4">
                <h6 style="text-align: left">ที่อยู่ปัจจุบัน</h6>
              </div>
              <div class="col-md-6">
                <h6 style="text-align: left">บ้านเลขที่ {{ descendants.no_current }} หมู่ที่ {{descendants.village_no_current}} 
                  ซอย {{descendants.road_current}} ถนน {{descendants.lane_current}} หมู่บ้าน {{descendants.village_current}}
                  ตำบล {{descendants.district_current}} อำเภอ {{descendants.sub_district_current}} จังหวัด {{descendants.province_current}} รหัสไปรษณีย์ {{postal_code_current}}
                </h6>
              </div>
            </div>
      </div>
          <div class="container">
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-11">
                <h5 style="text-align: left; margin-left: 10px">
                  แนบเอกสารทายาท
                </h5>
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">เอกสาร</th>
                      <th scope="col">ชื่อไฟล์</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="d in doclist" :key="d.category_doc_id">
                      <td>{{ d.name }}</td>
                      <td>
                        <a
                          :href="d.file_data"
                          target="_blank"
                        >
                          {{ d.file_name }}
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="bd-example mb-5">
            <button
              type="button"
              id="closeddes"
              class="btn btn-danger"
              style="margin-right: 20px; width: 150px"
              data-dismiss="modal"
            >
              ปิดหน้าต่าง
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="showModalImageDes"
      tabindex="-1"
      role="dialog"
      aria-labelledby="showModalImageDes"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row" style="height: 630px">
              <div class="col-md-12">
                <object v-if="imagetype == 'application/pdf'">
                  <embed
                    id="pdfID"
                    type="text/html"
                    width="100%"
                    height="100%"
                    :src="imagesrc"
                  />
                </object>
                <img
                  v-if="imagetype != 'application/pdf'"
                  :src="imagesrc"
                  style="width:100%"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script src="https://justice-api.sbpac.go.th/picker_date.js"></script>

<script>
import ProvinceDataService from "../../services/ProvinceDataService";
import AmphurDataService from "../../services/AmphurDataService";
import DistrictDataService from "../../services/DistrictDataService";
import RelativesDataService from "../../services/RelativesDataService";
import CasesDataService from "../../services/CasesDataService";
import SuspectsRelativeDataService from "../../services//SuspectsRelativeDataService";
import RelativesAddressDataService from "../../services//RelativesAddressDataService";
import AddressDataService from "../../services/AddressDataService";
import RelationshipsDataService from "../../services/RelationshipsDataService";
import DocsDataService from "../../services/DocsDataService";
import DocumentsDataService from "../../services/DocumentsDataService";
import ConvertService from "../../services/ConvertService";
import PrefixDataService from "../../services/PrefixDataService";

export default {
  components: {},
  data() {
    return {
      name: "HelloWorld",
      descendants: [],
      provinces: [],
      amphures: [],
      districts: [],
      provincescurrent: [],
      amphurescurrent: [],
      districtscurrent: [],
      postal_code_house: "",
      postal_code_current: "",
      sameaddress: false,
      other: false,
      suspect_id: "",
      relative_id: 0,
      list: [],
      address_id: 0,
      relationships: [],
      doclist: [],
      documents: [],
      image: "",
      docname: "",
      doctype: "",
      docid: "",
      catdocid: "",
      imagesrc: "",
      tempdoc: [],
      othername: "",
      cases: [],
      nationalityother: "",
      options: {
        format: "DD/MM/YYYY",
        useCurrent: false,
      },
      imagetype: "",
      prefix: [],
    };
  },

  mounted() {
    this.case_id = this.$route.params.id;
    CasesDataService.getCase(this.case_id).then((res) => {
      this.cases = res.data;
      this.suspect_id = res.data.suspect_id;
      this.getdescendant();
      this.getProvinceAll();
      this.getAmphurAll("");
      this.getDistrictAll("");
      this.getProvinceAllcurrent();
      this.getAmphurAllcurrent("");
      this.getDistrictAllcurrent("");
      this.getrelationship();
      this.getPrefix();
    });
    // console.log(this.case_id);
  },
  methods: {
    getPrefix() {
      PrefixDataService.getPrefixs().then((res) => {
        this.prefix = res.data;
        // console.log(res.data);
      });
    },
    getDoc() {
      DocumentsDataService.getDocRelative(this.relative_id).then((res) => {
        this.documents = res.data;
        // console.log(this.documents);
        DocsDataService.getDocByType(2).then((res) => {
          this.doclist = res.data;
          // console.log(res.data);
          if (this.documents.length != 0) {
            for (let d = 0; d < this.doclist.length; d++) {
              for (let dd = 0; dd < this.documents.length; dd++) {
                if (
                  this.doclist[d].category_doc_id ==
                  this.documents[dd].category_doc_id
                ) {
                  this.doclist[d].document_id = this.documents[dd].document_id;
                  this.doclist[d].file_name = this.documents[dd].file_name;
                  this.doclist[d].file_type = this.documents[dd].file_type;
                  this.doclist[d].file_data = this.documents[dd].file_data;
                }
              }
            }
          }
          this.tempdoc = this.doclist;
          // console.log(this.doclist);
        });
      });
    },
    onchange(catdocid) {
      this.catdocid = catdocid;
      document.getElementById("my_filedes").click();
    },
    deletedoc(id) {
      for (let t = 0; t < this.tempdoc.length; t++) {
        if (this.tempdoc[t].category_doc_id == id) {
          this.tempdoc[t].file_name = "";
          this.tempdoc[t].file_type = "";
          this.tempdoc[t].file_data = "";
        }
      }
      this.getDocTemp();
    },
    onFileChangeDes(evt) {
      const files = evt.target.files || evt.dataTransfer.files;
      const selectedImage = evt.target.files[0]; // get first file
      // console.log(selectedImage);
      this.docname = selectedImage.name;
      this.doctype = selectedImage.type;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      const reader = new FileReader();

      reader.onload = (evt) => {
        this.image = evt.target.result;
        // console.log(this.docid);
        // console.log(this.tempdoc);
        // console.log(this.catdocid);
        for (let t = 0; t < this.tempdoc.length; t++) {
          if (this.tempdoc[t].category_doc_id == this.catdocid) {
            this.tempdoc[t].file_name = this.docname;
            this.tempdoc[t].file_type = this.doctype;
            this.tempdoc[t].file_data = this.image;
          }
        }
        this.getDocTemp();
      };

      reader.readAsDataURL(file);
    },
    getDocTemp() {
      // console.log(this.doclist);
      // console.log(this.tempdoc);
      DocsDataService.getDocByType(2).then((res) => {
        this.doclist = res.data;
        for (let d = 0; d < this.doclist.length; d++) {
          for (let dd = 0; dd < this.tempdoc.length; dd++) {
            if (
              this.doclist[d].category_doc_id ==
              this.tempdoc[dd].category_doc_id
            ) {
              this.doclist[d].file_name = this.tempdoc[dd].file_name;
              this.doclist[d].file_type = this.tempdoc[dd].file_type;
              this.doclist[d].file_data = this.tempdoc[dd].file_data;
            }
          }
        }
      });
      // console.log(this.doclist);
    },
    getrelationship() {
      RelationshipsDataService.getRelationshipsActive().then((res) => {
        this.relationships = res.data;
      });
    },
    getdescendant() {
      SuspectsRelativeDataService.getDescendantBySuspect(this.suspect_id).then(
        (res) => {
          // console.log(this.list);
          if (this.list.length < 0) {
            this.list = [];
          } else {
            this.list = res.data;
          }
          // if (this.list.length == 0) {
          //           setTimeout(function () {
          //             location.reload();
          //           }, 300);
          //         }
        }
      );
    },
    getsrcimage(type, url) {
      this.imagetype = type;
      this.imagesrc = url;
      // console.log(this.imagesrc);
      document.getElementById("closeddes").click();
    },
    addDescendant(id) {
      // console.log(id);
      if (id != 0) {
        this.relative_id = id;
        this.getDoc();
        // console.log(id);
        RelativesDataService.getRelativeAddressCase(this.relative_id).then(
          (res) => {
            // console.log(res.data);
            this.descendants = res.data;

            if (this.descendants.nationality != null) {
              if (this.descendants.nationality != "ไทย") {
                this.other = true;
                this.nationalityother = this.descendants.nationality;
                this.descendants.nationality = "อื่นๆ";
              }
            }

            if (this.descendants.postal_code_house != null) {
              this.postal_code_house = res.data.postal_code_house;
            }
            if (this.descendants.postal_code_current != null) {
              this.postal_code_current = res.data.postal_code_current;
            }
            if (this.descendants.birthday != null) {
              this.descendants.birthday = ConvertService.getdate(
                new Date(this.descendants.birthday)
              );
              document.getElementById("birthday_des").value =
                this.descendants.birthday;
            }
            picker_date(document.getElementById("birthday_des"), {
              year_range: "-100:+0",
            });
            this.address_id = res.data.address_id;
            if (this.descendants.is_same == null) {
              this.sameaddress = false;
            }
            // console.log(this.descendants);
          }
        );
      } else {
        this.descendants = [];
        this.postal_code_house = "";
        this.postal_code_current = "";
        this.relative_id = 0;
        this.getDoc();
        document.getElementById("birthday_des").value = null;
        picker_date(document.getElementById("birthday_des"), {
          year_range: "-100:+0",
        });
      }
    },
    save() {
      if (isNaN(this.descendants.id_card_relative)) {
        alert("กรุณากรอกหมายเลขบัตรประชาชน");
      } else if (this.descendants.id_card_relative.length < 13) {
        alert("กรุณากรอกหมายเลขบัตรประชาชนให้ครบ");
      } else if (this.descendants.prefix == null) {
        alert("กรุณาเลือกคำนำหน้าชื่อ");
      } else if (this.descendants.firstname == null) {
        alert("กรุณากรอกชื่อ");
      } else if (this.descendants.lastname == null) {
        alert("กรุณากรอกนามสกุล");
      } else if (document.getElementById("birthday_des").value == "") {
        alert("กรุณากรอกวัน/เดือน/ปีเกิด");
      } else if (this.descendants.gender == null) {
        alert("กรุณาเลือกเพศ");
      } else if (this.descendants.status == null) {
        alert("กรุณาเลือกความสัมพันธ์");
      } else if (this.descendants.career == null) {
        alert("กรุณากรอกอาชีพ");
      } else if (this.descendants.income == null) {
        alert("กรุณากรอกรายได้");
      } else if (this.descendants.phone == null) {
        alert("กรุณากรอกเบอร์โทรศัพท์");
      } else if (this.descendants.phone.length == 9) {
        alert("กรุณากรอกเบอร์โทรศัพท์ให้ครบ 10 หลัก");
      } else if (this.descendants.status == null) {
        alert("กรุณาเลือกสถานะ");
      }
      //  else if (this.descendants.no_house == null) {
      //   alert("กรุณากรอกบ้านเลขที่");
      // }else if (this.descendants.village_no_house == null) {
      //   alert("กรุณากรอกหมู่ที่");
      // }
      else if (this.descendants.nationality == null) {
        alert("กรุณาเลือกสัญชาติ");
      }
      // else if (this.descendants.village_house == null) {
      //   alert("กรุณากรอกหมู่บ้าน");
      // }
      else if (this.descendants.province_house == null) {
        alert("กรุณาเลือกจังหวัดปัจจุบัน");
      } else if (this.descendants.district_house == null) {
        alert("กรุณาเลือกอำเภอปัจจุบัน");
      } else if (this.descendants.sub_district_house == null) {
        alert("กรุณาเลือกตำบลปัจจุบัน");
      }
      // else if (this.descendants.no_current == null) {
      //   alert("กรุณากรอกบ้านเลขที่ตามบัตรประชาชน");
      // }else if (this.descendants.village_current == null) {
      //   alert("กรุณากรอกหมู่บ้านตามบัตรประชาชน");
      // }else if (this.descendants.village_no_current == null) {
      //   alert("กรุณากรอกหมู่ที่ตามบัตรประชาชน");
      // }
      else if (this.descendants.province_current == null) {
        alert("กรุณาเลือกจังหวัดตามบัตรประชาชน");
      } else if (this.descendants.district_current == null) {
        alert("กรุณาเลือกอำเภอตามบัตรประชาชน");
      } else if (this.descendants.sub_district_current == null) {
        alert("กรุณาเลือกตำบลตามบัตรประชาชน");
      } else {
        if (this.descendants.nationality == "อื่นๆ") {
          this.descendants.nationality = this.nationalityother;
        }

        if (this.descendants.id_card_relative != "") {
          RelativesDataService.getIDCardDes(
            this.descendants.id_card_relative,
            this.case_id,
            this.relative_id
          ).then((res) => {
            // console.log(res.data.length);
            if (res.data.length != 0) {
              alert("เลขบัตรประชาชนนี้มีในเคสนี้แล้ว");
            } else {
              var descendant = {
                relative_type_id: 2,
                id_card_relative: this.descendants.id_card_relative,
                prefix: this.descendants.prefix,
                firstname: this.descendants.firstname,
                lastname: this.descendants.lastname,
                gender: this.descendants.gender,
                birthday: ConvertService.createddate(
                  document.getElementById("birthday_des").value
                ),
                phone: this.descendants.phone,
                career: this.descendants.career,
                income: this.descendants.income,
                nationality: this.descendants.nationality,
                relationship: this.descendants.relationship,
                status: this.descendants.status,
                created_by: this.currentUser.user_id,
                updated_by: this.currentUser.user_id,
              };
              // console.log(descendant);
              var address = {
                is_same: this.sameaddress,
                no_house: this.descendants.no_house,
                village_no_house: this.descendants.village_no_house,
                road_house: this.descendants.road_house,
                lane_house: this.descendants.lane_house,
                village_house: this.descendants.village_house,
                sub_district_house: this.descendants.sub_district_house,
                district_house: this.descendants.district_house,
                province_house: this.descendants.province_house,
                postal_code_house: this.postal_code_house,
                no_current: this.descendants.no_current,
                village_no_current: this.descendants.village_no_current,
                road_current: this.descendants.road_current,
                lane_current: this.descendants.lane_current,
                village_current: this.descendants.village_current,
                sub_district_current: this.descendants.sub_district_current,
                district_current: this.descendants.district_current,
                province_current: this.descendants.province_current,
                postal_code_current: this.postal_code_current,
                // longitude_house: this.descendants.longitude_house,
                // lattitude_house: this.descendants.lattitude_house,
                // longitude_current: this.descendants.longitude_current,
                // lattitude_current: this.descendants.lattitude_current,
                created_by: this.currentUser.user_id,
                updated_by: this.currentUser.user_id,
              };
              // console.log(address);
              // console.log(this.relative_id);
              if (this.relative_id != 0) {
                RelativesDataService.updateRelative(
                  this.relative_id,
                  descendant
                ).then((res) => {
                  AddressDataService.updateAddress(this.address_id, address)
                    .then((res) => {
                      // console.log(res.data);
                      DocumentsDataService.getDocRelative(
                        this.relative_id
                      ).then((res) => {
                        for (let d = 0; d < res.data.length; d++) {
                          DocumentsDataService.deleteDocument(
                            res.data[d].document_id
                          ).then(() => {});
                        }
                      });
                      for (let t = 0; t < this.tempdoc.length; t++) {
                        var doc = {
                          relative_id: this.relative_id,
                          category_doc_id: this.tempdoc[t].category_doc_id,
                          file_name: this.tempdoc[t].file_name,
                          file_type: this.tempdoc[t].file_type,
                          file_data: this.tempdoc[t].file_data,
                          created_by: this.currentUser.user_id,
                          updated_by: this.currentUser.user_id,
                        };
                        DocumentsDataService.createDocument(doc).then(() => {
                          setTimeout(function () {
                            location.reload();
                          }, 300);
                        });
                      }
                      this.getdescendant();
                      document.getElementById("closeddes").click();
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                });
              } else {
                RelativesDataService.createRelative(descendant).then((res) => {
                  this.relative_id = res.data.id;
                  // console.log(res.data.errno);
                  var suspectrelative = {
                    suspect_id: this.suspect_id,
                    relative_id: this.relative_id,
                    created_by: this.currentUser.user_id,
                    updated_by: this.currentUser.user_id,
                  };
                  // console.log(suspectrelative);
                  SuspectsRelativeDataService.createSuspectRelative(
                    suspectrelative
                  ).then(() => {
                    AddressDataService.createAddress(address).then((res) => {
                      var address_id = res.data.id;
                      // console.log(res.data);
                      var relativeaddress = {
                        relative_id: this.relative_id,
                        address_id: address_id,
                        created_by: this.currentUser.user_id,
                        updated_by: this.currentUser.user_id,
                      };
                      // console.log(relativeaddress);
                      RelativesAddressDataService.createRelativeAddress(
                        relativeaddress
                      ).then(() => {
                        SuspectsRelativeDataService.getDescendantBySuspect(
                          this.suspect_id
                        ).then((res) => {
                          // console.log(this.list.length);
                          this.list = res.data;
                        });
                        for (let t = 0; t < this.tempdoc.length; t++) {
                          var doc = {
                            relative_id: this.relative_id,
                            category_doc_id: this.tempdoc[t].category_doc_id,
                            file_name: this.tempdoc[t].file_name,
                            file_type: this.tempdoc[t].file_type,
                            file_data: this.tempdoc[t].file_data,
                            created_by: this.currentUser.user_id,
                            updated_by: this.currentUser.user_id,
                          };
                          DocumentsDataService.createDocument(doc).then(() => {
                            setTimeout(function () {
                              location.reload();
                            }, 300);
                          });
                        }
                      });
                    });
                    document.getElementById("closeddes").click();
                  });
                });
              }
            }
          });
        }
      }
    },
    deleteDescendant(relative_id, suspect_relative_id) {
      RelativesDataService.getRelativeAddress(relative_id).then((res) => {
        var relatives_address_id = res.data.relatives_address_id;
        var address_id = res.data.address_id;
        // console.log(relatives_address_id,address_id);
        // console.log(relative_id,suspect_relative_id);
        RelativesAddressDataService.deleteRelativeAddress(
          relatives_address_id
        ).then(() => {
          // console.log(res.data);
          SuspectsRelativeDataService.deleteSuspectRelative(
            suspect_relative_id
          ).then(() => {
            // console.log(res.data);
            AddressDataService.deleteAddress(address_id).then(() => {
              // console.log(res.data);
              RelativesDataService.deleteRelative(relative_id).then(() => {
                // console.log(res.data);
                if (this.list.length == 1) {
                  this.list = [];
                }
                this.getdescendant();
                //           setTimeout(function () {
                //   location.reload();
                // }, 300);
              });
            });
          });
        });
      });
    },
    onlyNumberDes($event) {
      if (!isNaN(this.descendants.id_card_relative)) {
        let keyCode = $event.keyCode ? $event.keyCode : $event.which;
        if (
          keyCode < 48 ||
          keyCode > 57 ||
          this.descendants.id_card_relative.length == 13
        ) {
          // 46 is dot
          $event.preventDefault();
        }
      }
    },
    onChangenational(event) {
      // console.log(event.target.value);
      if (event.target.value == "อื่นๆ") {
        this.other = true;
      } else {
        this.other = false;
      }
    },
    changeaddress() {
      if (this.sameaddress == false) {
        this.descendants.no_current = this.descendants.no_house;
        this.descendants.village_no_current = this.descendants.village_no_house;
        this.descendants.road_current = this.descendants.road_house;
        this.descendants.lane_current = this.descendants.lane_house;
        this.descendants.village_current = this.descendants.village_house;
        this.descendants.sub_district_current =
          this.descendants.sub_district_house;
        this.descendants.district_current = this.descendants.district_house;
        this.descendants.province_current = this.descendants.province_house;
        this.postal_code_current = this.postal_code_house;
        // this.descendants.longitude_current = this.descendants.longitude_house;
        // this.descendants.lattitude_current = this.descendants.lattitude_house;
      } else if (this.sameaddress == true && this.descendants.length == 0) {
        this.descendants.no_current = "";
        this.descendants.village_no_current = "";
        this.descendants.road_current = "";
        this.descendants.lane_current = "";
        this.descendants.village_current = "";
        this.descendants.sub_district_current = "";
        this.descendants.district_current = "";
        this.descendants.province_current = "";
        this.descendants.postal_code_current = "";
        // this.descendants.longitude_current = "";
        // this.descendants.lattitude_current = "";
      }
    },
    onChangeProvincecurrent(event) {
      // console.log(this.user.province)
      var province_id = event.target.value;
      this.descendants.district_current = "";
      this.descendants.sub_district_current = "";
      this.postal_code_current = "";
      this.getAmphurAllcurrent(province_id);
    },
    onChangeDistrictcurrent(event) {
      // console.log(this.user.province)
      var amphur_id = event.target.value;
      this.descendants.sub_district_current = "";
      this.getDistrictAllcurrent(amphur_id);
    },
    onChangePostalCodecurrent(event) {
      //  console.log(event.target.value)
      var district_id = event.target.value;
      this.getpostcodecurrent(district_id);
    },
    getProvinceAllcurrent() {
      ProvinceDataService.getProvinceAll().then((res) => {
        this.provincescurrent = res.data;
      });
    },
    getAmphurAllcurrent(id) {
      if (id == "") {
        AmphurDataService.getAmphursAll().then((res) => {
          this.amphurescurrent = res.data;
        });
      } else {
        AmphurDataService.getAmphurAll(id).then((res) => {
          this.amphurescurrent = res.data;
        });
      }
    },
    getpostcodecurrent(id) {
      DistrictDataService.getPostCode(id).then((res) => {
        // console.log(res.data[0].POST_CODE);
        this.postal_code_current = res.data[0].POST_CODE;
      });
    },
    getDistrictAllcurrent(id) {
      if (id == "") {
        DistrictDataService.getDistrictsAll().then((res) => {
          this.districtscurrent = res.data;
        });
      } else {
        DistrictDataService.getDistrictAll(id).then((res) => {
          this.districtscurrent = res.data;
          this.postal_code_current = "";
        });
      }
    },
    onChangeProvince(event) {
      // console.log(this.user.province)
      var province_id = event.target.value;
      this.descendants.district_house = "";
      this.descendants.sub_district_house = "";
      this.postal_code_house = "";
      this.getAmphurAll(province_id);
    },

    onChangeDistrict(event) {
      // console.log(this.user.province)
      var amphur_id = event.target.value;
      this.descendants.sub_district_house = "";
      this.getDistrictAll(amphur_id);
    },
    onChangePostalCode(event) {
      //  console.log(event.target.value)
      var district_id = event.target.value;
      this.getpostcode(district_id);
    },
    getProvinceAll() {
      ProvinceDataService.getProvinceAll().then((res) => {
        this.provinces = res.data;
      });
    },
    getAmphurAll(id) {
      if (id == "") {
        AmphurDataService.getAmphursAll().then((res) => {
          this.amphures = res.data;
        });
      } else {
        AmphurDataService.getAmphurAll(id).then((res) => {
          this.amphures = res.data;
        });
      }
    },
    getpostcode(id) {
      DistrictDataService.getPostCode(id).then((res) => {
        // console.log(res.data[0].POST_CODE);
        this.postal_code_house = res.data[0].POST_CODE;
      });
    },
    getDistrictAll(id) {
      if (id == "") {
        DistrictDataService.getDistrictsAll().then((res) => {
          this.districts = res.data;
        });
      } else {
        DistrictDataService.getDistrictAll(id).then((res) => {
          this.districts = res.data;
          this.postal_code_house = "";
        });
      }
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  font-family: "Kanit" !important;
}
.row {
  max-width: 1200px;
  margin: auto;
  background: white;
  padding: 10px;
  color: black !important;
}
.nav-link {
  color: black !important;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #09cc68;
}
.modal-content {
  float: center;
  text-align: center;
  width: 800px;
}
.modal {
  text-align: center;
  padding: 0 !important;
}
.modal:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}
.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  max-width: 700px;
}
#my_filedes {
  display: none;
}
</style>
