import http from "../http-common";

class RelativesAddressDataService {
  getRelativesAddress() {
    return http.get('/relatives_address');
  }
  getRelativeAddress(id) {
    return http.get(`/relatives_address/`+id);
  }
  createRelativeAddress(data) {
    return http.post('/relatives_address', data);
  }
  updateRelativeAddress(id,data) {
    return http.put(`/relatives_address/${id}`, data);
  }
  deleteRelativeAddress(id) {
    return http.delete(`/relatives_address/`+id);
  }
}

export default new RelativesAddressDataService();