import http from "../http-common";

class DamagesDataService {
  getDamages() {
    return http.get('/damage');
  }
  getDamagesAll() {
    return http.get('/damage/damageAll');
  }
  getDamage(id) {
    return http.get(`/damage/`+id);
  }
  getDamageCase(id) {
    return http.get(`/damage/getdamagecase/`+id);
  }
  createDamage(data) {
    return http.post('/damage', data);
  }
  updateDamage(id,data) {
    return http.put(`/damage/${id}`, data);
  }
  getDamagesAllByID(id) {
    return http.get('/map_damages_events/'+id);
  }
  createMapDamage(data) {
    return http.post('/map_damages_events', data);
  }getdamage
  getDamagesByCaseId(id) {
    return http.get('/map_damages_events/getdamage/'+id);
  }
  deleteDamage(id) {
    return http.delete(`/map_damages_events/`+id);
  }
}

export default new DamagesDataService();