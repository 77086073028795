<template>
  <div class="mt-5">
    <div class="card" style="width: 500px">
      <div class="card-body login-card-body">
        <h3 class="login-box-msg">ข้อมูลผู้ใช้งาน</h3>
        <form>
          <div class="card-body">
            <div class="form-group">
              <label for="username">ชื่อผู้ใช้งาน</label>
              <input
              disabled
                v-model="user.id_card"
                min="1"
                @keypress="onlyNumber"
                type="number"
                class="form-control"
                id="username"
                placeholder="กรุณากรอกชื่อผู้ใช้งาน"
              />
            </div>
            <div class="form-group">
              <label for="password">รหัสผ่าน</label>
              <input
                v-model="user.password"
                type="password"
                class="form-control"
                id="password"
                placeholder="กรุณากรอกรหัสผ่าน"
              />
            </div>
            <div class="form-group">
              <label for="firstname">ชื่อ</label>
              <input
              disabled
                v-model="user.firstname"
                type="text"
                class="form-control"
                id="firstname"
                placeholder="กรุณากรอกชื่อ"
              />
            </div>
            <div class="form-group">
              <label for="lastname">นามสกุล</label>
              <input
              disabled
                v-model="user.lastname"
                type="text"
                class="form-control"
                id="lastname"
                placeholder="กรุณากรอกนามสกุล"
              />
            </div>            
            <div class="form-group" style="text-align: left">
              <label for="province"
                >จังหวัดที่รับผิดชอบ<span style="color: red"> *</span></label
              >
              <select
              disabled
                class="form-select mb-3"
                v-model="user.province"
                @change="onChangeProvince"
              >
                <option
                  v-for="(p, i) in provinces"
                  v-bind:value="p.PROVINCE_ID"
                  :key="i"
                >
                  {{ p.PROVINCE_NAME }}
                </option>
              </select>
            </div>
            <div class="form-group" style="text-align: left">
              <label for="amphure"
                >อำเภอที่รับผิดชอบ<span style="color: red"> *</span></label
              >
              <select class="form-select mb-3" disabled v-model="user.amphure">
                <option
                  v-for="(a, i) in amphures"
                  v-bind:value="a.AMPHUR_ID"
                  :key="i"
                >
                  {{ a.AMPHUR_NAME }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label for="lastname">สิทธิ์การใช้งาน &nbsp;&nbsp;</label>
              <label style="font-weight:normal;">{{user.name}}</label>
            </div>
            <div class="social-auth-links text-center mb-3 mt-5">
              <a @click="save()" class="btn btn-block btn-success"> บันทึก </a>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        <button
          class="btn btn-warning"
          style="color: white"
          @click="hasHistory() ? $router.go(-1) : $router.push('/')"
        >
          ย้อนกลับ
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import AuthService from "../services/auth/auth.service";
import UsersDataService from "../services/UserDataService.js";
import ProvinceDataService from "../services/ProvinceDataService";
import AmphurDataService from "../services/AmphurDataService";

export default {
  name: "login",
  components: {},
  data() {
    return {
      user: [],
      hash: "",
      provinces: [],
      amphures: [],
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57 || this.user.id_card.length == 13) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    getuser() {
      AuthService.getname(this.currentUser["user_id"]).then(
        (response) => {
          this.user = response.data;
          this.hash = this.user.password;
          // console.log(response.data);
          this.getamphur(this.user.province);
        },
        (error) => {
          console.log(error);
        }
      );
    },
    save() {
      var user = {
        role_id: this.user.role_id,
        id_card: this.user.id_card,
        firstname: this.user.firstname,
        lastname: this.user.lastname,
        amphure: this.user.amphure,
        province: this.user.province,
        hash: this.hash,
        password: this.user.password,
        is_active: true,
        updated_by: this.currentUser.user_id,
      };
      var id_card = this.user.id_card;
      if (this.user.id_card == "" || this.user.id_card == null) {
        alert("กรุณากรอกเลขบัตรประชาชน");
      } else if (id_card.length != 13) {
        alert("กรุณากรอกเลขบัตรประชาชนให้ครบ 13 ตัว");
      } else if (this.user.password == "" || this.user.password == null) {
        alert("กรุณากรอกรหัสผ่าน");
      } else if (this.user.firstname == "" || this.user.firstname == null) {
        alert("กรุณากรอกชื่อ");
      } else if (this.user.lastname == "" || this.user.lastname == null) {
        alert("กรุณากรอกนามสกุล");
      } else if (this.user.amphure == "" || this.user.amphure == null) {
        alert("กรุณากรอกอำเภอที่รับผิดชอบ");
      } else if (this.user.province == "" || this.user.province == null) {
        alert("กรุณากรอกจังหวัดที่รับผิดชอบ");
      } else {
        UsersDataService.updateUser(this.currentUser.user_id, user)
          .then(() => {
            // console.log(response.data);
            setTimeout(function () {
              location.reload();
            }, 500);
            this.getuser();
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    getprovince() {
      ProvinceDataService.getProvince().then((res) => {
        this.provinces = res.data;
      });
    },
    getamphur(id) {
      AmphurDataService.getAmphur(id).then((res) => {
        // console.log(res.data);
        this.amphures = res.data;
      });
    },
    onChangeProvince(event) {
      var province_name = event.target.value;
      this.getamphur(province_name);
    },
    hasHistory() {
      return window.history.length > 2;
    },
  },
  mounted() {
    this.getuser();
    this.getprovince();
    this.getamphur(this.user.province);
  },
};
</script>

<style>
body {
  font-family: "Kanit" !important;
  color: black !important;
}
.card {
  max-width: 500px;
  margin: auto;
  background: white;
  padding: 10px;
  color: black !important;
}
</style>