<template>
  <div class="hello mt-3">
    <table class="table table-bordered">
      <thead>
        <tr>
          <th scope="col">ชื่อ-นามสกุล</th>
          <th scope="col">เลขบัตรประชาชน</th>
          <th scope="col" style="text-align: right"></th>
        </tr>
      </thead>
      <tbody v-if="list.length > 0">
        <tr v-for="l in list" :key="l.relative_id">
          <td>{{ l.firstname }} {{ l.lastname }}</td>
          <td>{{ l.id_card_relative }}</td>
          <td style="text-align: right">
            <button class="btn btn-success" style="color: white">
              <a
                style="color: white"
                data-toggle="modal"
                href="#"
                data-target="#addModalRelative"
                @click="viewRelative(l.relative_id)"
                >ดู</a
              >
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="list.length == 0" style="text-align: center">ไม่พบข้อมูล</div>

    <!-- Modal -->
    <div
      class="modal fade"
      id="addModalRelative"
      tabindex="-1"
      role="dialog"
      aria-labelledby="addModalRelativeLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <h5>ข้อมูลผู้ที่เกี่ยวข้อง</h5>
            </div>
            <div class="row">
              <div class="col-md-4">
                <h6 style="text-align: left">เลขบัตรประชาชน</h6>
              </div>
              <div class="col-md-8">
                <h6 style="text-align: left">
                  {{ relative.id_card_relative }}
                </h6>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <h6 style="text-align: left">ชื่อ-สกุล</h6>
              </div>
              <div class="col-md-8">
                <h6 style="text-align: left">{{ relative.firstname }} {{ relative.lastname }}</h6>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <h6 style="text-align: left">เบอร์โทรศัพท์</h6>
              </div>
              <div class="col-md-8">
                <h6 style="text-align: left">{{ relative.phone }}</h6>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <h6 style="text-align: left">ไอดีไลน์</h6>
              </div>
              <div class="col-md-8">
                <h6 style="text-align: left">{{ relative.line_id }}</h6>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <h6 style="text-align: left">ความสัมพันธ์</h6>
              </div>
              <div class="col-md-8">
                <h6 style="text-align: left">{{ relative.relationship }}</h6>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <h6 style="text-align: left">ลองจิจูด</h6>
              </div>
              <div class="col-md-8">
                <h6 style="text-align: left">{{ address.longitude_house || '-' }}</h6>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <h6 style="text-align: left">ละติจูด</h6>
              </div>
              <div class="col-md-8">
                <h6 style="text-align: left">{{ address.lattitude_house || '-' }}</h6>
              </div>
            </div>
             <div class="bd-example mb-5">
            <button
              type="button"
              id="closerelative"
              class="btn btn-danger"
              style="margin-right: 20px; width: 150px"
              data-dismiss="modal"
            >
              ปิดหน้าต่าง
            </button>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RelativesDataService from "../../services/RelativesDataService.js";
import SuspectsRelativeDataService from "../../services/SuspectsRelativeDataService";
import CasesDataService from "../../services/CasesDataService";
import PrefixDataService from "../../services/PrefixDataService";

export default {
  data() {
    return {
      name: "HelloWorld",
      relative: [],
      case_id: "",
      suspect_id: "",
      list: [],
      relative_id: 0,
      address: [],
      cases: [],
      marker: { position: { lat: 10, lng: 10 } },
      center: { lat: 10, lng: 10 },
      prefix: [],
    };
  },
  mounted() {
    this.case_id = this.$route.params.id;
    // console.log(this.case_id);
    CasesDataService.getCase(this.case_id).then((res) => {
      this.cases = res.data;
      this.suspect_id = res.data.suspect_id;
      // console.log(this.suspect_id);
      this.getralative();
      this.geolocate();
      this.getPrefix();
    });
  },
  methods: {
    getPrefix() {
      PrefixDataService.getPrefixs().then((res) => {
        this.prefix = res.data;
        // console.log(res.data);
      });
    },
    geolocate() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        // console.log(this.address);
        if (this.address.length == 0) {
          this.marker.position = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
        } else {
          this.marker.position = {
            lat: this.address.lattitude_house,
            lng: this.address.longitude_house,
          };
        }
      });
    },

    //sets the position of marker when dragged
    handleMarkerDrag(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    },

    //Moves the marker to click position on the map
    handleMapClick(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      this.address.longitude_house = this.marker.position.lng;
      this.address.lattitude_house = this.marker.position.lat;
    },
    getralative() {
      SuspectsRelativeDataService.getRelativeBySuspect(this.suspect_id).then(
        (res) => {
          // console.log(res.data);
          this.list = res.data;
          if (this.list.length == 0) {
            // setTimeout(function () {
            //   location.reload();
            // }, 300);
          }
          // console.log(this.list);
        }
      );
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (
        keyCode < 48 ||
        keyCode > 57 ||
        this.relative.id_card_relative.length == 13
      ) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    viewRelative(id) {
      if (id != 0) {
        console.log(id);
        this.relative_id = id;
        RelativesDataService.getRelative(this.relative_id).then((res) => {
          this.relative = res.data;
          RelativesDataService.getRelativeAddress(this.relative_id).then(
            (res) => {
              this.address = res.data;
              this.geolocate();
            }
          );
        });
      } else {
        this.relative_id = 0;
        this.relative = [];
        this.address = [];
        this.geolocate();
      }
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  font-family: "Kanit" !important;
}
.row {
  max-width: 1200px;
  margin: auto;
  background: white;
  padding: 10px;
  color: black !important;
}
.nav-link {
  color: black !important;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #09cc68;
}
.modal-content {
  float: center;
  text-align: center;
  width: 600px;
}
.modal {
  text-align: center;
  padding: 0 !important;
}
.modal:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}
.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  max-width: 600px;
}
</style>
