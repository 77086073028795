<template>
  <div class="hello mt-5">
    <div class="row">
      <div class="col-md-12">
        <h5 style="color: #2da3f2; font-weight: bold">ข้อมูลผู้เสียหาย</h5>
      </div>
      <div class="col-md-4 mt-3" v-if="cases.status_id == 5">
        <div class="form-group" style="text-align: left">
          <label for="username"> รายละเอียดการแก้ไข &nbsp;&nbsp;</label>
          <label style="font-weight: normal"> {{ cases.remark_edit }}</label>
        </div>
      </div>
      <div class="col-md-4" v-if="cases.status_id == 5"></div>
      <div class="col-md-4" v-if="cases.status_id == 5"></div>
      <div class="col-md-4 mt-3">
        <div class="form-group" style="text-align: left">
          <label for="username"
            >วันที่ยื่นคำร้อง <span style="color: red"> *</span></label
          >
          <input
            :disabled="cases.is_edit == 1"
            id="fill_date"
            class="form-control"
          />
          <!-- <date-picker v-model="filldate" :config="options"
           class="form-control" 
            min="1997-01-01"
            max="2030-12-31"
            id="filldate"
            ></date-picker> -->
        </div>
      </div>
      <div class="col-md-8"></div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="form-group" style="text-align: left">
          <label>เลขบัตรประชาชน <span style="color: red"> *</span></label>
          <input
            :disabled="cases.is_edit == 1"
            v-on:keyup="savedraft"
            type="number"
            v-model="suspect.id_card"
            @keypress="onlyNumber"
            min="1"
            class="form-control"
            id="idcard"
            placeholder="เลขบัตรประชาชน 13 หลัก"
          />
        </div>
      </div>
      <div class="col-md-8"></div>
    </div>
    <div class="row">
      <div class="col-md-3">
        <div class="form-group" style="text-align: left">
          <label for="province"
            >คำนำหน้า <span style="color: red"> *</span></label
          >
          <select
            id="prefix"
            @change="savedraft"
            class="form-select mb-3"
            :disabled="cases.is_edit == 1"
            v-model="suspect.prefix"
          >
            <option
              v-for="(s, i) in prefix"
              v-bind:value="s.prefix_id"
              :key="i + 1"
            >
              {{ s.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-md-4">
        <label for="username">ชื่อ <span style="color: red"> *</span></label>
        <input
          :disabled="cases.is_edit == 1"
          v-on:keyup="savedraft"
          v-model="suspect.firstname"
          type="text"
          min="1"
          class="form-control"
          id="firstname"
          placeholder="ชื่อ"
        />
      </div>
      <div class="col-md-4">
        <label for="username">นามสกุล <span style="color: red"> *</span></label>
        <input
          :disabled="cases.is_edit == 1"
          v-on:keyup="savedraft"
          v-model="suspect.lastname"
          type="text"
          min="1"
          class="form-control"
          id="lastname"
          placeholder="นามสกุล"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="form-group" style="text-align: left">
          <label for="username"
            >วัน/เดือน/ปีเกิด <span style="color: red"> *</span></label
          >
          <!-- <date-picker v-model="suspect.birthday" :config="options"
           class="form-control"
            min="1997-01-01"
            max="2030-12-31"
            id="birthday"
            ></date-picker> -->
          <input
            :disabled="cases.is_edit == 1"
            id="birthday"
            class="form-control"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group" style="text-align: left">
          <label for="username"
            >เบอร์โทรศัพท์ <span style="color: red"> *</span></label
          >
          <input
            :disabled="cases.is_edit == 1"
            v-on:keyup="savedraft"
            v-model="suspect.phone"
            type="number"
            min="1"
            class="form-control"
            id="phone"
            placeholder="เบอร์โทรศัพท์"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3">
        <div class="form-group" style="text-align: left">
          <label for="province">เพศ <span style="color: red"> *</span></label>
          <select
            @change="savedraft"
            :disabled="cases.is_edit == 1"
            id="gender"
            class="form-select mb-3"
            v-model="suspect.gender"
          >
            <option value="ชาย">ชาย</option>
            <option value="หญิง">หญิง</option>
            <option value="อื่นๆ">อื่นๆ</option>
          </select>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group" style="text-align: left">
          <label for="province"
            >ประเภทกลุ่มผู้เสียหาย <span style="color: red"> *</span></label
          >
          <select
            @change="savedraft"
            id="suspect_types_id"
            class="form-select mb-3"

            :disabled="cases.is_edit == 1"
            v-model="suspect.suspect_types_id"
          >
            <option
              v-for="(s, i) in suspecttype"
              v-bind:value="s.suspect_type_id"
              :key="i + 1"
            >
              {{ s.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group" style="text-align: left">
          <label for="province">สถานะ <span style="color: red"> *</span></label>
          <select
            @change="savedraft"
            :disabled="cases.is_edit == 1"
            id="status"
            class="form-select mb-3"
            v-model="suspect.status"
          >
            <option value="โสด">โสด</option>
            <option value="แต่งงานแล้ว">แต่งงานแล้ว</option>
            <option value="หย่าร้าง">หย่าร้าง</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="form-group" style="text-align: left">
          <label for="username">อาชีพ <span style="color: red"> *</span></label>
          <input
            :disabled="cases.is_edit == 1"
            v-on:keyup="savedraft"
            v-model="suspect.career"
            type="text"
            class="form-control"
            id="career"
            placeholder="อาชีพ"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group" style="text-align: left">
          <label for="username"
            >รายได้ <span style="color: red"> *</span></label
          >
          <div class="input-group mb-3">
            <input
              :disabled="cases.is_edit == 1"
              v-on:keyup="savedraft"
              v-model="suspect.income"
              type="number"
              min="0"
              class="form-control"
              id="income"
              placeholder="รายได้"
            />
            <span class="input-group-text" id="basic-addon2">บาทต่อเดือน</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group" style="text-align: left">
          <label for="username"
            >สัญชาติ <span style="color: red"> *</span></label
          >
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-check" style="text-align: left">
          <label>
            <input
              :disabled="cases.is_edit == 1"
              v-model="suspect.nationality"
              value="ไทย"
              id="thai"
              class="form-check-input"
              type="radio"
              @change="onChangenational"
            />
            <span> สัญชาติไทย </span>
          </label>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-check" style="text-align: left">
          <label>
            <input
              :disabled="cases.is_edit == 1"
              v-model="suspect.nationality"
              id="other"
              value="อื่นๆ"
              class="form-check-input"
              type="radio"
              @change="onChangenational"
            />
            <span> สัญชาติอื่นๆ </span>
          </label>
        </div>
      </div>
      <div class="col-md-3">
        <select
          id="others"
          class="form-select mb-3"
          v-if="other"
          :disabled="cases.is_edit == 1"
          v-model="nationalityother"
        >
          <option value="กัมพูชา">กัมพูชา</option>
          <option value="ลาว">ลาว</option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h5>ที่อยู่ตามบัตรประชาชน</h5>
      </div>
    </div>
    <div class="row">
      <div class="col-md-2">
        <div class="form-group" style="text-align: left">
          <label for="username">บ้านเลขที่ </label>
          <input
            :disabled="cases.is_edit == 1"
            v-on:keyup="savedraft"
            v-model="suspect.no_house"
            type="text"
            class="form-control"
            id="no_house"
            placeholder="บ้านเลขที่"
          />
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group" style="text-align: left">
          <label for="username">หมู่ที่ </label>
          <input
            :disabled="cases.is_edit == 1"
            v-on:keyup="savedraft"
            v-model="suspect.village_no_house"
            type="number"
            class="form-control"
            id="village_no_house"
            placeholder="หมู่ที่"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group" style="text-align: left">
          <label for="username">ซอย </label>
          <input
            :disabled="cases.is_edit == 1"
            v-on:keyup="savedraft"
            v-model="suspect.road_house"
            type="text"
            min="0"
            class="form-control"
            id="road_house"
            placeholder=""
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="form-group" style="text-align: left">
          <label for="username">ถนน </label>
          <input
            :disabled="cases.is_edit == 1"
            v-on:keyup="savedraft"
            v-model="suspect.lane_house"
            type="text"
            class="form-control"
            id="lane_house"
            placeholder="ถนน"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group" style="text-align: left">
          <label for="username">หมู่บ้าน </label>
          <input
            :disabled="cases.is_edit == 1"
            v-on:keyup="savedraft"
            v-model="suspect.village_house"
            type="text"
            min="0"
            class="form-control"
            id="village_house"
            placeholder="หมู่บ้าน"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="form-group" style="text-align: left">
          <label for="province"
            >จังหวัด <span style="color: red"> *</span></label
          >
          <select
            :disabled="cases.is_edit == 1"
            class="form-select mb-3"
            v-model="suspect.province_house"
            @change="onChangeProvince"
            id="province_house"
          >
            <option
              v-for="(p, i) in provinces"
              v-bind:value="p.PROVINCE_ID"
              :key="i + 2"
            >
              {{ p.PROVINCE_NAME }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group" style="text-align: left">
          <label for="province">อำเภอ <span style="color: red"> *</span></label>
          <select
            :disabled="cases.is_edit == 1"
            class="form-select mb-3"
            v-model="suspect.district_house"
            @change="onChangeDistrict"
            id="district_house"
          >
            <option
              v-for="(a, i) in amphures"
              v-bind:value="a.AMPHUR_ID"
              :key="i + 3"
            >
              {{ a.AMPHUR_NAME }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="form-group" style="text-align: left">
          <label for="province">ตำบล <span style="color: red"> *</span></label>
          <select
            :disabled="cases.is_edit == 1"
            class="form-select mb-3"
            v-model="suspect.sub_district_house"
            @change="onChangePostalCode"
            id="sub_district_house"
          >
            <option
              v-for="(d, i) in districts"
              v-bind:value="d.DISTRICT_ID"
              :key="i + 4"
              :name="d.DISTRICT_NAME"
            >
              {{ d.DISTRICT_NAME }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group" style="text-align: left">
          <label for="username"
            >รหัสไปรษณีย์ <span style="color: red"> *</span></label
          >
          <input
            :disabled="cases.is_edit == 1"
            v-model="postal_code_house"
            type="text"
            class="form-control"
            id="postal_code_house"
            placeholder=""
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="form-group" style="text-align: left">
          <label for="username">ลองจิจูด </label>
          <input
            :disabled="cases.is_edit == 1"
            v-model="suspect.longitude_house"
            type="text"
            class="form-control"
            id="longitude_house"
            placeholder=""
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group" style="text-align: left">
          <label for="username">ละติจูด </label>
          <input
            :disabled="cases.is_edit == 1"
            v-model="suspect.lattitude_house"
            type="text"
            min="0"
            class="form-control"
            id="lattitude_house"
            placeholder=""
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-2">
        <h5>ที่อยู่ปัจจุบัน</h5>
      </div>
      <div class="col-md-4">
        <label>
          <input
            :disabled="cases.is_edit == 1"
            id="sameaddress"
            v-model="sameaddress"
            @click="changeaddress"
            type="checkbox"
          />
          <span> ที่อยู่เดียวกับที่อยู่บัตรประชาชน </span>
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col-md-2">
        <div class="form-group" style="text-align: left">
          <label for="username">บ้านเลขที่ </label>
          <input
            :disabled="cases.is_edit == 1"
            v-on:keyup="savedraft"
            v-model="suspect.no_current"
            type="text"
            class="form-control"
            id="no_current"
            placeholder="บ้านเลขที่"
          />
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group" style="text-align: left">
          <label for="username">หมู่ที่ </label>
          <input
            v-on:keyup="savedraft"
            :disabled="cases.is_edit == 1"
            v-model="suspect.village_no_current"
            type="number"
            class="form-control"
            id="village_no_current"
            placeholder="หมู่ที่"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group" style="text-align: left">
          <label for="username">ซอย </label>
          <input
            :disabled="cases.is_edit == 1"
            v-model="suspect.road_current"
            type="text"
            min="0"
            class="form-control"
            id="road_current"
            placeholder=""
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="form-group" style="text-align: left">
          <label for="username">ถนน </label>
          <input
            :disabled="cases.is_edit == 1"
            v-model="suspect.lane_current"
            type="text"
            class="form-control"
            id="lane_current"
            placeholder="ถนน"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group" style="text-align: left">
          <label for="username">หมู่บ้าน </label>
          <input
            :disabled="cases.is_edit == 1"
            v-model="suspect.village_current"
            type="text"
            min="0"
            class="form-control"
            id="village_current"
            placeholder="หมู่บ้าน"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="form-group" style="text-align: left">
          <label for="province"
            >จังหวัด <span style="color: red"> *</span></label
          >
          <select
            :disabled="cases.is_edit == 1"
            class="form-select mb-3"
            v-model="suspect.province_current"
            @change="onChangeProvincecurrent"
            id="province_current"
          >
            <option
              v-for="(p, i) in provincescurrent"
              v-bind:value="p.PROVINCE_ID"
              :key="i + 5"
            >
              {{ p.PROVINCE_NAME }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group" style="text-align: left">
          <label for="province">อำเภอ <span style="color: red"> *</span></label>
          <select
            :disabled="cases.is_edit == 1"
            class="form-select mb-3"
            v-model="suspect.district_current"
            @change="onChangeDistrictcurrent"
            id="district_current"
          >
            <option
              v-for="(a, i) in amphurescurrent"
              v-bind:value="a.AMPHUR_ID"
              :key="i + 6"
            >
              {{ a.AMPHUR_NAME }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="form-group" style="text-align: left">
          <label for="province">ตำบล <span style="color: red"> *</span></label>
          <select
            :disabled="cases.is_edit == 1"
            class="form-select mb-3"
            v-model="suspect.sub_district_current"
            @change="onChangePostalCodecurrent"
            id="sub_district_current"
          >
            <option
              v-for="(d, i) in districtscurrent"
              v-bind:value="d.DISTRICT_ID"
              :key="i + 7"
            >
              {{ d.DISTRICT_NAME }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group" style="text-align: left">
          <label for="username"
            >รหัสไปรษณีย์ <span style="color: red"> *</span></label
          >
          <input
            :disabled="cases.is_edit == 1"
            v-model="postal_code_current"
            type="text"
            min="0"
            class="form-control"
            id="postal_code_current"
            placeholder=""
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="form-group" style="text-align: left">
          <label for="username">ลองจิจูด</label>
          <input
            :disabled="cases.is_edit == 1"
            v-model="suspect.longitude_current"
            type="text"
            class="form-control"
            id="longitude_current"
            placeholder=""
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group" style="text-align: left">
          <label for="username">ละติจูด </label>
          <input
            :disabled="cases.is_edit == 1"
            v-model="suspect.lattitude_current"
            type="text"
            min="0"
            class="form-control"
            id="lattitude_current"
            placeholder=""
          />
        </div>
      </div>
    </div>
    <div class="row">
      <h5 style="color: #2da3f2; font-weight: bold">แนบเอกสารผู้เสียหาย</h5>
      <table class="table table-bordered">
        <thead>
          <tr>
            <th scope="col">เอกสาร</th>
            <th scope="col">ชื่อไฟล์</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(d, i) in doclist" :key="i + 9">
            <td>{{ d.name }}</td>
            <td>
              <a
                :href="d.file_data"
                target="_blank"
              >
                {{ d.file_name }}
              </a>
            </td>
            <td style="text-align: right">
              <input
                id="my_file"
                class="form-control"
                type="file"
                accept="application/pdf"
                @change="onFileChangesus"
              />
              <button
                :disabled="cases.is_edit == 1"
                v-if="!d.file_name"
                id="get_file"
                class="btn btn-success"
                @click="onchange(d.document_id, d.category_doc_id)"
              >
                เพิ่ม
              </button>
              <button
                id="deletedocsuspect"
                class="btn btn-danger"
                @click="deletedocsus(d.document_id)"
                v-if="d.file_name"
                :disabled="cases.is_edit == 1"
              >
                ลบ
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        class="modal fade"
        id="showModalImage"
        tabindex="-1"
        role="dialog"
        aria-labelledby="showModalImage"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row" style="height: 631px">
                <div class="col-md-12">
                  <!-- {{imagesrc}} -->
                  <embed v-if="imagetype == 'application/pdf'" :src="imagesrc" frameborder="0" width="100%" height="100%">
                  <!-- <object v-if="imagetype == 'application/pdf'">
                    <embed
                      id="pdfID"
                      type="text/html"
                      width="100%"
                      height="100%"
                      :src="imagesrc"
                    />
                  </object> -->
                  <img
                    v-if="imagetype != 'application/pdf'"
                    :src="imagesrc"
                    style="width:100%"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <h5 style="color: #2da3f2; font-weight: bold">ข้อมูลผู้เกี่ยวข้อง</h5>
      <Relatives />
    </div>
    <div class="row mt-5">
      <div class="col-md-2">
        <h5 style="color: #2da3f2; font-weight: bold">ข้อมูลทายาท</h5>
      </div>
      <div class="col-md-10">
        <label>
          <input id="notdescendants" type="checkbox" v-model="notdescendants" />
          <span> ไม่มีทายาท </span>
        </label>
      </div>

      <Descendants v-if="!notdescendants" />
    </div>
    <div class="row mt-5">
      <div class="col-md-4">
        <label>
          <input id="privacy" type="checkbox" v-model="privacy" />
          <span> หนังสือยินยอมเก็บข้อมูลส่วนบุคคล </span>
        </label>
      </div>
      <div class="col-md-2">
        <label for="exampleFormControlFile1"
          ><div>แนบเอกสาร</div>
          &nbsp;</label
        >
      </div>
      <div class="col-md-6">
        <input
          v-if="docprivacy.length == 0"
          @change="onFileChange"
          accept="application/pdf"
          type="file"
          id="exampleFormControlFile1"
        />
        <a
          v-if="docprivacy.length != 0"
          data-toggle="modal"
          href="#"
          data-target="#showModalImage"
          @click="getsrcimage(docprivacy[0].file_type, docprivacy[0].file_data)"
        >
          {{ docprivacy[0].file_name }}
        </a>
        <button
          v-if="docprivacy.length != 0"
          :disabled="cases.is_edit == 1"
          class="btn btn-danger"
          @click="deletedoc(docprivacy[0].document_id)"
        >
          &nbsp;ลบ
        </button>
      </div>
    </div>
    <div></div>
    <div class="row">
      <div class="col-md-12 text-center">
        <button
          class="btn btn-warning"
          style="color: white; width: 200px"
          @click="back()"
        >
          กลับหน้าเดิม</button
        >&nbsp;
        <button
          class="btn btn-success"
          style="color: white; width: 200px"
          @click="save()"
          id="saveimage"
          :disabled="cases.is_edit == 1"
        >
          บันทึกและไปต่อ
        </button>
        &nbsp;
        <button
          class="btn btn-warning"
          style="color: white; width: 200px"
          @click="next()"
        >
          ถัดไป
        </button>
      </div>
    </div>
  </div>
</template>

  <script src="https://justice-api.sbpac.go.th/picker_date.js"></script>

<script>
import Relatives from "./Relatives.vue";
import Descendants from "./Descendants.vue";
import SuspectsTypeDataService from "../../services/SuspectsTypeDataService.js";
import ProvinceDataService from "../../services/ProvinceDataService";
import AmphurDataService from "../../services/AmphurDataService";
import DistrictDataService from "../../services/DistrictDataService";
import SuspectsDataService from "../../services/SuspectsDataService";
import AddressDataService from "../../services/AddressDataService";
import CasesDataService from "../../services/CasesDataService";
import DocumentsDataService from "../../services/DocumentsDataService";
import DocsDataService from "../../services/DocsDataService";
import axios from "axios";
import ConvertService from "../../services/ConvertService";
import PrefixDataService from "../../services/PrefixDataService";
import HistoryCaseDataService from "../../services/HistoryCaseDataService";

// import moment from "moment";
// import DatePick from 'vue-date-pick';
// import 'vue-date-pick/dist/vueDatePick.css';

export default {
  components: {
    Relatives,
    Descendants,
    // DatePick
  },
  data() {
    return {
      name: "HelloWorld",
      filldate: "",
      suspect: [],
      suspecttype: [],
      provinces: [],
      amphures: [],
      districts: [],
      provincescurrent: [],
      amphurescurrent: [],
      districtscurrent: [],
      postal_code_house: "",
      postal_code_current: "",
      sameaddress: false,
      other: false,
      case_id: "",
      suspect_id: "",
      address_id: "",
      privacy: false,
      docprivacy: [],
      doclist: [],
      imagesrc: "",
      notdescendants: false,
      cases: [],
      tempdoc: [],
      imagetype: "",
      document_id: "",
      nationalityother: "",
      options: {
        format: "DD/MM/YYYY",
        useCurrent: false,
      },
      prefix: [],
      item: [],
      json: [],
      listjson: {},
    };
  },
  mounted() {
    this.case_id = this.$route.params.id;
    if (this.case_id == 0) {
      this.createdsuspect();
    } else {
      CasesDataService.getCase(this.case_id).then((res) => {
        this.suspect_id = res.data.suspect_id;
        // console.log(this.suspect_id);
      });
      this.getcases();
      this.getSuspectType();
      this.getPrefix();
      this.getProvinceAll();
      this.getAmphurAll("");
      this.getDistrictAll("");
      this.getProvinceAllcurrent();
      this.getAmphurAllcurrent("");
      this.getDistrictAllcurrent("");
    }
  },
  methods: {
    onchange(document_id, catdocid) {
      this.document_id = document_id;
      // console.log(this.document_id);
      this.catdocid = catdocid;
      document.getElementById("my_file").click();
    },
    getprivacydoc() {
      // console.log(this.suspect_id);
      DocumentsDataService.getDocPrivacy(this.suspect_id).then((res) => {
        this.docprivacy = res.data;
        // console.log(this.docprivacy);
        if (this.docprivacy.length != 0) {
          this.privacy = true;
        }
      });
    },
    getcases() {
      CasesDataService.getCase(this.case_id).then((res) => {
        this.suspect_id = res.data.suspect_id;
        // console.log(this.suspect_id);
      });
      CasesDataService.getCase(this.case_id).then((res) => {
        this.cases = res.data;
        // console.log(this.cases);
        // console.log(res.data.filing_date);
        if (res.data.filing_date == null) {
          this.filldate = ConvertService.getdate(new Date());
          document.getElementById("fill_date").value = this.filldate;
        } else if (res.data.filing_date != null) {
          this.filldate = res.data.filing_date;
          this.filldate = ConvertService.getdate(this.filldate);
          // console.log(this.filldate);
          document.getElementById("fill_date").value = this.filldate;
        }
        // console.log(this.suspect_id);
        this.getprivacydoc();
        this.getDoc();
        SuspectsDataService.getaddresssuspect(this.suspect_id).then((res) => {
          this.suspect = res.data;
          // console.log(this.suspect);
          this.getAmphurAll(this.suspect.province_house);
          this.getDistrictAll(this.suspect.district_house);
          this.getpostcode(this.suspect.sub_district_house)
          this.getAmphurAllcurrent(this.suspect.province_current)
          this.getDistrictAllcurrent(this.suspect.district_current);
          this.getpostcodecurrent(this.suspect.sub_district_current)
          if (this.suspect.nationality != null) {
            if (this.suspect.nationality != "ไทย") {
              this.other = true;
              this.nationalityother = this.suspect.nationality;
              this.suspect.nationality = "อื่นๆ";
            }
            this.sameaddress = this.suspect.is_same;
          }
          if (this.suspect.nationality == null) {
            this.suspect.nationality = "ไทย";
          }
          if (this.suspect.id_card == null) {
            this.suspect.id_card = "";
          }
          if (this.suspect.postal_code_house != null) {
            this.postal_code_house = this.suspect.postal_code_house;
          }
          if (this.suspect.postal_code_current != null) {
            this.postal_code_current = this.suspect.postal_code_current;
          }
          if (res.data.birthday != null) {
            // this.suspect.birthday = new Date(res.data.birthday);
            this.suspect.birthday = ConvertService.getdate(res.data.birthday);
            // console.log(this.suspect.birthday);
            document.getElementById("birthday").value = this.suspect.birthday;
          }
          var year = new Date().getFullYear()
    var start = year-2004
    var before = "-"+start+":+0"
          picker_date(document.getElementById("fill_date"), {
            year_range: before,
          });
          picker_date(document.getElementById("birthday"), {
            year_range: "-100:+0",
          });
        });
        AddressDataService.getSuspectAddress(this.suspect_id).then((res) => {
          this.address_id = res.data.address_id; 
          // this.savedraft()
          // this.getjson();
        });
      });
      // }
    },
    getjson() {
      CasesDataService.getCase(this.case_id).then((res) => {
        this.suspect_id = res.data.suspect_id;
        // console.log(this.suspect_id);
      });
      this.item = localStorage.getItem('suspect');
      this.json = JSON.parse(this.item);
      // console.log(this.json);
      // console.log(this.json.filing_date);
      // console.log(this.json.birthday);
      if (this.json.filing_date == null) {
        this.filldate = ConvertService.getdate(new Date());
        document.getElementById("fill_date").value = this.filldate;
      } else if (this.json.filing_date != null) {
        this.filldate = this.json.filing_date;
        this.filldate = ConvertService.getdate(this.filldate);
        // console.log(this.filldate);
        document.getElementById("fill_date").value = this.filldate;
      }
      this.suspect = this.json;
      if (this.suspect.nationality != null) {
        if (this.suspect.nationality != "ไทย") {
          this.other = true;
          this.nationalityother = this.suspect.nationality;
          this.suspect.nationality = "อื่นๆ";
        }
        this.sameaddress = this.json.is_same;
      }
      if (this.suspect.nationality == null || this.suspect.nationality == "") {
        this.suspect.nationality = "ไทย";
      }
      if (this.suspect.id_card == null) {
        this.suspect.id_card = "";
      }
      if (this.suspect.postal_code_house != null) {
        this.postal_code_house = this.suspect.postal_code_house;
      }
      if (this.suspect.postal_code_current != null) {
        this.postal_code_current = this.suspect.postal_code_current;
      }
      if (this.json.birthday == null || this.json.birthday == 'NaN-NaN-NaN') {
        document.getElementById("birthday").value = null
      }else if (this.json.birthday != null){
        // var b = ConvertService.getdate(this.json.birthday)
        // console.log(b);
        document.getElementById("birthday").value = ConvertService.getdate(this.json.birthday);
      }
      this.address_id = this.json.address_id;
    },
    createdsuspect() {
      var suspect = {
        created_by: this.currentUser.user_id,
        updated_by: this.currentUser.user_id,
      };
      // console.log(suspect);
      SuspectsDataService.createSuspect(suspect)
        .then((res) => {
          // console.log(res.data.id);
          this.suspect_id = res.data.id;
          // this.getDoc();
          var cases = {
            suspect_id: this.suspect_id,
            status_id: null,
            is_edit: 0,
            created_by: this.currentUser.user_id,
            updated_by: this.currentUser.user_id,
          };
          // console.log(cases);
          CasesDataService.createCase(cases).then((res) => {
            this.case_id = res.data.id;
            var address = {
              is_edit: false,
              created_by: this.currentUser.user_id,
              updated_by: this.currentUser.user_id,
            };
            AddressDataService.createAddress(address).then((res) => {
              this.address_id = res.data.id;
              var suspectaddress = {
                suspect_id: this.suspect_id,
                address_id: this.address_id,
                created_by: this.currentUser.user_id,
                updated_by: this.currentUser.user_id,
              };
              AddressDataService.createSuspectAddress(suspectaddress).then(
                () => {
                  // console.log(res.data);
                  this.$router.push("/newtreatment/" + this.case_id + "/1");
                  setTimeout(function () {
                    location.reload();
                  }, 300);
                  this.getcases();
                  //             this.getSuspectType();
                  // this.getProvinceAll();
                  // this.getAmphurAll("");
                  // this.getDistrictAll("");
                  // this.getProvinceAllcurrent();
                  // this.getAmphurAllcurrent("");
                  // this.getDistrictAllcurrent("");
                }
              );
              // console.log(this.address_id);
            });
          });

          // console.log(this.suspect_id);
        })
        .catch(() => {
          // console.log(err);
        });
    },
    savedraft() {
      if (this.suspect.nationality == "อื่นๆ") {
          this.suspect.nationality = this.nationalityother;
        }
        if (document.getElementById("birthday").value == "") {
          this.suspect.birthday = null
        }else{
          this.suspect.birthday = ConvertService.createddate(
            document.getElementById("birthday").value)
        }
        var suspect = {
          suspect_types_id: this.suspect.suspect_types_id,
          id_card: this.suspect.id_card,
          prefix: this.suspect.prefix,
          firstname: this.suspect.firstname,
          lastname: this.suspect.lastname,
          gender: this.suspect.gender,
          birthday: this.suspect.birthday,
          career: this.suspect.career,
          income: this.suspect.income,
          nationality: this.suspect.nationality,
          religion: this.suspect.religion,
          status: this.suspect.status,
          phone: this.suspect.phone,
          updated_by: this.currentUser.user_id,
        };
        // console.log(suspect);
        SuspectsDataService.updateSuspect(this.suspect_id, suspect).then(
          (res) => {
            // console.log(res.data);
          }
        );
        var address = {
          is_same: this.sameaddress,
          no_house: this.suspect.no_house,
          village_no_house: this.suspect.village_no_house,
          road_house: this.suspect.road_house,
          lane_house: this.suspect.lane_house,
          village_house: this.suspect.village_house,
          sub_district_house: this.suspect.sub_district_house,
          district_house: this.suspect.district_house,
          province_house: this.suspect.province_house,
          postal_code_house: this.postal_code_house,
          no_current: this.suspect.no_current,
          village_no_current: this.suspect.village_no_current,
          road_current: this.suspect.road_current,
          lane_current: this.suspect.lane_current,
          village_current: this.suspect.village_current,
          sub_district_current: this.suspect.sub_district_current,
          district_current: this.suspect.district_current,
          province_current: this.suspect.province_current,
          postal_code_current: this.postal_code_current,
          longitude_house: this.suspect.longitude_house,
          lattitude_house: this.suspect.lattitude_house,
          longitude_current: this.suspect.longitude_current,
          lattitude_current: this.suspect.lattitude_current,
          updated_by: this.currentUser.user_id,
        };
        AddressDataService.updateAddress(this.address_id, address).then(
          (res) => {
            console.log(res.data);
          }
        );
        },
getnewfile(localUrl, name) {
  console.log(localUrl, name);
          const formData = new FormData();
          formData.append("file", localUrl); // appending file
          //  sending file to the backend
          // var http = "https://api-ri.ponnipa.in.th/upload";
          
    var link = ConvertService.gethttp()
    // console.log(link);
          var http = link+"/upload?name="+name;
          axios
            .post(http, formData)
            .then(() => {
              console.log(res.data);
            })
            .catch((err) => {
              console.log(err);
            });
    },
    save() {
      if (this.suspect.suspect_types_id == null) {
        alert("กรุณาเลือกประเภทผู้เสียหาย");
      } else if (this.suspect.id_card == null) {
        alert("กรุณากรอกหมายเลขบัตรประชาชน");
      } else if (this.suspect.id_card.length < 13) {
        alert("กรุณากรอกหมายเลขบัตรประชาชนให้ครบ");
      } else if (this.suspect.prefix == null) {
        alert("กรุณาเลือกคำนำหน้าชื่อ");
      } else if (this.suspect.firstname == null) {
        alert("กรุณากรอกชื่อ");
      } else if (this.suspect.lastname == null) {
        alert("กรุณากรอกนามสกุล");
      } else if (this.suspect.gender == null) {
        alert("กรุณาเลือกเพศ");
      } else if (document.getElementById("birthday").value == "") {
        alert("กรุณากรอกวัน/เดือน/ปีเกิด");
      } else if (this.suspect.career == null) {
        alert("กรุณากรอกอาชีพ");
      } else if (this.suspect.income == null) {
        alert("กรุณากรอกรายได้");
      } else if (this.suspect.nationality == null) {
        alert("กรุณาเลือกสัญชาติ");
      } else if (this.suspect.status == null) {
        alert("กรุณาเลือกสถานะ");
      } else if (this.suspect.phone == null) {
        alert("กรุณากรอกเบอร์โทรศัพท์");
      } else if (this.suspect.phone.length != 10) {
        alert("กรุณากรอกเบอร์โทรศัพท์ให้ครบ 10 หลัก");
      }
      // else if (this.suspect.no_house == null) {
      //   alert("กรุณากรอกบ้านเลขที่");
      // } else if (this.suspect.village_no_house == null) {
      //   alert("กรุณากรอกหมู่ที่");
      // } else if (this.suspect.village_house == null) {
      //   alert("กรุณากรอกหมู่บ้าน");
      // }
      else if (this.suspect.province_house == null) {
        alert("กรุณาเลือกจังหวัดปัจจุบัน");
      } else if (this.suspect.district_house == null) {
        alert("กรุณาเลือกอำเภอปัจจุบัน");
      } else if (this.suspect.sub_district_house == null) {
        alert("กรุณาเลือกตำบลปัจจุบัน");
      }
      //  else if (this.suspect.no_current == null) {
      //   alert("กรุณากรอกบ้านเลขที่ตามบัตรประชาชน");
      // } else if (this.suspect.village_no_current == null) {
      //   alert("กรุณากรอกหมู่ที่ตามบัตรประชาชน");
      // } else if (this.suspect.village_current == null) {
      //   alert("กรุณากรอกหมู่บ้านตามบัตรประชาชน");
      // }
      else if (this.suspect.province_current == null) {
        alert("กรุณาเลือกจังหวัดตามบัตรประชาชน");
      } else if (this.suspect.district_current == null) {
        alert("กรุณาเลือกอำเภอตามบัตรประชาชน");
      } else if (this.suspect.sub_district_current == null) {
        alert("กรุณาเลือกตำบลตามบัตรประชาชน");
      }
      // else if (this.privacy == false) {
      //   alert("กรุณายินยอมการขอเก็บข้อมูลส่วนบุคคล");
      // } else if (this.docprivacy.length == 0) {
      //   alert("กรุณาแนบเอกสารขอเก็บข้อมูลส่วนบุคคล");
      // }
      else {
        if (this.suspect.nationality == "อื่นๆ") {
          this.suspect.nationality = this.nationalityother;
        }
        var suspect = {
          suspect_types_id: this.suspect.suspect_types_id,
          id_card: this.suspect.id_card,
          prefix: this.suspect.prefix,
          firstname: this.suspect.firstname,
          lastname: this.suspect.lastname,
          gender: this.suspect.gender,
          birthday: ConvertService.createddate(
            document.getElementById("birthday").value
          ),
          career: this.suspect.career,
          income: this.suspect.income,
          nationality: this.suspect.nationality,
          religion: this.suspect.religion,
          status: this.suspect.status,
          phone: this.suspect.phone,
          updated_by: this.currentUser.user_id,
        };
        // console.log(suspect);
        SuspectsDataService.updateSuspect(this.suspect_id, suspect).then(
          () => {
            // console.log(res.data);
          }
        );
        var address = {
          is_same: this.sameaddress,
          no_house: this.suspect.no_house,
          village_no_house: this.suspect.village_no_house,
          road_house: this.suspect.road_house,
          lane_house: this.suspect.lane_house,
          village_house: this.suspect.village_house,
          sub_district_house: this.suspect.sub_district_house,
          district_house: this.suspect.district_house,
          province_house: this.suspect.province_house,
          postal_code_house: this.postal_code_house,
          no_current: this.suspect.no_current,
          village_no_current: this.suspect.village_no_current,
          road_current: this.suspect.road_current,
          lane_current: this.suspect.lane_current,
          village_current: this.suspect.village_current,
          sub_district_current: this.suspect.sub_district_current,
          district_current: this.suspect.district_current,
          province_current: this.suspect.province_current,
          postal_code_current: this.postal_code_current,
          longitude_house: this.suspect.longitude_house,
          lattitude_house: this.suspect.lattitude_house,
          longitude_current: this.suspect.longitude_current,
          lattitude_current: this.suspect.lattitude_current,
          updated_by: this.currentUser.user_id,
        };
        AddressDataService.updateAddress(this.address_id, address).then(
          () => {
            // console.log(res.data);
          }
        );
        // console.log(this.cases.status_id);
        var status = 1;
        if (this.cases.status_id == null) {
          status = 1;
        }
        else if (this.cases.status_id != 1) {
          status = this.cases.status_id;
        } 
        // console.log(status);
        var cases = {
          status_id: status,
          filing_date: ConvertService.createddate(
            document.getElementById("fill_date").value
          ),
          updated_by: this.currentUser.user_id,
        };
        CasesDataService.updateFillingCase(this.case_id, cases).then(() => {
          // console.log(res.data);

          HistoryCaseDataService.getHistoryCaseByStatusID(this.case_id, 1).then(
            (res) => {
              console.log(res.data);
              if (res.data == '') {
                var d={
                  case_id: this.case_id,
            history_status_id:3,
            user_id:this.currentUser.user_id
          }
          HistoryCaseDataService.createHistory(d).then(()=>{

          })
                var data = {
                  status_id: 1,
                  case_id: this.case_id,
                  created_by: this.currentUser.user_id,
                  created_date: ConvertService.createddate(
                    document.getElementById("fill_date").value
                  ),
                };
                HistoryCaseDataService.createHistoryCase(data).then(() => {
                  // console.log(res.data);
                });
              } 
              else {
                var d={
                  case_id: this.case_id,
            history_status_id:4,
            user_id:this.currentUser.user_id
          }
          HistoryCaseDataService.createHistory(d).then(()=>{
          })
                var dataupdate = {
                  created_date: ConvertService.createddate(
                    document.getElementById("fill_date").value
                  ),
                };
                HistoryCaseDataService.updateeHistoryCase(
                  res.data.history_case_id,
                  dataupdate
                ).then(() => {
                  // console.log(res.data);
                });
              }
               alert("บันทึกสำเร็จ");
        localStorage.removeItem("suspect");
        this.getcases();
        setTimeout(function () {
          location.reload();
        }, 300);
            }
          );
        });
      }
    },
    onChangenational(event) {
      // console.log(event.target.value);
      if (event.target.value == "อื่นๆ") {
        this.other = true;
      } else {
        this.other = false;
      }
      this.savedraft();
    },
    changeaddress() {
      // console.log(this.sameaddress);
      if (this.sameaddress == false) {
        this.getAmphurAllcurrent(this.suspect.province_house)
        this.getDistrictAllcurrent(this.suspect.district_house);
        this.getpostcodecurrent(this.suspect.sub_district_house);
        this.suspect.no_current = this.suspect.no_house;
        this.suspect.village_no_current = this.suspect.village_no_house;
        this.suspect.road_current = this.suspect.road_house;
        this.suspect.lane_current = this.suspect.lane_house;
        this.suspect.village_current = this.suspect.village_house;
        this.suspect.sub_district_current = this.suspect.sub_district_house;
        this.suspect.district_current = this.suspect.district_house;
        this.suspect.province_current = this.suspect.province_house;
        this.postal_code_current = this.postal_code_house;
        this.suspect.longitude_current = this.suspect.longitude_house;
        this.suspect.lattitude_current = this.suspect.lattitude_house;
      } else if (this.sameaddress == true) {
        this.suspect.no_current = "";
        this.suspect.village_no_current = "";
        this.suspect.road_current = "";
        this.suspect.lane_current = "";
        this.suspect.village_current = "";
        this.suspect.sub_district_current = "";
        this.suspect.district_current = "";
        this.suspect.province_current = "";
        this.postal_code_current = "";
        this.suspect.longitude_current = "";
        this.suspect.lattitude_current = "";
      }
      this.savedraft();
    },
    onChangeProvincecurrent(event) {
      // console.log(this.user.province)
      var province_id = event.target.value;
      this.suspect.district_current = "";
      this.suspect.sub_district_current = "";
      this.postal_code_current = "";
      this.getAmphurAllcurrent(province_id);
    },

    onChangeDistrictcurrent(event) {
      // console.log(this.user.province)
      var amphur_id = event.target.value;
      this.suspect.sub_district_current = "";
      this.getDistrictAllcurrent(amphur_id);
    },
    onChangePostalCodecurrent(event) {
      //  console.log(event.target.value)
      var district_id = event.target.value;
      this.getpostcodecurrent(district_id);
    },
    getProvinceAllcurrent() {
      ProvinceDataService.getProvinceAll().then((res) => {
        this.provincescurrent = res.data;
      });
    },
    getAmphurAllcurrent(id) {
      if (id == "") {
        AmphurDataService.getAmphursAll().then((res) => {
          this.amphurescurrent = res.data;
        });
      } else {
        AmphurDataService.getAmphurAll(id).then((res) => {
          this.amphurescurrent = res.data;
        });
      }
      // this.savedraft();
    },
    getpostcodecurrent(id) {
      if (id == null) {
        id = ''
      }
      DistrictDataService.getPostCode(id).then((res) => {
        // console.log(res.data[0].POST_CODE);
        this.postal_code_current = res.data[0].POST_CODE;

        this.savedraft();
        var district_name = "";
        var amphur_name = "";
        var province_name = "";
        var road_current = "";
        var lane_current = "";
        // this.suspect.lattitude_house, this.suspect.longitude_house = this.decodeLong(this.suspect.no_house,this.suspect.village_no_house,this.suspect.road_house,this.suspect.lane_house,this.suspect.village_house,this.suspect.sub_district_house,this.suspect.district_house,this.suspect.province_house,this.postal_code_house)
        if (
          this.suspect.sub_district_current != null &&
          this.suspect.district_current != null &&
          this.suspect.province_current != null &&
          this.postal_code_current != null
        ) {
          if (this.suspect.road_current == null) {
            road_current = "";
          } else {
            road_current = this.suspect.road_current;
          }
          if (this.suspect.lane_current == null) {
            lane_current = "";
          } else {
            lane_current = this.suspect.lane_current;
          }
          DistrictDataService.getname(this.suspect.sub_district_current).then(
            (res) => {
              district_name = res.data.DISTRICT_NAME;

              AmphurDataService.getname(this.suspect.district_current).then(
                (res) => {
                  amphur_name = res.data.AMPHUR_NAME;
                  ProvinceDataService.getname(
                    this.suspect.province_current
                  ).then((res) => {
                    province_name = res.data.PROVINCE_NAME;
                    var address =
                      this.suspect.no_current +
                      " " +
                      this.suspect.village_no_current +
                      " " +
                      road_current +
                      " " +
                      lane_current +
                      " " +
                      this.suspect.village_current +
                      " " +
                      district_name +
                      " " +
                      amphur_name +
                      " " +
                      province_name +
                      " " +
                      this.postal_code_current;
                    axios
                      .get(
                        "https://maps.googleapis.com/maps/api/geocode/json?address=" +
                          address +
                          ",+TH&key=AIzaSyD-TXPZpYTzg9_Mrxo9NrqJgNBwFbmKWcg"
                      )
                      .then((res) => {
                        this.suspect.longitude_current =
                          res.data.results[0].geometry.location.lng;
                        this.suspect.lattitude_current =
                          res.data.results[0].geometry.location.lat;

                        this.savedraft();
                      });
                  });
                }
              );
            }
          );
        }
      });
    },
    getDistrictAllcurrent(id) {
      if (id == "") {
        DistrictDataService.getDistrictsAll().then((res) => {
          this.districtscurrent = res.data;
        });
      } else {
        DistrictDataService.getDistrictAll(id).then((res) => {
          this.districtscurrent = res.data;
          this.postal_code_current = "";
        });
      }
    },
    onChangeProvince(event) {
      // console.log(this.user.province)
      var province_id = event.target.value;
      this.suspect.district_house = "";
      this.suspect.sub_district_house = "";
      this.postal_code_house = "";
      this.getAmphurAll(province_id);
    },

    onChangeDistrict(event) {
      // console.log(this.user.province)
      var amphur_id = event.target.value;
      this.suspect.sub_district_house = "";
      this.getDistrictAll(amphur_id);
    },
    onChangePostalCode(event) {
      var district_id = event.target.value;
      this.getpostcode(district_id);
    },
    getProvinceAll() {
      ProvinceDataService.getProvinceAll().then((res) => {
        this.provinces = res.data;
        // console.log(res.data);
      });
    },
    getAmphurAll(id) {
      if (id == "") {
        AmphurDataService.getAmphursAll().then((res) => {
          this.amphures = res.data;
          // console.log(res.data);
        });
      } else {
        AmphurDataService.getAmphurAll(id).then((res) => {
          this.amphures = res.data;
          // console.log(res.data);
        });
      }
      // this.savedraft();
    },
    getpostcode(id) {
      if (id == null) {
        id = ''
      }
      DistrictDataService.getPostCode(id).then((res) => {
        // console.log(res.data[0].POST_CODE);
        this.postal_code_house = res.data[0].POST_CODE;

        this.savedraft();
        var district_name = "";
        var amphur_name = "";
        var province_name = "";
        var road_house = "";
        var lane_house = "";
        // this.suspect.lattitude_house, this.suspect.longitude_house = this.decodeLong(this.suspect.no_house,this.suspect.village_no_house,this.suspect.road_house,this.suspect.lane_house,this.suspect.village_house,this.suspect.sub_district_house,this.suspect.district_house,this.suspect.province_house,this.postal_code_house)
        // console.log(this.suspect);
        if (
          this.suspect.sub_district_house != null &&
          this.suspect.district_house != null &&
          this.suspect.province_house != null &&
          this.postal_code_house != null
        ) {
          if (this.suspect.road_house == null) {
            road_house = "";
          } else {
            road_house = this.suspect.road_house;
          }
          if (this.suspect.lane_house == null) {
            lane_house = "";
          } else {
            lane_house = this.suspect.lane_house;
          }
          DistrictDataService.getname(this.suspect.sub_district_house).then(
            (res) => {
              district_name = res.data.DISTRICT_NAME;

              AmphurDataService.getname(this.suspect.district_house).then(
                (res) => {
                  amphur_name = res.data.AMPHUR_NAME;
                  ProvinceDataService.getname(this.suspect.province_house).then(
                    (res) => {
                      province_name = res.data.PROVINCE_NAME;
                      var address =
                        this.suspect.no_house +
                        " " +
                        this.suspect.village_no_house +
                        " " +
                        road_house +
                        " " +
                        lane_house +
                        " " +
                        this.suspect.village_house +
                        " " +
                        district_name +
                        " " +
                        amphur_name +
                        " " +
                        province_name +
                        " " +
                        this.postal_code_house;
                      axios
                        .get(
                          "https://maps.googleapis.com/maps/api/geocode/json?address=" +
                            address +
                            ",+TH&key=AIzaSyD-TXPZpYTzg9_Mrxo9NrqJgNBwFbmKWcg"
                        )
                        .then((res) => {
                          this.suspect.longitude_house =
                            res.data.results[0].geometry.location.lng;
                          this.suspect.lattitude_house =
                            res.data.results[0].geometry.location.lat;

                          this.savedraft();
                        })
                        .catch((err) => {
                          console.log(err);
                        });
                    }
                  );
                }
              );
            }
          );
        }
      });
    },
    getDistrictAll(id) {
      if (id == "") {
        DistrictDataService.getDistrictsAll().then((res) => {
          this.districts = res.data;
        });
      } else {
        DistrictDataService.getDistrictAll(id).then((res) => {
          this.districts = res.data;
          this.postal_code_house = "";
        });
      }
      // this.savedraft();
    },
    onlyNumber($event) {
      if (this.suspect.id_card != "") {
        let keyCode = $event.keyCode ? $event.keyCode : $event.which;
        if (keyCode < 48 || keyCode > 57 || this.suspect.id_card.length == 13) {
          // 46 is dot
          $event.preventDefault();
        }
      }
    },
    onFileChange(evt) {
      const files = evt.target.files || evt.dataTransfer.files;
      const selectedImage = evt.target.files[0]; // get first file
      // console.log(selectedImage);
      if (selectedImage.size < 1000000) {
        this.docname = selectedImage.name;
        this.doctype = selectedImage.type;
        this.createImage(files[0]);
      } else {
        alert("ไฟล์ขนาดใหญ่");
      }
      if (!files.length) return;
    },
    createImage(file) {
      const reader = new FileReader();

      reader.onload = (evt) => {
        this.image = evt.target.result;
        // console.log(this.docid);
        if (this.docid == null) {
          var doc = {
            category_doc_id: 10,
            suspect_id: this.suspect_id,
            case_id: this.case_id,
            file_name: this.docname,
            file_type: this.doctype,
            file_data: this.image,
            created_by: this.currentUser.user_id,
            updated_by: this.currentUser.user_id,
          };
          // console.log(doc);
          DocumentsDataService.createDocument(doc).then(() => {
            // console.log(res.data);
            this.getprivacydoc();
          });
        }
      };

      reader.readAsDataURL(file);
    },
    hasHistory() {
      return window.history.length > 2;
    },
    getPrefix() {
      PrefixDataService.getPrefixs().then((res) => {
        this.prefix = res.data;
        // console.log(res.data);
      });
    },
    getSuspectType() {
      SuspectsTypeDataService.getSuspectTypes().then((res) => {
        this.suspecttype = res.data;
        // console.log(res.data);
      });
    },
    deletedoc(id) {
      DocumentsDataService.deleteDocument(id).then(() => {
        this.docprivacy = [];
      });
    },
    getsrcimage(type, url) {
      this.imagetype = type;
      this.imagesrc = url;
      console.log(url);
    },
    getDoc() {
      this.documents = [];
      // console.log(this.suspect_id);
      DocumentsDataService.getDoc(this.suspect_id).then((res) => {
        this.documents = res.data;
        // console.log(this.documents);
        DocsDataService.getDocByType(1).then((res) => {
          this.doclist = res.data;
          // console.log(res.data);
          for (let d = 0; d < this.doclist.length; d++) {
            this.doclist[d].document_id = 0;
            for (let dd = 0; dd < this.documents.length; dd++) {
              if (
                this.doclist[d].category_doc_id ==
                this.documents[dd].category_doc_id
              ) {
                this.doclist[d].document_id = this.documents[dd].document_id;
                this.doclist[d].file_name = this.documents[dd].file_name;
                this.doclist[d].file_type = this.documents[dd].file_type;
                this.doclist[d].file_data = this.documents[dd].file_data;
              }
            }
          }
          this.tempdoc = this.doclist;
          // console.log(this.doclist);
        });
      });
    },
    deletedocsus(id) {
      DocumentsDataService.deleteDocument(id).then(() => {
        this.getDoc();
      });
      // console.log(id);
      // for (let t = 0; t < this.tempdoc.length; t++) {
      //   if (this.tempdoc[t].category_doc_id == id) {
      //     this.tempdoc[t].file_name = ''
      //     this.tempdoc[t].file_type = ''
      //     this.tempdoc[t].file_data = ''
      //   }
      // }
      // this.getDocTemp();
    },
    onFileChangesus(evt) {
      const files = evt.target.files || evt.dataTransfer.files;
      const selectedImage = evt.target.files[0]; // get first file
      // console.log(selectedImage);
      this.docname = selectedImage.name;
      this.doctype = selectedImage.type;
      if (!files.length) return;
      this.createImagesus(files[0]);
    },
    createImagesus(file) {
      const reader = new FileReader();

      reader.onload = (evt) => {
        this.image = evt.target.result;
        // console.log(evt);
        // console.log(this.docname);
         const formData = new FormData();
          formData.append("file", file); // appending file
          //  sending file to the backend
          // var http = "https://api-ri.ponnipa.in.th/upload";
          
    var link = ConvertService.gethttp()
    // console.log(link);
          var http = link+"/uploadsuspect?name="+this.docname;
          axios
            .post(http, formData)
            .then((res) => {
              console.log(res.data);
            })
            .catch((err) => {
              console.log(err);
            });
        var file_data = link+"/uploads/suspects/"+this.docname;
        var newdoc = {
          suspect_id: this.suspect_id,
          category_doc_id: this.catdocid,
          file_name: this.docname,
          file_type: this.doctype,
          file_data: file_data,
          created_by: this.currentUser.user_id,
          updated_by: this.currentUser.user_id,
        };
        // console.log(newdoc);
        if (this.document_id != 0) {
          DocumentsDataService.updateDocument(this.document_id, newdoc).then(
            () => {
              // console.log(res.da/ta);
              this.getDoc();
            }
          );
        } else {
          DocumentsDataService.createDocument(newdoc).then(() => {
            // console.log(res.data);
            this.getDoc();
          });
        }

        // this.tempdoc.push({
        //   category_doc_id: this.catdocid,
        //   file_name: this.docname,
        //   file_type: this.doctype,
        //   file_data: this.image,
        // });
        // this.getDocTemp();
      };

      reader.readAsDataURL(file);
    },
    getDocTemp() {
      this.doclist = [];
      // console.log(this.doclist);
      // console.log(this.tempdoc);
      DocsDataService.getDocByType(1).then((res) => {
        this.doclist = res.data;
        for (let d = 0; d < this.doclist.length; d++) {
          for (let dd = 0; dd < this.tempdoc.length; dd++) {
            if (
              this.doclist[d].category_doc_id ==
              this.tempdoc[dd].category_doc_id
            ) {
              // console.log(this.tempdoc[dd].category_doc_id);
              this.doclist[d].file_name = this.tempdoc[dd].file_name;
              this.doclist[d].file_type = this.tempdoc[dd].file_type;
              this.doclist[d].file_data = this.tempdoc[dd].file_data;
            }
          }
        }
      });
      // console.log(this.doclist);
    },
    back() {
      this.$router.push("/treatment");
      setTimeout(function () {
        location.reload();
      }, 500);
      window.scrollTo(0, 0);
    },
    next() {
      this.$router.push("/newtreatment/" + this.case_id + "/2");
      setTimeout(function () {
        location.reload();
      }, 300);      
      window.scrollTo(0, 0);
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  font-family: "Kanit" !important;
}
.row {
  max-width: 1200px;
  margin: auto;
  background: white;
  padding: 10px;
  color: black !important;
  padding-left: 0px;
}
.nav-link {
  color: black !important;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #09cc68;
}
.modal-content {
  float: center;
  text-align: center;
  width: 800px;
}
.modal {
  text-align: center;
  padding: 0 !important;
}
.modal:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}
.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  max-width: 700px;
}
#my_file {
  display: none;
}
</style>
