<template>
  <div class="hello">
    <HomeIcon />
    <div class="mt-5">
      <div class="row mt-5">
        <h4 style="text-align: center">ข้อมูลยื่นเรื่องขออนุมัติเบิกเงิน</h4>
      </div>
      <div class="row card mt-2">
        <div class="card-body p-0 mt-3">
          <h4 style="text-align: center">ค้นหา</h4>
          <div class="row">
            <div class="col-md-4"></div>
            <div class="col-md-4">
              <div class="form-group" style="text-align: left">
                <label for="username">เลขหนังสืออ้างอิง</label>
                <input
                  v-model="ref_no"
                  type="text"
                  class="form-control"
                  id="username"
                  placeholder="เลขหนังสืออ้างอิง"
                />
              </div>
            </div>
            <div class="col-md-4"></div>
          </div>
        </div>
        <div class="col text-center mb-3">
          <button
            @click="search()"
            class="btn btn-primary"
            style="color: white"
          >
            <i class="fas fa-search"></i> ค้นหา
          </button>
        </div>
      </div>
      <div class="mt-5">
        <div class="row" v-if="list.length != 0">
          <div class="col-md-12">
            <h4 style="text-align: left" class="mb-5">
              รายการข้อมูลการยื่นเรื่องขออนุมัติเบิกเงิน
            </h4>
          </div>
          <div class="col-md-12">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">
                    <input
                      v-model="statusinput"
                      @click="checktrue(statusinput)"
                      type="checkbox"
                      id="exampleCheck1"
                    />
                  </th>
                  <th v-for="(c, i) in columns" :key="i" scope="col">
                    {{ c.label }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(l, i) in pageOfItems" :key="i">
                  <th scope="row">
                    <input
                      v-if="l.no"
                      type="checkbox"
                      v-model="l.statuscheck"
                      :value="l.statuscheck"
                      id="exampleCheck1"
                    />
                  </th>
                  <td>{{ l.no }}</td>
                   <!-- <td :colspan="l.span" style="text-align:center">{{ l.prefix }}</td>
                  <td v-if="l.firstname">{{ l.firstname }}</td>
                  <td v-if="l.lastname">{{ l.lastname }}</td>
                  <td v-if="l.id_card">{{ l.id_card }}</td>
                  <td v-if="l.no_house">{{ l.no_house }}</td>
                  <td v-if="l.village_no_house">{{ l.village_no_house }}</td>
                  <td v-if="l.sub_district_house">{{ l.sub_district_house }}</td>
                  <td v-if="l.district_house">{{ l.district_house }}</td>
                  <td v-if="l.province_house">{{ l.province_house }}</td> -->
                  <td style="text-align:center">{{ l.prefix }}</td>
                  <td >{{ l.firstname }}</td>
                  <td >{{ l.lastname }}</td>
                  <td >{{ l.id_card }}</td>
                  <td >{{ l.no_house }}</td>
                  <td >{{ l.village_no_house }}</td>
                  <td>{{ l.sub_district_house }}</td>
                  <td >{{ l.district_house }}</td>
                  <td >{{ l.province_house }}</td>
                  <td>{{ l.event_type_name }}</td>
                  <td>{{ l.detained_start_date }}</td>
                  <td>{{ l.detained_end_date }}</td>
                  <td>{{ l.day }}</td>
                  <!-- <td v-for="(a,ii) in amounts" :key="ii">{{ a.one }}</td> -->
                  <td v-if="statusone">{{ l.one }}</td>
                  <td v-if="statustwo">{{ l.two }}</td>
                  <td v-if="statusthree">{{ l.three }}</td>
                  <td v-if="statusfour">{{ l.four }}</td>
                  <td v-if="statusfive">{{ l.five }}</td>
                  <td v-if="statussix">{{ l.six }}</td>
                  <td v-if="statusseven">{{ l.seven }}</td>
                  <td v-if="statuseight">{{ l.eight }}</td>
                  <td v-if="statusnine">{{ l.nine }}</td>
                  <td v-if="statusten">{{ l.ten }}</td>
                  <td>{{ l.amount }}</td>
                  <td>{{ l.department_detained_type_name }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div v-else style="text-align: center">
          <h5>ไม่พบข้อมูล</h5>
        </div>
      </div>
      <div class="row" align="right">
        <div class="col-md-12">
          <jw-pagination
            :items="list"
            @changePage="onChangePage"
            :labels="customLabels"
          ></jw-pagination>
        </div>
      </div>

      <div class="row card mt-5">
        <div class="card-body p-0 mt-3">
          <h4 style="text-align: center">
            ข้อมูลการยื่นเรื่องขออนุมัติเบิกเงิน
          </h4>
          <div class="form-group row" style="text-align: center">
            <div class="col-md-2"></div>
            <label for="inputPassword" class="col-sm-2 col-form-label"
              >เลขหนังสืออ้างอิง</label
            >
            <div class="col-sm-3">
              <input
                v-model="ref"
                type="text"
                class="form-control"
                placeholder="เลขหนังสืออ้างอิง"
              />
            </div>
            <div class="col-12" align="right" style="display: none">
              <vue-excel-xlsx
                id="excel"
                :data="data"
                :columns="columns"
                :file-name="filename"
                :file-type="'xlsx'"
                :sheet-name="'sheetname'"
              >
                Export Excel
              </vue-excel-xlsx>
            </div>
            <div class="col-sm-5" style="text-align: left">
              <button
                class="btn btn-primary"
                style="color: white"
                @click="create_ref()"
              >
                สร้างหมายเลขอ้างอิง</button
              >&nbsp;&nbsp;
              <button
                v-if="ref_no && list.length > 0"
                class="btn btn-success"
                style="color: white"
                @click="exportexcel()"
              >
                Export
              </button>              
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 text-center">
          <button
            class="btn btn-warning"
            style="color: white; width: 200px"
            @click="hasHistory() ? $router.go(-1) : $router.push('/')"
          >
            กลับหน้าเดิม</button
          >&nbsp;
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import HomeIcon from "../HomeIcon.vue";
import PaymentDataService from "../../services/PaymentDataService";
import PaymentCaseDataService from "../../services/PaymentCaseDataService";
import StatusDataService from "../../services/StatusDataService.js";
import CasesDataService from "../../services/CasesDataService";
import ConvertService from "../../services/ConvertService";
import HistoryCaseDataService from "../../services/HistoryCaseDataService";
import PrivilegeDataService from "../../services/PrivilegeDataService";

const customLabels = {
  first: "<<",
  last: ">>",
  previous: "<",
  next: ">",
};

export default {
  components: {
    HomeIcon,
  },
  data() {
    return {
      name: "HelloWorld",
      list: [],
      status: [],
      pageOfItems: [],
      date: "",
      options: {
        format: "DD/MM/YYYY",
        useCurrent: false,
      },
      location: "",
      latLong: "",
      customLabels,
      columns: [],
      data: [],
      filename: "",
      ref: "",
      statusinput: false,
      payment_id: 0,
      start: "",
      end: "",
      ref_no: "",
      privileged: [],
      privilegedall: [],
      one: 0,
      two: 0,
      three: 0,
      four: 0,
      amounts:[],
      statusone:false,
      statustwo:false,
      statusthree:false,
      statusfour:false,
      statusfive:false,
      statussix:false,
      statusseven:false,
      statuseight:false,
      statusnine:false,
      statusten:false,
    };
  },
  mounted() {
    this.start = this.$route.params.start;
    this.end = this.$route.params.end;
    // console.log(this.start, this.end);
    this.getCase();
    this.getstatus();
  },
  methods: {
    getprivil() {
      PrivilegeDataService.getPrivilegeGroup().then((res) => {
        // console.log(res.data);
        this.privileged = res.data;
        this.generatecolumn();
        for (let p = 0; p < this.privileged.length; p++) {
          if (this.privileged[p].privileged_id == 1) {
            this.statusone = true
          }else if (this.privileged[p].privileged_id == 2) {
            this.statustwo = true
          }else if (this.privileged[p].privileged_id == 3) {
            this.statusthree = true
          }else if (this.privileged[p].privileged_id == 4) {
            this.statusfour = true
          }else if (this.privileged[p].privileged_id == 5) {
            this.statusfive = true
          }else if (this.privileged[p].privileged_id == 6) {
            this.statussix = true
          }else if (this.privileged[p].privileged_id == 7) {
            this.statusseven = true
          }else if (this.privileged[p].privileged_id == 8) {
            this.statuseight = true
          }else if (this.privileged[p].privileged_id == 9) {
            this.statusnine = true
          }else if (this.privileged[p].privileged_id == 10) {
            this.statusten = true
          }          
        }
      });
    },
    exportexcel() {
      this.generateexcel();
      document.getElementById("excel").click();
    },
    generatecolumn() {
      this.columns = []
      this.columns.push(
        {
          label: "ลำดับที่",
          field: "no",
        },
        {
          label: "คำนำหน้า",
          field: "prefix",
        },
        {
          label: "ชื่อ",
          field: "firstname",
        },
        {
          label: "สกุล",
          field: "lastname",
        },
        {
          label: "เลขบัตรประจำตัวประชาชน",
          field: "id_card",
        },
        {
          label: "บ้านเลขที่",
          field: "no_house",
        },
        {
          label: "หมู่ที่",
          field: "village_no_house",
        },
        {
          label: "ตำบล",
          field: "sub_district_house",
        },
        {
          label: "อำเภอ",
          field: "district_house",
        },
        {
          label: "จังหวัด",
          field: "province_house",
        },
        {
          label: "ลักษณะเหตุการณ์",
          field: "event_type_name",
        },
        {
          label: "วันควบคุมตัว",
          field: "detained_start_date",
        },
        {
          label: "วันปล่อยตัว",
          field: "detained_end_date",
        },
        {
          label: "ระยะเวลา (วัน)",
          field: "day",
        },
      );      
      for (let p = 0; p < this.privileged.length; p++) {
        var fieldname = ''
        if (this.privileged[p].privileged_id == 1) {
          fieldname = 'one'
        }
        if (this.privileged[p].privileged_id  == 2) {
          fieldname = 'two'
        }
        if (this.privileged[p].privileged_id  == 3) {
          fieldname = 'three'
        }
        if (this.privileged[p].privileged_id  == 4) {
          fieldname = 'four'
        }
        if (this.privileged[p].privileged_id  == 5) {
          fieldname = 'five'
        }
        if (this.privileged[p].privileged_id  == 6) {
          fieldname = 'six'
        }
        if (this.privileged[p].privileged_id  == 7) {
          fieldname = 'seven'
        }
        if (this.privileged[p].privileged_id  == 8) {
          fieldname = 'eight'
        }
        if (this.privileged[p].privileged_id  == 9) {
          fieldname = 'nine'
        }
        if (this.privileged[p].privileged_id  == 10) {
          fieldname = 'ten'
        }
        this.columns.push(
          {
          label: this.privileged[p].name + ' (บาท)',
          field: fieldname
        },
        );
      }
      this.columns.push(
        {
          label: "รวมเป็นเงินที่ได้รับ (บาท)",
          field: "amount",
        },
        {
          label: "หน่วยงานที่ควบคุมตัว",
          field: "department_detained_type_name",
        }
      );
    },
    generateexcel() {
      this.data = [];
      this.data = this.list;
      // console.log(this.columns);
      // console.log(this.data);
    },
    checktrue(status) {
      if (status == false) {
        for (let li = 0; li < this.list.length; li++) {
          this.list[li].statuscheck = true;
        }
      } else {
        for (let li = 0; li < this.list.length; li++) {
          this.list[li].statuscheck = false;
        }
      }
    },
    create_ref() {
      // console.log(this.list);
      if (this.ref == "") {
        alert("กรุณากรอกเลขหนังสืออ้างอิง");
      } else {
        if (this.payment_id == 0) {
          var payment = {
            ref_no: this.ref,
            status_id: 7,
            created_by: this.currentUser.user_id,
            updated_by: this.currentUser.user_id,
          };
          PaymentDataService.creatPayment(payment).then((res) => {
            // console.log(res.data.id);
            this.payment_id = res.data.id;
            for (let li = 0; li < this.list.length; li++) {
              if (this.list[li].no != "" && this.list[li].statuscheck == true) {
                var data = {
                  payment_id: this.payment_id,
                  case_id: this.list[li].case_id,
                  created_by: this.currentUser.user_id,
                  updated_by: this.currentUser.user_id,
                };
                PaymentCaseDataService.createPaymentCase(data).then((res) => {
                  // console.log(res.data);
                  var save = {
                    status_id: 7,
                    updated_by: this.currentUser.user_id,
                  };
                  CasesDataService.updateCheck(res.data.case_id, save).then(
                    () => {
                      var now = ConvertService.getdate(Date.now());
                      now = ConvertService.createddate(now);
                      var data = {
                        status_id: 7,
                        case_id: res.data.case_id,
                        created_by: this.currentUser.user_id,
                        created_date: now,
                      };
                      HistoryCaseDataService.createHistoryCase(data).then(
                        (res) => {
                          // console.log(res.data);
                          var data={
            case_id: res.data.case_id,
            history_status_id:11,
            user_id:this.currentUser.user_id
          }
          HistoryCaseDataService.createHistory(data).then(()=>{

          })
                        }
                      );
                    }
                  );
                });
              }
            }
            alert("บันทึกสำเร็จ");
            this.ref_no = this.ref;
            this.search();
          });
        } else {
          PaymentCaseDataService.deletePaymentCases(this.payment_id).then(
            () => {
              var payment = {
                ref_no: this.ref,
                status_id: 7,
                created_by: this.currentUser.user_id,
                updated_by: this.currentUser.user_id,
              };
              PaymentDataService.updatePayment(this.payment_id, payment).then(
                () => {
                  for (let li = 0; li < this.list.length; li++) {
                    if (
                      this.list[li].no != "" &&
                      this.list[li].statuscheck == true
                    ) {
                      var data = {
                        payment_id: this.payment_id,
                        case_id: this.list[li].case_id,
                        created_by: this.currentUser.user_id,
                        updated_by: this.currentUser.user_id,
                      };
                      var case_id = this.list[li].case_id
                      PaymentCaseDataService.createPaymentCase(data).then(
                        () => {
                          HistoryCaseDataService.getHistoryCaseByStatusID(this.case_id, 1).then(
            (res) => {
              // console.log(res.data);
              if (res.data == "") {
                          var save = {
                            status_id: 7,
                            updated_by: this.currentUser.user_id,
                          }; 
                          // console.log(case_id);
                          CasesDataService.updateCheck(
                            this.case_id,
                            save
                          ).then(() => {
                            // // console.log(res.data);
                          });
                          var now = ConvertService.getdate(Date.now());
                          now = ConvertService.createddate(now);
                          var data = {
                            status_id: 7,
                            case_id: case_id,
                            created_by: this.currentUser.user_id,
                            created_date: now,
                          };
                          HistoryCaseDataService.createHistoryCase(data).then(
                            () => {
                              // console.log(res.data);
                            }
                          );                          
              }});
                        }                        
                      );
                    } else if (
                      this.list[li].no != "" &&
                      this.list[li].statuscheck == false
                    ) {
                      var save = {
                        status_id: 6,
                        updated_by: this.currentUser.user_id,
                      };
                      CasesDataService.updateCheck(
                        this.list[li].case_id,
                        save
                      ).then(() => {
                        var now = ConvertService.getdate(Date.now());
                        now = ConvertService.createddate(now);
                        var data = {
                          status_id: 6,
                          case_id: this.list[li].case_id,
                          created_by: this.currentUser.user_id,
                          created_date: now,
                        };
                        HistoryCaseDataService.createHistoryCase(data).then(
                          () => {
                            // console.log(res.data);
                          }
                        );
                      });
                    }
                  }                  
          alert("บันทึกสำเร็จ");
          this.ref_no = this.ref;
          this.search();
                }
              );
            }
          );
        }
      }
    },
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    },
    getstatus() {
      StatusDataService.getStatus().then((res) => {
        this.status = res.data;
      });
    },
    convertdatetothai(datetimes) {
      if (datetimes) {
        const date = new Date(datetimes);
        const result = date.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        // const myArray = result.split(" ");
        // var day = this.thaiNumber(parseInt(myArray[0]));
        // var month = myArray[1];
        // var year = this.thaiNumber(parseInt(myArray[2]));
        // var val = day + " " + month + " " + year;
        return result;
      } else {
        return "-";
      }
    },
    formatCurrency(number) {
      number = parseFloat(number);
      return number.toFixed(2).replace(/./g, function (c, i, a) {
        return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
      });
    },
    cal(start, end) {
      var date1 = new Date(start);
      var date2 = new Date(end);
      var diffTime = date2.getTime() - date1.getTime();
      var diffDay = diffTime / (1000 * 3600 * 24);
      return diffDay;
    },
    thaiNumber(num) {
      var array = {
        1: "๑",
        2: "๒",
        3: "๓",
        4: "๔",
        5: "๕",
        6: "๖",
        7: "๗",
        8: "๘",
        9: "๙",
        0: "๐",
      };

      if (num == null) {
        str = "-";
      } else {
        var str = num.toString();
        for (var val in array) {
          str = str.split(val).join(array[val]);
        }
      }
      return str;
    },
    getCase() {
      if (this.start != 0) {
        PaymentDataService.getPaymentAllDate(this.start, this.end).then(
          (res) => {
            // console.log(res.data);
            this.list = res.data;
            this.getprivil();          
          // this.getamount();
          this.managecontent()
            // this.checktrue(false);
          }
        );
        // // console.log(this.filename);
      } else {
        PaymentDataService.getPaymentAll().then((res) => {
          console.log(res.data);
          this.list = res.data;
          this.getprivil();          
          // this.getamount();
          this.managecontent()
          this.privilegedall = this.list
          // this.checktrue(false);
        });
      }
    },
    managecontent(){      
          var no = 0;
          if (this.list.length != 0) {
            this.filename = this.list[0].filing_date;
            this.filename = "ขออนุมัติเบิกเงิน_" + this.filename;
          }
          for (let l = 0; l < this.list.length; l++) {
            this.list[l].day =
              this.cal(
                this.list[l].detained_start_date,
                this.list[l].detained_end_date
              );
            this.list[l].detained_start_date = this.convertdatetothai(
              this.list[l].detained_start_date
            );
            this.list[l].detained_end_date = this.convertdatetothai(
              this.list[l].detained_end_date
            );
            // if (this.list[l].privileged_id == 1 || this.list[l].privileged_id == 3) {
            //   this.list[l].one = this.list[l].amount
            //   this.list[l].two = 0 ;
            // } else if (this.list[l].privileged_id == 2 || this.list[l].privileged_id == 4) {
            //   this.list[l].two = this.list[l].amount
            //   this.list[l].one = 0 ;
            // }
            // // console.log(this.list[l].case_id,this.list[l].one);

            if (this.list[l].one == null) {
              this.list[l].one = 0
            }
            if (this.list[l].two == null) {
              this.list[l].two = 0
            }
            if (this.list[l].three == null) {
              this.list[l].three = 0
            }
            if (this.list[l].four == null) {
              this.list[l].four = 0
            }
            if (this.list[l].five == null) {
              this.list[l].five = 0
            }
            if (this.list[l].six == null) {
              this.list[l].six = 0
            }
            if (this.list[l].seven == null) {
              this.list[l].seven = 0
            }
            if (this.list[l].eight == null) {
              this.list[l].eight = 0
            }
            if (this.list[l].nine == null) {
              this.list[l].nine = 0
            }
            if (this.list[l].ten == null) {
              this.list[l].ten = 0
            }
            if (l != 0) {
              // console.log(this.list[l].case_id, this.list[l - 1].case_id);
               if (
            this.list[l].case_id == this.list[l - 1].case_id &&
            this.list[l].event_type_id == this.list[l - 1].event_type_id
          ) {
            // console.log(this.list[l]);
            if (this.list[l].one) {
              // console.log(1);
              this.list[l-1].one = this.list[l].one
              this.list[l].one = 0
              // this.list.splice(l, 1);
            }
            else if (this.list[l].two) {
              // console.log(2);
              this.list[l - 1].two = this.list[l].two;
              this.list[l].two = 0;
              // this.list.splice(l, 1);
            }
            else if (this.list[l].three) {
              // console.log(3);
              this.list[l - 1].three = this.list[l].three;
              this.list[l].three = 0;
              // this.list.splice(l, 1);
            }
            else if (this.list[l].four) { 
              // console.log(4);
              this.list[l - 1].four = this.list[l].four;
              this.list[l].four = 0;
              // this.list.splice(l, 1);
            }
          }
              if (this.list[l].case_id == this.list[l - 1].case_id) {
                this.list[l].prefix = "";
                this.list[l].firstname = "";
                this.list[l].lastname = "";
                this.list[l].id_card = "";
                this.list[l].village_no_house = "";
                this.list[l].no_house = "";
                this.list[l].sub_district_house = "";
                this.list[l].district_house = "";
                this.list[l].province_house = "";
                if (this.list[l].case_id == this.list[l - 1].case_id) {
                  this.list[l].no = "";
                  // this.list[l].span = 9
                  // this.list[l].prefix =  "รวมเป็นเวลา "+ this.list[l].sumday+" วัน เป็นเงิน " +this.list[l].sumamount.toLocaleString() + " บาท"
                }
              } else {
                no = no + 1;
                this.list[l].no = no;
              }
            } else {
              no = no + 1;
              this.list[l].no = no;
            }
            if (this.list[l].village_no_house == null) {
              this.list[l].village_no_house = "-"
            }
            this.list[l].statuscheck = true;
            this.statusinput = true;
          }
          for (let li = 0; li < this.list.length; li++) {
             if (li != 0) {
               if (
            this.list[li].case_id == this.list[li - 1].case_id &&
            this.list[li].event_type_id == this.list[li - 1].event_type_id
          ) {
            // console.log(this.list[li]);
            if (this.list[li].one) {
              // console.log(1);
              this.list[li-1].one = this.list[li].one
              this.list[li].one = 0
              // this.list.splice(l, 1);
            }
            else if (this.list[li].two) {
              // console.log(2);
              this.list[li - 1].two = this.list[li].two;
              this.list[li].two = 0;
              // this.list.splice(l, 1);
            }
            else if (this.list[li].three) {
              // console.log(3);
              this.list[li - 1].three = this.list[li].three;
              this.list[li].three = 0;
              // this.list.splice(l, 1);
            }
            else if (this.list[li].four) { 
              // console.log(4);
              this.list[li - 1].four = this.list[li].four;
              this.list[li].four = 0;
              // this.list.splice(l, 1);
            }
          }
            }
            
          }
          for (let lr = 0; lr < this.list.length; lr++) {            
            if (lr != 0) {
              if ( this.list[lr].case_id == this.list[lr - 1].case_id &&
            this.list[lr].event_type_id == this.list[lr - 1].event_type_id) {
            //   console.log(this.list[lr].case_id);
            // console.log(this.list[lr].event_type_id);
          this.list.splice(lr, 1);
        }              
            }        
      }
          for (let ll = 0; ll < this.list.length; ll++) {
            // console.log(this.list[ll].amount);            
             this.list[ll].amount = 
              (this.list[ll].one+this.list[ll].two+this.list[ll].three+this.list[ll].four+this.list[ll].five+this.list[ll].six+this.list[ll].seven+this.list[ll].eight+this.list[ll].nine+this.list[ll].ten).toLocaleString()
            this.list[ll].one =  this.list[ll].one.toLocaleString()
            this.list[ll].two =  this.list[ll].two.toLocaleString()
            this.list[ll].three =  this.list[ll].three.toLocaleString()
            this.list[ll].four =  this.list[ll].four.toLocaleString()
            this.list[ll].five =  this.list[ll].five.toLocaleString()
            this.list[ll].six =  this.list[ll].six.toLocaleString()
            this.list[ll].seven =  this.list[ll].seven.toLocaleString()
            this.list[ll].eight =  this.list[ll].eight.toLocaleString()
            this.list[ll].nine =  this.list[ll].nine.toLocaleString()
            this.list[ll].ten =  this.list[ll].ten.toLocaleString()      
      } 
      for (let lr = 0; lr < this.list.length; lr++) {            
            if (lr != 0) {
              if ( this.list[lr].case_id == this.list[lr - 1].case_id &&
            this.list[lr].event_type_id == this.list[lr - 1].event_type_id) {
            //   console.log(this.list[lr].case_id);
            // console.log(this.list[lr].event_type_id);
          this.list.splice(lr, 1);
        }              
            }        
      }
    },
    getamounts(case_id, event_type_id) {
      this.one = 0;
      this.two = 0;
      this.three = 0;
      this.four = 0;
      PrivilegeDataService.getPrivilegeByEvent(case_id, event_type_id).then(
        (res) => {
          // one = 0
          // two = 0
          // three = 0
          // four = 0
          for (let re = 0; re < res.data.length; re++) {
            for (let pp = 0; pp < this.privileged.length; pp++) {
              if (
                this.privileged[pp].privileged_id == res.data[re].privileged_id
              ) {
                if (pp == 0) {
                  this.one = res.data[re].amount;
                }
                if (pp == 1) {
                  this.two = res.data[re].amount;
                }
                if (pp == 2) {
                  this.three = res.data[re].amount;
                }
                if (pp == 3) {
                  this.four = res.data[re].amount;
                }
              }
            }
          }
          // console.log(this.one, this.two, this.three, this.four);
          return 0;
          // {o:'one',
          // t:'two',
          // tt:'three',
          // ff:'four'};
          // this.list[li].one = one
          // this.list[li].two = two
          // this.list[li].three = three
          // this.list[li].four = four
        }
      );
    },

    getamount() {
      for (let li = 0; li < this.list.length; li++) {
        this.one = 0;
        this.two = 0;
        this.three = 0;
        this.four = 0;
        PrivilegeDataService.getPrivilegeByEvent(
          this.list[li].case_id,
          this.list[li].event_type_id
        ).then((res) => {
          this.one = 0;
        this.two = 0;
        this.three = 0;
        this.four = 0;
          this.geta(res.data)
          this.generateamount(this.list[li].case_id,this.list[li].event_type_id)
        });        
      }      
          this.managecontent()
          this.privilegedall = this.list
    },
    geta(res){
      // console.log(this.privileged);
for (let re = 0; re < res.length; re++) {
            for (let pp = 0; pp < this.privileged.length; pp++) {
              if (
                this.privileged[pp].privileged_id == res[re].privileged_id
              ) {
                if (pp == 0) {
                  this.one = res[re].amount;
                }
                if (pp == 1) {
                  this.two = res[re].amount;
                }
                if (pp == 2) {
                  this.three = res[re].amount;
                }
                if (pp == 3) {
                  this.four = res[re].amount;
                }
              }
            }
          }
    },
    generateamount(c,e){
for (let lll = 0; lll < this.list.length; lll++) {
  if (this.list[lll].case_id == c &&
  this.list[lll].event_type_id == e) {
    this.list[lll].one = this.one;
    this.list[lll].two = this.two;
    this.list[lll].three = this.three;
    this.list[lll].four = this.four;
  }
}
    },
    hasHistory() {
      return window.history.length > 2;
    },
    search() {
      this.list = [];
      PaymentDataService.getPaymentAllName(this.ref_no).then((res) => {
        // console.log(res.data);
        this.list = res.data;
        if (this.list.length != 0) {
          this.filename = this.list[0].filing_date;            
            this.filename = "ขออนุมัติเบิกเงิน_" + this.filename;
          this.payment_id = this.list[0].id;
        }
        this.getprivil();          
          // this.getamount();
          this.managecontent()

        this.statusinput = true;
        // this.checktrue(false);
        // // console.log(this.payment_id);
        PaymentDataService.getPaymentName(this.payment_id).then((res) => {
          // // console.log(res.data);
          this.ref = res.data.ref_no;
        });
      });
    },
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  font-family: "Kanit" !important;
}
.row {
  max-width: 1500px;
  margin: auto;
  background: white;
  padding: 10px;
  color: black !important;
}
a:visited {
  color: black;
} /* visited link */
a:hover {
  color: black;
} /* mouse over link */
a:active {
  color: black;
}
a:link {
  color: black;
}
</style>
