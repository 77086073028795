<template>
  <div class="hello">
    <div class="mt-5">
      <div class="row card mt-2">
        <div class="card-body p-0 mt-3">
          <h4 style="text-align: center">ค้นหา</h4>
          <div class="row">
            <div style="width: 12%"></div>
            <div class="col-md-9">
              <div class="form-group" style="text-align: left">
                <label for="province"
                  >แสดงรายงาน <span style="color: red"> *</span></label
                >
                <select
                  v-model="type"
                  class="form-select mb-3"
                  @change="getsearchtracking"
                >
                  <option value="1">
                    
                   รายงานแสดงข้อมูลของผู้เสียหายที่อยู่ในแต่ละเหตุการณ์ในลักษณะรายบุคคล
                  </option>
                  <option value="2">
                    รายงานสรุปจำนวนผู้เสียหาย ตามปี จังหวัด และอำเภอ
                  </option>
                  <option value="3">
                    รายงานสรุปจำนวนผู้ได้รับผลกระทบ ตามปี จังหวัด และอำเภอ
                    (ทายาท)
                  </option>
                  <option value="4">
                    รายงานการจ่ายเงินของผู้เสียหายในลักษณะรายบุคคล
                  </option>
                  <option value="5">
                    รายงานการจ่ายเงินประจำปีงบประมาณ
                  </option>
                  <option value="6">
                    รายงานการช่วยเหลือของผู้เสียหายและผู้ได้รับผลกระทบ
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row" id="hideone">
            <div style="width: 12%"></div>
            <div class="col-md-5">
              <div class="form-group" style="text-align: left">
                <label for="province">เลขบัตรประชาชนผู้เสียหาย </label>
                <input class="form-control mb-3" v-model="event.id_card" />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group" style="text-align: left">
                <label for="username"
                  >วันที่ยื่นคำร้อง <span style="color: red"> *</span></label
                >
                <input id="fill_start_date" class="form-control" />
                <!-- <date-picker v-model="date" :config="options"></date-picker> -->
              </div>
            </div>
          </div>
          <div class="row" id="hidetwo">
            <div style="width: 12%"></div>
            <div class="col-md-4">
              <div class="form-group" style="text-align: left">
                <label for="username"
                  >วันที่ยื่นคำร้อง <span style="color: red"> *</span></label
                >
                <input id="fill_start_date_two" class="form-control" />
                <!-- <date-picker v-model="date" :config="options"></date-picker> -->
              </div>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-4">
              <div class="form-group" style="text-align: left">
                <label for="username"
                  >ถึงวันที่ยื่นคำร้อง <span style="color: red"> *</span></label
                >
                <input id="fill_end_date_two" class="form-control" />
                <!-- <date-picker v-model="date" :config="options"></date-picker> -->
              </div>
            </div>
          </div>
          <div class="row" id="hidethree">
            <div style="width: 12%"></div>
            <div class="col-md-4">
              <div class="form-group" style="text-align: left">
                <label for="username"
                  >วันที่ยื่นคำร้อง <span style="color: red"> *</span></label
                >
                <input id="fill_start_date_three" class="form-control" />
                <!-- <date-picker v-model="date" :config="options"></date-picker> -->
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group" style="text-align: left">
                <label for="username"
                  >ถึงวันที่ยื่นคำร้อง <span style="color: red"> *</span></label
                >
                <input id="fill_end_date_three" class="form-control" />
                <!-- <date-picker v-model="date" :config="options"></date-picker> -->
              </div>
            </div>
          </div>
          <div class="row" v-if="type == 2 || type == 3">
            <div style="width: 12%"></div>
            <div class="col-md-3">
              <div class="form-group" style="text-align: left">
                <label for="province">จังหวัดตามทะเบียนบ้าน </label>
                <select
                  class="form-select mb-3"
                  v-model="event.province_house"
                  @change="onChangeProvince"
                  id="province_house"
                >
                  <option
                    v-for="(p, i) in provinces"
                    v-bind:value="p.PROVINCE_ID"
                    :key="i + 2"
                  >
                    {{ p.PROVINCE_NAME }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group" style="text-align: left">
                <label for="province">อำเภอตามทะเบียนบ้าน</label>
                <select
                  class="form-select mb-3"
                  v-model="event.district_house"
                  @change="onChangeDistrict"
                  id="district_house"
                >
                  <option
                    v-for="(a, i) in amphures"
                    v-bind:value="a.AMPHUR_ID"
                    :key="i + 3"
                  >
                    {{ a.AMPHUR_NAME }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group" style="text-align: left">
                <label for="province">ตำบลตามทะเบียนบ้าน</label>
                <select
                  class="form-select mb-3"
                  v-model="event.sub_district_house"
                  id="sub_district_house"
                >
                  <option
                    v-for="(d, i) in districts"
                    v-bind:value="d.DISTRICT_ID"
                    :key="i + 4"
                    :name="d.DISTRICT_NAME"
                  >
                    {{ d.DISTRICT_NAME }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row" v-if="type == 4">
            <div style="width: 12%"></div>
            <div class="col-md-5">
              <div class="form-group" style="text-align: left">
                <label for="province">เลขบัตรประชาชนผู้เสียหาย </label>
                <input class="form-control mb-3" v-model="event.id_card" />
              </div>
            </div>
          </div>
          <div class="row" id="hidefive">
            <!-- <div style="width: 12%"></div>
            <div class="col-md-4">
              <div class="form-group" style="text-align: left">
                <label for="username"
                  >วันที่ยื่นคำร้อง <span style="color: red"> *</span></label
                >
                <input id="fill_start_date_five" class="form-control" />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group" style="text-align: left">
                <label for="username"
                  >ถึงวันที่ยื่นคำร้อง <span style="color: red"> *</span></label
                >
                <input id="fill_end_date_five" class="form-control" />
              </div>
            </div> -->
            <div class="col-md-12"></div>
            <div style="width: 12%"></div>
            <div class="col-md-4">
              <div class="form-group" style="text-align: left">
                <label for="username"
                  >วันที่จ่ายเงิน <span style="color: red"> *</span></label
                >
                <input id="payment_start_date" class="form-control" />
                <!-- <date-picker v-model="date" :config="options"></date-picker> -->
              </div>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-4">
              <div class="form-group" style="text-align: left">
                <label for="username"
                  >ถึงวันที่จ่ายเงิน <span style="color: red"> *</span></label
                >
                <input id="payment_end_date" class="form-control" />
                <!-- <date-picker v-model="date" :config="options"></date-picker> -->
              </div>
            </div>
          </div>
          <div class="row" id="hidesix">
            <div style="width: 12%"></div>
            <div class="col-md-4">
              <div class="form-group" style="text-align: left">
                <label for="username"
                  >วันที่ยื่นคำร้อง <span style="color: red"> *</span></label
                >
                <input id="fill_start_date_six" class="form-control" />
                <!-- <date-picker v-model="date" :config="options"></date-picker> -->
              </div>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-4">
              <div class="form-group" style="text-align: left">
                <label for="username"
                  >ถึงวันที่ยื่นคำร้อง <span style="color: red"> *</span></label
                >
                <input id="fill_end_date_six" class="form-control" />
                <!-- <date-picker v-model="date" :config="options"></date-picker> -->
              </div>
            </div>
          </div>
            <div class="row" v-if="type != 4 && type != 5">
          <div style="width: 12%"></div>
          <div class="col-md-4">
            <div class="form-group" style="text-align: left">
              <label for="province">สถานะ </label>
               <multiselect 
    select-Label=""
    selected-Label=""
    deselect-Label=""
    v-model="value" 
    :options="status"
    :multiple="true"
    track-by="name"
    :custom-label="customLabel"
    :close-on-select="false"
    @select=onSelect($event)
    @remove=onRemove($event)
    >
    <span class="checkbox-label" slot="option" slot-scope="scope" @click.self="select(scope.option)">
    {{ scope.option.name }}
      <input class="test" type="checkbox" v-model="scope.option.checked" @focus.prevent/>
      
    </span>
  </multiselect>
            </div>
          </div>
        </div>
          <div class="col text-center">
            <button
              @click="search()"
              class="btn btn-primary mb-3"
              style="color: white"
            >
              <i class="fas fa-search"></i> ค้นหา
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-5" v-if="nodata">
      <div class="mb-3" style="text-align: right" v-if="type == 1">
            <button
              v-if="columns"
              class="btn btn-success"
              style="color: white"
              @click="exportTableToExcel('tblData')"
            >
              Export
            </button>
          </div>
      <div class="card-body p-0">
        <div class="row">
          <div class="col-md-12 mt-3">
            <h5 class="form-group" style="color: #2da3f2; font-weight: bold">
              {{ typename }}
            </h5>
          </div>
        </div>
        <div v-if="type == 1">
          <table class="table table-bordered" id="tblData">
            <thead>
              <tr>
                  <td rowspan="2" align="center">ลำดับ</td>
                  <td rowspan="2" align="center">วันที่ยื่น</td>
                  <td rowspan="2" align="center">เลขบัตรประชาชน</td>
                  <td rowspan="2" align="center">คำนำหน้า</td>
                  <td rowspan="2" align="center">ชื่อ-สกุล</td>
                  <td rowspan="2" align="center">วัน/เดือน/ปีเกิด</td>
                  <td rowspan="2" align="center">เบอร์โทร</td>
                  <td rowspan="2" align="center">เพศ</td>
                  <td rowspan="2" align="center">ประเภทกลุ่มผู้เสียหาย</td>
                  <td rowspan="2" align="center">อาชีพ</td>
                  <td rowspan="2" align="center">รายได้</td>
                  <td rowspan="2" align="center">สัญชาติ</td>
                  <td colspan="9" style="text-align: center">
                    ที่อยู่ตามทะเบียนบ้าน
                  </td>
                  <td colspan="9" style="text-align: center">
                    ที่อยู่ปัจจุบัน
                  </td>
                  <td colspan="6" style="text-align: center">
                    ข้อมูลลักษณะเหตุการณ์
                  </td>
                  <td colspan="2" style="text-align: center">
                    ข้อมูลเหตุการณ์
                  </td>
                  <td colspan="6" style="text-align: center">
                    ความเสียหายที่ได้รับ
                  </td>
                </tr>
                <tr>
                  <td style="text-align: center">บ้านเลขที่</td>
                  <td style="text-align: center">หมู่ที่</td>
                  <td style="text-align: center">ซอย</td>
                  <td style="text-align: center">ถนน</td>
                  <td style="text-align: center">หมู่บ้าน</td>
                  <td style="text-align: center">ตำบล</td>
                  <td style="text-align: center">อำเภอ</td>
                  <td style="text-align: center">จังหวัด</td>
                  <td style="text-align: center">รหัสไปรษณีย์</td>
                  <td style="text-align: center">บ้านเลขที่</td>
                  <td style="text-align: center">หมู่ที่</td>
                  <td style="text-align: center">ซอย</td>
                  <td style="text-align: center">ถนน</td>
                  <td style="text-align: center">หมู่บ้าน</td>
                  <td style="text-align: center">ตำบล</td>
                  <td style="text-align: center">อำเภอ</td>
                  <td style="text-align: center">จังหวัด</td>
                  <td style="text-align: center">รหัสไปรษณีย์</td>
                  <td style="text-align: center">ลักษณะเหตุการณ์</td>
                  <td style="text-align: center">วันที่ควบคุมตัว</td>
                  <td style="text-align: center">วันที่ปล่อยตัว</td>
                  <td style="text-align: center">จำนวนวัน</td>
                  <td style="text-align: center">หน่วยงานที่ควบคุมตัว</td>
                  <td style="text-align: center">สถานที่</td>
                  <td style="text-align: center">วันที่เกิดเหตุ</td>
                  <td style="text-align: center">จุดเกิดเหตุการณ์</td>
                  <td
                    style="text-align: center"
                    v-for="(p, i) in privileged"
                    :key="'P' + i"
                  >
                    {{ p.name }}
                  </td>
                </tr>
            </thead>
            <tbody>
             <tr v-for="(l, i) in list" :key="i">
                  <td style="text-align: right">{{ l.no }}</td>
                  <!-- <td :colspan="l.span" style="text-align:center">{{ l.prefix }}</td>
                  <td v-if="l.firstname">{{ l.firstname }}</td>
                  <td v-if="l.lastname">{{ l.lastname }}</td>
                  <td v-if="l.id_card">{{ l.id_card }}</td>
                  <td v-if="l.no_house">{{ l.no_house }}</td>
                  <td v-if="l.village_no_house">{{ l.village_no_house }}</td>
                  <td v-if="l.sub_district_house">{{ l.sub_district_house }}</td>
                  <td v-if="l.district_house">{{ l.district_house }}</td>
                  <td v-if="l.province_house">{{ l.province_house }}</td> -->
                  <td style="text-align: right">{{ l.filing_date }}</td>
                  <td style="text-align: right"><span style="color:white">'</span>{{ l.id_card }}</td>
                  <td style="text-align: left">{{ l.prefix }}</td>
                  <td style="text-align: left">{{ l.name }}</td>
                  <td style="text-align: right">{{ l.birthday }}</td>
                  <td style="text-align: right"><span style="color:white">'</span>{{ l.phone }}</td>
                  <td style="text-align: left">{{ l.gender }}</td>
                  <td style="text-align: left">{{ l.suspect_types }}</td>
                  <td style="text-align: left">{{ l.career }}</td>
                  <td style="text-align: right">{{ l.income }}</td>
                  <td style="text-align: left">{{ l.nationality }}</td>
                  <td style="text-align: right">{{ l.village_no_house }}</td>
                  <td style="text-align: right">{{ l.no_house }}</td>
                  <td style="text-align: left">{{ l.road_house }}</td>
                  <td style="text-align: left">{{ l.lane_house }}</td>
                  <td style="text-align: left">{{ l.village_house }}</td>
                  <td style="text-align: left">{{ l.sub_district_house }}</td>
                  <td style="text-align: left">{{ l.district_house }}</td>
                  <td style="text-align: left">{{ l.province_house }}</td>
                  <td style="text-align: right">{{ l.postal_code_house }}</td>
                  <td style="text-align: right">{{ l.village_no_current }}</td>
                  <td style="text-align: right">{{ l.no_current }}</td>
                  <td style="text-align: left">{{ l.road_current }}</td>
                  <td style="text-align: left">{{ l.lane_current }}</td>
                  <td style="text-align: left">{{ l.village_current }}</td>
                  <td style="text-align: left">
                    {{ l.sub_district_current }}
                  </td>
                  <td style="text-align: left">{{ l.district_current }}</td>
                  <td style="text-align: left">{{ l.province_current }}</td>
                  <td style="text-align: right">
                    {{ l.postal_code_current }}
                  </td>
                  <td style="text-align: left">{{ l.event_type_name }}</td>
                  <td style="text-align: right">
                    {{ l.detained_start_date }}
                  </td>
                  <td style="text-align: right">{{ l.detained_end_date }}</td>
                  <td style="text-align: right">{{ l.day }}</td>
                  <td style="text-align: left">
                    {{ l.department_detained_type_name }}
                  </td>
                  <td style="text-align: left">{{ l.event_id }}</td>
                  <td style="text-align: right">{{ l.crime_date }}</td>
                  <td style="text-align: left">{{ l.crime_scene }}</td>
                  <td style="text-align: center" v-if="statusone">
                    {{ l.one }}
                  </td>
                  <td style="text-align: center" v-if="statustwo">
                    {{ l.two }}
                  </td>
                  <td style="text-align: center" v-if="statusthree">
                    {{ l.three }}
                  </td>
                  <td style="text-align: center" v-if="statusfour">
                    {{ l.four }}
                  </td>
                  <td style="text-align: center" v-if="statusfive">
                    {{ l.five }}
                  </td>
                  <td style="text-align: center" v-if="statussix">
                    {{ l.six }}
                  </td>
                  <td style="text-align: center" v-if="statusseven">
                    {{ l.seven }}
                  </td>
                  <td style="text-align: center" v-if="statuseight">
                    {{ l.eight }}
                  </td>
                  <td style="text-align: center" v-if="statusnine">
                    {{ l.nine }}
                  </td>
                  <td style="text-align: center" v-if="statusten">
                    {{ l.ten }}
                  </td>
                </tr>
            </tbody>
          </table>
        </div>
        <div class="row" v-if="type == 2 || type == 3">
           <div class="mb-3" style="text-align: right">
            <button
              v-if="columns"
              class="btn btn-success"
              style="color: white"
              @click="exportTableToExcel('tblData2')"
            >
              Export
            </button>
          </div>
          <div class="col-md-12 mt-3" id="tblData2">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th
                    scope="col"
                    style="text-align: center; background-color: #d9d9d9"
                    colspan="4"
                  >
                    {{ typename }}
                  </th>
                </tr>
                <tr>
                  <th
                    scope="col"
                    style="text-align: center; background-color: #d9d9d9"
                    colspan="4"
                  >
                    วันที่ยื่นคำร้อง {{fillstart}}  ถึง {{fillend}}
                  </th>
                </tr>
                <tr>
                  <th
                    scope="col"
                    style="text-align: center; background-color: #d9d9d9"
                    colspan="4"
                  >
                    ตำบล/อำเภอ/จังหวัดตามทะเบียนบ้าน
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th style="text-align: center" scope="row">พ.ศ.</th>
                  <th
                    style="text-align: center"
                    v-for="(h, i) in head"
                    :key="'H' + i"
                  >
                    {{ h.name }}
                  </th>
                </tr>
                <tr v-for="(y, i) in year" :key="'Y' + i">
                  <td style="text-align: right" scope="row">{{ y.year }}</td>
                  <td
                    style="text-align: right"
                    v-for="(s, i) in y.data"
                    :key="'S' + i"
                  >
                    {{ s || 0 }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row" v-if="type == 4">
           <div class="mb-3" style="text-align: right">
            <button
              v-if="columns"
              class="btn btn-success"
              style="color: white"
              @click="exportTableToExcel('tblData4')"
            >
              Export
            </button>
          </div>
          <div class="col-md-12 mt-3" id="tblData4">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th
                    scope="col"
                    style="text-align: center; background-color: #d9d9d9"
                    colspan="5"
                  >
                    {{ typename }}
                  </th>
                </tr>
                <tr>
                  <th
                    scope="col"
                    style="text-align: center; background-color: #d9d9d9"
                    colspan="5"
                  >
                    เลขบัตรประชาชน {{ event.id_card }}
                  </th>
                </tr>
                <tr>
                  <th
                    scope="col"
                    style="text-align: center; background-color: #d9d9d9"
                    colspan="5"
                  >
                    {{ event.firstname }} {{ event.lastname }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th style="text-align: center" scope="row">วันที่ยื่น</th>
                  <th
                    style="text-align: center"
                    v-for="(h, i) in head"
                    :key="'H' + i"
                  >
                    {{ h.name }}
                  </th>
                  <th style="text-align: center">รวม</th>
                </tr>
                <tr v-for="(y, i) in year" :key="'Y' + i">
                  <td style="text-align: right" scope="row">
                    {{ convertdatetothai(y.filldate) }}
                  </td>
                  <td style="text-align: right">
                    {{ y.data1.toLocaleString() || 0 }}
                  </td>
                  <td style="text-align: right">
                    {{ y.data2.toLocaleString() || 0 }}
                  </td>
                  <td style="text-align: right">
                    {{ y.data3.toLocaleString() || 0 }}
                  </td>
                  <td style="text-align: right">
                    {{ (y.data1 + y.data2 + y.data3).toLocaleString() }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row" v-if="type == 5">
          <div class="mb-3" style="text-align: right">
            <button
              v-if="columns"
              class="btn btn-success"
              style="color: white"
              @click="exportTableToExcel('tblData5')"
            >
              Export
            </button>
          </div>
          <div class="col-md-12 mt-3" id="tblData5">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th
                    scope="col"
                    style="text-align: center; background-color: #d9d9d9"
                    colspan="5"
                  >
                    {{ typename }}
                  </th>
                </tr>
                <tr>
                  <th
                    scope="col"
                    style="text-align: center; background-color: #d9d9d9"
                    colspan="5"
                  >
                     วันที่จ่ายเงิน {{fillstart}}  ถึง {{fillend}}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th style="text-align: center" scope="row">ปี</th>
                  <th
                    style="text-align: center"
                    v-for="(h, i) in head"
                    :key="'H' + i"
                  >
                    {{ h.name }}
                  </th>
                  <th style="text-align: center">รวม</th>
                </tr>
                <tr v-for="(y, i) in year" :key="'Y' + i">
                  <td style="text-align: right" scope="row">{{ y.year }}</td>
                  <td
                    style="text-align: right"
                    v-for="(s, i) in y.data"
                    :key="'S' + i"
                  >
                    {{ s.toLocaleString() || 0 }}
                  </td>
                  <td style="text-align: right">
                    {{ y.sum.toLocaleString() }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-if="type == 6">
          <div class="mb-3" style="text-align: right">
            <button
              v-if="columns"
              class="btn btn-success"
              style="color: white"
              @click="exportTableToExcel('tblData6')"
            >
              Export
            </button>
          </div>
          <table class="table table-bordered" id="tblData6">
            <thead>
              <tr>
                <th v-for="(c, i) in columns" :key="i" scope="col">
                  {{ c.label }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(l, i) in list" :key="i">
                <td style="text-align: right">{{ l.no }}</td>
                <td style="text-align: right">{{ l.filing_date }}</td>
                <td style="text-align: right"><span style="color:white">'</span>{{ l.id_card }}</td>
                <td style="text-align: left">{{ l.prefix }}</td>
                <td style="text-align: left">{{ l.name }}</td>
                <td style="text-align: right">{{ l.birthday }}</td>
                <td style="text-align: right"><span style="color:white">'</span>{{ l.phone }}</td>
                <td style="text-align: left">{{ l.gender }}</td>
                <td style="text-align: left">{{ l.suspect_types }}</td>
                <td style="text-align: left">{{ l.career }}</td>
                <td style="text-align: right">{{ l.income }}</td>
                <td style="text-align: left">{{ l.nationality }}</td>
                <td style="text-align: right">{{ l.no_house }}</td>
                <td style="text-align: right">{{ l.village_no_house }}</td>
                <td style="text-align: left">{{ l.sub_district_house }}</td>
                <td style="text-align: left">{{ l.district_house }}</td>
                <td style="text-align: left">{{ l.province_house }}</td>
                <td style="text-align: right">{{ l.postal_code_house }}</td>
                <td style="text-align: right">{{ l.no_current }}</td>
                <td style="text-align: right">{{ l.village_no_current }}</td>
                <td style="text-align: left">{{ l.sub_district_current }}</td>
                <td style="text-align: left">{{ l.district_current }}</td>
                <td style="text-align: left">{{ l.province_current }}</td>
                <td style="text-align: left">{{ l.postal_code_current }}</td>
                <td style="text-align: left">{{ l.onename }}</td>
                <td style="text-align: right">{{ l.one || "-" }}</td>
                <td style="text-align: right">{{ l.two || "-" }}</td>
                <td style="text-align: right">{{ l.three || "-" }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col text-center">
        <button class="btn btn-warning" style="color: white" @click="back()">
          ย้อนกลับ
        </button>
      </div>
    </div>
  </div>
</template>

  <script src="https://justice-api.sbpac.go.th/picker_date.js"></script>

<script>
const customLabels = {
  first: "<<",
  last: ">>",
  previous: "<",
  next: ">",
};

import ConvertService from "../../services/ConvertService";
import CasesEventDataService from "../../services/CasesEventDataService";
import ProvinceDataService from "../../services/ProvinceDataService";
import AmphurDataService from "../../services/AmphurDataService";
import DistrictDataService from "../../services/DistrictDataService";
import EventTypesDataService from "../../services//EventTypesDataService.js";
import ReportDataService from "../../services//ReportDataService";
import DamagesDataService from "../../services/DamagesDataService";
import StatusDataService from "../../services/StatusDataService";
import SuspectsDataService from '../../services/SuspectsDataService';

export default {
  components: {
    Multiselect: window.VueMultiselect.default
    },
  data() {
    return {
      name: "HelloWorld",
      case_id: 0,
      id_card: "",
      columns: [],
      data: [],
      list: [],
      status: [],
      nodata: false,
      event: {},
      provinces: [],
      amphures: [],
      districts: [],
      event_type: [],
      type: 1,
      typename:
        "รายงานแสดงข้อมูลของผู้เสียหายที่อยู่ในแต่ละเหตุการณ์ในลักษณะรายบุคคล",
      head: [],
      year: [],
      statusone: false,
      statustwo: false,
      statusthree: false,
      statusfour: false,
      statusfive: false,
      statussix: false,
      statusseven: false,
      statuseight: false,
      statusnine: false,
      statusten: false,
      privileged:[],
      value: [],
    };
  },
  mounted() {
    var year = new Date().getFullYear()
    var start = year-2004
    var before = "-"+start+":+1"
    picker_date(document.getElementById("fill_start_date"), {
      year_range: before,
    });
    picker_date(document.getElementById("payment_start_date"), {
      year_range: before,
    });
    picker_date(document.getElementById("payment_end_date"), {
      year_range: before,
    });
    picker_date(document.getElementById("fill_start_date_two"), {
      year_range: before,
    });
    picker_date(document.getElementById("fill_end_date_two"), {
      year_range: before,
    });
    picker_date(document.getElementById("fill_start_date_three"), {
      year_range: before,
    });
    picker_date(document.getElementById("fill_end_date_three"), {
      year_range: before,
    });
    picker_date(document.getElementById("fill_start_date_five"), {
      year_range: before,
    });
    picker_date(document.getElementById("fill_end_date_five"), {
      year_range: before,
    });
    picker_date(document.getElementById("fill_start_date_six"), {
      year_range: before,
    });
    picker_date(document.getElementById("fill_end_date_six"), {
      year_range: before,
    });
    this.getProvince();
    this.getAmphur(0);
    this.getDistrict(0);
    this.geteventtype();
    this.getstatus();
    this.event.province_house = 0;
    this.event.district_house = 0;
    this.event.sub_district_house = 0;
  },
  methods: {
    customLabel (option) {
      return `${option.name}`
    },
    onSelect (option) {
      let index = this.status.findIndex(item => item.name==option.name);
      this.status[index].checked = true;
    },    
    onRemove (option) {
      let index = this.status.findIndex(item => item.name==option.name);
      this.status[index].checked = false;
      // console.log(option.name + "  Removed!! " + option.checked);
    },
    getstatus() {
     StatusDataService.getStatus().then((res) => {
        // console.log(res.data);
        for (let s = 0; s < res.data.length; s++) {
          this.status.push({
            status_id: res.data[s].status_id,
            name: res.data[s].name,
            checked:true
          });
        }
      });
      this.value = this.status
      },
    exportTableToExcel(tableID, filename = "") {
      var downloadLink;
      var dataType = "application/vnd.ms-excel";
      var tableSelect = document.getElementById(tableID);
      var tableHTML = tableSelect.outerHTML.replace(/ /g, "%20");

      // Specify file name
      var file = ''
      if (this.type == 1) {
        file = "รายงานแสดงข้อมูลของผู้เสียหายที่อยู่ในแต่ละเหตุการณ์ในลักษณะรายบุคคล.xls"
      }else if (this.type == 2) {
        file = "รายงานสรุปจำนวนผู้เสียหาย ตามปี จังหวัด และอำเภอ.xls"
      }else if (this.type == 3) {
        file = " รายงานสรุปจำนวนผู้ได้รับผลกระทบ ตามปี จังหวัด และอำเภอ (ทายาท).xls"
      }else if (this.type == 4) {
        file = "รายงานการจ่ายเงินของผู้เสียหายในลักษณะรายบุคคล.xls"
      }else if (this.type == 5) {
        file = " รายงานการจ่ายเงินประจำปีงบประมาณ.xls"
      }else if (this.type == 6) {
        file = "รายงานการช่วยเหลือของผู้เสียหายและผู้ได้รับผลกระทบ .xls"
      }
      filename = filename
        ? filename + ".xls"
        : file;

      // Create download link element
      downloadLink = document.createElement("a");

      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        var blob = new Blob(["\ufeff", tableHTML], {
          type: dataType,
        });
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Create a link to the file
        downloadLink.href = "data:" + dataType + ", " + tableHTML;

        // Setting the file name
        downloadLink.download = filename;

        //triggering the function
        downloadLink.click();
      }
    },
    generatecolumn() {
      this.columns = [];
      this.columns.push(
        {
          label: "ลำดับที่",
          field: "no",
          align: "center",
        },
        {
          label: "วันที่ยื่น",
          field: "filing_date",
        },
        {
          label: "เลขบัตรประจำตัวประชาชน",
          field: "id_card",
        },
        {
          label: "คำนำหน้า",
          field: "prefix",
        },
        {
          label: "ชื่อ-สกุล",
          field: "name",
        },
        {
          label: "วัน/เดือน/ปีเกิด",
          field: "birthday",
        },
        {
          label: "เบอร์โทร",
          field: "phone",
        },
        {
          label: "เพศ",
          field: "gender",
        },
        {
          label: "ประเภทกลุ่มผู้เสียหาย",
          field: "suspect_types",
        },
        {
          label: "อาชีพ",
          field: "career",
        },
        {
          label: "รายได้",
          field: "income",
        },
        {
          label: "สัญชาติ",
          field: "nationality",
        },
        {
          label: "บ้านเลขที่",
          field: "no_house",
        },
        {
          label: "หมู่ที่",
          field: "village_no_house",
        },
        {
          label: "ตำบล",
          field: "sub_district_house",
        },
        {
          label: "อำเภอ",
          field: "district_house",
        },
        {
          label: "จังหวัด",
          field: "province_house",
        },
        {
          label: "รหัสไปรษณีย์",
          field: "postal_code_house",
        },
        {
          label: "บ้านเลขที่",
          field: "no_current",
        },
        {
          label: "หมู่ที่",
          field: "village_no_current",
        },
        {
          label: "ตำบล",
          field: "sub_district_current",
        },
        {
          label: "อำเภอ",
          field: "district_current",
        },
        {
          label: "จังหวัด",
          field: "province_current",
        },
        {
          label: "รหัสไปรษณีย์",
          field: "postal_code_current",
        },
        {
          label: "ลักษณะเหตุการณ์",
          field: "event_type_name",
        },
        {
          label: "วันที่ควบคุมตัว",
          field: "detained_start_date",
        },
        {
          label: "วันที่ปล่อยตัว",
          field: "detained_end_date",
        },
        {
          label: "จำนวนวัน",
          field: "day",
        },
        {
          label: "หน่วยงานที่ควบคุมตัว",
          field: "department_detained_type_name",
        },
        {
          label: "สถานที่",
          field: "events",
        },
        {
          label: "วันที่เกิดเหตุการณ์",
          field: "crime_date",
        },
        {
          label: "จุดเกิดเหคุการณ์",
          field: "crime_scene",
        }
      );
      for (let p = 0; p < this.privileged.length; p++) {
        var fieldname = "";
        if (this.privileged[p].damage_type_id == 1) {
          fieldname = "one";
        }
        if (this.privileged[p].damage_type_id == 2) {
          fieldname = "two";
        }
        if (this.privileged[p].damage_type_id == 3) {
          fieldname = "three";
        }
        if (this.privileged[p].damage_type_id == 4) {
          fieldname = "four";
        }
        if (this.privileged[p].damage_type_id == 5) {
          fieldname = "five";
        }
        if (this.privileged[p].damage_type_id == 6) {
          fieldname = "six";
        }
        if (this.privileged[p].damage_type_id == 7) {
          fieldname = "seven";
        }
        if (this.privileged[p].damage_type_id == 8) {
          fieldname = "eight";
        }
        if (this.privileged[p].damage_type_id == 9) {
          fieldname = "nine";
        }
        if (this.privileged[p].damage_type_id == 10) {
          fieldname = "ten";
        }
        this.columns.push({
          label: this.privileged[p].name,
          field: fieldname,
        });
      }
    },
    generatecolumnsix() {
      this.columns = [];
      this.columns.push(
        {
          label: "ลำดับที่",
          field: "no",
          align: "center",
        },
        {
          label: "วันที่ยื่น",
          field: "filing_date",
        },
        {
          label: "เลขบัตรประจำตัวประชาชน",
          field: "id_card",
        },
        {
          label: "คำนำหน้า",
          field: "prefix",
        },
        {
          label: "ชื่อ-สกุล",
          field: "name",
        },
        {
          label: "วัน/เดือน/ปีเกิด",
          field: "birthday",
        },
        {
          label: "เบอร์โทร",
          field: "phone",
        },
        {
          label: "เพศ",
          field: "gender",
        },
        {
          label: "ประเภทกลุ่มผู้เสียหาย",
          field: "suspect_types",
        },
        {
          label: "อาชีพ",
          field: "career",
        },
        {
          label: "รายได้",
          field: "income",
        },
        {
          label: "สัญชาติ",
          field: "nationality",
        },
        {
          label: "บ้านเลขที่",
          field: "no_house",
        },
        {
          label: "หมู่ที่",
          field: "village_no_house",
        },
        {
          label: "ตำบล",
          field: "sub_district_house",
        },
        {
          label: "อำเภอ",
          field: "district_house",
        },
        {
          label: "จังหวัด",
          field: "province_house",
        },
        {
          label: "รหัสไปรษณีย์",
          field: "postal_code_house",
        },
        {
          label: "บ้านเลขที่",
          field: "no_current",
        },
        {
          label: "หมู่ที่",
          field: "village_no_current",
        },
        {
          label: "ตำบล",
          field: "sub_district_current",
        },
        {
          label: "อำเภอ",
          field: "district_current",
        },
        {
          label: "จังหวัด",
          field: "province_current",
        },
        {
          label: "รหัสไปรษณีย์",
          field: "postal_code_current",
        },
        {
          label: "สิทธิ์ที่ได้รับ",
          field: "onename",
        }
      );
      for (let p = 0; p < this.privileged.length; p++) {
        var fieldname = "";
        if (this.privileged[p].event_type_id == 1) {
          fieldname = "one";
        }
        if (this.privileged[p].event_type_id == 2) {
          fieldname = "two";
        }
        if (this.privileged[p].event_type_id == 3) {
          fieldname = "three";
        }
        if (this.privileged[p].event_type_id == 4) {
          fieldname = "four";
        }
        if (this.privileged[p].event_type_id == 5) {
          fieldname = "five";
        }
        if (this.privileged[p].event_type_id == 6) {
          fieldname = "six";
        }
        if (this.privileged[p].event_type_id == 7) {
          fieldname = "seven";
        }
        if (this.privileged[p].event_type_id == 8) {
          fieldname = "eight";
        }
        if (this.privileged[p].event_type_id == 9) {
          fieldname = "nine";
        }
        if (this.privileged[p].event_type_id == 10) {
          fieldname = "ten";
        }
        this.columns.push({
          label: this.privileged[p].name,
          field: fieldname,
        });
      }
    },
    getprivil() {
      DamagesDataService.getDamagesAll().then((res) => {
        // console.log(res.data);
        this.privileged = res.data;
        this.generatecolumn();
        for (let p = 0; p < this.privileged.length; p++) {
          if (this.privileged[p].damage_type_id == 1) {
            this.statusone = true;
          } else if (this.privileged[p].damage_type_id == 2) {
            this.statustwo = true;
          } else if (this.privileged[p].damage_type_id == 3) {
            this.statusthree = true;
          } else if (this.privileged[p].damage_type_id == 4) {
            this.statusfour = true;
          } else if (this.privileged[p].damage_type_id == 5) {
            this.statusfive = true;
          } else if (this.privileged[p].damage_type_id == 6) {
            this.statussix = true;
          } else if (this.privileged[p].damage_type_id == 7) {
            this.statusseven = true;
          } else if (this.privileged[p].damage_type_id == 8) {
            this.statuseight = true;
          } else if (this.privileged[p].damage_type_id == 9) {
            this.statusnine = true;
          } else if (this.privileged[p].damage_type_id == 10) {
            this.statusten = true;
          }
        }
      });
    },
     managecontent() {
      var no = 0;
      if (this.list.length != 0) {
        this.filename =
          "รายงานแสดงรายละเอียดเหตุการณ์ตามจังหวัด และอำเภอที่เกิดเหตุการณ์";
      }
      for (let l = 0; l < this.list.length; l++) {
        this.list[l].day = this.cal(
          this.list[l].detained_start_date,
          this.list[l].detained_end_date
        );
        this.list[l].detained_start_date = this.convertdatetothai(
          this.list[l].detained_start_date
        );
        this.list[l].detained_end_date = this.convertdatetothai(
          this.list[l].detained_end_date
        );
        this.list[l].filing_date = this.convertdatetothai(
          this.list[l].filing_date
        );
        this.list[l].birthday = this.convertdatetothai(this.list[l].birthday);
        this.list[l].crime_date = this.convertdatetothai(
          this.list[l].crime_date
        );
        if (!this.list[l].event_id) {
          this.list[l].event_id = "-";
        }
         if (!this.list[l].crime_scene) {
          this.list[l].crime_scene = "-";
        }
        if (!this.list[l].village_no_house) {
          this.list[l].village_no_house = "-";
        }
        if (!this.list[l].road_house) {
          this.list[l].road_house = "-";
        }
        if (!this.list[l].lane_house) {
          this.list[l].lane_house = "-";
        }
        if (!this.list[l].village_house) {
          this.list[l].village_house = "-";
        }
        if (!this.list[l].village_no_current) {
          this.list[l].village_no_current = "-";
        }
        if (!this.list[l].road_current) {
          this.list[l].road_current = "-";
        }
        if (!this.list[l].lane_current) {
          this.list[l].lane_current = "-";
        }
        if (!this.list[l].village_current) {
          this.list[l].village_current = "-";
        }
        if (!this.list[l].village_no_event) {
          this.list[l].village_no_event = "-";
        }
        if (!this.list[l].road_event) {
          this.list[l].road_event = "-";
        }
        if (!this.list[l].lane_event) {
          this.list[l].lane_event = "-";
        }
        if (!this.list[l].village_event) {
          this.list[l].village_event = "-";
        }
        this.list[l].events =
          "ม." +
          this.list[l].village_no_event +
          " ซ." +
          this.list[l].road_event +
          " ถนน" +
          this.list[l].lane_event +
          " หมู่บ้าน" +
          this.list[l].village_event +
          " ต." +
          this.list[l].sub_district_event +
          " อ." +
          this.list[l].district_event +
          " จ." +
          this.list[l].province_event +
          " " +
          this.list[l].post_code_event;
        // console.log(this.list[l]);
        this.list[l].name =
          this.list[l].firstname + " " + this.list[l].lastname;
        if (l != 0) {
          // console.log(this.list[l].case_id, this.list[l - 1].case_id);
          if (this.list[l].case_id == this.list[l - 1].case_id) {
            this.list[l].prefix = "";
            this.list[l].name = "";
            this.list[l].id_card = "";
            this.list[l].village_house = "";
            this.list[l].lane_house = "";
            this.list[l].road_house = "";
             this.list[l].village_current = "";
            this.list[l].lane_current = "";
            this.list[l].road_current = "";
            this.list[l].village_no_house = "";
            this.list[l].no_house = "";
            this.list[l].sub_district_house = "";
            this.list[l].district_house = "";
            this.list[l].province_house = "";
            this.list[l].postal_code_house = "";
            this.list[l].birthday = "";
            this.list[l].filing_date = "";
            this.list[l].phone = "";
            this.list[l].gender = "";
            this.list[l].suspect_types = "";
            this.list[l].career = "";
            this.list[l].income = "";
            this.list[l].nationality = "";
            this.list[l].no_current = "";
            this.list[l].village_no_current = "";
            this.list[l].sub_district_current = "";
            this.list[l].sub_district_current = "";
            this.list[l].district_current = "";
            this.list[l].province_current = "";
            this.list[l].postal_code_current = "";

            if (this.list[l].case_id == this.list[l - 1].case_id) {
              this.list[l].no = "";
              // this.list[l].span = 9
              // this.list[l].prefix =  "รวมเป็นเวลา "+ this.list[l].sumday+" วัน เป็นเงิน " +this.list[l].sumamount.toLocaleString() + " บาท"
            }
          } else {
            no = no + 1;
            this.list[l].no = no;
          }
        } else {
          no = no + 1;
          this.list[l].no = no;
        }
        if (this.list[l].village_no_house == null) {
          this.list[l].village_no_house = "-";
        }
      }
    },
    getprivilsix() {
      EventTypesDataService.getEventTypesAll().then((res) => {
        // console.log(res.data);
        this.privileged = res.data;
        this.generatecolumnsix();
        for (let p = 0; p < this.privileged.length; p++) {
          if (this.privileged[p].event_type_id == 1) {
            this.statusone = true;
          } else if (this.privileged[p].event_type_id == 2) {
            this.statustwo = true;
          } else if (this.privileged[p].event_type_id == 3) {
            this.statusthree = true;
          } else if (this.privileged[p].event_type_id == 4) {
            this.statusfour = true;
          } else if (this.privileged[p].event_type_id == 5) {
            this.statusfive = true;
          } else if (this.privileged[p].event_type_id == 6) {
            this.statussix = true;
          } else if (this.privileged[p].event_type_id == 7) {
            this.statusseven = true;
          } else if (this.privileged[p].event_type_id == 8) {
            this.statuseight = true;
          } else if (this.privileged[p].event_type_id == 9) {
            this.statusnine = true;
          } else if (this.privileged[p].event_type_id == 10) {
            this.statusten = true;
          }
        }
      });
    },
    managecontentsix() {
      var no = 0;
      if (this.list.length != 0) {
        this.filename =
          "รายงานแสดงข้อมูลของผู้เสียหายที่อยู่ในแต่ละเหตุการณ์ในลักษณะรายบุคคล";
      }
      for (let l = 0; l < this.list.length; l++) {
        this.list[l].day = this.cal(
          this.list[l].detained_start_date,
          this.list[l].detained_end_date
        );
        this.list[l].detained_start_date = this.convertdatetothai(
          this.list[l].detained_start_date
        );
        this.list[l].detained_end_date = this.convertdatetothai(
          this.list[l].detained_end_date
        );
        this.list[l].filing_date = this.convertdatetothai(
          this.list[l].filing_date
        );
        this.list[l].birthday = this.convertdatetothai(this.list[l].birthday);
        this.list[l].crime_date = this.convertdatetothai(
          this.list[l].crime_date
        );
        if (!this.list[l].village_no_event) {
          this.list[l].village_no_event = "-";
        }
        if (!this.list[l].road_event) {
          this.list[l].road_event = "-";
        }
        if (!this.list[l].lane_event) {
          this.list[l].lane_event = "-";
        }
        if (!this.list[l].village_event) {
          this.list[l].village_event = "-";
        }
        this.list[l].name =
          this.list[l].firstname + " " + this.list[l].lastname;
        this.list[l].events =
          "ม." +
          this.list[l].village_no_event +
          " ซ." +
          this.list[l].road_event +
          " ถนน" +
          this.list[l].lane_event +
          " หมู่บ้าน" +
          this.list[l].village_event +
          " ต." +
          this.list[l].sub_district_event +
          " อ." +
          this.list[l].district_event +
          " จ." +
          this.list[l].province_event +
          " " +
          this.list[l].post_code_event;
        if (this.list[l].one) {
          this.list[l].one = this.list[l].one.toLocaleString();
        }
        if (this.list[l].two) {
          this.list[l].two = this.list[l].two.toLocaleString();
        }
        if (this.list[l].three) {
          this.list[l].three = this.list[l].three.toLocaleString();
        }
        if (l != 0) {
          if (
            this.list[l].case_id == this.list[l - 1].case_id &&
            this.list[l].privileged_id == this.list[l - 1].privileged_id
          ) {
            if (this.list[l].two) {
              this.list[l - 1].two = this.list[l].two;
              this.list[l].two = "";
              this.list[l].onename = "";
              // this.list.splice(l, 1);
            }
          }
          // console.log(this.list[l].case_id, this.list[l - 1].case_id);
          if (this.list[l].case_id == this.list[l - 1].case_id) {
            this.list[l].prefix = "";
            this.list[l].firstname = "";
            this.list[l].lastname = "";
            this.list[l].id_card = "";
            this.list[l].village_no_house = "";
            this.list[l].no_house = "";
            this.list[l].sub_district_house = "";
            this.list[l].district_house = "";
            this.list[l].province_house = "";
            this.list[l].postal_code_house = "";
            this.list[l].birthday = "";
            this.list[l].filing_date = "";
            this.list[l].phone = "";
            this.list[l].gender = "";
            this.list[l].suspect_types = "";
            this.list[l].career = "";
            this.list[l].income = "";
            this.list[l].nationality = "";
            this.list[l].no_current = "";
            this.list[l].village_no_current = "";
            this.list[l].sub_district_current = "";
            this.list[l].sub_district_current = "";
            this.list[l].district_current = "";
            this.list[l].province_current = "";
            this.list[l].postal_code_current = "";
            this.list[l].name = "";
            if (this.list[l].case_id == this.list[l - 1].case_id) {
              this.list[l].no = "";
              // this.list[l].span = 9
              // this.list[l].prefix =  "รวมเป็นเวลา "+ this.list[l].sumday+" วัน เป็นเงิน " +this.list[l].sumamount.toLocaleString() + " บาท"
            }
          } else {
            no = no + 1;
            this.list[l].no = no;
          }
        } else {
          no = no + 1;
          this.list[l].no = no;
        }
        if (this.list[l].village_no_house == null) {
          this.list[l].village_no_house = "-";
        }
      }
      for (let ll = 0; ll < this.list.length; ll++) {
        if (!this.list[ll].one && !this.list[ll].two && !this.list[ll].thee) {
          this.list.splice(ll, 1);
        }
      }
    },
    getsearchtracking(evt) {
      this.type = evt.target.value;
      this.typename = evt.target.options[evt.target.options.selectedIndex].text;
      this.nodata = false;
      this.event.id_card = "";
      this.event.province_house = 0;
            this.event.district_house = 0;
            this.event.sub_district_house = 0;
      this.head = [];
      this.year = [];
      this.list = [];
      var one = document.getElementById("hideone");
      if (one) {
        one.style.display = "none";
      }
      var two = document.getElementById("hidetwo");
      if (two) {
        two.style.display = "none";
      }
      var three = document.getElementById("hidethree");
      if (three) {
        three.style.display = "none";
      }
      var five = document.getElementById("hidefive");
      if (five) {
        five.style.display = "none";
      }
      var six = document.getElementById("hidesix");
      if (six) {
        six.style.display = "none";
      }
      //  document.getElementById( 'hidetwo' ).style.display = 'none';
      //  document.getElementById( 'hidetwothree' ).style.display = 'none';
      //  document.getElementById( 'hidetwothree' ).style.display = 'none';
      //  document.getElementById( 'hidefour' ).style.display = 'none';
      //  document.getElementById( 'hidefive' ).style.display = 'none';
      //   document.getElementById( 'hidefivesix' ).style.display = 'none';
      if (this.type == 1) {
        document.getElementById("hideone").style.display = "flex";
      } else if (this.type == 2) {
        document.getElementById("hidetwo").style.display = "flex";
      } else if (this.type == 3) {
        document.getElementById("hidethree").style.display = "flex";
      } else if (this.type == 5) {
        document.getElementById("hidefive").style.display = "flex";
      } else if (this.type == 6) {
        document.getElementById("hidesix").style.display = "flex";
      }
    },
    geteventtype() {
      EventTypesDataService.getEventTypesAll().then((res) => {
        // console.log(res.data);
        this.event_type.push({
          event_type_id: 0,
          name: "ทั้งหมด",
        });
        for (let r = 0; r < res.data.length; r++) {
          this.event_type.push({
            event_type_id: res.data[r].event_type_id,
            name: res.data[r].name,
          });
        }
        this.event.event_type_id = this.event_type[0].event_type_id;
        this.event.event_type_name = this.event_type[0].name;
        document.getElementById("hideone").style.display = "flex";
      });
    },
    onChangeDistrict(event) {
      // console.log(this.user.province)
      var amphur_id = event.target.value;
      this.event.sub_district_house = 0;
      this.getDistrict(amphur_id);
    },
    onChangeProvince(event) {
      // console.log(this.user.province)
      var province_id = event.target.value;
      this.event.district_house = 0;
      this.event.sub_district_house = 0;
      this.getAmphur(province_id);
    },
    getDistrict(id) {
      this.districts = []
      if (id == 0) {
        DistrictDataService.getDistrictsAll().then((res) => {
          this.districts.push({
          DISTRICT_ID: 0,
          DISTRICT_NAME: "ทั้งหมด",
        });
        for (let r = 0; r < res.data.length; r++) {
          this.districts.push({
            DISTRICT_ID: res.data[r].DISTRICT_ID,
            DISTRICT_NAME: res.data[r].DISTRICT_NAME,
          });
        }
        });
      } else {
        DistrictDataService.getDistrictAll(id).then((res) => {
          this.districts.push({
          DISTRICT_ID: 0,
          DISTRICT_NAME: "ทั้งหมด",
        });
        for (let r = 0; r < res.data.length; r++) {
          this.districts.push({
            DISTRICT_ID: res.data[r].DISTRICT_ID,
            DISTRICT_NAME: res.data[r].DISTRICT_NAME,
          });
        }
        });
      }
    },
    getAmphur(id) {
      this.amphures = []
      if (id == 0) {
        AmphurDataService.getAmphursAll().then((res) => {
          // this.amphures = res.data;
          this.amphures.push({
          AMPHUR_ID: 0,
          AMPHUR_NAME: "ทั้งหมด",
        });
        for (let r = 0; r < res.data.length; r++) {
          this.amphures.push({
            AMPHUR_ID: res.data[r].AMPHUR_ID,
            AMPHUR_NAME: res.data[r].AMPHUR_NAME,
          });
        }
        });
      } else {
        AmphurDataService.getAmphurAll(id).then((res) => {
          this.amphures.push({
          AMPHUR_ID: 0,
          AMPHUR_NAME: "ทั้งหมด",
        });
        for (let r = 0; r < res.data.length; r++) {
          this.amphures.push({
            AMPHUR_ID: res.data[r].AMPHUR_ID,
            AMPHUR_NAME: res.data[r].AMPHUR_NAME,
          });
        }
          // console.log(res.data);
        });
      }
      // this.savedraft();
    },
    getProvince() {
      ProvinceDataService.getProvinceAll().then((res) => {
        // this.provinces = res.data;
        // console.log(res.data);
        this.provinces.push({
          PROVINCE_ID: 0,
          PROVINCE_NAME: "ทั้งหมด",
        });
        for (let r = 0; r < res.data.length; r++) {
          this.provinces.push({
            PROVINCE_ID: res.data[r].PROVINCE_ID,
            PROVINCE_NAME: res.data[r].PROVINCE_NAME,
          });
        }
      });
    },
    cal(start, end) {
      var date1 = new Date(start);
      var date2 = new Date(end);
      var diffTime = date2.getTime() - date1.getTime();
      var diffDay = diffTime / (1000 * 3600 * 24);
      return diffDay;
    },
    getEventByCaseID() {
      CasesEventDataService.getEventByCaseID(this.case_id).then((res) => {
        this.list = res.data;
        // console.log(res.data);
      });
    },
    back() {
      this.$router.push("/home");
    },
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    },
    convertdatetothai(datetimes) {
      if (datetimes) {
        const date = new Date(datetimes);
        const result = date.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return result;
      } else {
        return "-";
      }
    },
    hasHistory() {
      return window.history.length > 2;
    },
    search() {
      if (this.event.id_card != '') {
         SuspectsDataService.getSuspects(this.event.id_card).then((res)=>{
                  this.event.firstname = res.data.firstname
                  this.event.lastname = res.data.lastname
                  // console.log(res.data.firstname);
                  // console.log(this.event);
                })
      }
      var status = '';
      for (let s = 0; s < this.value.length; s++) {
        status += this.value[s].status_id + ','
        
      }
      if (this.type == 1) {
        if (document.getElementById("fill_start_date").value == "") {
          alert("กรุณากรอกวันที่ยื่นคำร้อง");
        } else {
          var start;
          var s = document.getElementById("fill_start_date").value;
          // var dd = new Date(d)
          start = ConvertService.createddate(s);
          this.fillstart = this.convertdatetothai(start);
          var user_id = "";
          if (this.currentUser.role_id != 3 && this.currentUser.role_id != 1) {
            user_id = this.currentUser.user_id;
          }
          if (this.event.id_card == null) {
            this.event.id_card = ''
          }
          ReportDataService.getHelpone(this.event.id_card, start, user_id,status).then(
            (res) => {
              this.list = res.data;
              // console.log(res.data);
              if (res.data.length != 0) {
                this.nodata = true;
                this.getprivil();
                this.managecontent();                
              } else {
                alert("ไม่พบข้อมูล");
                this.nodata = false;
              }
            }
          );
        }
      }
      if (this.type == 2) {
        if (document.getElementById("fill_start_date_two").value == "") {
          alert("กรุณากรอกวันที่ยื่นคำร้อง");
        } else if (document.getElementById("fill_end_date_two").value == "") {
          alert("กรุณากรอกถึงวันที่ยื่นคำร้อง");
        } else {
          var start;
          var end;
          var s = document.getElementById("fill_start_date_two").value;
          var e = document.getElementById("fill_end_date_two").value;
          // var dd = new Date(d)
          start = ConvertService.createddate(s);
          end = ConvertService.createddate(e);
          this.fillstart = this.convertdatetothai(start);
          this.fillend = this.convertdatetothai(end);
          var user_id = "";
          if (this.currentUser.role_id != 3 && this.currentUser.role_id != 1) {
            user_id = this.currentUser.user_id;
          }
          ReportDataService.getHelptwo(
            start,
            end,
            this.event.province_house,
            this.event.district_house,
            this.event.sub_district_house,
            user_id,
            status
          ).then((res) => {
            // console.log(res.data);
            if (res.data[0].month != 0) {
              this.head = res.data[0].series;
              this.year = res.data[0].month;
              this.nodata = true;
            } else {
              alert("ไม่พบข้อมูล");
              this.nodata = false;
            }
          });
        }
      }
      if (this.type == 3) {
        if (document.getElementById("fill_start_date_three").value == "") {
          alert("กรุณากรอกวันที่ยื่นคำร้อง");
        } else if (document.getElementById("fill_end_date_three").value == "") {
          alert("กรุณากรอกถึงวันที่ยื่นคำร้อง");
        } else {
          var start;
          var end;
          var s = document.getElementById("fill_start_date_three").value;
          var e = document.getElementById("fill_end_date_three").value;
          // var dd = new Date(d)
          start = ConvertService.createddate(s);
          end = ConvertService.createddate(e);
          this.fillstart = this.convertdatetothai(start);
          this.fillend = this.convertdatetothai(end);
          var user_id = "";
          if (this.currentUser.role_id != 3 && this.currentUser.role_id != 1) {
            user_id = this.currentUser.user_id;
          }
          ReportDataService.getHelpthree(
            start,
            end,
            this.event.province_house,
            this.event.district_house,
            this.event.sub_district_house,
            user_id,
            status
          ).then((res) => {
            // console.log(res.data);
            if (res.data[0].month != 0) {
              this.nodata = true;
              this.head = res.data[0].series;
              this.year = res.data[0].month;
            } else {
              alert("ไม่พบข้อมูล");
              this.nodata = false;
            }
          });
        }
      }
      if (this.type == 4) {
        if (this.event.id_card == "") {
          alert("กรุณากรอกเลขบัตรประชาชน");
        } else {
          ReportDataService.getHelpfour(this.event.id_card,status).then((res) => {
            // console.log(res.data);
            if (res.data[0].month != 0) {             
              this.nodata = true;
              this.head = res.data[0].series;
              this.year = res.data[0].month;
              
            } else {
              alert("ไม่พบข้อมูล");
              this.nodata = false;
            }
          });
        }
      }
      if (this.type == 5) {
        if (
          document.getElementById("payment_start_date").value == ""
        ) {
          alert("กรุณากรอกวันที่จ่ายเงิน");
        } else if (
          document.getElementById("payment_end_date").value == ""
        ) {
          alert(
            "กรุณาเลือกถึงวันที่จ่ายเงิน"
          );
        }else {
          var paymentstart;
          var paymentend;
            var s = document.getElementById("payment_start_date").value;
          var e = document.getElementById("payment_end_date").value;  
          paymentstart = ConvertService.createddate(s);
          paymentend = ConvertService.createddate(e);   
          start = ''
          end=''  
          
          // var dd = new Date(d)
          this.fillstart = this.convertdatetothai(paymentstart);
          this.fillend = this.convertdatetothai(paymentend);
          var user_id = "";
          if (this.currentUser.role_id != 3 && this.currentUser.role_id != 1) {
            user_id = this.currentUser.user_id;
          }
          ReportDataService.getHelpfive(start, end, paymentstart, paymentend, user_id).then(
            (res) => {
              console.log(res.data);
              if (res.data[0].month != 0) {
                this.nodata = true;
                this.head = res.data[0].series;
                this.year = res.data[0].month;
                for (let ev = 0; ev < this.year.length; ev++) {
                  var total = 0;
                  for (
                    let index = 0;
                    index < this.year[ev].data.length;
                    index++
                  ) {
                    total += this.year[ev].data[index];
                  }
                  this.year[ev].sum = total;
                }
              } else {
                alert("ไม่พบข้อมูล");
                this.nodata = false;
              }
            }
          );
        }
      }
      if (this.type == 6) {
        if (document.getElementById("fill_start_date_six").value == "") {
          alert("กรุณากรอกวันที่ยื่นคำร้อง");
        } else if (document.getElementById("fill_end_date_six").value == "") {
          alert("กรุณากรอกถึงวันที่ยื่นคำร้อง");
        } else {
          var start;
          var end;
          var s = document.getElementById("fill_start_date_six").value;
          var e = document.getElementById("fill_end_date_six").value;
          // var dd = new Date(d)
          start = ConvertService.createddate(s);
          end = ConvertService.createddate(e);
          this.fillstart = this.convertdatetothai(start);
          this.fillend = this.convertdatetothai(end);
          var user_id = "";
          if (this.currentUser.role_id != 3 && this.currentUser.role_id != 1) {
            user_id = this.currentUser.user_id;
          }
          ReportDataService.getHelpsix(
            start,
            end,
            user_id,
            status
          ).then((res) => {
            this.list = res.data;

            if (res.data.length != 0) {
              this.nodata = true;
              // console.log(res.data);
              this.getprivilsix();
              this.managecontentsix();
            } else {
              alert("ไม่พบข้อมูล");
              this.nodata = false;
            }
          });
        }
      }
    },
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  font-family: "Kanit" !important;
}
.row {
  max-width: 1200px;
  margin: auto;
  background: white;
  padding: 10px;
  color: black !important;
}
a:visited {
  color: black;
} /* visited link */
a:hover {
  color: black;
} /* mouse over link */
a:active {
  color: black;
}
a:link {
  color: black;
}
#hideone {
  display: none;
}
#hidetwo {
  display: none;
}
#hidethree {
  display: none;
}
#hidefour {
  display: none;
}
#hidefive {
  display: none;
}
#hidesix {
  display: none;
}
</style>
