<template>
  <div class="hello">
    <HomeIcon />
    <div class="mt-5">
      <div class="row mt-5">
          <h4 style="text-align: center">รายการข้อมูลการเงิน</h4>
      </div>
      <div class="row card mt-2">
        <div class="card-body p-0 mt-3">
          <h4 style="text-align: center">ค้นหา</h4>
          <div class="row">
            <div class="col-md-4"></div>
            <div class="col-md-4">
              <div class="form-group" style="text-align: left">
                <label for="username">เลขหนังสืออ้างอิง</label>
                <input
                v-model="ref"
                  type="text"
                  class="form-control"
                  id="username"
                  placeholder="เลขหนังสืออ้างอิง"
                />
              </div>
            </div>
            <div class="col-md-4"></div>
          </div>
        </div>
        <div class="col text-center mb-3">
          <button @click="search()" class="btn btn-primary" style="color: white">
            <i class="fas fa-search"></i> ค้นหา
          </button>
        </div>
      </div>
      <div class="mt-5" >
        <div class="row">
          <div class="col-md-12">
            <h4 style="text-align: left" class="mb-5">
              รายการข้อมูลการเงิน
            </h4>
          </div>
           <div class="col-md-3">เลขหนังสืออ้างอิง</div>
          <div class="col-md-2">จำนวน</div>
          <div class="col-md-2">วันที่ลงนาม</div>
          <div class="col-md-2">วันที่จ่ายเงิน</div>
          <div class="col-md-2">สถานะ</div>
          <div class="col-md-1">จัดการ</div>
        </div>
        <div v-if="list.length > 0">
        <div class="row card" v-for="(l, i) in pageOfItems" :key="i">
          <div class="card-body p-0">
            <div class="row">
              <div class="col-md-3">
                <i class="fas fa-file-alt"></i> {{l.ref_no}}
              </div>
              <div class="col-md-2">{{l.count}} คน</div>
              <div class="col-md-2">
               {{convertdatetothai(l.signing_date)}}
              </div>
              <div class="col-md-2"> {{convertdatetothai(l.payment_date)}}</div>
              <div class="col-md-2">{{l.status}}</div>
              <div class="col-md-1">
                <a v-if="l.status_id == 7 || l.status_id == 8" :href="'/pending/' + l.id"
                  ><i class="fas fa-edit"></i>&nbsp;</a
                >
                <a v-if="l.status_id == 8 || l.status_id == 9"
                  :href="'/approve/' + l.id"
                  ><i class="fas fa-check"></i>&nbsp;</a
                >
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
       <div v-if="list.length == 0" style="text-align: center">
          <h5>ไม่พบข้อมูล</h5>
        </div>
      <div class="row" align="right">
        <div class="col-md-12">
          <jw-pagination
            :items="list"
            @changePage="onChangePage"
            :labels="customLabels"
          ></jw-pagination>
        </div>
      </div>

      <div class="row">
      <div class="col-md-12 text-center">
        <button
          class="btn btn-warning"
          style="color: white; width: 200px"
          @click="back()"
        >
          กลับหน้าเดิม</button
        >&nbsp;
      </div>
    </div>
    </div>
  </div>
</template>


<script>
import HomeIcon from "../HomeIcon.vue";
import PaymentDataService from "../../services/PaymentDataService";

const customLabels = {
    first: '<<',
    last: '>>',
    previous: '<',
    next: '>'
};

export default {
  components: {
    HomeIcon,
  },
  data() {
    return {
      name: "HelloWorld",
      list: [],
      status: [],
      pageOfItems: [],
      date: "",
      options: {
        format: "DD/MM/YYYY",
        useCurrent: false,
      },
      location: "",
      latLong: "",
      customLabels,
      ref:''
    };
  },
  mounted() {
    this.getCase();
  },
  methods: {
    back() {
      this.$router.push("/treatment");
      setTimeout(function () {
        location.reload();
      }, 500);
    },
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    },
    convertdatetothai(datetimes) {
      if (datetimes) {
        const date = new Date(datetimes);
        const result = date.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return result;
      } else {
        return "-";
      }
    },
    getCase() {
      PaymentDataService.getPayments('').then(
        (res) => {
          // console.log(res.data);
          this.list = res.data;
        }
      );
    },
    hasHistory() {
      return window.history.length > 2;
    },
    search(){
      PaymentDataService.getPayments(this.ref).then(
        (res) => {
          // console.log(res.data);
          this.list = res.data;
        }
      );
    }
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  font-family: "Kanit" !important;
}
.row {
  max-width: 1200px;
  margin: auto;
  background: white;
  padding: 10px;
  color: black !important;
}
a:visited {
  color: black;
} /* visited link */
a:hover {
  color: black;
} /* mouse over link */
a:active {
  color: black;
}
a:link {
  color: black;
}
</style>
