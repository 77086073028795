import http from "../http-common";

class UsersDataService {
  getUsers() {
    return http.get('/user');
  }
  getUser(id) {
    console.log(`/user/`, id);
    return http.get(`/user/`+id);
  }
  getRoles() {
    return http.get('/role');
  }
  getRole(id) {
    // console.log(`/role/`, id);
    return http.get(`/role/`+id);
  }
  createRole(data) {
    return http.post('/role', data);
  }  
  createUser(data) {
    return http.post('/user', data);
  }  
  updateUser(id,data) {
    return http.put(`/user/${id}`, data);
  }
  updateRole(id,data) {
    return http.put(`/role/${id}`, data);
  }
  deleteUser(id) {
    return http.delete(`/user/${id}`);
  }
}

export default new UsersDataService();