import http from "../http-common";

class ProvinceDataService {
  getProvince() {
    return http.get('/province');
  }
  getname(id) {
    return http.get('/province/'+id);
  }
  getProvinceAll() {
    return http.get('/province/getprovinceall');
  }
}

export default new ProvinceDataService();