import http from "../http-common";

class MenusDataService {
    getMenu() {
    return http.get('/menu');
  }
  getstatusRoleMenu(id) {
    return http.get('/menu/findstatusByRoleId/'+id);
  }
  getstatusUserMenu(id) {
    return http.get('/menu/findstatusByUserId/'+id);
  }
  getRoleMenu(id) {
    return http.get('/menu/findByRoleId/'+id);
  }
  getUserMenu(id) {
    return http.get('/usermenu/userMenu/'+id);
  }
  createUserMenu(data) {
    return http.post('/usermenu', data);
  }
  createRoleMenu(data) {
    return http.post('/rolemenu', data);
  }
  deleteUserMenu(id) {
    return http.delete(`/usermenu/${id}`);
  }
  deleteRoleMenu(id) {
    return http.delete(`/rolemenu/${id}`);
  }
}

export default new MenusDataService();