import http from "../http-common";

class SuspectsRelativeDataService {
  getSuspectsRelatives() {
    return http.get('/suspect_relatives');
  }
  getSuspectRelative(id) {
    return http.get(`/suspect_relatives/`+id);
  }
  createSuspectRelative(data) {
    return http.post('/suspect_relatives', data);
  }
  updateSuspectRelative(id,data) {
    return http.put(`/suspect_relatives/${id}`, data);
  }
  getRelativeBySuspect(id) {
    return http.get(`/suspect_relatives/findrelativebysuspectid/`+id);
  }
  getDescendantBySuspect(id) {
    return http.get(`/suspect_relatives/finddescendantbysuspectid/`+id);
  }
  getDescendantTracking() {
    return http.get(`/suspect_relatives/finddescendanttracking`);
  }
  deleteSuspectRelative(id) {
    return http.delete(`/suspect_relatives/`+id);
  }
}

export default new SuspectsRelativeDataService();