import http from "../http-common";

class ReportDataService {
  getEventReport(start,end,province,amphur,subdistrict,event,status,user_id) {
    return http.get('/report/eventreport?start='+start+'&&end='+end+'&&province='+province+'&&amphur='+amphur+'&&subdistrict='+subdistrict+'&&event='+event+'&&status='+status+'&&user_id='+user_id);
  }
  getEventDetailReport(start,end,province,amphur,subdistrict,event,status,user_id) {
    return http.get('/report/eventdetailreport?start='+start+'&&end='+end+'&&province='+province+'&&amphur='+amphur+'&&subdistrict='+subdistrict+'&&event='+event+'&&user_id='+user_id+'&&status='+status);
  }
  getStatusReport(start,end,status,user_id) {
    return http.get('/report/statusreport?start='+start+'&&end='+end+'&&status='+status+'&&user_id='+user_id);
  }
  getHelpone(id_card,start,user_id,status) {
    return http.get('/report/helpone?id_card='+id_card+'&&start='+start+'&&user_id='+user_id+'&&status='+status);
  }
  getHelptwo(start,end,province,amphur,subdistrict,user_id,status) {
    return http.get('/report/helptwo?start='+start+'&&end='+end+'&&province='+province+'&&amphur='+amphur+'&&subdistrict='+subdistrict+'&&user_id='+user_id+'&&status='+status);
  }
  getHelpthree(start,end,province,amphur,subdistrict,user_id,status) {
    return http.get('/report/helpthree?start='+start+'&&end='+end+'&&province='+province+'&&amphur='+amphur+'&&subdistrict='+subdistrict+'&&user_id='+user_id+'&&status='+status);
  }
  getHelpfour(id_card,status) {
    return http.get('/report/helpfour?id_card='+id_card+'&&status='+status);
  }
  getHelpfive(start,end,pay_start,pay_end,user_id) {
    return http.get('/report/helpfive?start='+start+'&&end='+end+'&&pay_start='+pay_start+'&&pay_end='+pay_end+'&&user_id='+user_id);
  }
  getHelpsix(start,end,user_id,status) {
    return http.get('/report/helpsix?start='+start+'&&end='+end+'&&user_id='+user_id+'&&status='+status);
  }
  getTrackingone(id_card,start,end,province,amphur,subdistrict,user_id) {
    return http.get('/report/trackingone?id_card='+id_card+'&&user_id='+user_id+'&&start='+start+'&&end='+end+'&&province='+province+'&&amphur='+amphur+'&&subdistrict='+subdistrict);
  }
  getTrackingthree(id_card,start,end,province,amphur,subdistrict,user_id) {
    return http.get('/report/trackingthree?id_card='+id_card+'&&user_id='+user_id+'&&start='+start+'&&end='+end+'&&province='+province+'&&amphur='+amphur+'&&subdistrict='+subdistrict);
  }
  getQualityone(id_card,start,end,province,amphur,subdistrict,user_id) {
    return http.get('/report/qualityone?id_card='+id_card+'&&user_id='+user_id+'&&start='+start+'&&end='+end+'&&province='+province+'&&amphur='+amphur+'&&subdistrict='+subdistrict);
  }
  getQualitythree(id_card,start,end,province,amphur,subdistrict,user_id) {
    return http.get('/report/qualitythree?id_card='+id_card+'&&user_id='+user_id+'&&start='+start+'&&end='+end+'&&province='+province+'&&amphur='+amphur+'&&subdistrict='+subdistrict);
  }
  getTrackingtwo(start,end,province,amphur,subdistrict,user_id) {
    return http.get('/report/trackingtwo?start='+start+'&&end='+end+'&&province='+province+'&&amphur='+amphur+'&&subdistrict='+subdistrict+'&&user_id='+user_id);
  }
  getQualitytwo(start,end,province,amphur,subdistrict,user_id) {
    return http.get('/report/qualitytwo?start='+start+'&&end='+end+'&&province='+province+'&&amphur='+amphur+'&&subdistrict='+subdistrict+'&&user_id='+user_id);
  }
  getStat1(start,end,event,user_id,status) {
    return http.get('/report/stat1?start='+start+'&&end='+end+'&&event='+event+'&&user_id='+user_id+'&&status='+status);
  }
  getStat2(start,end,province,amphur,subdistrict,user_id,status) {
    return http.get('/report/stat2?start='+start+'&&end='+end+'&&province='+province+'&&amphur='+amphur+'&&subdistrict='+subdistrict+'&&user_id='+user_id+'&&status='+status);
  }
  getStat3(start,end,damage,user_id,status) {
    return http.get('/report/stat3?start='+start+'&&end='+end+'&&damage='+damage+'&&user_id='+user_id+'&&status='+status);
  }  
  getStat4(start,end,user_id,status) {
    return http.get('/report/stat4?start='+start+'&&end='+end+'&&user_id='+user_id+'&&status='+status);
  }
  getHistory(id_card,start,user_id,status) {
    return http.get('/report/gethistory?id_card='+id_card+'&&start='+start+'&&user_id='+user_id+'&&status='+status);
  }
}

export default new ReportDataService();