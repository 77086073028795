import http from "../http-common";

class CasesTypeDataService {
  getCasesTypes() {
    return http.get('/case_types');
  }
  getCasesTypesAll() {
    return http.get('/case_types/getcasetype');
  }
  getCasesTypesForm() {
    return http.get('/case_types/form');
  }
  getCaseType(id) {
    return http.get(`/case_types/`+id);
  }
  createCaseType(data) {
    return http.post('/case_types', data);
  }
  updateCaseType(id,data) {
    return http.put(`/case_types/${id}`, data);
  }
}

export default new CasesTypeDataService();