<template>
  <div class="hello mt-3">
    <table class="table table-bordered">
      <thead>
        <tr>
          <th scope="col">ชื่อ-นามสกุล</th>
          <th scope="col">เลขบัตรประชาชน</th>
          <th scope="col" style="text-align: right">
            <button :disabled="cases.is_edit == 1" class="btn btn-success">
              <a
                data-toggle="modal"
                href="#"
                style="color: white"
                data-target="#addModalDescendants"
                @click="addDescendant(0)"
                >เพิ่ม</a
              >
            </button>
          </th>
        </tr>
      </thead>
      <tbody v-if="list.length > 0">
        <tr v-for="l in list" :key="l.relative_id">
          <td>{{ l.firstname }} {{ l.lastname }}</td>
          <td>{{ l.id_card_relative }}</td>
          <td style="text-align: right">
            <button
              :disabled="cases.is_edit == 1"
              class="btn btn-warning"
              style="color: white"
            >
              <a
                style="color: white"
                data-toggle="modal"
                href="#"
                data-target="#addModalDescendants"
                @click="addDescendant(l.relatives_id)"
                >แก้ไข</a
              >
            </button>
            &nbsp;
            <button
              class="btn btn-danger"
              :disabled="cases.is_edit == 1"
              @click="deleteDescendant(l)"
            >
              ลบ
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="list.length == 0" style="text-align: center">ไม่พบข้อมูล</div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="addModalDescendants"
      tabindex="-1"
      role="dialog"
      aria-labelledby="addModalDescendantsLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <h5>ข้อมูลทายาท</h5>
            </div>
            <div class="row">
              <div class="col-md-6 mt-2">
                <div class="form-group" style="text-align: left">
                  <label for="username"
                    >เลขบัตรประชาชน <span style="color: red"> *</span></label
                  >
                  <input
                    v-model="descendants.id_card_relative"
                    @keypress="onlyNumberDes"
                    type="number"
                    min="1"
                    class="form-control"
                    id="username"
                    placeholder="เลขบัตรประชาชน 13 หลัก"
                  />
                </div>
              </div>
              <div class="col-md-8"></div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <div class="form-group" style="text-align: left">
                  <label for="province"
                    >คำนำหน้า <span style="color: red"> *</span></label
                  >
                  <select
                    id="prefix"
                    class="form-select mb-3"
                    :disabled="cases.is_edit == 1"
                    v-model="descendants.prefix"
                  >
                    <option
                      v-for="(s, i) in prefix"
                      v-bind:value="s.prefix_id"
                      :key="i + 1"
                    >
                      {{ s.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group" style="text-align: left">
                  <label for="username"
                    >ชื่อ <span style="color: red"> *</span></label
                  >
                  <input
                    v-model="descendants.firstname"
                    type="text"
                    min="1"
                    class="form-control"
                    id="firstname"
                    placeholder="ชื่อ"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group" style="text-align: left">
                  <label for="username"
                    >นามสกุล <span style="color: red"> *</span></label
                  >
                  <input
                    v-model="descendants.lastname"
                    type="text"
                    min="1"
                    class="form-control"
                    id="lastname"
                    placeholder="นามสกุล"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group" style="text-align: left">
                  <label for="username"
                    >วัน/เดือน/ปีเกิด <span style="color: red"> *</span></label
                  >
                  <input id="birthday_des" class="form-control" />
                  <!-- <date-picker v-model="descendants.birthday" :config="options"
           class="form-control"
            min="1997-01-01"
            max="2030-12-31"
            id="birthday"
            ></date-picker> -->
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <div class="form-group" style="text-align: left">
                  <label for="province"
                    >เพศ <span style="color: red"> *</span></label
                  >
                  <select class="form-select mb-3" v-model="descendants.gender">
                    <option>ชาย</option>
                    <option>หญิง</option>
                    <option>อื่นๆ</option>
                  </select>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group" style="text-align: left">
                  <label for="province"
                    >ความสัมพันธ์ <span style="color: red"> *</span></label
                  >
                  <select
                    class="form-select mb-3"
                    v-model="descendants.relationship"
                  >
                    <option
                      v-for="(r, i) in relationships"
                      v-bind:value="r.relationship_type_id"
                      :key="i"
                    >
                      {{ r.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group" style="text-align: left">
                  <label for="province"
                    >อาชีพ <span style="color: red"> *</span></label
                  >
                  <input
                    v-model="descendants.career"
                    type="text"
                    min="0"
                    class="form-control"
                    id="income"
                    placeholder="อาชีพ"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group" style="text-align: left">
                  <label for="username"
                    >รายได้ <span style="color: red"> *</span></label
                  >
                  <div class="input-group mb-3">
                    <input
                      v-model="descendants.income"
                      type="number"
                      min="0"
                      class="form-control"
                      id="income"
                      placeholder="รายได้"
                    />
                    <span class="input-group-text" id="basic-addon2"
                      >บาทต่อเดือน</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group" style="text-align: left">
                  <label for="username"
                    >เบอร์โทรศัพท์ <span style="color: red"> *</span></label
                  >
                  <input
                    v-model="descendants.phone"
                    type="number"
                    min="1"
                    class="form-control"
                    id="username"
                    placeholder="เบอร์โทรศัพท์"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group" style="text-align: left">
                  <label for="province"
                    >สถานะ <span style="color: red"> *</span></label
                  >
                  <select class="form-select mb-3" v-model="descendants.status">
                    <option value="โสด">โสด</option>
                    <option value="แต่งงานแล้ว">แต่งงานแล้ว</option>
                    <option value="หย่าร้าง">หย่าร้าง</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group" style="text-align: left">
                  <label for="username"
                    >สัญชาติ <span style="color: red"> *</span></label
                  >
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-check" style="text-align: left">
                  <label>
                    <input
                      v-model="descendants.nationality"
                      value="ไทย"
                      name="national"
                      class="form-check-input"
                      type="radio"
                      @change="onChangenational"
                    />
                    <span> สัญชาติไทย </span>
                  </label>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-check" style="text-align: left">
                  <label>
                    <input
                      v-model="descendants.nationality"
                      name="national"
                      value="อื่นๆ"
                      class="form-check-input"
                      type="radio"
                      @change="onChangenational"
                    />
                    <span> สัญชาติอื่นๆ </span>
                  </label>
                </div>
              </div>
              <div class="col-md-3">
                <select
                  class="form-select mb-3"
                  v-if="other"
                  v-model="nationalityother"
                >
                  <option value="กัมพูชา">กัมพูชา</option>
                  <option value="ลาว">ลาว</option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <h5 style="text-align: left">ที่อยู่ตามบัตรประชาชน</h5>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2">
                <div class="form-group" style="text-align: left">
                  <label for="username">บ้านเลขที่ </label>
                  <input
                    v-model="descendants.no_house"
                    type="text"
                    class="form-control"
                    id="no_house"
                    placeholder="บ้านเลขที่"
                  />
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group" style="text-align: left">
                  <label for="username">หมู่ที่ </label>
                  <input
                    v-model="descendants.village_no_house"
                    type="number"
                    class="form-control"
                    id="vilegeno_current"
                    placeholder="หมู่ที่"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group" style="text-align: left">
                  <label for="username">ซอย </label>
                  <input
                    v-model="descendants.road_house"
                    type="text"
                    min="0"
                    class="form-control"
                    id="vilegeno_current"
                    placeholder=""
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group" style="text-align: left">
                  <label for="username">ถนน </label>
                  <input
                    v-model="descendants.lane_house"
                    type="text"
                    class="form-control"
                    id="vilegeno_current"
                    placeholder="ถนน"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group" style="text-align: left">
                  <label for="username">หมู่บ้าน </label>
                  <input
                    v-model="descendants.village_house"
                    type="text"
                    min="0"
                    class="form-control"
                    id="income"
                    placeholder="หมู่บ้าน"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group" style="text-align: left">
                  <label for="province"
                    >จังหวัด <span style="color: red"> *</span></label
                  >
                  <select
                    class="form-select mb-3"
                    v-model="descendants.province_house"
                    @change="onChangeProvince"
                  >
                    <option
                      v-for="(p, i) in provinces"
                      v-bind:value="p.PROVINCE_ID"
                      :key="i"
                    >
                      {{ p.PROVINCE_NAME }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group" style="text-align: left">
                  <label for="province"
                    >อำเภอ <span style="color: red"> *</span></label
                  >
                  <select
                    class="form-select mb-3"
                    v-model="descendants.district_house"
                    @change="onChangeDistrict"
                  >
                    <option
                      v-for="(a, i) in amphures"
                      v-bind:value="a.AMPHUR_ID"
                      :key="i"
                    >
                      {{ a.AMPHUR_NAME }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group" style="text-align: left">
                  <label for="province"
                    >ตำบล <span style="color: red"> *</span></label
                  >
                  <select
                    class="form-select mb-3"
                    v-model="descendants.sub_district_house"
                    @change="onChangePostalCode"
                  >
                    <option
                      v-for="(d, i) in districts"
                      v-bind:value="d.DISTRICT_ID"
                      :key="i"
                    >
                      {{ d.DISTRICT_NAME }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group" style="text-align: left">
                  <label for="username"
                    >รหัสไปรษณีย์ <span style="color: red"> *</span></label
                  >
                  <input
                    v-model="postal_code_house"
                    type="text"
                    min="0"
                    class="form-control"
                    id="income"
                    placeholder=""
                  />
                </div>
              </div>
            </div>
            <!-- <div class="row">
              <div class="col-md-4">
                <div class="form-group" style="text-align: left">
                  <label for="username"
                    >ลองจิจูด <span style="color: red"> *</span></label
                  >
                  <input
                    v-model="descendants.longitude_house"
                    type="text"
                    class="form-control"
                    id="vilegeno_current"
                    placeholder=""
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group" style="text-align: left">
                  <label for="username"
                    >ละติจูด <span style="color: red"> *</span></label
                  >
                  <input
                    v-model="descendants.lattitude_house"
                    type="text"
                    min="0"
                    class="form-control"
                    id="income"
                    placeholder=""
                  />
                </div>
              </div>
            </div> -->
            <div class="row">
              <div class="col-md-3">
                <h5 style="text-align: left">ที่อยู่ปัจจุบัน</h5>
              </div>
              <div class="col-md-9" style="text-align: left">
                <label>
                  <input
                    v-model="sameaddress"
                    @click="changeaddress"
                    type="checkbox"
                  />
                  <span> ที่อยู่เดียวกับที่อยู่บัตรประชาชน </span>
                </label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2">
                <div class="form-group" style="text-align: left">
                  <label for="username">บ้านเลขที่ </label>
                  <input
                    v-model="descendants.no_current"
                    type="text"
                    class="form-control"
                    id="no_current"
                    placeholder="บ้านเลขที่"
                  />
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group" style="text-align: left">
                  <label for="username">หมู่ที่ </label>
                  <input
                    v-model="descendants.village_no_current"
                    type="number"
                    class="form-control"
                    id="vilegeno_current"
                    placeholder="หมู่ที่"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group" style="text-align: left">
                  <label for="username">ซอย </label>
                  <input
                    v-model="descendants.road_current"
                    type="text"
                    min="0"
                    class="form-control"
                    id="vilegeno_current"
                    placeholder=""
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group" style="text-align: left">
                  <label for="username">ถนน </label>
                  <input
                    v-model="descendants.lane_current"
                    type="text"
                    class="form-control"
                    id="vilegeno_current"
                    placeholder="ถนน"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group" style="text-align: left">
                  <label for="username">หมู่บ้าน </label>
                  <input
                    v-model="descendants.village_current"
                    type="text"
                    min="0"
                    class="form-control"
                    id="income"
                    placeholder="หมู่บ้าน"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group" style="text-align: left">
                  <label for="province"
                    >จังหวัด <span style="color: red"> *</span></label
                  >
                  <select
                    class="form-select mb-3"
                    v-model="descendants.province_current"
                    @change="onChangeProvincecurrent"
                  >
                    <option
                      v-for="(p, i) in provincescurrent"
                      v-bind:value="p.PROVINCE_ID"
                      :key="i"
                    >
                      {{ p.PROVINCE_NAME }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group" style="text-align: left">
                  <label for="province"
                    >อำเภอ <span style="color: red"> *</span></label
                  >
                  <select
                    class="form-select mb-3"
                    v-model="descendants.district_current"
                    @change="onChangeDistrictcurrent"
                  >
                    <option
                      v-for="(a, i) in amphurescurrent"
                      v-bind:value="a.AMPHUR_ID"
                      :key="i"
                    >
                      {{ a.AMPHUR_NAME }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group" style="text-align: left">
                  <label for="province"
                    >ตำบล <span style="color: red"> *</span></label
                  >
                  <select
                    class="form-select mb-3"
                    v-model="descendants.sub_district_current"
                    @change="onChangePostalCodecurrent"
                  >
                    <option
                      v-for="(d, i) in districtscurrent"
                      v-bind:value="d.DISTRICT_ID"
                      :key="i"
                    >
                      {{ d.DISTRICT_NAME }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group" style="text-align: left">
                  <label for="username"
                    >รหัสไปรษณีย์ <span style="color: red"> *</span></label
                  >
                  <input
                    v-model="postal_code_current"
                    type="text"
                    min="0"
                    class="form-control"
                    id="income"
                    placeholder=""
                  />
                </div>
              </div>
            </div>
            <!-- <div class="row">
              <div class="col-md-4">
                <div class="form-group" style="text-align: left">
                  <label for="username"
                    >ลองจิจูด <span style="color: red"> *</span></label
                  >
                  <input
                    v-model="descendants.longitude_current"
                    type="text"
                    class="form-control"
                    id="vilegeno_current"
                    placeholder=""
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group" style="text-align: left">
                  <label for="username"
                    >ละติจูด <span style="color: red"> *</span></label
                  >
                  <input
                    v-model="descendants.lattitude_current"
                    type="text"
                    min="0"
                    class="form-control"
                    id="income"
                    placeholder=""
                  />
                </div>
              </div>
            </div> -->
          </div>
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <h5 style="text-align: left; margin-left: 10px">
                  แนบเอกสารทายาท
                </h5>
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">เอกสาร</th>
                      <th scope="col">ชื่อไฟล์</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="d in doclist" :key="d.category_doc_id">
                      <td>{{ d.name }}</td>
                      <td>
                        <a
                          :href="d.file_data"
                          target="_black"
                        >
                          {{ d.file_name }}
                        </a>
                      </td>
                      <td style="text-align: right">
                        <input
                          id="my_filedes"
                          class="form-control"
                          type="file"
                          accept="application/pdf"
                          @change="onFileChangeDes"
                        />
                        <button
                          v-if="!d.file_name"
                          id="get_file"
                          class="btn btn-success"
                          @click="onchange(d.category_doc_id)"
                        >
                          เพิ่ม
                        </button>
                        <button
                          class="btn btn-danger"
                          @click="deletedoc(d.category_doc_id)"
                          v-if="d.file_name"
                        >
                          ลบ
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="bd-example mb-5">
            <button
              type="button"
              style="margin-right: 20px; width: 150px"
              class="btn btn-success"
              @click="save()"
            >
              บันทึก
            </button>
            <button
              type="button"
              id="closeddes"
              class="btn btn-danger"
              style="margin-right: 20px; width: 150px"
              data-dismiss="modal"
            >
              ยกเลิก
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="showModalImageDes"
      tabindex="-1"
      role="dialog"
      aria-labelledby="showModalImageDes"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row" style="height: 630px">
              <div class="col-md-12">
                <object v-if="imagetype == 'application/pdf'">
                  <embed
                    id="pdfID"
                    type="text/html"
                    width="100%"
                    height="100%"
                    :src="imagesrc"
                  />
                </object>
                <img
                  v-if="imagetype != 'application/pdf'"
                  :src="imagesrc"
                  style="width:100%"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script src="https://justice-api.sbpac.go.th/picker_date.js"></script>

<script>
import ProvinceDataService from "../../services/ProvinceDataService";
import AmphurDataService from "../../services/AmphurDataService";
import DistrictDataService from "../../services/DistrictDataService";
import RelativesDataService from "../../services/RelativesDataService";
import CasesDataService from "../../services/CasesDataService";
import SuspectsRelativeDataService from "../../services//SuspectsRelativeDataService";
import RelativesAddressDataService from "../../services//RelativesAddressDataService";
import AddressDataService from "../../services/AddressDataService";
import RelationshipsDataService from "../../services/RelationshipsDataService";
import DocsDataService from "../../services/DocsDataService";
import DocumentsDataService from "../../services/DocumentsDataService";
import ConvertService from "../../services/ConvertService";
import PrefixDataService from "../../services/PrefixDataService";
import axios from "axios";

export default {
  components: {},
  data() {
    return {
      name: "HelloWorld",
      descendants: [],
      provinces: [],
      amphures: [],
      districts: [],
      provincescurrent: [],
      amphurescurrent: [],
      districtscurrent: [],
      postal_code_house: "",
      postal_code_current: "",
      sameaddress: false,
      other: false,
      suspect_id: "",
      relative_id: 0,
      list: [],
      address_id: 0,
      relationships: [],
      doclist: [],
      documents: [],
      image: "",
      docname: "",
      doctype: "",
      docid: "",
      catdocid: "",
      imagesrc: "",
      tempdoc: [],
      othername: "",
      cases: [],
      nationalityother: "",
      options: {
        format: "DD/MM/YYYY",
        useCurrent: false,
      },
      imagetype: "",
      prefix: [],
    };
  },

  mounted() {
    // console.log('test');
    this.case_id = this.$route.params.id;

    CasesDataService.getCase(this.case_id).then((res) => {
      this.cases = res.data;
      this.suspect_id = res.data.suspect_id;
      this.getdescendant();
      this.getProvinceAll();
      this.getAmphurAll("");
      this.getDistrictAll("");
      this.getProvinceAllcurrent();
      this.getAmphurAllcurrent("");
      this.getDistrictAllcurrent("");
      this.getrelationship();
      this.getPrefix();
    });
    // console.log(this.case_id);
  },
  methods: {
    getPrefix() {
      PrefixDataService.getPrefixs().then((res) => {
        this.prefix = res.data;
        // console.log(res.data);
      });
    },
    getDoc() {
      DocumentsDataService.getDocRelative(this.relative_id).then((res) => {
        this.documents = res.data;
        // console.log(this.documents);
        DocsDataService.getDocByType(2).then((res) => {
          this.doclist = res.data;
          // console.log(res.data);
          if (this.documents.length != 0) {
            for (let d = 0; d < this.doclist.length; d++) {
              for (let dd = 0; dd < this.documents.length; dd++) {
                if (
                  this.doclist[d].category_doc_id ==
                  this.documents[dd].category_doc_id
                ) {
                  this.doclist[d].document_id = this.documents[dd].document_id;
                  this.doclist[d].file_name = this.documents[dd].file_name;
                  this.doclist[d].file_type = this.documents[dd].file_type;
                  this.doclist[d].file_data = this.documents[dd].file_data;
                }
              }
            }
          }
          this.tempdoc = this.doclist;
          // console.log(this.doclist);
        });
      });
    },
    onchange(catdocid) {
      this.catdocid = catdocid;
      document.getElementById("my_filedes").click();
    },
    deletedoc(id) {
      for (let t = 0; t < this.tempdoc.length; t++) {
        if (this.tempdoc[t].category_doc_id == id) {
          this.tempdoc[t].file_name = "";
          this.tempdoc[t].file_type = "";
          this.tempdoc[t].file_data = "";
        }
      }
      this.getDocTemp();
    },
    onFileChangeDes(evt) {
      const files = evt.target.files || evt.dataTransfer.files;
      const selectedImage = evt.target.files[0]; // get first file
      // console.log(selectedImage);
      this.docname = selectedImage.name;
      this.doctype = selectedImage.type;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      const reader = new FileReader();

      reader.onload = (evt) => {
        this.image = evt.target.result;
        // console.log(this.docid);
        // console.log(this.tempdoc);
        // console.log(this.catdocid);
        const formData = new FormData();
          formData.append("file", file); // appending file
          //  sending file to the backend
          // var http = "https://api-ri.ponnipa.in.th/upload";
          
    var link = ConvertService.gethttp()
    // console.log(link);
          var http = link+"/uploaddescendants?name="+this.docname;
          axios
            .post(http, formData)
            .then((res) => {
              console.log(res.data);
            })
            .catch((err) => {
              console.log(err);
            });
var file_data = link+"/uploads/Descendants/"+this.docname;
        for (let t = 0; t < this.tempdoc.length; t++) {
          if (this.tempdoc[t].category_doc_id == this.catdocid) {
            this.tempdoc[t].file_name = this.docname;
            this.tempdoc[t].file_type = this.doctype;
            this.tempdoc[t].file_data = file_data;
          }
        }
        this.getDocTemp();
      };

      reader.readAsDataURL(file);
    },
    getDocTemp() {
      // console.log(this.doclist);
      // console.log(this.tempdoc);
      DocsDataService.getDocByType(2).then((res) => {
        this.doclist = res.data;
        for (let d = 0; d < this.doclist.length; d++) {
          for (let dd = 0; dd < this.tempdoc.length; dd++) {
            if (
              this.doclist[d].category_doc_id ==
              this.tempdoc[dd].category_doc_id
            ) {
              this.doclist[d].file_name = this.tempdoc[dd].file_name;
              this.doclist[d].file_type = this.tempdoc[dd].file_type;
              this.doclist[d].file_data = this.tempdoc[dd].file_data;
            }
          }
        }
      });
      // console.log(this.doclist);
    },
    getrelationship() {
      RelationshipsDataService.getRelationshipsActive().then((res) => {
        this.relationships = res.data;
      });
    },
    getdescendant() {
      SuspectsRelativeDataService.getDescendantBySuspect(this.suspect_id).then(
        (res) => {
          // console.log(this.list);
          if (this.list.length < 0) {
            this.list = [];
          } else {
            this.list = res.data;
          }
          // if (this.list.length == 0) {
          //           setTimeout(function () {
          //             location.reload();
          //           }, 300);
          //         }
        }
      );
    },
    getsrcimage(type, url) {
      this.imagetype = type;
      this.imagesrc = url;
      // console.log(this.imagesrc);
      document.getElementById("closeddes").click();
    },
    addDescendant(id) {
      if (id != 0) {
        this.relative_id = id;
        this.getDoc();
        // console.log(id);
        RelativesDataService.getRelativeAddress(this.relative_id).then(
          (res) => {
            console.log(res.data);
            this.getAmphurAll(res.data.province_house);
          this.getDistrictAll(res.data.district_house);
          this.getpostcode(res.data.sub_district_house)
          this.getAmphurAllcurrent(res.data.province_current)
          this.getDistrictAllcurrent(res.data.district_current);
          this.getpostcodecurrent(res.data.sub_district_current)
            this.descendants = res.data;

            if (this.descendants.nationality != null) {
              if (this.descendants.nationality != "ไทย") {
                this.other = true;
                this.nationalityother = this.descendants.nationality;
                this.descendants.nationality = "อื่นๆ";
              }
            }

            if (this.descendants.postal_code_house != null) {
              this.postal_code_house = res.data.postal_code_house;
            }
            if (this.descendants.postal_code_current != null) {
              this.postal_code_current = res.data.postal_code_current;
            }
            if (this.descendants.birthday != null) {
              this.descendants.birthday = ConvertService.getdate(
                new Date(this.descendants.birthday)
              );
              document.getElementById("birthday_des").value =
                this.descendants.birthday;
            }
            
            picker_date(document.getElementById("birthday_des"), {
              year_range: "-100:+0",
            });
            this.address_id = res.data.address_id;
            if (this.descendants.is_same == null) {
              this.sameaddress = false;
            }
            // console.log(this.descendants);
          }
        );
      } else {
        this.descendants = [];
        this.postal_code_house = "";
        this.postal_code_current = "";
        this.relative_id = 0;
        this.getDoc();
        document.getElementById("birthday_des").value = null;
        picker_date(document.getElementById("birthday_des"), {
          year_range: "-100:+0",
        });
      }
    },
    save() {
      if (isNaN(this.descendants.id_card_relative)) {
        alert("กรุณากรอกหมายเลขบัตรประชาชน");
      } else if (this.descendants.id_card_relative.length < 13) {
        alert("กรุณากรอกหมายเลขบัตรประชาชนให้ครบ");
      } else if (this.descendants.prefix == null) {
        alert("กรุณาเลือกคำนำหน้าชื่อ");
      } else if (this.descendants.firstname == null) {
        alert("กรุณากรอกชื่อ");
      } else if (this.descendants.lastname == null) {
        alert("กรุณากรอกนามสกุล");
      } else if (document.getElementById("birthday_des").value == "") {
        alert("กรุณากรอกวัน/เดือน/ปีเกิด");
      } else if (this.descendants.gender == null) {
        alert("กรุณาเลือกเพศ");
      } else if (this.descendants.status == null) {
        alert("กรุณาเลือกความสัมพันธ์");
      } else if (this.descendants.career == null) {
        alert("กรุณากรอกอาชีพ");
      } else if (this.descendants.income == null) {
        alert("กรุณากรอกรายได้");
      } else if (this.descendants.phone == null) {
        alert("กรุณากรอกเบอร์โทรศัพท์");
      } else if (this.descendants.phone.length == 9) {
        alert("กรุณากรอกเบอร์โทรศัพท์ให้ครบ 10 หลัก");
      } else if (this.descendants.status == null) {
        alert("กรุณาเลือกสถานะ");
      }
      //  else if (this.descendants.no_house == null) {
      //   alert("กรุณากรอกบ้านเลขที่");
      // }else if (this.descendants.village_no_house == null) {
      //   alert("กรุณากรอกหมู่ที่");
      // }
      else if (this.descendants.nationality == null) {
        alert("กรุณาเลือกสัญชาติ");
      }
      // else if (this.descendants.village_house == null) {
      //   alert("กรุณากรอกหมู่บ้าน");
      // }
      else if (this.descendants.province_house == null) {
        alert("กรุณาเลือกจังหวัดปัจจุบัน");
      } else if (this.descendants.district_house == null) {
        alert("กรุณาเลือกอำเภอปัจจุบัน");
      } else if (this.descendants.sub_district_house == null) {
        alert("กรุณาเลือกตำบลปัจจุบัน");
      }
      // else if (this.descendants.no_current == null) {
      //   alert("กรุณากรอกบ้านเลขที่ตามบัตรประชาชน");
      // }else if (this.descendants.village_current == null) {
      //   alert("กรุณากรอกหมู่บ้านตามบัตรประชาชน");
      // }else if (this.descendants.village_no_current == null) {
      //   alert("กรุณากรอกหมู่ที่ตามบัตรประชาชน");
      // }
      else if (this.descendants.province_current == null) {
        alert("กรุณาเลือกจังหวัดตามบัตรประชาชน");
      } else if (this.descendants.district_current == null) {
        alert("กรุณาเลือกอำเภอตามบัตรประชาชน");
      } else if (this.descendants.sub_district_current == null) {
        alert("กรุณาเลือกตำบลตามบัตรประชาชน");
      } else {
        if (this.descendants.nationality == "อื่นๆ") {
          this.descendants.nationality = this.nationalityother;
        }

        if (this.descendants.id_card_relative != "") {
          RelativesDataService.getIDCardDes(
            this.descendants.id_card_relative,
            this.case_id,
            this.relative_id
          ).then((res) => {
            // console.log(res.data.length);
            if (res.data.length != 0) {
              alert("เลขบัตรประชาชนนี้มีในเคสนี้แล้ว");
            } else {
              var descendant = {
                relative_type_id: 2,
                id_card_relative: this.descendants.id_card_relative,
                prefix: this.descendants.prefix,
                firstname: this.descendants.firstname,
                lastname: this.descendants.lastname,
                gender: this.descendants.gender,
                birthday: ConvertService.createddate(
                  document.getElementById("birthday_des").value
                ),
                phone: this.descendants.phone,
                career: this.descendants.career,
                income: this.descendants.income,
                nationality: this.descendants.nationality,
                relationship: this.descendants.relationship,
                status: this.descendants.status,
                created_by: this.currentUser.user_id,
                updated_by: this.currentUser.user_id,
              };
              // console.log(descendant);
//               if (this.descendants.no_house == null) {
//               this.descendants.no_house = ''                
//               }
//               if (this.descendants.village_no_house == null) {
//               this.descendants.village_no_house = ''                
//               }
//               if (this.descendants.road_house == null) {
//               this.descendants.road_house = ''                
//               }
//               if (this.descendants.lane_house == null) {
//               this.descendants.lane_house = ''                
//               }
//               if (this.descendants.village_house == null) {
//               this.descendants.village_house = ''                
//               }
//               if (this.descendants.no_current == null) {
//               this.descendants.no_current = ''                
//               }              
// if (this.descendants.village_no_current == null) {
//               this.descendants.village_no_current = ''                
//               }
//               if (this.descendants.road_current == null) {
//               this.descendants.road_current = ''                
//               }
//               if (this.descendants.lane_current == null) {
//               this.descendants.lane_current = ''                
//               }
//               if (this.descendants.village_current == null) {
//               this.descendants.village_current = ''                
//               }
              var address = {
                is_same: this.sameaddress,
                no_house: this.descendants.no_house,
                village_no_house: this.descendants.village_no_house,
                road_house: this.descendants.road_house,
                lane_house: this.descendants.lane_house,
                village_house: this.descendants.village_house,
                sub_district_house: this.descendants.sub_district_house,
                district_house: this.descendants.district_house,
                province_house: this.descendants.province_house,
                postal_code_house: this.postal_code_house,
                no_current: this.descendants.no_current,
                village_no_current: this.descendants.village_no_current,
                road_current: this.descendants.road_current,
                lane_current: this.descendants.lane_current,
                village_current: this.descendants.village_current,
                sub_district_current: this.descendants.sub_district_current,
                district_current: this.descendants.district_current,
                province_current: this.descendants.province_current,
                postal_code_current: this.postal_code_current,
                // longitude_house: this.descendants.longitude_house,
                // lattitude_house: this.descendants.lattitude_house,
                // longitude_current: this.descendants.longitude_current,
                // lattitude_current: this.descendants.lattitude_current,
                created_by: this.currentUser.user_id,
                updated_by: this.currentUser.user_id,
              };
              console.log(address);
              // console.log(this.relative_id);
              if (this.relative_id != 0) {
                RelativesDataService.updateRelative(
                  this.relative_id,
                  descendant
                ).then((res) => {
                  AddressDataService.updateAddress(this.address_id, address)
                    .then((res) => {
                      // console.log(res.data);
                      DocumentsDataService.getDocRelative(
                        this.relative_id
                      ).then((res) => {
                        for (let d = 0; d < res.data.length; d++) {
                          DocumentsDataService.deleteDocument(
                            res.data[d].document_id
                          ).then(() => {});
                        }
                      });
                      for (let t = 0; t < this.tempdoc.length; t++) {
                        var doc = {
                          relative_id: this.relative_id,
                          category_doc_id: this.tempdoc[t].category_doc_id,
                          file_name: this.tempdoc[t].file_name,
                          file_type: this.tempdoc[t].file_type,
                          file_data: this.tempdoc[t].file_data,
                          created_by: this.currentUser.user_id,
                          updated_by: this.currentUser.user_id,
                        };
                        DocumentsDataService.createDocument(doc).then(() => {
                          // setTimeout(function () {
                          //   location.reload();
                          // }, 300);
                        });
                      }
                      this.getdescendant();
                      document.getElementById("closeddes").click();
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                });
              } else {
                 AddressDataService.createAddress(address).then((res) => {
                      var address_id = res.data.id;
                      console.log(address_id);
                        RelativesDataService.createRelative(descendant).then((res) => {
                  this.relative_id = res.data.id;
                  console.log(res.data);
                  var suspectrelative = {
                    suspect_id: this.suspect_id,
                    relative_id: this.relative_id,
                    created_by: this.currentUser.user_id,
                    updated_by: this.currentUser.user_id,
                  };
                  console.log(suspectrelative);
                  SuspectsRelativeDataService.createSuspectRelative(
                    suspectrelative
                  ).then(() => {
                    var relativeaddress = {
                        relative_id: this.relative_id,
                        address_id: address_id,
                        created_by: this.currentUser.user_id,
                        updated_by: this.currentUser.user_id,
                      };
                      console.log(relativeaddress);
                      RelativesAddressDataService.createRelativeAddress(
                        relativeaddress
                      ).then(() => {
                        //  this.getdescendant();
                    setTimeout(function () {
                location.reload();
              }, 1000);
                      });
                  });
                  for (let t = 0; t < this.tempdoc.length; t++) {
                          var doc = {
                            relative_id: this.relative_id,
                            category_doc_id: this.tempdoc[t].category_doc_id,
                            file_name: this.tempdoc[t].file_name,
                            file_type: this.tempdoc[t].file_type,
                            file_data: this.tempdoc[t].file_data,
                            created_by: this.currentUser.user_id,
                            updated_by: this.currentUser.user_id,
                          };
                          DocumentsDataService.createDocument(doc).then(() => {
                            // setTimeout(function () {
                            //   location.reload();
                            // }, 300);
                          });
                        }
                 document.getElementById("closeddes").click();
                //  this.getdescendant();
              //       setTimeout(function () {
              //   location.reload();
              // }, 1000);
                });
                 });
              
              }
            }
          });
        }
      }
    },
    deleteDescendant(re) {
      RelativesAddressDataService.deleteRelativeAddress(
          re.relatives_id
        ).then(() => {
          if (this.list.length == 1) {
                  this.list = [];
                }
                this.getdescendant();
        });
    },
    onlyNumberDes($event) {
      if (!isNaN(this.descendants.id_card_relative)) {
        let keyCode = $event.keyCode ? $event.keyCode : $event.which;
        if (
          keyCode < 48 ||
          keyCode > 57 ||
          this.descendants.id_card_relative.length == 13
        ) {
          // 46 is dot
          $event.preventDefault();
        }
      }
    },
    onChangenational(event) {
      // console.log(event.target.value);
      if (event.target.value == "อื่นๆ") {
        this.other = true;
      } else {
        this.other = false;
      }
    },
    changeaddress() {
      if (this.sameaddress == false) {
        this.getAmphurAllcurrent(this.descendants.province_house)
        this.getDistrictAllcurrent(this.descendants.district_house);
        this.getpostcodecurrent(this.descendants.sub_district_house);
        this.descendants.no_current = this.descendants.no_house;
        this.descendants.village_no_current = this.descendants.village_no_house;
        this.descendants.road_current = this.descendants.road_house;
        this.descendants.lane_current = this.descendants.lane_house;
        this.descendants.village_current = this.descendants.village_house;
        this.descendants.sub_district_current =
          this.descendants.sub_district_house;
        this.descendants.district_current = this.descendants.district_house;
        this.descendants.province_current = this.descendants.province_house;
        this.postal_code_current = this.postal_code_house;
        // this.descendants.longitude_current = this.descendants.longitude_house;
        // this.descendants.lattitude_current = this.descendants.lattitude_house;
      } else if (this.sameaddress == true && this.descendants.length == 0) {
        this.descendants.no_current = "";
        this.descendants.village_no_current = "";
        this.descendants.road_current = "";
        this.descendants.lane_current = "";
        this.descendants.village_current = "";
        this.descendants.sub_district_current = "";
        this.descendants.district_current = "";
        this.descendants.province_current = "";
        this.descendants.postal_code_current = "";
        // this.descendants.longitude_current = "";
        // this.descendants.lattitude_current = "";
      }
    },
    onChangeProvincecurrent(event) {
      // console.log(this.user.province)
      var province_id = event.target.value;
      this.descendants.district_current = "";
      this.descendants.sub_district_current = "";
      this.postal_code_current = "";
      this.getAmphurAllcurrent(province_id);
    },
    onChangeDistrictcurrent(event) {
      // console.log(this.user.province)
      var amphur_id = event.target.value;
      this.descendants.sub_district_current = "";
      this.getDistrictAllcurrent(amphur_id);
    },
    onChangePostalCodecurrent(event) {
      //  console.log(event.target.value)
      var district_id = event.target.value;
      this.getpostcodecurrent(district_id);
    },
    getProvinceAllcurrent() {
      ProvinceDataService.getProvinceAll().then((res) => {
        this.provincescurrent = res.data;
      });
    },
    getAmphurAllcurrent(id) {
      if (id == "") {
        AmphurDataService.getAmphursAll().then((res) => {
          this.amphurescurrent = res.data;
        });
      } else {
        AmphurDataService.getAmphurAll(id).then((res) => {
          this.amphurescurrent = res.data;
        });
      }
    },
    getpostcodecurrent(id) {
      DistrictDataService.getPostCode(id).then((res) => {
        // console.log(res.data[0].POST_CODE);
        if (res.data[0]) {
          this.postal_code_current = res.data[0].POST_CODE;
        }
      });
    },
    getDistrictAllcurrent(id) {
      if (id == "") {
        DistrictDataService.getDistrictsAll().then((res) => {
          this.districtscurrent = res.data;
        });
      } else {
        DistrictDataService.getDistrictAll(id).then((res) => {
          this.districtscurrent = res.data;
          this.postal_code_current = "";
        });
      }
    },
    onChangeProvince(event) {
      // console.log(this.user.province)
      var province_id = event.target.value;
      this.descendants.district_house = "";
      this.descendants.sub_district_house = "";
      this.postal_code_house = "";
      this.getAmphurAll(province_id);
    },

    onChangeDistrict(event) {
      // console.log(this.user.province)
      var amphur_id = event.target.value;
      this.descendants.sub_district_house = "";
      this.getDistrictAll(amphur_id);
    },
    onChangePostalCode(event) {
      //  console.log(event.target.value)
      var district_id = event.target.value;
      this.getpostcode(district_id);
    },
    getProvinceAll() {
      ProvinceDataService.getProvinceAll().then((res) => {
        this.provinces = res.data;
      });
    },
    getAmphurAll(id) {
      if (id == "") {
        AmphurDataService.getAmphursAll().then((res) => {
          this.amphures = res.data;
        });
      } else {
        AmphurDataService.getAmphurAll(id).then((res) => {
          this.amphures = res.data;
        });
      }
    },
    getpostcode(id) {
      DistrictDataService.getPostCode(id).then((res) => {
        // console.log(res.data[0]);
        if (res.data[0]) {
          this.postal_code_house = res.data[0].POST_CODE;
        }
      });
    },
    getDistrictAll(id) {
      if (id == "") {
        DistrictDataService.getDistrictsAll().then((res) => {
          this.districts = res.data;
        });
      } else {
        DistrictDataService.getDistrictAll(id).then((res) => {
          this.districts = res.data;
          this.postal_code_house = "";
        });
      }
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  font-family: "Kanit" !important;
}
.row {
  max-width: 1200px;
  margin: auto;
  background: white;
  padding: 10px;
  color: black !important;
}
.nav-link {
  color: black !important;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #09cc68;
}
.modal-content {
  float: center;
  text-align: center;
  width: 800px;
}
.modal {
  text-align: center;
  padding: 0 !important;
}
.modal:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}
.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  max-width: 700px;
}
#my_filedes {
  display: none;
}
</style>
