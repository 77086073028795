import http from "../http-common";

class AmphurDataService {
  getAmphurs() {
    return http.get('/amphur');
  }
  getname(id) {
    return http.get('/amphur/'+id);
  }
  getAmphur(id) {
    return http.get(`/amphur/?id=`+id);
  }
  getAmphursAll() {
    return http.get('/amphur/getamphurAll');
  }
  getAmphurAll(id) {
    return http.get(`/amphur/getamphurAll?id=`+id);
  }
}

export default new AmphurDataService();