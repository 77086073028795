<template>
  <div class="hello mt-3">
    <table class="table table-bordered">
      <thead>
        <tr>
          <th scope="col" style="text-align: center">วันที่เกิดเหตุ</th>
          <th scope="col" style="text-align: center">จุดเกิดเหตุการณ์</th>
          <th></th>
        </tr>
      </thead>
      <tbody v-if="list.length > 0">
        <tr v-for="(l, i) in list" :key="i">
          <td>{{ convertdatetothai(l.date) }}</td>
          <td style="text-align: center">{{ l.crime_scene }}</td>
          <td style="text-align: right">
            <button class="btn btn-success" style="color: white">
              <a
                data-toggle="modal"
                href="#"
                style="color: white"
                data-target="#addModalCase"
                @click="sendInfo(l.event_id)"
                >ดู</a
              >
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="list.length == 0" style="text-align: center">ไม่พบข้อมูล</div>

    <!-- Modal -->
    <div
      class="modal fade"
      id="addModalCase"
      tabindex="-1"
      role="dialog"
      aria-labelledby="addModalCaseLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <h5>ข้อมูลเหตุการณ์</h5>
            </div>
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-4">
                <h6 style="text-align: left">วันที่เกิดเหตุ</h6>
              </div>
              <div class="col-md-6">
                <h6 style="text-align: left">
                  {{ convertdatetothai(cases.date) }}
                </h6>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-4">
                <h6 style="text-align: left">สถานที่เกิดเหตุ</h6>
              </div>
              <div class="col-md-6">
                <h6 style="text-align: left">
                  หมู่ที่ {{ cases.village_no }} ซอย {{ cases.road }} ถนน
                  {{ cases.lane }} หมู่บ้าน {{ cases.village }} ตำบล
                  {{ cases.sub_district }} อำเภอ {{ cases.district }} จังหวัด
                  {{ cases.province }} รหัสไปรษณีย์ {{ postal_code }}
                </h6>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-4">
                <h6 style="text-align: left">จุดเกิดเหตุการณ์</h6>
              </div>
              <div class="col-md-6">
                <h6 style="text-align: left">{{ cases.crime_scene }}</h6>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-4">
                <h6 style="text-align: left">รายละเอียดเหตุการณ์</h6>
              </div>
              <div class="col-md-6">
                <h6 style="text-align: left">
                  {{ cases.event_detail || "-" }}
                </h6>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-4">
                <h6 style="text-align: left">ประเภทคดี</h6>
              </div>
              <div class="col-md-6">
                <h6 style="text-align: left">{{ cases.case_type_id }}</h6>
              </div>
            </div>
          </div>
          <div class="bd-example mb-5">
            <button
              type="button"
              id="closedevent"
              class="btn btn-danger"
              style="margin-right: 20px; width: 150px"
              data-dismiss="modal"
            >
              ปิดหน้าต่าง
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

  <script src="https://justice-api.sbpac.go.th/picker_date.js"></script>

<script>
import ProvinceDataService from "../../services/ProvinceDataService";
import AmphurDataService from "../../services/AmphurDataService";
import DistrictDataService from "../../services/DistrictDataService";
import CasesTypeDataService from "../../services/CasesTypeDataService";
import EventsDataService from "../../services/EventsDataService";
import CasesDataService from "../../services/CasesDataService";
import ConvertService from "../../services/ConvertService";

export default {
  components: {},
  data() {
    return {
      name: "HelloWorld",
      provinces: [],
      amphures: [],
      districts: [],
      cases: [],
      casetype: [],
      postal_code: "",
      case_id: "",
      list: [],
      event_id: 0,
      header: "",
      casesedit: [],
      options: {
        format: "DD/MM/YYYY",
        useCurrent: false,
      },
    };
  },
  mounted() {
    this.case_id = this.$route.params.id;
    if (this.case_id != 0) {
      this.getprovince();
      this.getamphur("");
      this.getdistrict("");
      this.geteventtype();
      this.getEvent();
      this.getcase();
    }
  },
  methods: {
    getcase() {
      CasesDataService.getCase(this.case_id).then((res) => {
        this.casesedit = res.data;
      });
    },
    getEvent() {
      EventsDataService.getEventAll(this.case_id).then((res) => {
        this.list = res.data;
        // console.log(this.list);
      });
    },
    sendInfo(id) {
      var year = new Date().getFullYear()
    var start = year-2004
    var before = "-"+start+":+0"
      if (id != 0) {
        this.header = "แก้ไข";
        this.event_id = id;
        // console.log(this.event_id);
        EventsDataService.getEventcaseByID(this.event_id).then((res) => {
          this.cases = res.data;
          // console.log(res.data);
          this.postal_code = res.data.postal_code;
        });
      } else {
        this.header = "เพิ่ม";
        this.cases = [];
        this.postal_code = "";
        picker_date(document.getElementById("case_date"), {
          year_range: before,
        });
      }
    },
    save() {
      if (document.getElementById("case_date").value == "") {
        alert("กรุณาเลือกวันที่เกิดเหตุ");
      } else if (this.cases.province == null) {
        alert("กรุณาเลือกจังหวัด");
      } else if (this.cases.district == null) {
        alert("กรุณาเลือกอำเภอ");
      } else if (this.cases.sub_district == null) {
        alert("กรุณาเลือกตำบล");
      }
      // else if (this.cases.crime_scene == null) {
      //   alert("กรุณากรอกจุดเกิดเหตุการณ์")
      // }
      else if (this.cases.case_type_id == null) {
        alert("กรุณาเลือกประเภทคดี");
      } else {
        var event = {
          case_type_id: this.cases.case_type_id,
          case_id: this.case_id,
          date: ConvertService.createddate(
            document.getElementById("case_date").value
          ),
          crime_scene: this.cases.crime_scene,
          village_no: this.cases.village_no,
          road: this.cases.road,
          lane: this.cases.lane,
          village: this.cases.village,
          sub_district: this.cases.sub_district,
          district: this.cases.district,
          province: this.cases.province,
          postal_code: this.postal_code,
          event_detail: this.cases.event_detail,
          created_by: this.currentUser.user_id,
          updated_by: this.currentUser.user_id,
        };
        // console.log(event);
        if (this.event_id == 0) {
          EventsDataService.createEvent(event).then((res) => {
            // console.log(res.data);
            document.getElementById("closedevent").click();
            setTimeout(function () {
              location.reload();
            }, 300);
          });
        } else {
          EventsDataService.updateEvent(this.event_id, event).then((res) => {
            // console.log(res.data);
            document.getElementById("closedevent").click();
            setTimeout(function () {
              location.reload();
            }, 300);
          });
        }
      }
    },
    geteventtype() {
      CasesTypeDataService.getCasesTypesAll().then((res) => {
        this.casetype = res.data;
      });
    },
    getprovince() {
      ProvinceDataService.getProvince().then((res) => {
        this.provinces = res.data;
      });
    },
    getamphur(id) {
      if (id == "") {
        AmphurDataService.getAmphurs().then((res) => {
          this.amphures = res.data;
        });
      } else {
        AmphurDataService.getAmphur(id).then((res) => {
          this.amphures = res.data;
        });
      }
    },
    getpostcode(id) {
      DistrictDataService.getPostCode(id).then((res) => {
        // console.log(res.data[0].POST_CODE);
        this.postal_code = res.data[0].POST_CODE;
      });
    },
    getdistrict(id) {
      if (id == "") {
        DistrictDataService.getDistricts().then((res) => {
          this.districts = res.data;
        });
      } else {
        DistrictDataService.getDistrict(id).then((res) => {
          this.districts = res.data;
          this.postal_code_house = "";
        });
      }
    },
    onChangeProvince(event) {
      // console.log(this.user.province)
      var province_id = event.target.value;
      this.getamphur(province_id);
    },

    onChangeDistrict(event) {
      // console.log(this.user.province)
      var amphur_id = event.target.value;
      this.getdistrict(amphur_id);
    },
    onChangePostalCode(event) {
      //  console.log(event.target.value)
      var district_id = event.target.value;
      this.getpostcode(district_id);
    },
    convertdatetothai(datetimes) {
      if (datetimes) {
        const date = new Date(datetimes);
        const result = date.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return result;
      } else {
        return "-";
      }
    },
    deleteevent(id) {
      EventsDataService.deleteEvent(id).then(() => {
        if (this.list.length == 1) {
          this.list = [];
        }
        this.getEvent();
      });
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  font-family: "Kanit" !important;
}
.row {
  max-width: 1200px;
  margin: auto;
  background: white;
  padding: 10px;
  color: black !important;
}
.nav-link {
  color: black !important;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #09cc68;
}
.modal-content {
  float: center;
  text-align: center;
  width: 800px;
}
.modal {
  text-align: center;
  padding: 0 !important;
}
.modal:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}
.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  max-width: 700px;
}
</style>
