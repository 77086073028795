import http from "../http-common";

class RelationshipsDataService {
  getRelationships() {
    return http.get('/relationship');
  }
  getRelationship(id) {
    return http.get(`/relationship/`+id);
  }
  createRelationship(data) {
    return http.post('/relationship', data);
  }
  updateRelationship(id,data) {
    return http.put(`/relationship/${id}`, data);
  }
  getRelationshipsActive() {
    return http.get('/relationship/getrelationship');
  }
}

export default new RelationshipsDataService();