<template>
  <div class="hello">
    <HomeIcon />
    <div class="mt-5">
      <h3 style="text-align: center">ประเภท{{ header }}</h3>
      <div class="row">
        <div class="col-12" v-if="type == 'doc'">
          <div class="form-group" style="text-align: left">
            <label for="province"
              >หมวดหมู่เอกสาร<span style="color: red"> *</span></label
            >
            <select
              class="form-select mb-3"
              v-model="doc_type_id"
              @change="onChangeDocType"
            >
              <option
                v-for="(p, i) in doctypes"
                v-bind:value="p.category_type_id"
                :key="i"
              >
                {{ p.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-10"></div>
        <div class="col-2" style="text-align: right">
          <button
            type="button"
            class="btn btn-block btn-success"
            data-toggle="modal"
            data-target="#addModal"
            @click="sendInfo('add', 0)"
          >
            เพิ่ม
          </button>
        </div>
        <!-- Modal -->
        <div
          class="modal fade"
          id="addModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="addModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <!-- Role -->
                <h4 style="text-align: center; font-weight: bold">
                  {{ title }}
                </h4>
                <div class="row mt-5">
                  <div class="col-12">
                    <div class="form-group" style="text-align: left">
                      <label for="name"
                        >ประเภท{{ header
                        }}<span style="color: red"> *</span></label
                      >
                      <input
                        v-model="data.name"
                        type="text"
                        class="form-control"
                        id="name"
                        :placeholder="'กรุณากรอกประเภท' + header"
                      />
                    </div>
                  </div>
                  <div class="col-2" style="text-align: left">
                    <label for="status"
                      >สถานะ<span style="color: red"> *</span></label
                    >
                  </div>
                  <div class="col-3" style="text-align: left">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        id="active"
                        value="Active"
                        type="radio"
                        name="active"
                        v-model="ischeck"
                      />
                      <label class="form-check-label" for="active"
                        >ใช้งานอยู่</label
                      >
                    </div>
                  </div>
                  <div class="col-7">
                    <div class="form-check" style="text-align: left">
                      <input
                        class="form-check-input"
                        id="disactive"
                        value="Disactive"
                        type="radio"
                        name="active"
                        v-model="ischeck"
                      />
                      <label class="form-check-label" for="disactive"
                        >เลิกใช้งาน</label
                      >
                    </div>
                  </div> 
                  <h4 v-if="type == 'doc' && doc_type_id == 4" class="mt-5" style="text-align: center; font-weight: bold">
                  กำหนดลักษณะเหตุการณ์
                </h4>
                <div class="row" v-if="type == 'doc' && doc_type_id == 4">
                  <div class="col-12" v-for="m in eventtype" :key="m.event_type_id">
                    <div class="form-group" style="text-align: left">
                      <label>
                        <input
                          type="checkbox"
                          v-model="event_type_id"
                          :id="m.event_type_id"
                          :value="m.event_type_id"
                        />
                        <span> {{ m.name }}</span>
                      </label>
                    </div>
                  </div>
                </div>                 
                </div>
              </div>
              <div class="bd-example mb-5">
                <button
                  type="button"
                  style="margin-right: 20px; width: 150px"
                  class="btn btn-success"
                  @click="save()"
                >
                  บันทึก
                </button>
                <button
                  type="button"
                  id="closed"
                  class="btn btn-danger"
                  style="margin-right: 20px; width: 150px"
                  data-dismiss="modal"
                >
                  ยกเลิก
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-5 mb-5" v-if="list.length == 0">
        <h4 style="text-align: center">ไม่พบข้อมูลในระบบ</h4>
      </div>

      <div class="row mt-5" v-else>
        <div class="col-1"></div>
        <div class="col-7" v-if="type !== 'doc'">ประเภท{{ header }}</div>
        <div class="col-2" v-if="type !== 'doc'">สถานะ</div>
        <div class="col-4" v-if="type == 'doc'">ประเภท{{ header }}</div>
        <div class="col-3" v-if="type == 'doc'">หมวดหมู่{{ header }}</div>
        <div class="col-2" v-if="type == 'doc'">สถานะ</div>
        <div class="col-2" style="text-align: right">จัดการ</div>
      </div>
      <div class="row card mt-2" v-for="(l, index) in list" :key="index">
        <div class="card-body p-0">
          <div class="row">
            <div class="col-1">{{ index + 1 }}</div>
            <div class="col-7" v-if="type !== 'doc'">{{ l.name }}</div>
            <div class="col-4" v-if="type == 'doc'">{{ l.name }}</div>
            <div class="col-3" v-if="type == 'doc'">{{ l.type }}</div>
            <div class="col-3" v-if="l.is_active == true">ใช้งานอยู่</div>
            <div class="col-3" v-if="l.is_active == false">เลิกใช้งาน</div>
            <div class="col-1" style="text-align: right">
              <a
                v-if="type == 'case'"
                data-toggle="modal"
                data-target="#addModal"
                href="#"
                @click="sendInfo('edit', l.case_type_id)"
                ><i class="fas fa-pencil-alt"></i
              ></a>
              <a
                v-if="type == 'suspacts'"
                data-toggle="modal"
                data-target="#addModal"
                href="#"
                @click="sendInfo('edit', l.suspect_type_id)"
                ><i class="fas fa-pencil-alt"></i
              ></a>
              <a
                v-if="type == 'detained'"
                data-toggle="modal"
                data-target="#addModal"
                href="#"
                @click="sendInfo('edit', l.department_detained_type_id)"
                ><i class="fas fa-pencil-alt"></i
              ></a>
              <a
                v-if="type == 'doc'"
                data-toggle="modal"
                data-target="#addModal"
                href="#"
                @click="sendInfo('edit', l.category_doc_id)"
                ><i class="fas fa-pencil-alt"></i
              ></a>
              <a
                v-if="type == 'relationship'"
                data-toggle="modal"
                data-target="#addModal"
                href="#"
                @click="sendInfo('edit', l.relationship_type_id)"
                ><i class="fas fa-pencil-alt"></i
              ></a>
              <a
                v-if="type == 'damage'"
                data-toggle="modal"
                data-target="#addModal"
                href="#"
                @click="sendInfo('edit', l.damage_type_id)"
                ><i class="fas fa-pencil-alt"></i
              ></a>
              <a
                v-if="type == 'prefix'"
                data-toggle="modal"
                data-target="#addModal"
                href="#"
                @click="sendInfo('edit', l.prefix_id)"
                ><i class="fas fa-pencil-alt"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col text-center">
        <button
          class="btn btn-warning"
          style="color: white"
          @click="hasHistory() ? $router.go(-1) : $router.push('/')"
        >
          ย้อนกลับ
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import CasesTypeDataService from "../../services/CasesTypeDataService";
import SuspectsTypeDataService from "../../services/SuspectsTypeDataService";
import DetainedDataService from "../../services/DetainedDataService";
import DocsDataService from "../../services/DocsDataService";
import RelationshipsDataService from "../../services/RelationshipsDataService";
import DamagesDataService from "../../services/DamagesDataService";
import HomeIcon from "../HomeIcon.vue";
import PrefixDataService from "../../services/PrefixDataService";
import EventTypesDataService from "../../services/EventTypesDataService";
import DocsEventDataService from "../../services/DocsEventDataService";

export default {
  components: {
    HomeIcon,
  },

  data() {
    return {
      name: "HelloWorld",
      ischeck: "",
      header: "",
      id: 0,
      title: "",
      list: [],
      data: [],
      type: "",
      nametype: "",
      doc_type_id: 1,
      doctypes: [],
      eventtype:[],
      event_type_id:[]
    };
  },
  mounted() {
    this.type = this.$route.params.name;
    // console.log(this.type);
    this.getlist();
  },
  methods: {
    getDocEvent(category_doc_id) {
      DocsEventDataService.getDocEventByEventID(category_doc_id).then((res) => {
        // console.log(res.data.event_type_id);
        if (res.data.event_type_id == null) {
          this.event_type_id = [];
        } else {
          this.event_type_id = JSON.parse(res.data.event_type_id);
        }
      });
    },
    geteventtype()
{
EventTypesDataService.getEventTypesAll().then((res)=>{
  // console.log(res.data);
  this.eventtype = res.data
})
},
    getdoctype() {
      DocsDataService.getDocTypes(this.doc_type_id).then((res) => {
        this.doctypes = res.data;
      });
    },
    getlist() {
      if (this.type == "case") {
        this.header = "คดี";
        CasesTypeDataService.getCasesTypes().then((res) => {
          this.list = res.data;
          // console.log(res.data);
        });
      } else if (this.type == "suspacts") {
        this.header = "ผู้เสียหาย";
        SuspectsTypeDataService.getSuspectTypes().then((res) => {
          this.list = res.data;
          // console.log(res.data);
        });
      } else if (this.type == "detained") {
        this.header = "หน่วยงานที่ควบคุมตัว";
        DetainedDataService.getDetained().then((res) => {
          this.list = res.data;
          // console.log(res.data);
        });
      } else if (this.type == "doc") {
        this.getdoctype();
      this.geteventtype()
        this.header = "เอกสาร";
        this.getDocByType();
      } else if (this.type == "relationship") {
        this.header = "ความสัมพันธ์ของทายาท";
        RelationshipsDataService.getRelationships().then((res) => {
          this.list = res.data;
          // console.log(res.data);
        });
      } else if (this.type == "damage") {
        this.header = "ความเสียหายที่ได้รับ";
        DamagesDataService.getDamages().then((res) => {
          this.list = res.data;
          // console.log(res.data);
        });
      }else if (this.type == "prefix") {
        this.header = "คำนำหน้า";
        PrefixDataService.getPrefixs().then((res) => {
          this.list = res.data;
          // console.log(res.data);
        });
      }
    },
    getid(id) {
      if (this.type == "case") {
        this.header = "คดี";
        CasesTypeDataService.getCaseType(id).then((res) => {
          // console.log(res.data);
          this.data = res.data;
          this.id = id;
          if (this.data.is_active == true) {
            this.ischeck = "Active";
          } else {
            this.ischeck = "Disactive";
          }
        });
      } else if (this.type == "suspacts") {
        this.header = "ผู้เสียหาย";
        SuspectsTypeDataService.getSuspectType(id).then((res) => {
          // console.log(res.data);
          this.data = res.data;
          if (this.data.is_active == true) {
            this.ischeck = "Active";
          } else {
            this.ischeck = "Disactive";
          }
        });
      } else if (this.type == "detained") {
        this.header = "หน่วยงานที่ควบคุมตัว";
        DetainedDataService.getDetain(id).then((res) => {
          // console.log(res.data);
          this.data = res.data;
          if (this.data.is_active == true) {
            this.ischeck = "Active";
          } else {
            this.ischeck = "Disactive";
          }
        });
      } else if (this.type == "doc") {
        this.header = "เอกสาร";
        DocsDataService.getDoc(id).then((res) => {
          this.getDocEvent(id);
          // console.log(res.data);
          this.data = res.data;
          if (this.data.is_active == true) {
            this.ischeck = "Active";
          } else {
            this.ischeck = "Disactive";
          }
        });
      } else if (this.type == "relationship") {
        this.header = "ความสัมพันธ์ของทายาท";
        RelationshipsDataService.getRelationship(id).then((res) => {
          // console.log(res.data);
          this.data = res.data;
          if (this.data.is_active == true) {
            this.ischeck = "Active";
          } else {
            this.ischeck = "Disactive";
          }
        });
      } else if (this.type == "damage") {
        this.header = "ความเสียหายที่ได้รับ";
        DamagesDataService.getDamage(id).then((res) => {
          // console.log(res.data);
          this.data = res.data;
          if (this.data.is_active == true) {
            this.ischeck = "Active";
          } else {
            this.ischeck = "Disactive";
          }
        });
      }else if (this.type == "prefix") {
        this.header = "คำนำหน้า";
        PrefixDataService.getPrefix(id).then((res) => {
          // console.log(res.data);
          this.data = res.data;
          if (this.data.is_active == true) {
            this.ischeck = "Active";
          } else {
            this.ischeck = "Disactive";
          }
        });
      }
    },
    onChangeDocType(event) {
      // console.log(this.user.province)
      // console.log(this.doc_type_id);
      this.doc_type_id = event.target.value;
      this.getDocEvent(this.doc_type_id)
      this.getDocByType();
    },
    sendInfo(item, id) {
      // console.log(item, id);
      if (item == "add") {
        this.ischeck = "Active";
        this.data = [];        
        this.event_type_id=[]
        this.title = "เพิ่มประเภท" + this.header;
      } else {
        this.title = "แก้ไขประเภท" + this.header;
        this.getid(id);
      }
    },
    save() {
      if (this.ischeck == "Active") {
        this.data.is_active = true;
      } else {
        this.data.is_active = false;
      }
      var listdata = {
        name: this.data.name,
        is_active: this.data.is_active,
        created_by: this.currentUser.user_id,
        updated_by: this.currentUser.user_id,
      };
      // console.log(listdata);
      if (this.data.name == "" || this.data.name == null) {
        alert("กรุณากรอกข้อมูลให้ครบ");
      } else {
        if (this.type == "case") {
          if (this.data.case_type_id) {
            CasesTypeDataService.updateCaseType(this.data.case_type_id, listdata)
              .then(() => {
                // console.log(response.data);
                this.data = [];
                this.ischeck = true;
                this.getlist();
                document.getElementById("closed").click();
              })
              .catch((e) => {
                console.log(e);
              });
          } else {
            CasesTypeDataService.createCaseType(listdata)
              .then(() => {
                // console.log(response.data);
                this.data = [];
                this.ischeck = true;
                this.getlist();
                document.getElementById("closed").click();
              })
              .catch((e) => {
                console.log(e);
              });
          }
        }
        if (this.type == "suspacts") {
          if (this.data.suspect_type_id) {
            SuspectsTypeDataService.updateSuspectType(
              this.data.suspect_type_id,
              listdata
            )
              .then(() => {
                // console.log(response.data);
                this.data = [];
                this.ischeck = true;
                this.getlist();
                document.getElementById("closed").click();
              })
              .catch((e) => {
                console.log(e);
              });
          } else {
            SuspectsTypeDataService.createSuspectType(listdata)
              .then(() => {
                // console.log(response.data);
                this.data = [];
                this.ischeck = true;
                this.getlist();
                document.getElementById("closed").click();
              })
              .catch((e) => {
                console.log(e);
              });
          }
        }
        if (this.type == "detained") {
          if (this.data.department_detained_type_id) {
            DetainedDataService.updateDetain(
              this.data.department_detained_type_id,
              listdata
            )
              .then(() => {
                // console.log(response.data);
                this.data = [];
                this.ischeck = true;
                this.getlist();
                document.getElementById("closed").click();
              })
              .catch((e) => {
                console.log(e);
              });
          } else {
            DetainedDataService.createDetain(listdata)
              .then(() => {
                // console.log(response.data);
                this.data = [];
                this.ischeck = true;
                this.getlist();
                document.getElementById("closed").click();
              })
              .catch((e) => {
                console.log(e);
              });
          }
        }
        if (this.type == "doc") {
          DocsEventDataService.deleteDocEventBycategorydocid(this.data.category_doc_id).then(()=>{
            // console.log(res.data);
            for (let e = 0; e < this.event_type_id.length; e++) {
            var docevent = {
            event_type_id: this.event_type_id[e],
            category_doc_id: this.data.category_doc_id,
            is_active: true,
            created_by: this.currentUser.user_id,
            updated_by: this.currentUser.user_id,
          };
            DocsEventDataService.createDocEvent(docevent).then(()=>{
              // console.log(res.data);
            })            
          }
          })
          var listdocdata = {
            name: this.data.name,
            type: this.doc_type_id,
            is_active: this.data.is_active,
            created_by: this.currentUser.user_id,
            updated_by: this.currentUser.user_id,
          };
          // console.log(listdocdata);
          if (this.data.category_doc_id) {
            DocsDataService.updateDoc(this.data.category_doc_id, listdocdata)
              .then(() => {
                // console.log(response.data);
                this.data = [];
                this.ischeck = true;
                this.getDocByType();
                document.getElementById("closed").click();
              })
              .catch((e) => {
                console.log(e);
              });
          } else {
            DocsDataService.createDoc(listdocdata)
              .then(() => {
                // console.log(response.data);
                this.data = [];
                this.ischeck = true;
                this.getDocByType();
                document.getElementById("closed").click();
              })
              .catch((e) => {
                console.log(e);
              });
          }
        }
        if (this.type == "relationship") {
          if (this.data.relationship_type_id) {
            RelationshipsDataService.updateRelationship(
              this.data.relationship_type_id,
              listdata
            )
              .then(() => {
                // console.log(response.data);
                this.data = [];
                this.ischeck = true;
                this.getlist();
                document.getElementById("closed").click();
              })
              .catch((e) => {
                console.log(e);
              });
          } else {
            RelationshipsDataService.createRelationship(listdata)
              .then(() => {
                // console.log(response.data);
                this.data = [];
                this.ischeck = true;
                this.getlist();
                document.getElementById("closed").click();
              })
              .catch((e) => {
                console.log(e);
              });
          }
        }
        if (this.type == "damage") {
          if (this.data.damage_type_id) {
            DamagesDataService.updateDamage(this.data.damage_type_id, listdata)
              .then(() => {
                // console.log(response.data);
                this.data = [];
                this.ischeck = true;
                this.getlist();
                document.getElementById("closed").click();
              })
              .catch((e) => {
                console.log(e);
              });
          } else {
            DamagesDataService.createDamage(listdata)
              .then(() => {
                // console.log(response.data);
                this.data = [];
                this.ischeck = true;
                this.getlist();
                document.getElementById("closed").click();
              })
              .catch((e) => {
                console.log(e);
              });
          }
        }
        if (this.type == "prefix") {
          // console.log(this.data.prefix_id);
          if (this.data.prefix_id) {
            PrefixDataService.updatePrefix(this.data.prefix_id, listdata)
              .then(() => {
                // console.log(response.data);
                this.data = [];
                this.ischeck = true;
                this.getlist();
                document.getElementById("closed").click();
              })
              .catch((e) => {
                console.log(e);
              });
          } else {
            PrefixDataService.createPrefix(listdata)
              .then(() => {
                // console.log(response.data);
                this.data = [];
                this.ischeck = true;
                this.getlist();
                document.getElementById("closed").click();
              })
              .catch((e) => {
                console.log(e);
              });
          }
        }
      }
    },
    getDocByType() {
      // console.log(this.doc_type_id);
      DocsDataService.getDocs(this.doc_type_id).then((res) => {
        this.list = res.data;
        // console.log(res.data);
      });
    },
    hasHistory() {
      return window.history.length > 2;
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  font-family: "Kanit" !important;
}
.row {
  max-width: 700px;
  margin: auto;
  background: white;
  padding: 10px;
  color: black !important;
}
a:visited {
  color: black;
} /* visited link */
a:hover {
  color: black;
} /* mouse over link */
a:active {
  color: black;
}
a:link {
  color: black;
}

.modal-content {
  float: center;
  text-align: center;
  width: 600px;
}
.modal {
  text-align: center;
  padding: 0 !important;
}
.modal:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}
.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  max-width: 600px;
}
</style>
