<template>
  <div class="hello">
    <HomeIcon />
    <div class="mt-5">
      <div class="row mt-5"></div>
      <div class="row card mt-2">
        <div class="card-body mt-2">
          <h4 style="text-align: center">ระบบการยื่นข้อมูลผู้เสียหาย</h4>
        </div>
        <div style="padding: 50px">
          <div class="row">
            <div class="col-md-12">
              <h5 style="color: #2da3f2; font-weight: bold">
                ข้อมูลผู้เสียหาย
              </h5>
            </div>
          </div>
          <div class="row">
            <div class="col-md-2">
              <h6>วันที่ยื่นคำร้อง</h6>
            </div>
            <div class="col-md-10">
              <h6>{{ convertdatetothai(cases.filing_date) }}</h6>
            </div>
          </div>
          <div class="row">
            <div class="col-md-2">
              <h6>หมายเลขบัตรประชาชน</h6>
            </div>
            <div class="col-md-10">
              <h6>{{ suspect.id_card }}</h6>
            </div>
          </div>
          <div class="row">
            <div class="col-md-2">
              <h6>ชื่อ-นามสกุล</h6>
            </div>
            <div class="col-md-10">
              <h6>
                {{ suspect.prefix }} {{ suspect.firstname }}
                {{ suspect.lastname }}
              </h6>
            </div>
          </div>
          <div class="row">
            <div class="col-md-2">
              <h6>วัน/เดือน/ปีเกิด</h6>
            </div>
            <div class="col-md-10">
              <h6>{{ convertdatetothai(suspect.birthday) }}</h6>
            </div>
          </div>
          <div class="row">
            <div class="col-md-2">
              <h6>เบอร์โทรศัพท์</h6>
            </div>
            <div class="col-md-10">
              <h6>{{ suspect.phone }}</h6>
            </div>
          </div>
          <div class="row">
            <div class="col-md-2">
              <h6>เพศ</h6>
            </div>
            <div class="col-md-10">
              <h6>{{ suspect.gender }}</h6>
            </div>
          </div>
          <div class="row">
            <div class="col-md-2">
              <h6>อาชีพ</h6>
            </div>
            <div class="col-md-10">
              <h6>{{ suspect.career }}</h6>
            </div>
          </div>
          <div class="row">
            <div class="col-md-2">
              <h6>รายได้</h6>
            </div>
            <div class="col-md-10">
              <h6 v-if="suspect.income">{{ suspect.income.toLocaleString() }} บาทต่อเดือน</h6>
              <h6 v-else> -  บาทต่อเดือน</h6>
            </div>
          </div>
          <div class="row">
            <div class="col-md-2">
              <h6>สัญชาติ</h6>
            </div>
            <div class="col-md-10">
              <h6>{{ suspect.nationality }}</h6>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <h6>ที่อยู่ตามบัตรประชาชน</h6>
            </div>
            <div class="col-md-12">
              <h6>
                บ้านเลขที่ {{ suspect.no_house || "-"}} 
                หมู่ที่ {{ suspect.village_no_house || "-"}} ซอย
                {{ suspect.road_house || "-" }} ถนน
                {{ suspect.lane_house || "-" }} หมู่บ้าน
                {{ suspect.village_house || "-" }} ตำบล
                {{ suspect.sub_district_house }} อำเภอ
                {{ suspect.district_house }} จังหวัด
                {{ suspect.province_house }} รหัสไปรษณีย์
                {{ suspect.postal_code_house }}
              </h6>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <h6>ที่อยู่ปัจจุบัน</h6>
            </div>
            <div class="col-md-12">
              <h6>
                บ้านเลขที่ {{ suspect.no_current || "-"}}
                หมู่ที่ {{ suspect.village_no_current || "-" }} ซอย
                {{ suspect.road_current || "-" }} ถนน
                {{ suspect.lane_current || "-" }} หมู่บ้าน{{
                  suspect.village_current || "-"
                }}
                ตำบล {{ suspect.sub_district_current }} อำเภอ
                {{ suspect.district_current }} จังหวัด
                {{ suspect.province_current }} รหัสไปรษณีย์
                {{ suspect.postal_code_current }}
              </h6>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-md-12">
              <h5 style="color: #2da3f2; font-weight: bold">
                แนบเอกสารผู้เสียหาย
              </h5>
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">เอกสาร</th>
                    <th scope="col">ชื่อไฟล์</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(d, i) in doclist" :key="i + 9">
                    <td>{{ d.name }}</td>
                    <td>
                      <a
                        data-toggle="modal"
                        href="#"
                        data-target="#showModalImageSuspect"
                        @click="getsrcimage(d.file_type, d.file_data)"
                      >
                        {{ d.file_name }}
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-md-12">
              <h5 style="color: #2da3f2; font-weight: bold">
                ข้อมูลผู้เกี่ยวข้อง
              </h5>
              <RelativesView />
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-md-12">
              <h5 style="color: #2da3f2; font-weight: bold">ข้อมูลทายาท</h5>
              <DescendantsView />
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-md-12">
              <h5 style="color: #2da3f2; font-weight: bold">
                ข้อมูลลักษณะเหตุการณ์
              </h5>
            </div>
            <NewEventCom />
          </div>
          <div class="row mt-5">
            <div class="col-md-12">
              <h5 style="color: #2da3f2; font-weight: bold">ข้อมูลเหตุการณ์</h5>
            </div>
            <NewCasesView />
          </div>
          <div class="row mt-5">
            <div class="col-md-12">
              <h6 style="color: black; font-weight: bold">
                ความเสียหายที่ได้รับ
              </h6>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <label v-for="(d, i) in damage" :key="i"
                >{{ d.name }} &nbsp;&nbsp;</label
              >
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-md-12">
              <h5 style="color: #2da3f2; font-weight: bold">
                เอกสารลักษณะเหตุการณ์
              </h5>
            </div>
            <DocEventView />
          </div>
          <div v-if="cases.status_id > 1">
          <div class="row mt-5">
            <div class="col-md-12">
              <h5 style="color: #2da3f2; font-weight: bold">กรรมการรับรอง</h5>
            </div>
          </div>
          <div class="row">
            <div class="col-md-2">
              <h6>วันที่การพิจารณา</h6>
            </div>
            <div class="col-md-10">
              <h6>{{ convertdatetothai(cases.committee_date) }}</h6>
            </div>
          </div>
          <div class="row">
            <div class="col-md-2">
              <h6>ผลการพิจารณา</h6>
            </div>
            <div class="col-md-10">
              <h6 v-if="cases.is_consideration == 1">รับรอง</h6>
              <h6 v-if="cases.is_consideration == 0">ไม่รับรอง</h6>
            </div>
          </div>
          <div class="row">
            <div class="col-md-2">
              <h6>รายละเอียดเพิ่มเติม</h6>
            </div>
            <div class="col-md-10">
              <h6>{{ cases.detail || "-"}}</h6>
            </div>
          </div>
          </div>
          <PrivilegeInfo class="mt-5" v-if="cases.status_id > 1"/>
          <div class="row mt-5">
            <div class="col-md-12">
              <h5 style="color: #2da3f2; font-weight: bold">สถานะ</h5>
            </div>
            <!-- <div class="col-md-2">
              <h5>สถานะ</h5>
            </div>
            <div class="col-md-10">
              <h5>{{ cases.name }}</h5>
            </div> -->
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">วันที่</th>
                  <th scope="col">สถานะ</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(d, i) in status" :key="i + 9">
                  <td>{{ convertdatetothai(d.created_date) }}</td>
                  <td>
                    {{ d.name }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group" style="text-align: left">
                <label for="username">หมายเหตุ </label>
                <input
                  :disabled="cases.is_edit == 0"
                  v-model="remark"
                  type="text"
                  class="form-control"
                  id="vilegeno_current"
                  placeholder="หมายเหตุ"
                />
              </div>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col text-center">
              <button
            class="btn btn-warning"
            style="color: white; width: 200px"
            @click="back()"
          >
            กลับหน้าเดิม</button
          >&nbsp;
              <button
                v-if="cases.status_id == 4"
                :disabled="cases.is_edit == 0"
                class="btn btn-danger"
                style="color: white; width: 200px"
                @click="saveEdit()"
              >
                ปลดล็อคให้แก้ไข 
              </button>
              &nbsp;
              <button
                v-if="cases.status_id == 4"
                :disabled="cases.is_edit == 0"
                class="btn btn-success"
                style="color: white; width: 200px"
                @click="save()"
              >
                ตรวจสอบข้อมูลเรียบร้อย
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="showModalImageSuspect"
      tabindex="-1"
      role="dialog"
      aria-labelledby="showModalImageSuspect"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row" style="height: 631px">
              <div class="col-md-12">
                 <object>
                    <embed
                      id="pdfID"
                      type="text/html"
                      width="100%"
                      height="100%"
                      :src="imagesrc"
                    />
                  </object>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HomeIcon from "../HomeIcon.vue";
import NewEventCom from "../Events/NewEventCom.vue";
import PrivilegeInfo from "../Privillege/PrivilegeInfo.vue";
import CasesDataService from "../../services/CasesDataService.js";
import AddressDataService from "../../services/AddressDataService";
import RelativesDataService from "../../services/RelativesDataService";
import DocumentsDataService from "../../services/DocumentsDataService";
import DocsDataService from "../../services/DocsDataService";
import RelativesView from "./RelativesView.vue";
import DescendantsView from "./DescendantsView.vue";
import NewCasesView from "../Events/NewCasesView.vue";
import DocEventView from "../Events/DocEventView.vue";
import DamagesDataService from "../../services/DamagesDataService";
import NotifyDataService from "../../services/NotifyDataService";
import ConvertService from "../../services/ConvertService";
import HistoryCaseDataService from "../../services/HistoryCaseDataService";

export default {
  props: ["msg"],
  components: {
    HomeIcon,
    NewEventCom,
    PrivilegeInfo,
    RelativesView,
    DescendantsView,
    NewCasesView,
    DocEventView,
  },
  data() {
    return {
      head: "0",
      name: "HelloWorld",
      suspect: [],
      case_id: "",
      cases: [],
      relative: [],
      imagetype: "",
      imagesrc: "",
      doclist: [],
      documents: [],
      remark: "",
      commit: {},
      damage: [],
      status: [],
    };
  },
  mounted() {
    this.case_id = this.$route.params.id;
    this.getdata();
    this.getcommit();
    this.getdamage();
  },
  methods: {
    back() {
      this.$router.push("/treatment");
      setTimeout(function () {
        location.reload();
      }, 500);
    },
    hasHistory() {
      return window.history.length > 2;
    },
    getDoc(suspect_id) {
      DocumentsDataService.getDoc(suspect_id).then((res) => {
        this.documents = res.data;
        // console.log(this.documents);
        DocsDataService.getDocByType(1).then((res) => {
          this.doclist = res.data;
          // console.log(res.data);
          for (let d = 0; d < this.doclist.length; d++) {
            this.doclist[d].document_id = 0;
            for (let dd = 0; dd < this.documents.length; dd++) {
              if (
                this.doclist[d].category_doc_id ==
                this.documents[dd].category_doc_id
              ) {
                this.doclist[d].document_id = this.documents[dd].document_id;
                this.doclist[d].file_name = this.documents[dd].file_name;
                this.doclist[d].file_type = this.documents[dd].file_type;
                this.doclist[d].file_data = this.documents[dd].file_data;
              }
            }
          }
          // console.log(this.doclist);
        });
      });
    },
    getsrcimage(type, url) {
      this.imagetype = type;
      this.imagesrc = url;
    },
    save() {
      var save = {
        status_id: 6,
        updated_by: this.currentUser.user_id,
      };
      CasesDataService.updateCheck(this.case_id, save).then(() => {
        // console.log(res.data);
        var now = ConvertService.getdate(Date.now());
        now = ConvertService.createddate(now);
        var data = {
          status_id: 6,
          case_id: this.case_id,
          created_by: this.currentUser.user_id,
          created_date: now,
        };
        HistoryCaseDataService.createHistoryCase(data).then(() => {
          // console.log(res.data);
          alert("บันทึกสำเร็จ");
        setTimeout(function () {
        location.reload();
      }, 300);
        });
      });
    },
    saveEdit() {
      var edit = {
        remark_edit: this.remark,
        updated_by: this.currentUser.user_id,
      };
      CasesDataService.updateEdit(this.case_id, edit).then(() => {
        // console.log(res.data);
        var data = {
          case_id: this.case_id,
          status_id: 5,
          is_read: false,
          user_id: this.cases.created_by,
          role_id: 2,
          created_by: this.currentUser.user_id,
          updated_by: this.currentUser.user_id,
        };
        NotifyDataService.createNotify(data).then(() => {
          // console.log(res.data);
        });
      });
      var now = ConvertService.getdate(Date.now());
      now = ConvertService.createddate(now);
      var data = {
        status_id: 5,
        case_id: this.case_id,
        created_by: this.currentUser.user_id,
        created_date: now,
      };
      HistoryCaseDataService.createHistoryCase(data).then(() => {
        // console.log(res.data);
        alert("บันทึกสำเร็จ");
        setTimeout(function () {
        location.reload();
      }, 300);
      });
    },
    getdamage() {
      DamagesDataService.getDamageCase(this.case_id).then((res) => {
        this.damage = res.data;
        // console.log(res.data);
      });
    },
    getcommit() {
      CasesDataService.getcommitt(this.case_id).then((res) => {
        this.commit = res.data;
        this.remark = res.data.remark_edit;
        // console.log(res.data);
      });
    },
    getdata() {
      CasesDataService.getCaseInfo(this.case_id).then((res) => {
        this.cases = res.data;
        // console.log(this.cases);
        this.remark = this.cases.remark_edit;
        HistoryCaseDataService.getHistoryCaseByCaseID(this.case_id).then((res) => {
          this.status = res.data
          // console.log(res.data);
        });

        // console.log(res.data);// console.log(
        this.getDoc(res.data.suspect_id);
        AddressDataService.getAddressSuspect(res.data.suspect_id).then(
          (res) => {
            this.suspect = res.data;
            // console.log(this.suspect);
          }
        );
        RelativesDataService.getRelativeBySuspect(res.data.suspect_id).then(
          (res) => {
            this.relative = res.data;
            // console.log(this.relative);
          }
        );
      });
    },
    convertdatetothai(datetimes) {
      if (datetimes) {
        const date = new Date(datetimes);
        const result = date.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return result;
      } else {
        return "-";
      }
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  font-family: "Kanit" !important;
}
.row {
  max-width: 1200px;
  margin: auto;
  background: white;
  padding: 10px;
  color: black !important;
}
a:visited {
  color: black;
} /* visited link */
a:hover {
  color: black;
} /* mouse over link */
a:active {
  color: black;
}
a:link {
  color: black;
}
</style>
