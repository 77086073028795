<template>
  <div class="hello">
    <HomeIcon />
    <div class="mt-5">
      <h3 style="text-align: center">การตั้งค่าระบบ</h3>
      <div class="row mt-5">
        <div class="col-1"></div>
        <div class="col-9">ประเภทของการตั้งค่า</div>
        <div class="col-2" style="text-align: right">จัดการ</div>
      </div>
      <div class="row card mt-2" v-for="l in list" :key="l.no">
        <div class="card-body p-0">
          <div class="row">
            <div class="col-1">{{ l.no }}</div>
            <div class="col-10">{{ l.name }}</div>
            <div class="col-1" style="text-align: right">
              <router-link
                :to="{
                  path: l.route,
                }"
              >
                <i class="fas fa-pencil-alt"> </i>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col text-center">
        <button
          class="btn btn-warning"
          style="color: white"
          @click="hasHistory() ? $router.go(-1) : $router.push('/')"
        >
          ย้อนกลับ
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import HomeIcon from "./HomeIcon.vue";

export default {
  components: {
    HomeIcon,
  },
  data() {
    return {
      name: "HelloWorld",
      list: [
        {
          no: 1,
          name: "การจัดการผู้ใช้งาน",
          route: "user",
        },
        {
          no: 2,
          name: "การจัดการบทบาทผู้ใช้งาน",
          route: "role",
        },
        {
          no: 3,
          name: "ประเภทคดี",
          route: "setting/case",
        },
        {
          no: 4,
          name: "ประเภทกลุ่มผู้เสียหาย",
          route: "setting/suspacts",
        },
        {
          no: 5,
          name: "ประเภทการช่วยเหลือตามระเบียบ กพต.",
          route: "settinghelp",
        },
        {
          no: 6,
          name: "สิทธิ์ที่ได้รับตามระเบียบ กพต.",
          route: "settingprivilege",
        },
        {
          no: 7,
          name: "หน่วยงานที่ควบคุมตัว",
          route: "setting/detained",
        },
        {
          no: 8,
          name: "ประเภทเอกสาร",
          route: "setting/doc",
        },
        {
          no: 9,
          name: "ความสัมพันธ์ของทายาท",
          route: "setting/relationship",
        },
        {
          no: 10,
          name: "ประเภทความเสียหายที่ได้รับ",
          route: "setting/damage",
        },
        {
          no: 11,
          name: "ประเภทคำนำหน้า",
          route: "setting/prefix",
        },
      ],
    };
  },
  methods: {
    hasHistory() {
      return window.history.length > 2;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  font-family: "Kanit" !important;
}
.row {
  max-width: 700px;
  margin: auto;
  background: white;
  padding: 10px;
  color: black !important;
}
a:visited {
  color: black;
} /* visited link */
a:hover {
  color: black;
} /* mouse over link */
a:active {
  color: black;
}
a:link {
  color: black;
}
</style>
