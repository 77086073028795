import http from "../http-common";

class PaymentDataService {
  getPayments(name) {
    return http.get('/payment?name='+name);
  }
  getPaymentAll() {
    return http.get('/payment/getcaserequestall');
  }
  getPaymentAllName(name) {
    return http.get('/payment/getcaserequestall?name='+name);
  }
  getPaymentAllDate(start,end) {
    return http.get('/payment/getcaserequestall?start='+start+'&&end='+end);
  }
  getPayment(id) {
    return http.get(`/payment/`+id);
  }
  getPaymentName(id) {
    return http.get(`/payment/getname/`+id);
  }
  getDamageCase(id) {
    return http.get(`/payment/getdamagecase/`+id);
  }
  creatPayment(data) {
    return http.post('/payment', data);
  }
  updatePayment(id,data) {
    return http.put(`/payment/${id}`, data);
  }
  updatePaymentPending(id,data) {
    return http.put(`/payment/updatepending/${id}`, data);
  }
  updatePaymentApprove(id,data) {
    return http.put(`/payment/updateapprove/${id}`, data);
  }
  getDamagesAllByID(id) {
    return http.get('/map_damages_events/'+id);
  }
  createMapDamage(data) {
    return http.post('/map_damages_events', data);
  }getdamage
  getDamagesByCaseId(id) {
    return http.get('/map_damages_events/getdamage/'+id);
  }
  deletePayments(id) {
    return http.delete(`/payment/`+id);
  }
}

export default new PaymentDataService();