<template>
  <div class="hello">
    <HomeIcon />
    <div class="mt-5">
      <h3 style="text-align: center">การจัดการผู้ใช้งาน</h3>
      <div class="row">
        <div class="col-10"></div>
        <div class="col-2">
          <button
            type="button"
            class="btn btn-block btn-success"
            data-toggle="modal"
            data-target="#addModal"
            @click="sendInfo('add', 0)"
          >
            เพิ่ม
          </button>
        </div>

        <!-- Modal -->
        <div
          class="modal fade"
          id="addModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="addModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <!-- Role -->
                <h4 style="text-align: center; font-weight: bold">
                  {{ title }}
                </h4>
                <div class="row mt-5">
                  <div class="col-6">
                    <div class="form-group" style="text-align: left">
                      <label for="username"
                        >ชื่อผู้ใช้งาน (เลขบัตรประชาชน 13 หลัก)
                        <span style="color: red"> *</span></label
                      >
                      <input
                        v-model="user.id_card"
                        type="number"
                        min="1"
                        @keypress="onlyNumber"
                        class="form-control"
                        id="username"
                        placeholder="กรุณากรอกชื่อผู้ใช้งาน (เลขบัตรประชาชน 13 หลัก)"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group" style="text-align: left">
                      <label for="password"
                        >รหัสผ่าน<span style="color: red"> *</span></label
                      >
                      <input
                        v-model="user.password"
                        type="password"
                        class="form-control"
                        id="password"
                        placeholder="กรุณากรอกรหัสผ่าน"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group" style="text-align: left">
                      <label for="firstname"
                        >ชื่อ<span style="color: red"> *</span></label
                      >
                      <input
                        v-model="user.firstname"
                        type="text"
                        class="form-control"
                        id="firstname"
                        placeholder="กรุณากรอกชื่อ"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group" style="text-align: left">
                      <label for="lastname"
                        >นามสกุล<span style="color: red"> *</span></label
                      >
                      <input
                        type="text"
                        v-model="user.lastname"
                        class="form-control"
                        id="lastname"
                        placeholder="กรุณากรอกนามสกุล"
                      />
                    </div>
                  </div>
                  
                  <div class="col-6">
                    <div class="form-group" style="text-align: left">
                      <label for="province"
                        >จังหวัดที่รับผิดชอบ<span style="color: red">
                          *</span
                        ></label
                      >
                      <select
                        class="form-select mb-3"
                        v-model="user.province"
                        @change="onChangeProvince"
                      >
                        <option
                          v-for="(p, i) in provinces"
                          v-bind:value="p.PROVINCE_ID"
                          :key="i"
                        >
                          {{ p.PROVINCE_NAME }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group" style="text-align: left">
                      <label for="amphure"
                        >อำเภอที่รับผิดชอบ<span style="color: red">
                          *</span
                        ></label
                      >
                      <select class="form-select mb-3" v-model="user.amphure">
                        <option
                          v-for="(a, i) in amphures"
                          v-bind:value="a.AMPHUR_ID"
                          :key="i"
                        >
                          {{ a.AMPHUR_NAME }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group" style="text-align: left">
                      <label for="role"
                        >สิทธิ์การใช้งาน<span style="color: red">
                          *</span
                        ></label
                      >
                      <select
                        class="form-select mb-3"
                        @change="onChange"
                        v-model="user.role_id"
                      >
                        <option
                          v-for="(r, i) in roles"
                          v-bind:value="r.role_id"
                          :key="i"
                        >
                          {{ r.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <h4 class="mt-5" style="text-align: center; font-weight: bold">
                  กำหนดสิทธิ์การเข้าถึง
                </h4>
                <div class="row">
                  <div class="col-12" v-for="m in menus" :key="m.menu_id">
                    <div class="form-group" style="text-align: left">
                      <label>
                        <input
                          type="checkbox"
                          v-model="menu_id"
                          :id="m.page" 
                          :value="m.menu_id"
                        />
                        <span> {{ m.page }}</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bd-example mb-5">
                <button
                  type="button"
                  style="margin-right: 20px; width: 150px"
                  class="btn btn-success"
                  @click="save()"
                >
                  บันทึก
                </button>
                <button
                  type="button"
                  id="closed"
                  class="btn btn-danger"
                  style="margin-right: 20px; width: 150px"
                  data-dismiss="modal"
                >
                  ยกเลิก
                </button>
              </div>
            </div>
          </div>
        </div>
          <!-- Modal -->
        <div
          class="modal fade"
          id="deleteModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="addModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
               <h5>ยืนยันการยกเลิกผู้ใช้งาน</h5>
              </div>
              <div class="bd-example mb-5">
                <button
                  type="button"
                  style="margin-right: 20px; width: 150px"
                  class="btn btn-success"
                  @click="confirmdeleteuser()"
                >
                  ยืนยัน
                </button>
                <button
                  type="button"
                  id="closeddelete"
                  class="btn btn-danger"
                  style="margin-right: 20px; width: 150px"
                  data-dismiss="modal"
                >
                  ยกเลิก
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-1"></div>
        <div class="col-4">ชื่อผู้ใช้งาน</div>
        <div class="col-3">สิทธิ์</div>
        <div class="col-3">เข้าสู่ระบบครั้งล่าสุด</div>
        <div class="col-1" style="text-align: right">จัดการ</div>
      </div>
      <div class="row card mt-2" v-for="(l, index) in list" :key="l.no">
        <div class="card-body p-0">
          <div class="row">
            <div class="col-1">{{ index + 1 }}</div>
            <div class="col-4">{{ l.id_card }}</div>
            <div class="col-3">{{ l.name }}</div>
            <div class="col-3">{{ convertdatetothai(l.last_login) }}</div>
            <div class="col-1" style="text-align: right">
              <a
                data-toggle="modal"
                href="#"
                data-target="#addModal"
                @click="sendInfo('edit', l.user_id)"
                ><i class="fas fa-pencil-alt"></i
              ></a>&nbsp;
              <a
              a
                data-toggle="modal"
                href="#"
                data-target="#deleteModal"
                @click="deleteuser(l.user_id)"
                ><i class="fas fa-trash"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col text-center">
        <button
          class="btn btn-warning"
          style="color: white"
          @click="hasHistory() ? $router.go(-1) : $router.push('/')"
        >
          ย้อนกลับ
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import UsersDataService from "../../services/UserDataService.js";
import MenusDataService from "../../services/MenuDataService";
import HomeIcon from "../HomeIcon.vue";
import ProvinceDataService from "../../services/ProvinceDataService";
import AmphurDataService from "../../services/AmphurDataService";

export default {
  components: {
    HomeIcon,
  },
  data() {
    return {
      name: "HelloWorld",
      provinces: [],
      amphures: [],
      listamphures: [],
      amphure: "",
      title: "",
      selectedUser: "",
      list: [],
      roles: [],
      menus: [],
      user: [],
      rolemenu: [],
      menu_id: [],
      province: "",
      hash: "",
      role: "",
      userid:''
    };
  },
  mounted() {
    this.getlist();
    this.getrole();
    this.getmenu();
    this.getprovince();
    this.getamphur('');
  },
  methods: {
    deleteuser(id){
this.userid = id
    },
    confirmdeleteuser(){
UsersDataService.deleteUser(this.userid).then(()=>{
  // console.log(res.data);  
  document.getElementById("closeddelete").click();
  this.getlist()
})
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57 || this.user.id_card.length == 13) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    getmenu() {
      MenusDataService.getMenu().then((res) => {
        this.menus = res.data;
      });
    },
    getprovince() {
      ProvinceDataService.getProvince().then((res) => {
        this.provinces = res.data
      });
    },
    getamphur(id) {
      if (id == '') {
        AmphurDataService.getAmphurs().then((res) => {
        this.amphures = res.data;
      });
      }else{
        AmphurDataService.getAmphur(id).then((res) => {
        this.amphures = res.data;
      });
      }
      
    },
    getlist() {
      UsersDataService.getUsers().then((res) => {
        this.list = res.data;
        // console.log(res.data);
      });
    },
    getid(id) {
      // console.log(id);
      this.getusermenu(id);
      UsersDataService.getUser(id).then((res) => {
        this.user = res.data;
        this.hash = this.user.password;
        this.role = this.user.role_id;        
        this.getamphur(this.user.province)
        // console.log(res.data);
      });
    },
    getusermenu(user_id) {
      MenusDataService.getstatusUserMenu(user_id).then((res) => {
console.log(res.data);        
if (res.data.menu == null) {
          this.menu_id = [];
        } else {
          this.menu_id = JSON.parse(res.data.menu);
        }
      });
    },
    sendInfo(item, id) {
      // console.log(item, id);
      if (item == "add") {
        this.user = [];
        this.menu_id = [];
        this.role = "";
        this.user.id_card = "";
        this.getmenu();
        this.getamphur('')
        this.title = "เพิ่มข้อมูลผู้ใช้งาน";
      } else {
        this.title = "แก้ไขข้อมูลผู้ใช้งาน";
        this.getid(id);
      }
    },
    onChangeProvince(event) {
      // console.log(this.user.province)
      var province_name = event.target.value;
      this.getamphur(province_name)
    },
    onChange(event) {
      this.user.role_id = parseInt(event.target.value);
      if (this.user.role_id == this.role) {
        this.getusermenu(this.user.user_id);
      } else {
        // console.log(event.target.value);
        this.getmenubyrole();
      }
    },
    getmenubyrole() {
      MenusDataService.getstatusRoleMenu(this.user.role_id).then((res) => {
        if (res.data.menu == null) {
          this.menu_id = [];
        } else {
          this.menu_id = JSON.parse(res.data.menu);
        }
      });
    },
    save() {
      var user = {
        role_id: this.user.role_id,
        id_card: this.user.id_card,
        firstname: this.user.firstname,
        lastname: this.user.lastname,
        amphure: this.user.amphure,
        province: this.user.province,
        password: this.user.password,
        hash: this.hash,
        is_active: true,
        created_by: this.currentUser.user_id,
        updated_by: this.currentUser.user_id,
      };
      var id = this.user.user_id;
      // console.log(id);
      var id_card = this.user.id_card;
      if (this.user.id_card == "" || this.user.id_card == null) {
        alert("กรุณากรอกเลขบัตรประชาชน");
      } else if (id_card.length != 13) {
        alert("กรุณากรอกเลขบัตรประชาชนให้ครบ 13 ตัว");
      } else if (this.user.password == "" || this.user.password == null) {
        alert("กรุณากรอกรหัสผ่าน");
      } else if (this.user.firstname == "" || this.user.firstname == null) {
        alert("กรุณากรอกชื่อ");
      } else if (this.user.lastname == "" || this.user.lastname == null) {
        alert("กรุณากรอกนามสกุล");
      } else if (this.user.amphure == "" || this.user.amphure == null) {
        alert("กรุณากรอกอำเภอที่รับผิดชอบ");
      } else if (this.user.province == "" || this.user.province == null) {
        alert("กรุณากรอกจังหวัดที่รับผิดชอบ");
      } else if (this.user.role_id == "" || this.user.role_id == null) {
        alert("กรุณาเลือกสิทธิ์การใช้งาน");
      } else {
        if (this.user.user_id) {
         MenusDataService.deleteUserMenu(id).then(()=>{ 
 UsersDataService.updateUser(id, user)
            .then(() => {
              // console.log(response.data);
              this.user = [];
             //  MenusDataService.deleteUserMenu(id).then(()=>{                
              // console.log(this.menu_id);            
           //   })              
              for (let m = 0; m < this.menu_id.length; m++) {
                var menu = {
                  menu_id: this.menu_id[m],
                  user_id: id,
                  created_by: this.currentUser.user_id,
                  updated_by: this.currentUser.user_id,
                };
                // console.log(menu);
                MenusDataService.createUserMenu(menu)
                  .then(() => {
                    // console.log(response.data);
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              }  
              this.user = [];
              this.getlist();
              document.getElementById("closed").click();
            })
})
            .catch((e) => {
              console.log(e);
            });
        } else {
          UsersDataService.createUser(user)
            .then((response) => {
              for (let m = 0; m < this.menu_id.length; m++) {
                var menu = {
                  menu_id: this.menu_id[m],
                  user_id: response.data.id,
                  created_by: this.currentUser.user_id,
                  updated_by: this.currentUser.user_id,
                };
                // console.log(menu);
                MenusDataService.createUserMenu(menu)
                  .then(() => {
                    // console.log(response.data);
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              }
              this.user = [];
              this.getlist();
              document.getElementById("closed").click();
            })
            .catch((e) => {
              console.log(e);
            });
        }
      }
    },
    hasHistory() {
      return window.history.length > 2;
    },
    convertdatetothai(datetimes) {
      if (datetimes) {
        const date = new Date(datetimes);
        const result = date.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return result;
      } else {
        return "ยังไม่ได้เข้าสู่ระบบ";
      }
    },
    getrole() {
      UsersDataService.getRoles().then((res) => {
        this.roles = res.data;
        // console.log(res.data);
      });
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  font-family: "Kanit" !important;
}
.row {
  max-width: 700px;
  margin: auto;
  background: white;
  padding: 10px;
  color: black !important;
}
a:visited {
  color: black;
} /* visited link */
a:hover {
  color: black;
} /* mouse over link */
a:active {
  color: black;
}
a:link {
  color: black;
}
.modal-content {
  float: center;
  text-align: center;
  width: 600px;
}
.modal {
  text-align: center;
  padding: 0 !important;
}
.modal:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}
.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  max-width: 600px;
}
</style>
