import http from "../../http-common";
import HistoryCaseDataService from '../HistoryCaseDataService'
class AuthService {
  async login(user) {
    console.log(user)
    return http.post('user/signin', user)
      .then(response => {
        console.log(response.data);
        if (response.data.accessToken) {
          http.put(`user/lastLogin/${response.data.user_id}`, response.data);
          localStorage.setItem('user', JSON.stringify(response.data));
          var data={
            history_status_id:1,
            user_id:response.data.user_id
          }
          HistoryCaseDataService.createHistory(data).then(()=>{

          })
        }
        return response.data;
      },
        error => {
          console.log(error);
          this.$router.push("/login");
          alert('ชื่อหรือรหัสผ่านไม่ถูกต้อง')
        }
      );
  }

  logout() {
    localStorage.removeItem('user');
  }

  role() {
    return http.get('role');
  }

  getpage(id) {
    return http.get(`menu/menubyuserid/`+id);
  }

  
  getname(id) {
    return http.get(`user/`+id);
  }

}
export default new AuthService();