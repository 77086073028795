import http from "../http-common";

class DocumentsDataService {
  getDocuments() {
    return http.get('/documents');
  }
  getDocument(id) {
    return http.get(`/documents/`+id);
  }
  createDocument(data) {
    return http.post('/documents', data);
  }
  updateDocument(id,data) {
    return http.put(`/documents/${id}`, data);
  }
  getDoc(id){
    return http.get(`/documents/getdocsuspect?id=`+id);
  }
  getDocEvent(id){
    return http.get(`/documents/getdocevent?id=`+id);
  }
  getDocRelative(id){
    return http.get(`/documents/getdocrelative?id=`+id);
  }
  getDocPrivacy(id){
    return http.get(`/documents/getdocprivacy?id=`+id);
  }
  getDocTracking(id,type){
    return http.get(`/documents/getdoctracking?id=`+id+'&&type='+type);
  }
  getDocQuality(id,type){
    return http.get(`/documents/getdocquality?id=`+id+'&&type='+type);
  }
  getDocMSociety(id,type){
    return http.get(`/documents/getdocmsociety?id=`+id+'&&type='+type);
  }
  getDocMOE(id,type){
    return http.get(`/documents/getdocmoe?id=`+id+'&&type='+type);
  }
  getDocDisaster(id,type){
    return http.get(`/documents/getdocdisaster?id=`+id+'&&type='+type);
  }
  getDocDLA(id,type){
    return http.get(`/documents/getdocdla?id=`+id+'&&type='+type);
  }
  getDocISOC(id,type){
    return http.get(`/documents/getdocisoc?id=`+id+'&&type='+type);
  }
  getDocMOPH(id,type){
    return http.get(`/documents/getdocmoph?id=`+id+'&&type='+type);
  }
  deleteDocument(id) {
    return http.delete(`/documents/${id}`);
  }
  deleteintegration(id,type){
    return http.delete(`/documents/deleteintegration/${id}/${type}`);
  }
}

export default new DocumentsDataService();