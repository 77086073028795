<template>
  <div class="hello">
    <HomeIcon />
    <div class="mt-5">
      <h3 style="text-align: center">การจัดการบทบาทผู้ใช้งาน</h3>
      <div class="row">
        <div class="col-10"></div>
        <div class="col-2" style="text-align: right">
          <button
            type="button"
            class="btn btn-block btn-success"
            data-toggle="modal"
            data-target="#addModal"
            @click="sendInfo('add', 0)"
          >
            เพิ่ม
          </button>
        </div>

        <!-- Modal -->
        <div
          class="modal fade"
          id="addModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="addModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <!-- Role -->
                <h4 style="text-align: center; font-weight: bold">
                  {{ title }}
                </h4>
                <div class="row mt-5">
                  <div class="col-6">
                    <div class="form-group" style="text-align: left">
                      <label for="role"
                        >สิทธิ์การใช้งาน<span style="color: red">
                          *</span
                        ></label
                      >
                      <input
                        v-model="user.name"
                        type="text"
                        class="form-control"
                        id="role"
                        placeholder="กรุณากรอกชื่อสิทธิ์การใช้งาน"
                      />
                    </div>
                  </div>
                  <div class="col-6"></div>
                </div>
                <h4 class="mt-5" style="text-align: center; font-weight: bold">
                  กำหนดสิทธิ์การเข้าถึง
                </h4>
                <div class="row">
                  <div class="col-12" v-for="m in menus" :key="m.menu_id">
                    <div class="form-group" style="text-align: left">
                      <label>
                        <input
                          type="checkbox"
                          v-model="menu_id"
                          v-bind:value="m.menu_id"
                          v-bind:id="m.menu_id"
                        />
                        <span> {{ m.page }}</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bd-example mb-5">
                <button
                  type="button"
                  style="margin-right: 20px; width: 150px"
                  class="btn btn-success"
                  @click="save()"
                >
                  บันทึก
                </button>
                <button
                  type="button"
                  id="closed"
                  class="btn btn-danger"
                  style="margin-right: 20px; width: 150px"
                  data-dismiss="modal"
                >
                  ยกเลิก
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-1"></div>
        <div class="col-9">สิทธิ์</div>
        <div class="col-2" style="text-align: right">จัดการ</div>
      </div>
      <div class="row card mt-2" v-for="(l, index) in list" :key="l.no">
        <div class="card-body p-0">
          <div class="row">
            <div class="col-1">{{ index + 1 }}</div>
            <div class="col-9">{{ l.name }}</div>
            <div class="col-2" style="text-align: right">
              <a
                data-toggle="modal"
                data-target="#addModal"
                href="#"
                @click="sendInfo('edit', l.role_id)"
                ><i class="fas fa-pencil-alt"></i
              ></a>
              <!-- <a style="margin-left: 10px" href="#">
                <i class="fas fa-trash" style="color: gray"></i
              ></a> -->
              <a style="margin-left: 10px" href="#">
                <i class="fas fa-trash" style="color: black"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col text-center">
        <button
          class="btn btn-warning"
          style="color: white"
          @click="hasHistory() ? $router.go(-1) : $router.push('/')"
        >
          ย้อนกลับ
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import UsersDataService from "../../services/UserDataService.js";
import MenusDataService from "../../services/MenuDataService";
import HomeIcon from "../HomeIcon.vue";

export default {
  components: {
    HomeIcon,
  },
  data() {
    return {
      name: "HelloWorld",
      title: "",
      list: [],
      menus: [],
      user: [],
      test: [],
      menu_id: [],
    };
  },
  mounted() {
    this.getlist();
    this.getmenu();
  },
  methods: {
    getlist() {
      UsersDataService.getRoles().then((res) => {
        this.list = res.data;
        // console.log(res.data);
      });
    },
    getmenu() {
      MenusDataService.getMenu().then((res) => {
        this.menus = res.data;
        // console.log(res.data);
      });
    },
    getid(id) {
      // console.log(id);
      UsersDataService.getRole(id).then((res) => {
        this.user = res.data;
        this.getrolemenu(this.user.role_id);
        // console.log(this.user);
      });
    },
    getrolemenu(role_id) {
      MenusDataService.getstatusRoleMenu(role_id).then((res) => {
        if (res.data.menu == null) {
          this.menu_id = [];
        } else {
          this.menu_id = JSON.parse(res.data.menu);
        }
      });
    },
    sendInfo(item, id) {
      // console.log(item, id);
      if (item == "add") {
        this.user = [];
        this.menu_id = [];
        this.title = "เพิ่มบทบาทผู้ใช้งาน";
      } else {
        this.title = "แก้ไขบทบาทผู้ใช้งาน";
        this.getid(id);
      }
    },
    save() {
      // console.log(this.menus);
      // console.log(this.user);
      var user = {
        name: this.user.name,
        created_by: this.currentUser.user_id,
        updated_by: this.currentUser.user_id,
      };
      var id = this.user.role_id;
      // console.log(id);
      if (this.user.name == "" || this.user.name == null) {
        alert("กรุณากรอกข้อมูลให้ครบ");
      } else {
        if (this.user.role_id) {
          UsersDataService.updateRole(id, user)
            .then(() => {
              // console.log(response.data);
              MenusDataService.deleteRoleMenu(id);
              // console.log(this.menu_id);
              for (let m = 0; m < this.menu_id.length; m++) {
                var menu = {
                  menu_id: this.menu_id[m],
                  role_id: id,
                  created_by: this.currentUser.user_id,
                  updated_by: this.currentUser.user_id,
                };
                // console.log(menu);
                MenusDataService.createRoleMenu(menu)
                  .then(() => {
                    // console.log(response.data);
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              }
              this.user = [];
              this.getlist();
              document.getElementById("closed").click();
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
          UsersDataService.createRole(user)
            .then((response) => {
              // console.log(response.data.id);
              for (let m = 0; m < this.menu_id.length; m++) {
                var menu = {
                  menu_id: this.menu_id[m],
                  role_id: response.data.id,
                  created_by: this.currentUser.user_id,
                  updated_by: this.currentUser.user_id,
                };
                // console.log(menu);
                MenusDataService.createRoleMenu(menu)
                  .then(() => {
                    // console.log(response.data);
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              }
              this.user = [];
              this.getlist();
              document.getElementById("closed").click();
            })
            .catch((e) => {
              console.log(e);
            });
        }
      }
    },
    hasHistory() {
      return window.history.length > 2;
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  font-family: "Kanit" !important;
}
.row {
  max-width: 700px;
  margin: auto;
  background: white;
  padding: 10px;
  color: black !important;
}
a:visited {
  color: black;
} /* visited link */
a:hover {
  color: black;
} /* mouse over link */
a:active {
  color: black;
}
a:link {
  color: black;
}

.modal-content {
  float: center;
  text-align: center;
  width: 600px;
}
.modal {
  text-align: center;
  padding: 0 !important;
}
.modal:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}
.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  max-width: 600px;
}
</style>
