import http from "../http-common";

class MOPHDetailDataService {
  getQualities(id_card,id_card_des) {
    return http.get('/moph_detail?id_card='+id_card+'&&id_card_des='+id_card_des);
  }
  getIDSuspectQuality() {
    return http.get('/moph_detail/getidcardsuspect');
  }
  getQuality(id,type) {
    return http.get(`/moph_detail/`+id+'/'+type);
  }
  getDetailQuality(id,type) {
    return http.get(`/moph_detail/getdetail/`+id+'/'+type);
  }  
  createQuality(data) {
    return http.post('/moph_detail', data);
  }
  createMophAid(data) {
    return http.post('/moph_aid', data);
  }
  getMophAidByID(id) {
    return http.get('/moph_aid/getaid/'+id);
  }
  deleteMophAidByID(id) {
    return http.delete(`/moph_aid/${id}`);
  }
  updateQuality(id,data) {
    return http.put(`/moph_detail/${id}`, data);
  }
}

export default new MOPHDetailDataService();