import http from "../http-common";

class EventsDataService {
  getEvents() {
    return http.get('/event');
  }
  getEvent(id) {
    return http.get(`/event/`+id);
  }
  getEventAll(id) {
    return http.get(`/event/geteventbycaseid/`+id);
  }
  getEventByID(id) {
    return http.get(`/event/getevent/`+id);
  }
  getEventcaseByID(id) {
    return http.get(`/event/getcaseinfoevent/`+id);
  }
  createEvent(data) {
    return http.post('/event', data);
  }
  updateEvent(id,data) {
    return http.put(`/event/${id}`, data);
  }
  deleteEvent(id) {
    return http.delete(`/event/${id}`);
  }
}

export default new EventsDataService();