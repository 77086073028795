<template>
  <div class="hello">
    <div
      v-if="currentUser"
      style="text-align: right; margin-top: 10px; margin-right: 10px"
    >
    <a href="https://forms.gle/U3tTYJND7iUpKNq1A" target="_blank"
        ><i class="fas fa-exclamation-triangle" style="color: black"></i>
        <span style="color: black"> แจ้งปัญหาการใช้งาน</span></a
      >&nbsp;&nbsp;
      <a href="https://justice-api.sbpac.go.th/คู่มือการใช้งานระบบสารสนเทศการช่วยเหลือเยียวยา.pdf" target="_blank"
        ><i class="fas fa-file-alt" style="color: black"></i>
        <span style="color: black"> คู่มือการใช้งาน</span></a
      >
    </div>
    <div class="hello mt-5" style="padding: 50px">
      <h3 style="text-align: center">
        ระบบสารสนเทศการช่วยเหลือเยียวยาผู้ได้รับผลความเสียหายและผู้ได้รับผลกระทบจากการกระทำของเจ้าหน้าที่รัฐ
      </h3>
      <div class="row box container mt-5" style="margin:auto;">
        <div
        v-if="!currentUser"
          class="col-sm-3"
          style="padding: 5px;"
        ></div>
        <div
          class="col-sm-3"
          style="padding: 5px;"
          v-for="p in page"
          :key="p.menu_id"
        >
          <button
          style="width:150px"
            type="button"
            class="btn btn-block btn-outline-success btn-lg"
          >
            <a :href="p.route">
              <div>
                <i id="icon" style="color: white" :class="p.icon"></i><br />
                <div style="color: black; margin-top: 15px; font-size: 1rem">
                  {{ p.page }}
                </div>
              </div>
            </a>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthService from "../services/auth/auth.service";
export default {
  data() {
    return {
      page: [],
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    this.loadpage();
  },
  methods: {
    loadpage() {
      if (this.currentUser) {
        AuthService.getpage(this.currentUser["user_id"]).then(
          (response) => {
            this.page = response.data;
            // console.log(this.page);
          },
          (error) => {
            console.log(error);
          }
        );
      } else {
        this.page = [
          {
            page: "ตรวจสอบข้อมูล",
            icon: "fas fa-check",
            route: "/suspect/1",
          },
          {
            page: "การให้คําปรึกษา",
            icon: "fas fa-comment",
            route: "/chat",
          },
        ];
      }
    },
  },
};
</script>

<style scoped>
body {
  font-family: "Kanit" !important;
}
.box {
  color: black !important;
}
#icon {
  background: #09cc68;
  display: inline-block;
  border-radius: 50px;
  padding: 10px;
}
.row {
  max-width: 700px;
  margin: auto;
  background: white;
  padding: 10px;
  color: black !important;
}
a:visited {
  color: black;
} /* visited link */
a:hover {
  color: black;
} /* mouse over link */
a:active {
  color: black;
}
a:link {
  color: black;
}
</style>
