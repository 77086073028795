<template>
  <div class="hello">
    <HomeIcon />
    <div class="mt-5">
      <h3 style="text-align: center">สิทธิ์ที่ได้รับตามระเบียบ กพต.</h3>
      <div class="row">
        <div class="col-10"></div>
        <div class="col-2" style="text-align: right">
          <button
            type="button"
            class="btn btn-block btn-success"
            data-toggle="modal"
            data-target="#exampleModal"
            @click="sendInfo('add', 0)"
          >
            เพิ่ม
          </button>
        </div>

        <!-- Modal -->
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <!-- Role -->
                <h4 style="text-align: center; font-weight: bold">
                  {{ title }}
                </h4>
                <div class="row mt-5">
                  <div class="col-12">
                    <div class="form-group" style="text-align: left">
                      <label for="name"
                        >{{ header }}<span style="color: red"> *</span></label
                      >
                      <input
                        type="text"
                        v-model="data.name"
                        class="form-control"
                        id="name"
                        :placeholder="'กรุณากรอก' + header"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group" style="text-align: left">
                      <label for="amount"
                        >จำนวนเงิน<span style="color: red"> *</span></label
                      >
                      <input
                        type="number"
                        @keypress="onlyNumber"
                        min="0"
                        v-model="data.amount"
                        class="form-control"
                        id="amount"
                        placeholder="กรุณากรอกจำนวนเงิน"
                      />
                    </div>
                  </div>
                  <div class="col-6"></div>
                  <div class="col-3" style="text-align: left">
                    <label for="type"
                      >ประเภทการจ่าย<span style="color: red"> *</span></label
                    >
                  </div>
                  <div class="col-3" style="text-align: left">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        id="fixed"
                        value="fixed"
                        type="radio"
                        name="paid"
                        v-model="ispaid"
                      />
                      <label class="form-check-label">รายครั้ง</label>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-check" style="text-align: left">
                      <input
                        class="form-check-input"
                        id="daily"
                        value="daily"
                        type="radio"
                        name="paid"
                        v-model="ispaid"
                      />
                      <label class="form-check-label">รายวัน</label>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-check" style="text-align: left">
                      <input
                        class="form-check-input"
                        id="once"
                        value="once"
                        type="radio"
                        name="paid"
                        v-model="ispaid"
                      />
                      <label class="form-check-label">ครั้งแรก</label>
                    </div>
                  </div>
                  <div class="col-3" style="text-align: left">
                    <label for="status"
                      >สถานะ<span style="color: red"> *</span></label
                    >
                  </div>
                  <div class="col-3" style="text-align: left">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        id="active"
                        value="Active"
                        type="radio"
                        name="active"
                        v-model="isstatus"
                      />
                      <label class="form-check-label">ใช้งานอยู่</label>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-check" style="text-align: left">
                      <input
                        class="form-check-input"
                        id="disactive"
                        value="Disactive"
                        type="radio"
                        name="active"
                        v-model="isstatus"
                      />
                      <label class="form-check-label">เลิกใช้งาน</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bd-example mb-5">
                <button
                  type="button"
                  style="margin-right: 20px; width: 150px"
                  class="btn btn-success"
                  @click="save()"
                >
                  บันทึก
                </button>
                <button
                  type="button"
                  id="closed"
                  class="btn btn-danger"
                  style="margin-right: 20px; width: 150px"
                  data-dismiss="modal"
                >
                  ยกเลิก
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-5 mb-5" v-if="list.length == 0">
        <h4 style="text-align: center">ไม่พบข้อมูลในระบบ</h4>
      </div>

      <div v-else class="row mt-5">
        <div class="col-1"></div>
        <div class="col-3">สิทธิ์ที่ได้รับ</div>
        <div class="col-2">จำนวนเงิน</div>
        <div class="col-3">ประเภทการจ่าย</div>
        <div class="col-1">สถานะ</div>
        <div class="col-2" style="text-align: right">จัดการ</div>
      </div>
      <div class="row card mt-2" v-for="(l, index) in list" :key="l.no">
        <div class="card-body p-0">
          <div class="row">
            <div class="col-1">{{ index + 1 }}</div>
            <div class="col-3">{{ l.name }}</div>
            <div class="col-2">{{ convernumber(l.amount) }}</div>
            <div class="col-3" v-if="l.fixed_or_daily == 'fixed'">รายครั้ง</div>
            <div class="col-3" v-if="l.fixed_or_daily == 'daily'">รายวัน</div>
            <div class="col-3" v-if="l.fixed_or_daily == 'once'">ครั้งแรก</div>
            <div class="col-2" v-if="l.is_active == true">ใช้งานอยู่</div>
            <div class="col-2" v-if="l.is_active == false">เลิกใช้งาน</div>
            <div class="col-1" style="text-align: right">
              <a
                data-toggle="modal"
                data-target="#exampleModal"
                href="#"
                @click="sendInfo('edit', l.privileged_id)"
                ><i class="fas fa-pencil-alt"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        <button
          class="btn btn-warning"
          style="color: white"
          @click="hasHistory() ? $router.go(-1) : $router.push('/')"
        >
          ย้อนกลับ
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import PrivilegeDataService from "../../services/PrivilegeDataService";
import HomeIcon from "../HomeIcon.vue";

export default {
  components: {
    HomeIcon,
  },
  data() {
    return {
      name: "HelloWorld",
      title: "",
      header: "สิทธิ์ที่ได้รับตามระเบียบ กพต.",
      list: [],
      data: [],
      isstatus: "Active",
      ispaid: "daily",
    };
  },
  mounted() {
    this.getlist();
  },
  methods: {
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }
    },
    getlist() {
      PrivilegeDataService.getPrivileges().then((res) => {
        this.list = res.data;
        // console.log(res.data);
      });
    },
    getid(id) {
      PrivilegeDataService.getPrivilege(id).then((res) => {
        this.data = res.data;
        // console.log(res.data);
        if (this.data.is_active == true) {
          this.isstatus = "Active";
        } else {
          this.isstatus = "Disactive";
        }
        this.ispaid = this.data.fixed_or_daily;
      });
    },
    sendInfo(item, id) {
      // console.log(item, id);
      if (item == "add") {
        this.data = [];
        this.title = "เพิ่ม" + this.header;
      } else {
        this.title = "แก้ไข" + this.header;
        this.getid(id);
      }
    },
    save() {
      // console.log(this.data);
      if (this.isstatus == "Active") {
        this.data.is_active = true;
      } else {
        this.data.is_active = false;
      }
      var listdata = {
        name: this.data.name,
        fixed_or_daily: this.ispaid,
        amount: this.data.amount,
        detail: "",
        is_active: this.data.is_active,
        created_by: this.currentUser.user_id,
        updated_by: this.currentUser.user_id,
      };
      // console.log(listdata);
      if (
        this.data.name == "" ||
        this.data.name == null ||
        this.data.amount == "" ||
        this.data.amount == null
      ) {
        alert("กรุณากรอกข้อมูลให้ครบ");
      } else {
        if (this.data.privileged_id) {
          PrivilegeDataService.updatePrivilege(
            this.data.privileged_id,
            listdata
          )
            .then(() => {
              // console.log(response.data);
              this.data = [];
              this.isstatus = "Active";
              this.ispaid = "daily";
              this.getlist();
              document.getElementById("closed").click();
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
          PrivilegeDataService.createPrivilege(listdata)
            .then(() => {
              // console.log(response.data);
              this.data = [];
              this.isstatus = "Active";
              this.ispaid = "daily";
              this.getlist();
              document.getElementById("closed").click();
            })
            .catch((e) => {
              console.log(e);
            });
        }
      }
    },
    hasHistory() {
      return window.history.length > 2;
    },
    convernumber(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  font-family: "Kanit" !important;
}
.row {
  max-width: 900px;
  margin: auto;
  background: white;
  padding: 10px;
  color: black !important;
}
a:visited {
  color: black;
} /* visited link */
a:hover {
  color: black;
} /* mouse over link */
a:active {
  color: black;
}
a:link {
  color: black;
}

.modal-content {
  float: center;
  text-align: center;
  width: 600px;
}
.modal {
  text-align: center;
  padding: 0 !important;
}
.modal:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}
.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  max-width: 600px;
}
</style>
