<template>
  <div class="hello">
    <HomeIcon />
    <div class="mt-5">
      <div class="row card mt-5" style="padding:10px;">
        <div class="card-body p-0" style="margin: 5px">
          <h3 style="text-align: center">รายละเอียดประเภทการช่วยเหลือ</h3>
          <div class="row">
            <div class="col-4" style="font-weight: bold">
              ประเภทการช่วยเหลือ
            </div>
            <div class="col-8">{{ event.name }}</div>
          </div>
          <div class="row">
            <div class="col-4" style="font-weight: bold">สถานะ</div>
            <div class="col-8" v-if="event.is_active == true">ใช้งานอยู่</div>
            <div class="col-8" v-if="event.is_active == false">เลิกใช้งาน</div>
          </div>
        </div>
      </div>
      <div class="row card mt-5">
        <div class="card-body p-0" style="margin: 5px">
          <h3 style="text-align: center">สิทธิ์ที่ได้รับตามระเบียบ กพต.</h3>
          <div>
            <div class="row">
              <div class="col-10"></div>
              <div class="col-2" style="text-align: right">
                <button
                  type="button"
                  class="btn btn-block btn-success"
                  data-toggle="modal"
                  data-target="#addModal"
                  @click="sendInfo('add', 0)"
                >
                  เพิ่ม
                </button>
              </div>
              <!-- Modal Delete -->
              <div
                class="modal fade"
                id="deleteModal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="editModal"
                aria-hidden="true"
              >
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <!-- Role -->
                      <h4 style="text-align: center; font-weight: bold">
                        ยืนยันการลบข้อมูล
                      </h4>
                    </div>
                    <div class="bd-example mb-5">
                      <button
                        type="button"
                        style="margin-right: 20px; width: 150px"
                        class="btn btn-success"
                        @click="comfirmdeleteItem()"
                      >
                        ลบ
                      </button>
                      <button
                        type="button"
                        id="deleteclosed"
                        class="btn btn-danger"
                        style="margin-right: 20px; width: 150px"
                        data-dismiss="modal"
                      >
                        ยกเลิก
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Modal Create -->
              <div
                class="modal fade"
                id="addModal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="editModal"
                aria-hidden="true"
              >
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <!-- Role -->
                      <h4 style="text-align: center; font-weight: bold">
                        {{ title }}
                      </h4>
                      <div class="row mt-5">
                        <div class="col-12">
                          <div class="form-group" style="text-align: left">
                            <label for="exampleInputEmail1"
                              >{{ header
                              }}<span style="color: red"> *</span></label
                            >
                            <select
                              class="form-select mb-3"
                              @change="getPrivilegeid"
                            >
                              <option selected>เลือก</option>
                              <option
                                v-for="a in privilege"
                                v-bind:value="a.privileged_id"
                                :key="a.privileged_id"
                              >
                                {{ a.name }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="bd-example mb-5">
                      <button
                        type="button"
                        style="margin-right: 20px; width: 150px"
                        class="btn btn-success"
                        @click="save()"
                      >
                        บันทึก
                      </button>
                      <button
                        type="button"
                        id="closed"
                        class="btn btn-danger"
                        style="margin-right: 20px; width: 150px"
                        data-dismiss="modal"
                      >
                        ยกเลิก
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Modal Edit -->
              <div
                class="modal fade"
                id="exampleModal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="editModal"
                aria-hidden="true"
              >
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <!-- Role -->
                      <h4 style="text-align: center; font-weight: bold">
                        {{ title }}
                      </h4>
                      <div class="row mt-5">
                        <div class="col-12">
                          <div class="form-group" style="text-align: left">
                            <label for="exampleInputEmail1"
                              >{{ header
                              }}<span style="color: red"> *</span></label
                            >
                            <input
                              type="text"
                              class="form-control"
                              id="exampleInputEmail1"
                              :placeholder="'กรุณากรอก' + header"
                            />
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form-group" style="text-align: left">
                            <label for="exampleInputEmail1"
                              >จำนวนเงิน<span style="color: red">
                                *</span
                              ></label
                            >
                            <input
                              type="number"
                              class="form-control"
                              id="exampleInputEmail1"
                              placeholder="กรุณากรอกจำนวนเงิน"
                            />
                          </div>
                        </div>
                        <div class="col-6"></div>
                        <div class="col-3" style="text-align: left">
                          <label for="exampleInputEmail1"
                            >ประเภทการจ่าย<span style="color: red">
                              *</span
                            ></label
                          >
                        </div>
                        <div class="col-3" style="text-align: left">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="radio1"
                              checked=""
                            />
                            <label class="form-check-label">รายครั้ง</label>
                          </div>
                        </div>
                        <div class="col-3">
                          <div class="form-check" style="text-align: left">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="radio1"
                            />
                            <label class="form-check-label">รายวัน</label>
                          </div>
                        </div>
                        <div class="col-3">
                          <div class="form-check" style="text-align: left">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="radio1"
                            />
                            <label class="form-check-label">ครั้งแรก</label>
                          </div>
                        </div>
                        <div class="col-3" style="text-align: left">
                          <label for="exampleInputEmail1"
                            >สถานะ<span style="color: red"> *</span></label
                          >
                        </div>
                        <div class="col-3" style="text-align: left">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="radio1"
                              checked=""
                            />
                            <label class="form-check-label">ใช้งานอยู่</label>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form-check" style="text-align: left">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="radio1"
                            />
                            <label class="form-check-label">เลิกใช้งาน</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="bd-example mb-5">
                      <button
                        type="button"
                        style="margin-right: 20px; width: 150px"
                        class="btn btn-success"
                        @click="save()"
                      >
                        บันทึก
                      </button>
                      <button
                        type="button"
                        class="btn btn-danger"
                        style="margin-right: 20px; width: 150px"
                        data-dismiss="modal"
                      >
                        ยกเลิก
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-5 mb-5" v-if="list.length == 0">
              <h4 style="text-align: center">ไม่พบข้อมูลในระบบ</h4>
            </div>
            <div v-else class="row mt-5">
              <div class="col-1"></div>
              <div class="col-3">สิทธิ์ที่ได้รับ</div>
              <div class="col-2">จำนวนเงิน</div>
              <div class="col-3">ประเภทการจ่าย</div>
              <div class="col-1">สถานะ</div>
              <div class="col-2" style="text-align: right">จัดการ</div>
            </div>
            <div class="row card mt-2" v-for="(l, index) in list" :key="l.no">
              <div class="card-body p-0">
                <div class="row">
                  <div class="col-1">{{ index + 1 }}</div>
                  <div class="col-3">{{ l.name }}</div>
                  <div class="col-2">{{ l.amount }}</div>
                  <div class="col-3" v-if="l.fixed_or_daily == 'fixed'">
                    รายครั้ง
                  </div>
                  <div class="col-3" v-if="l.fixed_or_daily == 'daily'">
                    รายวัน
                  </div>
                  <div class="col-3" v-if="l.fixed_or_daily == 'once'">
                    ครั้งแรก
                  </div>
                  <div class="col-2" v-if="l.is_active == true">ใช้งานอยู่</div>
                  <div class="col-2" v-if="l.is_active == false">
                    เลิกใช้งาน
                  </div>
                  <div class="col-1" style="text-align: right">
                    <a
                      style="margin-left: 5px; color: red"
                      data-toggle="modal"
                      data-target="#deleteModal"
                      href="#"
                      @click="deleteItem(l.event_type_privilege_id)"
                      ><i class="fas fa-trash-alt"></i
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col text-center">
              <button
                class="btn btn-warning"
                style="color: white"
                @click="hasHistory() ? $router.go(-1) : $router.push('/')"
              >
                ย้อนกลับ
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PrivilegeDataService from "../../services/PrivilegeDataService";
import EventTypesDataService from "../../services/EventTypesDataService";
import HomeIcon from "../HomeIcon.vue";

export default {
  components: {
    HomeIcon,
  },

  data() {
    return {
      name: "HelloWorld",
      title: "",
      PrivilegeId: "",
      header: "สิทธิ์ที่ได้รับตามระเบียบ กพต.",
      list: [],
      privilege: [],
      event_type_id: "",
      id: "",
      event: [],
    };
  },
  mounted() {
    this.event_type_id = this.$route.params.id;
    // // console.log(this.$route.params.id);
    this.getevent();
    this.getlist();
    this.getprivilege();
  },
  methods: {
    getevent() {
      EventTypesDataService.getEventType(this.event_type_id).then((res) => {
        this.event = res.data;
        // // console.log(res.data);
      });
    },
    getlist() {
      PrivilegeDataService.getPrivilegeByEventId(this.event_type_id).then(
        (res) => {
          this.list = res.data;
          // // console.log(res.data);
        }
      );
    },
    sendInfo(item, id) {
      // // console.log(item, id);
      if (item == "add") {
        this.title = "เพิ่ม" + this.header;
      } else {
        this.title = "แก้ไข" + this.header;
        this.getid(id);
      }
    },
    save() {
      // console.log(this.event_type_id);
      // console.log(this.PrivilegeId);
      if (
        this.event_type_id == "" ||
        this.event_type_id == null ||
        this.PrivilegeId == "" ||
        this.PrivilegeId == null
      ) {
        alert("กรุณากรอกข้อมูลให้ครบ");
      } else {
        var check = {
          event_type_id: this.event_type_id,
          privilege_id: this.PrivilegeId,
        };
        PrivilegeDataService.checkeventandpri(check)
          .then((response) => {
            // // console.log(response.data);
            if (response.data == "") {
              var listdata = {
                event_type_id: this.event_type_id,
                privilege_id: this.PrivilegeId,
                is_active: true,
                created_by: this.currentUser.user_id,
                updated_by: this.currentUser.user_id,
              };
              // // console.log(listdata);
              PrivilegeDataService.createEventPrivilege(listdata)
                .then(() => {
                  // // console.log(response.data);
                  document.getElementById("closed").click();
                  // // console.log(this.list);
                  if (this.list.length == 0) {
                    setTimeout(function () {
                      location.reload();
                    }, 300);
                  }
                })
                .catch((e) => {
                  console.log(e);
                });
            } else {
              this.PrivilegeId = "";
              alert("ข้อมูลนี้มีอยู่ในตารางแล้ว");
            }
            this.getlist();
          })
          .catch(() => {});
      }
    },
    deleteItem(id) {
      this.id = id;
      // // console.log(this.id);
    },
    async comfirmdeleteItem() {
      // // console.log(this.id);
      PrivilegeDataService.deletePrivilege(this.id).then(() => {
        // // console.log(res.data);
        document.getElementById("deleteclosed").click();
        this.getlist();
        if (this.list.length == 1) {
          setTimeout(function () {
            location.reload();
          }, 300);
        }
      });
    },
    getprivilege() {
      PrivilegeDataService.getPrivileges().then((res) => {
        this.privilege = res.data;
        // // console.log(res.data);
      });
    },
    getPrivilegeid(event) {
      this.PrivilegeId = event.target.value;
    },
    hasHistory() {
      return window.history.length > 2;
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<style scoped>
body {
  font-family: "Kanit" !important;
}
.row {
  max-width: 900px;
  margin: auto;
  background: white;
  padding: 10px;
  color: black !important;
}
a:visited {
  color: black;
} /* visited link */
a:hover {
  color: black;
} /* mouse over link */
a:active {
  color: black;
}
a:link {
  color: black;
}

.modal-content {
  float: center;
  text-align: center;
  width: 600px;
}
.modal {
  text-align: center;
  padding: 0 !important;
}
.modal:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}
.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  max-width: 600px;
}
</style>
