<template>
   <div class="hello">
    <div class="mt-5">
      <div class="row card mt-2">
        <div class="card-body p-0 mt-3 col-md-10">
          <h4 style="text-align: center">ค้นหา</h4>
          <div class="row">
            <div style="width: 12%"></div>
            <div class="col-md-9">
              <div class="form-group" style="text-align: left">
                <label for="province"
                  >แสดงรายงาน <span style="color: red"> *</span></label
                >
                <select
                  v-model="type"
                  class="form-select mb-3"
                  @change="getsearchtracking"
                >
                  <option value="1">
                    รายงานการช่วยเหลือด้านคุณภาพชีวิตลักษณะรายบุคคล
                  </option>
                  <option value="2">
                    รายงานสรุปจำนวนการช่วยเหลือด้านคุณภาพชีวิต ตามปี
                    จังหวัด และอำเภอ
                  </option>
                   <option value="3">
                    รายงานรายละเอียดช่วยเหลือด้านคุณภาพชีวิต
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row" v-if="type == 1">
            <div style="width: 12%"></div>
            <div class="col-md-5">
              <div class="form-group" style="text-align: left">
                <label for="province">เลขบัตรประชาชนผู้เสียหาย </label>
                <input class="form-control mb-3" v-model="event.id_card" />
              </div>
            </div>
          </div>
          <div class="row" id="hidetwo">
            <div style="width: 12%"></div>
            <div class="col-md-4">
              <div class="form-group" style="text-align: left">
                <label for="username"
                  >วันที่ยื่นคำร้อง <span style="color: red"> *</span></label
                >
                <input id="fill_start_date" class="form-control" />
                <!-- <date-picker v-model="date" :config="options"></date-picker> -->
              </div>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-4">
              <div class="form-group" style="text-align: left">
                <label for="username"
                  >ถึงวันที่ยื่นคำร้อง <span style="color: red"> *</span></label
                >
                <input id="fill_end_date" class="form-control" />
                <!-- <date-picker v-model="date" :config="options"></date-picker> -->
              </div>
            </div>
          </div>
           <div class="row" id="hidethree">
            <div style="width: 12%"></div>
            <div class="col-md-4">
              <div class="form-group" style="text-align: left">
                <label for="username"
                  >วันที่ช่วยเหลือ</label
                >
                <input id="quality_start_date" class="form-control" />
                <!-- <date-picker v-model="date" :config="options"></date-picker> -->
              </div>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-4">
              <div class="form-group" style="text-align: left">
                <label for="username"
                  >ถึงวันที่ช่วยเหลือ </label
                >
                <input id="quality_end_date" class="form-control" />
                <!-- <date-picker v-model="date" :config="options"></date-picker> -->
              </div>
            </div>
          </div>
          <div class="row" v-if="type != 1">
            <div style="width: 12%"></div>
            <div class="col-md-3">
              <div class="form-group" style="text-align: left">
                <label for="province">จังหวัดตามที่อยู่ปัจจุบัน </label>
                <select
                  class="form-select mb-3"
                  v-model="event.province_house"
                  @change="onChangeProvince"
                  id="province_house"
                >
                  <option
                    v-for="(p, i) in provinces"
                    v-bind:value="p.PROVINCE_ID"
                    :key="i + 2"
                  >
                    {{ p.PROVINCE_NAME }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group" style="text-align: left">
                <label for="province">อำเภอตามที่อยู่ปัจจุบัน</label>
                <select
                  class="form-select mb-3"
                  v-model="event.district_house"
                  @change="onChangeDistrict"
                  id="district_house"
                >
                  <option
                    v-for="(a, i) in amphures"
                    v-bind:value="a.AMPHUR_ID"
                    :key="i + 3"
                  >
                    {{ a.AMPHUR_NAME }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group" style="text-align: left">
                <label for="province">ตำบลตามที่อยู่ปัจจุบัน</label>
                <select
                  class="form-select mb-3"
                  v-model="event.sub_district_house"
                  id="sub_district_house"
                >
                  <option
                    v-for="(d, i) in districts"
                    v-bind:value="d.DISTRICT_ID"
                    :key="i + 4"
                    :name="d.DISTRICT_NAME"
                  >
                    {{ d.DISTRICT_NAME }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          
          <div class="col text-center">
            <button
              @click="search()"
              class="btn btn-primary mb-3"
              style="color: white"
            >
              <i class="fas fa-search"></i> ค้นหา
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-5" v-if="nodata">
      <div class="col-sm-12" style="text-align: right" v-if="type == 1">
        <button
          v-if="columns"
          class="btn btn-success"
          style="color: white"
          @click="exportTableToExcel('tblData')"
        >
          Export
        </button>
      </div>
       <div class="col-sm-12" style="text-align: right" v-if="type == 3">
        <button
          v-if="columns"
          class="btn btn-success"
          style="color: white"
          @click="exportTableToExcel('tblData3')"
        >
          Export
        </button>
      </div>
      <div class="col-12" align="right" style="display: none">
        <vue-excel-xlsx
          id="excel"
          :data="data"
          :columns="columns"
          :file-name="filename"
          :file-type="'xlsx'"
          :sheet-name="'sheetname'"
        >
          Export Excel
        </vue-excel-xlsx>
      </div>
      <div class="col-md-12 mt-3">
        <h5 class="form-group" style="color: #2da3f2; font-weight: bold">
          {{ typename }}
        </h5>
      </div>
      <div class="row" v-if="type == 1">
        <div class="mt-3">
          <table class="table table-bordered" id="tblData">
            <thead>
              <tr>
                <th
                  scope="col"
                  style="text-align: center; background-color: #d9d9d9"
                  colspan="10"
                >
                  {{ typename }}
                </th>
              </tr>
              <tr>
                <th
                  scope="col"
                  style="text-align: center; background-color: #d9d9d9"
                  colspan="10"
                >
                  เลขบัตรประชาชน {{ event.id_card }}
                </th>
              </tr>
              <tr>
                <th
                  scope="col"
                  style="text-align: center; background-color: #d9d9d9"
                  colspan="10"
                >
                   {{ event.firstname }} {{ event.lastname }}
                </th>
              </tr>
              <tr>
                <td rowspan="2" align="center">ลำดับ</td>
                <td rowspan="2" align="center">เลขบัตรประชาชน</td>
                <td rowspan="2" align="center">คำนำหน้า</td>
                <td rowspan="2" align="center">ชื่อ-สกุล</td>
                <td rowspan="2" align="center">ครั้งที่</td>
                <td colspan="3" style="text-align: center">ทายาท</td>
                <td colspan="2" style="text-align: center">
                  ข้อมูลการช่วยเหลือการติดตาม
                </td>
              </tr>
              <tr>
                <td style="text-align: center">เลขบัตรประชาชน</td>
                <td style="text-align: center">คำนำหน้า</td>
                <td style="text-align: center">ชื่อ-สกุล</td>
                <td style="text-align: center">วันที่ช่วยเหลือคุณภาพชีวิต</td>
                <td style="text-align: center">ข้อมูลการช่วยเหลือ</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(y, i) in list" :key="'Y' + i">
                <td style="text-align: right">{{ i + 1 }}</td>
                <td style="text-align: right; width: 150px">
                  <span style="color:white">'</span>{{ y.id_card }}
                </td>
                <td style="text-align: left; width: 85px">{{ y.prefix }}</td>
                <td style="text-align: left; width: 200px">{{ y.name }}</td>
                <td style="text-align: right">{{ y.no }}</td>
                <td style="text-align: right; width: 180px">
                  <span style="color:white">'</span>{{ y.id_card_relative }}
                </td>
                <td style="text-align: left; width: 120px">
                  {{ y.prefix_relative }}
                </td>
                <td style="text-align: left; width: 220px">
                  {{ y.name_relative }}
                </td>
                <td style="text-align: right; width: 180px">
                  {{ y.quality_date }}
                </td>
                <td style="text-align: left; width: 150px">
                  {{ y.help_detail }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row" v-if="type == 2">
        <div class="mb-3" style="text-align: right">
            <button
              v-if="columns"
              class="btn btn-success"
              style="color: white"
              @click="exportTableToExcel('tblData2')"
            >
              Export
            </button>
          </div>
        <div class="col-md-12 mt-3" id="tblData2">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th
                  scope="col"
                  style="text-align: center; background-color: #d9d9d9"
                  colspan="5"
                >
                  {{ typename }}
                </th>
              </tr>
              <tr>
                <th
                  scope="col"
                  style="text-align: center; background-color: #d9d9d9"
                  colspan="5"
                >
                  วันที่ยื่นคำร้อง {{fillstart}}  ถึง {{fillend}}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th style="text-align: center" scope="row">ปี</th>
                <th
                  style="text-align: center"
                  v-for="(h, i) in head"
                  :key="'H' + i"
                >
                  {{ h.name }}
                </th>
                <th style="text-align: center">รวมเคส</th>
              </tr>
              <tr v-for="(y, i) in year" :key="'Y' + i">
                <th style="text-align: right" scope="row">{{ y.year }}</th>
                <td
                  style="text-align: right"
                  v-for="(s, i) in y.data"
                  :key="'S' + i"
                >
                  {{ s || 0 }}
                </td>
                <td style="text-align: right">{{ y.sum }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row" v-if="type == 3">
        <div class="mt-3">
          <table class="table table-bordered" id="tblData3">
            <thead>
              <tr>
                <th
                  scope="col"
                  style="text-align: center; background-color: #d9d9d9"
                  colspan="9"
                >
                  {{ typename }}
                </th>
              </tr>
              <tr>
                <th
                  scope="col"
                  style="text-align: center; background-color: #d9d9d9"
                  colspan="9"
                >
                  วันที่ช่วยเหลือ {{fillstart}}  ถึง {{fillend}}
                </th>
              </tr>
              <tr>
                <td rowspan="2" align="center">ลำดับ</td>
                <td rowspan="2" align="center">เลขบัตรประชาชน</td>
                <td rowspan="2" align="center">คำนำหน้า</td>
                <td rowspan="2" align="center">ชื่อ-สกุล</td>
                <td colspan="3" style="text-align: center">ทายาท</td>
                <td colspan="2" style="text-align: center">
                  ข้อมูลการช่วยเหลือการติดตาม
                </td>
              </tr>
              <tr>
                <td style="text-align: center">เลขบัตรประชาชน</td>
                <td style="text-align: center">คำนำหน้า</td>
                <td style="text-align: center">ชื่อ-สกุล</td>
                <td style="text-align: center">วันที่ช่วยเหลือคุณภาพชีวิต</td>
                <td style="text-align: center">ข้อมูลการช่วยเหลือ</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(y, i) in list" :key="'Y' + i">
                <td style="text-align: right">{{ i + 1 }}</td>
                <td style="text-align: right; width: 150px">
                  <span style="color:white">'</span>{{ y.id_card }}
                </td>
                <td style="text-align: left; width: 85px">{{ y.prefix }}</td>
                <td style="text-align: left; width: 200px">{{ y.name }}</td>
                <td style="text-align: right; width: 180px">
                  <span style="color:white">'</span>{{ y.id_card_relative }}
                </td>
                <td style="text-align: left; width: 120px">
                  {{ y.prefix_relative }}
                </td>
                <td style="text-align: left; width: 220px">
                  {{ y.name_relative }}
                </td>
                <td style="text-align: right; width: 180px">
                  {{ y.quality_date }}
                </td>
                <td style="text-align: left; width: 150px">
                  {{ y.help_detail }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col text-center">
        <button class="btn btn-warning" style="color: white" @click="back()">
          ย้อนกลับ
        </button>
      </div>
    </div>
  </div>
</template>

  <script src="https://justice-api.sbpac.go.th/picker_date.js"></script>

<script>
const customLabels = {
  first: "<<",
  last: ">>",
  previous: "<",
  next: ">",
};
import ConvertService from "../../services/ConvertService";

import CasesEventDataService from "../../services/CasesEventDataService";
import HistoryCaseDataService from "../../services/HistoryCaseDataService";
import ProvinceDataService from "../../services/ProvinceDataService";
import AmphurDataService from "../../services/AmphurDataService";
import DistrictDataService from "../../services/DistrictDataService";
import ReportDataService from "../../services/ReportDataService";
import SuspectsDataService from "../../services/SuspectsDataService";

export default {
  components: {},
  data() {
    return {
      name: "HelloWorld",
      case_id: 0,
      id_card: "",
      data: [],
      list: [],
      status: [],
      nodata: false,
      event: {},
      provinces: [],
      amphures: [],
      districts: [],
      type: 1,
      typename: "รายงานการช่วยเหลือด้านคุณภาพชีวิตลักษณะรายบุคคล",
      head: [],
      year: [],
      columns: [],
      filename: "รายงานการช่วยเหลือด้านคุณภาพชีวิตลักษณะรายบุคคล",
    };
  },
  mounted() {
    var year = new Date().getFullYear()
    var start = year-2004
    var before = "-"+start+":+1"
    picker_date(document.getElementById("fill_start_date"), {
      year_range: before,
    });
    picker_date(document.getElementById("fill_end_date"), {
      year_range: before,
    });
    picker_date(document.getElementById("quality_start_date"), {
      year_range: before,
    });
    picker_date(document.getElementById("quality_end_date"), {
      year_range: before,
    });
    this.getProvince();
    this.getAmphur(0);
    this.getDistrict(0);
    this.event.province_house = 0
            this.event.district_house = 0
            this.event.sub_district_house = 0
  },
  methods: {
    exportTableToExcel(tableID, filename = "") {
      var downloadLink;
      var dataType = "application/vnd.ms-excel";
      var tableSelect = document.getElementById(tableID);
      var tableHTML = tableSelect.outerHTML.replace(/ /g, "%20");

      // Specify file name
      var file = ''
      if (this.type == 1) {
        file = "รายงานการช่วยเหลือด้านคุณภาพชีวิตลักษณะรายบุคคล.xls";
      }else if (this.type == 2) {
        file = "รายงานสรุปจำนวนการช่วยเหลือด้านคุณภาพชีวิต ตามปี จังหวัด และอำเภอ.xls";
      }else if (this.type == 3) {
        file = "รายงานรายละเอียดช่วยเหลือด้านคุณภาพชีวิต.xls";
      }
      filename = filename
        ? filename + ".xls"
        : file;

      // Create download link element
      downloadLink = document.createElement("a");

      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        var blob = new Blob(["\ufeff", tableHTML], {
          type: dataType,
        });
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Create a link to the file
        downloadLink.href = "data:" + dataType + ", " + tableHTML;

        // Setting the file name
        downloadLink.download = filename;

        //triggering the function
        downloadLink.click();
      }
    },
    getsearchtracking(evt) {
      this.type = evt.target.value;
      this.typename = evt.target.options[evt.target.options.selectedIndex].text;
      this.nodata = false;
      var one = document.getElementById("hidetwo");
      var two = document.getElementById("hidethree");
      if (one) {
        one.style.display = "none";
      }
      if (two) {
        two.style.display = "none";
      }
      if (this.type == 3) {
        document.getElementById("hidethree").style.display = "flex";
      }
      else if (this.type == 2) {
        document.getElementById("hidetwo").style.display = "flex";
      }
    },
    onChangeDistrict(event) {
      // console.log(this.user.province)
      var amphur_id = event.target.value;
      this.event.sub_district_house = 0;
      this.getDistrict(amphur_id);
    },
    onChangeProvince(event) {
      // console.log(this.user.province)
      var province_id = event.target.value;
      this.event.district_house = 0;
      this.event.sub_district_house = 0;
      this.getAmphur(province_id);
    },
    getDistrict(id) {
      this.districts = []
      if (id == 0) {
        DistrictDataService.getDistrictsAll().then((res) => {
          this.districts.push({
          DISTRICT_ID: 0,
          DISTRICT_NAME: "ทั้งหมด",
        });
        for (let r = 0; r < res.data.length; r++) {
          this.districts.push({
            DISTRICT_ID: res.data[r].DISTRICT_ID,
            DISTRICT_NAME: res.data[r].DISTRICT_NAME,
          });
        }
        });
      } else {
        DistrictDataService.getDistrictAll(id).then((res) => {
          this.districts.push({
          DISTRICT_ID: 0,
          DISTRICT_NAME: "ทั้งหมด",
        });
        for (let r = 0; r < res.data.length; r++) {
          this.districts.push({
            DISTRICT_ID: res.data[r].DISTRICT_ID,
            DISTRICT_NAME: res.data[r].DISTRICT_NAME,
          });
        }
        });
      }
    },
    getAmphur(id) {
      this.amphures = []
      if (id == 0) {
        AmphurDataService.getAmphursAll().then((res) => {
          // this.amphures = res.data;
          this.amphures.push({
          AMPHUR_ID: 0,
          AMPHUR_NAME: "ทั้งหมด",
        });
        for (let r = 0; r < res.data.length; r++) {
          this.amphures.push({
            AMPHUR_ID: res.data[r].AMPHUR_ID,
            AMPHUR_NAME: res.data[r].AMPHUR_NAME,
          });
        }
        });
      } else {
        AmphurDataService.getAmphurAll(id).then((res) => {
          this.amphures.push({
          AMPHUR_ID: 0,
          AMPHUR_NAME: "ทั้งหมด",
        });
        for (let r = 0; r < res.data.length; r++) {
          this.amphures.push({
            AMPHUR_ID: res.data[r].AMPHUR_ID,
            AMPHUR_NAME: res.data[r].AMPHUR_NAME,
          });
        }
          // console.log(res.data);
        });
      }
      // this.savedraft();
    },
    getProvince() {
      ProvinceDataService.getProvinceAll().then((res) => {
        // this.provinces = res.data;
        // console.log(res.data);
        this.provinces.push({
          PROVINCE_ID: 0,
          PROVINCE_NAME: "ทั้งหมด",
        });
        for (let r = 0; r < res.data.length; r++) {
          this.provinces.push({
            PROVINCE_ID: res.data[r].PROVINCE_ID,
            PROVINCE_NAME: res.data[r].PROVINCE_NAME,
          });
        }
      });
    },
    getstatus() {
      this.status = [];
      HistoryCaseDataService.getHistoryCaseByCaseID(this.case_id).then(
        (res) => {
          this.status = res.data;
          // console.log(res.data);
        }
      );
    },
    cal(start, end) {
      var date1 = new Date(start);
      var date2 = new Date(end);
      var diffTime = date2.getTime() - date1.getTime();
      var diffDay = diffTime / (1000 * 3600 * 24);
      return diffDay;
    },
    getEventByCaseID() {
      CasesEventDataService.getEventByCaseID(this.case_id).then((res) => {
        this.list = res.data;
        // console.log(res.data);
      });
    },
    back() {
      this.$router.push("/home");
    },
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    },
    convertdatetothai(datetimes) {
      if (datetimes) {
        const date = new Date(datetimes);
        const result = date.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return result;
      } else {
        return "-";
      }
    },
    hasHistory() {
      return window.history.length > 2;
    },
    exportexcel() {
      this.generateexcel();
      document.getElementById("excel").click();
    },
    generateexcel() {
      this.data = [];
      this.data = this.list;
      // console.log(this.columns);
      // console.log(this.data);
    },
    generatecolumn() {
      this.columns = [];
      this.columns.push(
        {
          label: "ลำดับ",
          field: "index",
        },
        {
          label: "เลขบัตรประชาชน",
          field: "id_card",
        },
        {
          label: "คำนำหน้า",
          field: "prefix",
        },
        {
          label: "ชื่อ-สกุล",
          field: "name",
        },
        {
          label: "ครั้งที่",
          field: "no",
        },
        {
          label: "เลขบัตรประชาชนทายาท",
          field: "id_card_relative",
        },
        {
          label: "คำนำหน้าทายาท",
          field: "prefix_relative",
        },
        {
          label: "ชื่อ-สกุลทายาท",
          field: "name_relative",
        },
        {
          label: "วันที่ช่วยเหลือคุณภาพชีวิต",
          field: "help_detail",
        },
        {
          label: "ข้อมูลการช่วยเหลือ",
          field: "quality_date",
        }
      );
    },
    search() {
      if (this.event.id_card != '') {
         SuspectsDataService.getSuspects(this.event.id_card).then((res)=>{
                  this.event.firstname = res.data.firstname
                  this.event.lastname = res.data.lastname
                  // console.log(res.data.firstname);
                  // console.log(this.event);
                })
      }
      if (this.type == 1) {
        if (this.event.id_card == null) {
          alert("กรุณากรอกเลขบัตรประชาชน");
        } else {
         
          var user_id = "";
          if (this.currentUser.role_id != 3 && this.currentUser.role_id != 1) {
            user_id = this.currentUser.user_id;
          }
          ReportDataService.getQualityone(this.event.id_card,
          '',
            '',
            0,
            0,
            0, user_id).then(
            (res) => {
              // console.log(res.data);
              this.list = res.data;
              if (res.data.length != 0) {
                this.nodata = true;
                for (let l = 0; l < this.list.length; l++) {
                  this.list[l].index = l + 1;
                  this.list[l].quality_date = this.convertdatetothai(
                    this.list[l].quality_date
                  );
                }
                this.generatecolumn();
              } else {
                alert("ไม่พบข้อมูล");
                this.nodata = false;
              }
            }
          );
        }
      }else if (this.type == 2)  {
        if (document.getElementById("fill_start_date").value == "") {
          alert("กรุณากรอกวันที่ยื่นคำร้อง");
        } else if (document.getElementById("fill_end_date").value == "") {
          alert("กรุณากรอกถึงวันที่ยื่นคำร้อง");
        } else {
          var start;
          var end;
          var s = document.getElementById("fill_start_date").value;
          var e = document.getElementById("fill_end_date").value;
          // var dd = new Date(d)
          start = ConvertService.createddate(s);
          end = ConvertService.createddate(e);
          this.fillstart = this.convertdatetothai(start);
          this.fillend = this.convertdatetothai(end);
          var user_id = "";
          if (this.currentUser.role_id != 3 && this.currentUser.role_id != 1) {
            user_id = this.currentUser.user_id;
          }
          ReportDataService.getQualitytwo(
            start,
            end,
            this.event.province_house,
            this.event.district_house,
            this.event.sub_district_house,
            user_id
          ).then((res) => {
            // console.log(res.data);
            if (res.data[0].month != 0) {
              this.head = res.data[0].series;
              this.year = res.data[0].month;
              this.nodata = true;
              for (let ev = 0; ev < this.year.length; ev++) {
                var total = 0;
                for (
                  let index = 0;
                  index < this.year[ev].data.length;
                  index++
                ) {
                  total += this.year[ev].data[index];
                }
                this.year[ev].sum = total;
              }
            } else {
              alert("ไม่พบข้อมูล");
              this.nodata = false;
            }
          });
        }
      }else if (this.type == 3) {
        if (document.getElementById("quality_start_date").value == '') {
          alert("กรุณากรอกวันที่ช่วยเหลือ");
        } else if (document.getElementById("quality_end_date").value == '') {
          alert("กรุณากรอกถึงวันที่ช่วยเหลือ");
        }else {
          var start = '';
          var end = '';
         
            var s = document.getElementById("quality_start_date").value;
          var e = document.getElementById("quality_end_date").value;
          // var dd = new Date(d)
          start = ConvertService.createddate(s);
          end = ConvertService.createddate(e);
          this.fillstart = this.convertdatetothai(start);
          this.fillend = this.convertdatetothai(end);           
          
          // console.log(start,end);
          var user_id = "";
          if (this.currentUser.role_id != 3 && this.currentUser.role_id != 1) {
            user_id = this.currentUser.user_id;
          }
          ReportDataService.getQualitythree('',
          start,
            end,
            this.event.province_house,
            this.event.district_house,
            this.event.sub_district_house, user_id).then(
            (res) => {
              // console.log(res.data);
              this.list = res.data;
              if (res.data.length != 0) {
                this.nodata = true;
                for (let l = 0; l < this.list.length; l++) {
                  this.list[l].index = l + 1;
                  this.list[l].quality_date = this.convertdatetothai(
                    this.list[l].quality_date
                  );
                }
                this.generatecolumn();
              } else {
                alert("ไม่พบข้อมูล");
                this.nodata = false;
              }
            }
          );
        }
      }
    },
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  font-family: "Kanit" !important;
}
.row {
  max-width: 1200px;
  margin: auto;
  background: white;
  padding: 10px;
  color: black !important;
}
a:visited {
  color: black;
} /* visited link */
a:hover {
  color: black;
} /* mouse over link */
a:active {
  color: black;
}
a:link {
  color: black;
}
#hidetwo {
  display: none;
}
#hidethree {
  display: none;
}
</style>
