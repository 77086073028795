<template>
  <div class="hello">
    <HomeIcon />
    <div class="mt-5">
      <div class="row card mt-2">
        <div class="card-body p-0">
          <h4 style="text-align: center">ค้นหา</h4>
          <div class="row">
            <div class="col-sm-2"></div>
            <div class="col-sm-4">
              <div class="form-group" style="text-align: left">
                <label for="username">เลขบัตรประชาชนผู้เสียหาย</label>
                <input
                  v-model="id_card"
                  type="number"
                  class="form-control"
                  id="username"
                  placeholder="เลขบัตรประชาชน 13 หลัก"
                />
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group" style="text-align: left">
                <label for="username"
                  >วันที่ยื่นคำร้องขอรับการช่วยเหลือเยียวยา
                </label>
                <input id="fill_start_date" class="form-control" />
              </div>
            </div>
            <div class="col-sm-2"></div>
          </div>
        </div>
        <div class="col text-center">
          <button
            @click="search()"
            class="btn btn-primary"
            style="color: white"
          >
            <i class="fas fa-search"></i> ค้นหา
          </button>
        </div>
      </div>
      <div class="row card mt-5" v-if="case_id != 0">
        <div class="card-body p-0">
          <div class="row">
            <div class="col-md-12 mt-3">
              <h5 class="form-group" style="color: #2da3f2; font-weight: bold">
                ข้อมูลผู้เสียหาย
              </h5>
            </div>
            <div class="col-md-3">
              <label>เลขบัตรประชาชนผู้เสียหาย</label>
            </div>
            <div class="col-md-9">
              <label>{{ data.id_card }}</label>
            </div>
            <div class="col-md-3">
              <label>ชื่อ-สกุล</label>
            </div>
            <div class="col-md-9">
              <label>{{ data.firstname }} {{ data.lastname }}</label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 mt-3">
              <h5 class="form-group" style="color: #2da3f2; font-weight: bold">
                ข้อมูลลักษณะเหตุการณ์
              </h5>
               <table class="table table-bordered">
      <thead>
        <tr>
          <th style="text-align:center" scope="col">ลักษณะเหตุการณ์</th>
          <th style="text-align:center" scope="col">วันที่ควบคุมตัว</th>
          <th style="text-align:center" scope="col">วันที่ปล่อยตัว</th>
          <th style="text-align:center" scope="col">จำนวนวัน</th>
          <th style="text-align:center" scope="col">หน่วยงานที่ควบคุมตัว</th>
          <th style="text-align:center" scope="col">สถานที่</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(l, i) in list" :key="i">
          <td>{{ l.event_name }}</td>
          <td style="text-align:center">{{ convertdatetothai(l.detained_start_date) }}</td>
          <td style="text-align:center">{{ convertdatetothai(l.detained_end_date) }}</td>
          <td style="text-align:center">{{ cal(l.detained_start_date, l.detained_end_date) }} วัน</td>
          <td style="text-align:center">{{ l.department_detained }}</td>
          <td style="text-align:center">{{ l.event_id }}</td>
        </tr>
      </tbody>
    </table>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 mt-3">
              <h5 class="form-group" style="color: #2da3f2; font-weight: bold">
                สถานะ
              </h5>
              <table class="table table-bordered">
        <thead>
          <tr>
            <th scope="col">วันที่</th>
            <th scope="col">สถานะ</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(d, i) in status" :key="i + 9">
            <td>{{ convertdatetothai(d.created_date) }}</td>
            <td>
              {{ d.name }}
            </td>
          </tr>
        </tbody>
      </table>
            </div>
          </div>
        </div>
      </div>
<div class="row card mt-5" v-if="nodata">
          <div class="card-body p-0" >
          <div class="row">
            <div class="col-md-12 mt-3">
              <h5 class="form-group" style="text-align: center;color: #2da3f2; font-weight: bold">
                ไม่พบข้อมูล
              </h5>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col text-center">
          <button class="btn btn-warning" style="color: white" @click="back()">
            ย้อนกลับ
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

  <script src="https://justice-api.sbpac.go.th/picker_date.js"></script>

<script>
const customLabels = {
  first: "<<",
  last: ">>",
  previous: "<",
  next: ">",
};

import HomeIcon from "../HomeIcon.vue";
import CasesDataService from "../../services/CasesDataService";
import ConvertService from "../../services/ConvertService";
import CasesEventDataService from "../../services/CasesEventDataService";
import HistoryCaseDataService from "../../services/HistoryCaseDataService";

export default {
  components: {
    HomeIcon,
  },
  data() {
    return {
      name: "HelloWorld",
      case_id: 0,
      id_card: "",
      data: {},
      list: [],
      status:[],
      nodata:false
    };
  },
  mounted() {
    var year = new Date().getFullYear()
    var start = year-2004
    var before = "-"+start+":+0"
    picker_date(document.getElementById("fill_start_date"), {
      year_range: before,
    });
  },
  methods: {
    getstatus() {
      this.status = []
      HistoryCaseDataService.getHistoryCaseByCaseID(this.case_id).then(
        (res) => {
          this.status = res.data;
          console.log(res.data);
        }
      );
    },
    cal(start, end) {
      var date1 = new Date(start);
      var date2 = new Date(end);
      var diffTime = date2.getTime() - date1.getTime();
      var diffDay = diffTime / (1000 * 3600 * 24);
      return diffDay;
    },
    getEventByCaseID() {
      CasesEventDataService.getEventByCaseID(this.case_id).then((res) => {
        this.list = res.data;
        // console.log(res.data);
      });
    },
    back() {
      this.$router.push("/home");
    },
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    },
    convertdatetothai(datetimes) {
      if (datetimes) {
        const date = new Date(datetimes);
        const result = date.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return result;
      } else {
        return "-";
      }
    },
    hasHistory() {
      return window.history.length > 2;
    },
    search() {
      this.case_id = 0
      if (this.id_card == "") {
        alert("กรุณากรอกเลขบัตรประชาชนผู้เสียหาย");
      } else if (document.getElementById("fill_start_date").value == "") {
        alert("กรุณากรอกวันที่ยื่นคำร้องขอรับการช่วยเหลือเยียวยา");
      } else {
        var da;
        var d = document.getElementById("fill_start_date").value;
        // var dd = new Date(d)
        if (d != "") {
          da = ConvertService.createddate(d);
        } else if (d == "") {
          da = "";
        }
        // if (this.id_card == '') {
        //   this.id_card = null
        // }
        // if (this.status_id == '') {
        //   this.status_id = null
        // }
        // console.log(this.id_card);
        // console.log(da);
        CasesDataService.getCaseSuspect(this.id_card, da).then((res) => {
          // console.log(res.data);
           if (res.data.length == 0) {
            this.nodata = true
          }else{
             this.nodata = false
          this.case_id = res.data.case_id;
          this.data = res.data;
          this.getEventByCaseID()
          this.getstatus()
          // console.log(this.case_id);
          }
        });
      }
    },
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  font-family: "Kanit" !important;
}
.row {
  max-width: 1200px;
  margin: auto;
  background: white;
  padding: 10px;
  color: black !important;
}
a:visited {
  color: black;
} /* visited link */
a:hover {
  color: black;
} /* mouse over link */
a:active {
  color: black;
}
a:link {
  color: black;
}
</style>
