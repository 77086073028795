import http from "../http-common";

class SuspectsDataService {
  getSuspects(id_card) {
    return http.get('/suspect?id_card='+id_card);
  }  
  getSuspect(id) {
    return http.get(`/suspect/`+id);
  }
  createSuspect(data) {
    return http.post('/suspect', data);
  }
  updateSuspect(id,data) {
    return http.put(`/suspect/${id}`, data);
  }
  getaddresssuspect(id){
    return http.get(`/suspectaddress/getaddresssuspect/`+id);
  }
}

export default new SuspectsDataService();