import http from "../http-common";

class RelativesDataService {
  getRelatives() {
    return http.get('/relative');
  }
  getRelative(id) {
    return http.get(`/relative/`+id);
  }
  getRelativeBySuspect(id) {
    return http.get(`/relative/getrelative/`+id);
  }
  createRelative(data) {
    return http.post('/relative', data);
  }
  updateRelative(id,data) {
    return http.put(`/relative/${id}`, data);
  }
  getRelativeAddressCase(id) {
    return http.get(`/relative/getrelativeaddresscase/`+id);
  } 
  getRelativeAddress(id) {
    return http.get(`/relative/getrelativeaddress/`+id);
  }  
  getRelativeAddressTracking(id) {
    return http.get(`/relative/getrelativeaddresstracking/`+id);
  } 
  deleteRelative(id) {
    return http.delete(`/relative/`+id);
  }
  getRelativeSuspect(id) {
    return http.get(`/relative/getrelativesuspect/`+id);
  }
  getIDCardDes(id_card,case_id,relativeid) {
    return http.get(`/relative/getIDDes/`+id_card+'/'+case_id+'/'+relativeid);
  }
}

export default new RelativesDataService();