import http from "../http-common";

class DLADataService {
  getQualities(id_card,id_card_des) {
    return http.get('/dla?id_card='+id_card+'&&id_card_des='+id_card_des);
  }
  getSuspectByIDCard(id) {
    return http.get(`/dla/getidcard/`+id);
  }
  getIDSuspectQuality() {
    return http.get('/dla/getidcardsuspect');
  }
  getQuality(id) {
    return http.get(`/dla/`+id);
  }
  createQuality(data) {
    return http.post('/dla', data);
  }
  updateQuality(id,data) {
    return http.put(`/dla/${id}`, data);
  }
}

export default new DLADataService();