import http from "../http-common";

class MSocietyDetailDataService {
  getQualities(id_card,id_card_des) {
    return http.get('/m_society_detail?id_card='+id_card+'&&id_card_des='+id_card_des);
  }
  getIDSuspectQuality() {
    return http.get('/m_society_detail/getidcardsuspect');
  }
  getQuality(id,type) {
    return http.get(`/m_society_detail/`+id+'/'+type);
  }
  getDetailQuality(id,type) {
    return http.get(`/m_society_detail/getdetail/`+id+'/'+type);
  }  
  createQuality(data) {
    return http.post('/m_society_detail', data);
  }
  updateQuality(id,data) {
    return http.put(`/m_society_detail/${id}`, data);
  }
}

export default new MSocietyDetailDataService();