import http from "../http-common";

class CasesPrivilegeDataService {
  getCasePrivileged() {
    return http.get('/cases_privilege');
  }
  getCasePrivilege(id) {
    return http.get(`/cases_privilege/`+id);
  }
  getCasePrivilegeByID(id) {
    return http.get(`/cases_privilege/getcaseprivilege/`+id);
  }
  getgroupCasePrivilegeByID(id) {
    return http.get(`/cases_privilege/getgroupcaseprivilege/`+id);
  }
  getCasePrivilegeGroupEvent(id) {
    return http.get(`/cases_privilege/getcaseprivilegegroupevent/`+id);
  }
  createCasePrivilege(data) {
    return http.post('/cases_privilege', data);
  }
  updateCasePrivilege(id,data) {
    return http.put(`/cases_privilege/${id}`, data);
  }
  deleteCasePrivilege(id) {
    return http.delete(`/cases_privilege/`+id);
  }
  
  getlisthelp(id) {
    return http.get(`/cases_privilege/getlisthelp/`+id);
  }
  getlisthelpsum(id) {
    return http.get(`/cases_privilege/getlisthelpsum/`+id);
  }
}

export default new CasesPrivilegeDataService();