<template>
  <div id="app" class="" style="padding: 0">
    <!-- Navbar -->
    <nav class="navbar navbar-expand">
      <!-- Left navbar links -->
      <div class="">
        <ul class="navbar-nav">
          <div class="d-flex">
            <router-link to="/home">
              <div class="image">
                <img src="../src/assets/icon.svg" />
                 <img src="../src/assets/user.png" style="display:none" />
                 <img src="../src/assets/logo.jpg" style="display:none" />
              </div>
            </router-link>

            <router-link to="/home">
              <div class="title">
                <span style="color: white"
                  >ศูนย์อำนวยการบริหารจังหวัดชายแดนภาคใต้</span
                ><br />
                <span style="color: white"
                  >Southern Border Provinces Administrative Centre</span
                >
              </div>
            </router-link>
          </div>
        </ul>
      </div>
      <div class="collapse navbar-collapse">
        <!-- Right navbar links -->
        <ul class="navbar-nav ml-auto" style="width: 150px">
          <!-- Navbar Search -->
          <!-- Notifications Dropdown Menu -->
          <li class="nav-item dropdown" v-if="currentUser && currentUser.role_id != 6">
            <a class="nav-link" data-toggle="dropdown" href="#">
              <i class="far fa-bell fa-lg" style="color: white"></i>
              <span class="badge badge-warning navbar-badge">{{
                noti.length
              }}</span>
            </a>
            <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
              <span class="dropdown-item dropdown-header"
                >{{ noti.length }} แจ้งเตือน</span
              >
              <div v-for="(n, i) in noti" :key="i">
                <div class="dropdown-divider"></div>
                <a
                  v-if="currentUser.role_id == 2"
                  @click="updateread(n.notify_id)"
                  :href="'/newtreatment/' + n.case_id + '/1'"
                  class="dropdown-item"
                >
                  <i class="fas fa-file mr-2"></i>case {{ n.case_id }}
                  <span class="float-right text-muted text-sm">{{
                    convertdatetothai(n.updated_date)
                  }}</span>
                </a>
                <a
                  v-if="currentUser.role_id == 3"
                  @click="updateread(n.notify_id)"
                  :href="'/caseinfo/' + n.case_id"
                  class="dropdown-item"
                >
                  <i class="fas fa-file mr-2"></i>case {{ n.case_id }}
                  <span class="float-right text-muted text-sm">{{
                    convertdatetothai(n.updated_date)
                  }}</span>
                </a>
              </div>
              <div class="dropdown-divider"></div>
              <a href="#" class="dropdown-item dropdown-footer">แสดงทั้งหมด</a>
            </div>
          </li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <li
            class="nav-item dropdown"
            v-if="currentUser"
            style="margin-right: 100px"
          >
            <a
              class="nav-link dropdown-toggle"
              style="color: white"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {{ user.firstname }}
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li>
                <router-link to="/profile">
                  <a class="dropdown-item" href="#"
                    >ข้อมูลผู้ใช้งาน</a
                  ></router-link
                >
              </li>
              <li>
                <a class="dropdown-item" @click.prevent="logOut" href="#"
                  >ออกจากระบบ</a
                >
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a
              v-if="!currentUser"
              class="nav-link"
              data-widget="control-sidebar"
              data-slide="true"
              href="#"
              role="button"
            >
              <router-link to="/login">
                <i class="fas fa-sign-in-alt" style="color: white">
                  เข้าสู่ระบบ</i
                ></router-link
              >
            </a>
          </li>
        </ul>
      </div>
    </nav>
    <!-- /.navbar -->
    <!-- Main Sidebar Container -->

    <div style="float: center">
      <router-view />
    </div>

    <footer class="mt-5" style="background-color: #3e4041">
      <!-- Footer main -->
      <div>
        <img style="width: 250px" src="./assets/title.png" />
      </div>
      <!-- Footer social -->
      <div style="margin-top: 10px">
        <span style="color: #f6b60e"
          >ศูนย์อำนวยการบริหารจังหวัดชายแดนภาคใต้</span
        >

        <br />
        <img
          class="social"
          style="margin-top: 10px"
          src="./assets/contact.png"
        />
        <br />

        <img style="width: 100px; margin-top: 10px" src="./assets/social.png" />
      </div>

      <!-- Footer legal -->
      <div>
        <hr style="color: #09cc68" />
        <span style="color: white; font-size: 0.7rem"
          >สงวนลิขสิทธิ์ © 2022 ศูนย์อำนวยการบริหารจังหวัดชายแดนภาคใต้</span
        >
      </div>
    </footer>
  </div>
</template>

<script>
import AuthService from "./services/auth/auth.service";
import NotifyDataService from "./services/NotifyDataService";
import HistoryCaseDataService from "./services/HistoryCaseDataService";

export default {
  name: "app",
  components: {},
  data() {
    return {
      user: "",
      noti: [],
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    logOut() {
      var data={
            history_status_id:2,
            user_id:this.currentUser.user_id
          }
          HistoryCaseDataService.createHistory(data).then(()=>{

          })
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
    convertdatetothai(datetimes) {
      if (datetimes) {
        const date = new Date(datetimes);
        const result = date.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return result;
      } else {
        return "-";
      }
    },
    updateread(notify_id) {
      var read = {
        is_read: true,
        created_by: this.currentUser.user_id,
        updated_by: this.currentUser.user_id,
      };
      NotifyDataService.updateRead(notify_id, read).then(() => {
        // console.log(res.data);
      });
    },
  },
  mounted() {
    // console.log(this.currentUser);
    if (this.currentUser) {
      AuthService.getname(this.currentUser["user_id"]).then(
        (response) => {
          this.user = {
            firstname: response.data.firstname,
          };
          if (response.data.role_id == 3) {
            NotifyDataService.getnotiByRoleID(response.data.user_id).then(
              (res) => {
                this.noti = res.data;
                // console.log(res.data);
              }
            );
          } else if (response.data.role_id == 2) {
            NotifyDataService.getnotiByUserID(response.data.user_id).then(
              (res) => {
                this.noti = res.data;
                // console.log(res.data);
              }
            );
          }
          // console.log(response.data);
        },
        (error) => {
          console.log(error);
        }
      );
    }
  },
};
</script>

<style>
.navbar {
  background-color: #09cc68;
}
.header {
  padding: 5px;
  background-color: #09cc68;
  color: white;
}
.text {
  font-size: 15px;
  /* min-height: 1em; */
  margin: 5px;
  vertical-align: middle;
}
footer {
  /* display: grid; */
  text-align: center;
  padding: 30px;
  background: #3e4041;
}
@media only screen and (min-width: 280px) {
  /* For tablets: */
  img {
    width: 30px;
  }
  .title {
    font-size: 0.5rem;
    margin: 2px;
  }
  .social {
    width: 300px;
  }
}
@media only screen and (min-width: 375px) {
  /* For tablets: */
  img {
    width: 30px;
  }
  .title {
    font-size: 0.7rem;
    margin: 2px;
  }
  .social {
    width: 320px;
  }
}
@media only screen and (min-width: 600px) {
  /* For tablets: */
  img {
    width: 50px;
  }
  .title {
    font-size: 0.8rem;
    margin: 12px;
  }
  .social {
    width: 400x;
  }
}
@media only screen and (min-width: 768px) {
  /* For desktop: */
  img {
    width: 80px;
  }
  .title {
    font-size: 1rem;
    margin: 12px;
  }
  .social {
    width: 600px;
  }
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0rem !important;
  padding-left: 0rem !important;
}
</style>
