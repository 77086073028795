import http from "../http-common";

class ChatDataService {
  getTrackings(id_card,id_card_des) {
    return http.get('/tracking_detail?id_card='+id_card+'&&id_card_des='+id_card_des);
  }
  getRooms() {
    return http.get('/rooms');
  }
  getMessage(id) {
    return http.get(`/messages/`+id);
  }
  getRoom(id) {
    return http.get(`/rooms/`+id);
  }
  createRoom(data) {
    return http.post('/rooms', data);
  }
  updateRoom(id,data) {
    return http.put(`/rooms/${id}`, data);
  }
  createMessage(data) {
    return http.post('/messages', data);
  }
  createMessageFile(data) {
    return http.post('/messages_files', data);
  }
  getMessageFile(id) {
    return http.get(`/messages_files/`+id);
  }
  getDetailTracking(id,type) {
    return http.get(`/tracking_detail/getdetail/`+id+'/'+type);
  }  
  createTracking(data) {
    return http.post('/tracking_detail', data);
  }
  updateTracking(id,data) {
    return http.put(`/tracking_detail/${id}`, data);
  }
  getlastmessage(id) {
    return http.get(`/messages/getlastmessage/`+id);
  } 
  getuserinroom(id) {
    return http.get(`/messages/getuserinroom/`+id);
  } 
  updateRoomSupport(id,data) {
    return http.put(`/rooms/updatesupport/${id}`, data);
  }
}

export default new ChatDataService();