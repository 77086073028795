import http from "../http-common";

class HistoryCaseDataService {
  createHistoryCase(data) {
    return http.post('/history_case', data);
  }
  updateeHistoryCase(id,data) {
    return http.put(`/history_case/${id}`, data);
  }
  getHistoryCaseByStatusID(id,status) {
    return http.get('/history_case/status/' + id + '/'+status);
  }
  getHistoryCaseByCaseID(id) {
    return http.get('/history_case/' + id);
  }
  createHistory(data) {
    return http.post('/history', data);
  }
}

export default new HistoryCaseDataService();